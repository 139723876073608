import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    PatientTitle: {
        marginTop: 36,
        paddingBottom: 3,
        fontStyle: "bold",
        fontFamily: "Helvetica-Oblique",
    },
    table: {
        display: "table",
        width: "auto",
        /* borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0  */
    },
    tableColHeader: {
        
        //textAlign: 'center',
        fontStyle: 'bold',
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "20%",
        backgroundColor: '#606060',
        color:"white"
        /* borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0  */
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    },
    tableColComment: {
        width: "80%",
        borderStyle: "solid",
        //margin: "10",
        //borderWidth: 1, 
        //borderLeftWidth: 1, 
        //borderTopWidth: 1,
        paddingBottom: '20',
    },
    tableCellCommentTitle: {
        //margin: "auto",
        marginTop: 10,
        fontSize: 12,
        textAlign: 'left'
    },
    tableCellComment: {
        //margin: "auto",
        marginTop: 10,
        fontSize: 10,
        textAlign: 'justify',
    },
    tableLastRow: {
        width: "100%",
        height: "10",
        backgroundColor: '#606060',
        //borderStyle: "solid", 
        //margin: "10",
        //borderWidth: 1, 
        //borderLeftWidth: 1, 
        //borderTopWidth: 1,
        paddingBottom: '20',
    },
    tableCellLastRow: {
        margin: "auto",
        marginTop: 5,
        fontSize: 4
    },
});

const comment = "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo"

function ReportFindingsTable({ReportStudy}) {
    return (
        <><Text style={styles.PatientTitle}>Findings:</Text><View style={styles.table}>
            <View style={styles.tableRow} >
                {["No.", "Finding Name", "Width", "Height", "Area"].map((Item, index) => (
                    <View style={styles.tableColHeader}  key={index} >
                        <Text style={styles.tableCell}>{Item}</Text>
                    </View>))}
            </View>


            {ReportStudy["findings"].map((Item, index) => (<>
            <View style={styles.tableRow} key={index} >
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{index+1}</Text>
                    </View>
            
            
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{Item.structureReporting.name}</Text>
                    </View>
            
            
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{Item.data.width}</Text>
                    </View>
            
            
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{Item.data.height}</Text>
                    </View>
           
            
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{Item.data.area}</Text>
                    </View>
                    </View>
                    <View style={styles.tableRow} >    
                        <View style={styles.tableColComment} >
                            <Text style={styles.tableCellCommentTitle}>Finding description:</Text>
                            <Text style={styles.tableCellComment} >{Item.structureReporting.description}</Text>
                        </View>
                    </View>
            
            
            {/* <View style={styles.tableRow} >
                {["No.", "Finding Description", "Width", "Height", "Area"].map((Item) => (
                    <View style={styles.tableCol} >
                        <Text style={styles.tableCell} >{No}</Text>
                    </View>))}

            </View>
            <View style={styles.tableRow}>
                {["Comments"].map((Item) => (
                    <View style={styles.tableColComment}>
                        <Text style={styles.tableCellComment}>{comment}</Text>
                    </View>))}


            </View> */}

           {/*  <View style={styles.tableRow}>
                      <View style={styles.tableLastRow}>
                          <Text style={styles.tableCellLastRow}></Text>
                      </View>
            </View> */}

                </>))}
        </View></>
    )
}

export default ReportFindingsTable