export default function getStudyInfoExternal(id){
    console.log("from getStudyInfoExternal", id)
    const values = {
       "id": id}

    console.log("getStudyInfoExternal", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.api_anonymization_load, requestOptions)

}
