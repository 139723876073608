//React Components
import * as React from 'react';
// MUI Components
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// Custom components
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";



function stringAvatar(name) {
  const initials=name.split(' ')[0][0] + name.split(' ')[1][0]
  return initials;
}

export default function LetterAvatars({FullName}) {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")

  const styles = {
    textcolor:{
      color: colors.dark_grey[500]
    },
    avatarStyles:{
      width: 24, 
      height: 24,
      backgroundColor:colors.greenFlow[100]
    }
  }

  return (
      <Avatar 
        sx={styles.avatarStyles}>
        <Typography sx={[themeStyles.typography.h7 , styles.textcolor]}>
        {stringAvatar(FullName)}
          </Typography>
      </Avatar>

  );
}