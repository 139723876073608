import { createSlice} from '@reduxjs/toolkit'



export const notificationSlice= createSlice({
    name:"notifications",
    initialState: {
        notifications: [],
        status: ""
      },
     reducers:{
        addNotification:(state,action)=>{
            //console.log("notifications payload", action.payload)
            state.notifications = action.payload
        }
     }
})


export const { addNotification } = notificationSlice.actions;
const notificationsReducer =  notificationSlice.reducer;
export default notificationsReducer;
