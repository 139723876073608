import React, { useEffect, useState } from 'react';
import axios from 'axios';

const NewUploadFile = ({file}) => {
  //const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const source = axios.CancelToken.source();

    useEffect(() => {
      handleUpload()
    }, [])
    
  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file.');
      return;
    }

    try {
      setUploading(true);
      setMessage('');

      const formData = new FormData();
      formData.append('file', file);

      const url = global.config.api_fileupload;

      const options = {
        onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent;
          let percent = Math.floor( (loaded * 100) / total )
          console.log( `${loaded}kb of ${total}kb | ${percent}%` );
  
        //   if( percent < 100 ){
        //     this.setState({ uploadPercentage: percent })
        //   }
        }
      }
  
      axios.post(url, formData, options).then(res => { 
          console.log(res)
        //   this.setState({ avatar: res.data.url, uploadPercentage: 100 }, ()=>{
        //     setTimeout(() => {
        //       this.setState({ uploadPercentage: 0 })
        //     }, 1000);
        //  })
      })
    }



    //   const response = await axios.post(url, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //     onUploadProgress: (progressEvent) => {
    //       const uploadPercentage = Math.round(
    //         (progressEvent.loaded * 100) / progressEvent.total
    //       );
    //       setProgress(uploadPercentage);
    //       console.log('Upload Progress:', uploadPercentage);
    //     },
    //     cancelToken: source.token,
    //   });

    //   setMessage('File uploaded successfully!');
    //   console.log('File uploaded successfully!');
    // } 
    catch (error) {
      if (axios.isCancel(error)) {
        setMessage('Upload cancelled.');
        console.log('Upload cancelled.');
      } else {
        setMessage('File upload failed.');
        console.error('Error uploading file:', error);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleCancel = () => {
    source.cancel('Upload cancelled by user.');
  };

  return (
    <div>
      {/* <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={uploading}>
        Upload
      </button> */}
      <button onClick={handleCancel} disabled={!uploading}>
        Cancel
      </button>
      <p>Progress: {progress}%</p>
      <p>{message}</p>
    </div>
  );
};

export default NewUploadFile;
