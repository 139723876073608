export const aisampledata=[
    {
        "General":{
        "Productname": "NeoMediSys",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany",
            "Email": "Contact@NeoMediSys.com"        
        },
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"MG",
        "Subspeciality":"BREAST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "License":{
            "Type":"Research",

        },
        "Certification":{
            "CE":{
                "granted":true,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "Version":"1.0.0",
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
            "Results":{
                "Diseasetargeted":"Breast Cancer"
        }       
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "Smart Lungs",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"CT",
        "Subspeciality":"BRAIN",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":false,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "Prostate AI",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"France"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"X-RAY",
        "Subspeciality":"CHEST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":false,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "DeepLook",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany",
            "Email": "Contact@NeoMediSys.com"        
        },
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"MG",
        "Subspeciality":"BREAST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "License":{
            "Type":"Research",

        },
        "Certification":{
            "CE":{
                "granted":true,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "Version":"1.0.0",
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
            "Results":{
                "Diseasetargeted":"Breast Cancer"
        }       
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "Smart Lungs",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"CT",
        "Subspeciality":"BRAIN",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":false,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "NeoMediSys3",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"France"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"X-RAY",
        "Subspeciality":"CHEST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":false,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "NeoMediSys",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany",
            "Email": "Contact@NeoMediSys.com"        
        },
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"MG",
        "Subspeciality":"BREAST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "License":{
            "Type":"Research",

        },
        "Certification":{
            "CE":{
                "granted":true,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "Version":"1.0.0",
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
            "Results":{
                "Diseasetargeted":"Breast Cancer"
        }       
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "NeoMediSys2",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"Germany"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"CT",
        "Subspeciality":"BRAIN",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":true,
                "MDD":false
            },
            "FDA":{
                "granted":false,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    },
    {
        "General":{
        "Productname": "NeoMediSys3",
        "manufacturer": {
            "Name":"NeoMediSys AI",
            "Address":"",
            "Country":"France"        
        },
        "License":"Free",
        "Logo":"https://appstore-public-images.s3.eu-central-1.amazonaws.com/AILogo.png",
        "Modality":"X-RAY",
        "Subspeciality":"CHEST",
        "description":"The NeoMediSys AI, an innovative medical companion, utilizes advanced neural networks and machine learning algorithms to analyze complex medical data with precision and speed. Integrated into hospital systems, it efficiently interprets diagnostic images, provides predictive analytics for disease progression, and offers personalized treatment recommendations. Its adaptive nature continuously learns from vast medical databases, ensuring up-to-date insights and supporting healthcare professionals in decision-making. With its intuitive interface and ethical framework, NeoMediSys AI stands as a reliable ally in revolutionizing patient care and optimizing medical outcomes.",
        "highlights":[
            "Fast triaging", "Super accurate results", "95% Acuracy",
        ]
        },
        "Certification":{
            "CE":{
                "granted":false,
                "class":"Class IIb",
                "MDR":false,
                "MDD":false
            },
            "FDA":{
                "granted":true,
                "510(k)":true,
                "class":"Class II",
            },
        },
        "AIDetails":
        {
            "TrainingDataVolume":200000,
            "Accuracy%":95,
            "AItype":"Classify",
            "FindingLevel":"main",
        },
        "Results":{
            "Diseasetargeted":"Breast Cancer"
        },
        "Evidence":[
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            },
            {
                "Type": "Peer reviewed papers on performance",
                "Title":"Automated prostate multi-regional segmentation in magnetic resonance using fully convolutional neural networks",
                "Link":"https://doi.org/10.1007/s00330-023-09410-9"
            }
        ]
    }
]