import { Zoom } from "../../../../../utilityComponents/images/viewerIcons";
import TonalityOutlinedIcon from '@mui/icons-material/TonalityOutlined';
import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';

export const ImageActions =[
    {
        icon: <Zoom fontSize="small" />,
        name:"Zoom",        
        shortcut:"Z",
        functionname: ()=> global.viewportsCore.setMouseButtonFunction(1, 2)
    },
    {
      icon: <TonalityOutlinedIcon fontSize="small" />,
      name:"Windowing/Leveling",        
      shortcut:"1/6 ",
      functionname:()=>  global.viewportsCore.setMouseButtonFunction(1, 1)
  },
    {
        icon: <Rotate90DegreesCcwOutlinedIcon fontSize="small" />,
        name:"Rotate",        
        shortcut:"R",
        functionname: ()=>viewportsCore.getActiveViewport().applyRightRotation()
    },
    {
        icon: <PanToolAltOutlinedIcon fontSize="small" />,
        name:"Pan",        
        shortcut:"P",
        functionname: ()=> global.viewportsCore.setMouseButtonFunction(1, 3)
    },
    {
        icon: <SwipeVerticalIcon fontSize="small" />,
        name:"Pan",        
        shortcut:"P",
        functionname: ()=> global.viewportsCore.setMouseButtonFunction(1, 4)
    },
    {
        icon: <FlipOutlinedIcon fontSize="small" />,
        name:"Vertical Flip",        
        shortcut:"F",
        functionname:()=> viewportsCore.getActiveViewport().applyHorizontalFlip()
    },

]
 