import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import BackgroundLetterAvatars from './avatarSender';
import { tokens, themeSettings } from '../../../utilityComponents/theme';
import { Typography } from '@mui/material';

const ChatMsg = (props) => {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark");

    const {
        avatar,
        messages,
        Name,
        time,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        TypographyProps,
        ChatTypographyProps,
        getTypographyProps,
    } = props;

    const date = new Date("2023-03-17 13:32:56.857862+00");

    const StyledGrid = styled(Grid)(({ theme }) => ({
        margin: "10px 0px 30px 00px",
        padding: "0px",
        backgroundColor: side === 'right' ? colors.grey[700] : colors.grey[500],
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: side === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'center',
        '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: side === 'right' ? 'flex-end' : 'flex-start',
        },
        '& .MuiTypography-root': {
            ...themeStyles.typography.h61,
            color: colors.grey[100],
            whiteSpace: 'pre-line',
        },
    }));

    return (
        <StyledGrid
            container
            {...GridContainerProps}
        >
            {/* Left Avatar */}
            {side === 'left' && (
                <Grid item {...GridItemProps} xs={1}>
                    <BackgroundLetterAvatars Name={Name} />
                </Grid>
            )}

            {/* Messages */}
            <Grid item xs={11}>
                <Grid container>
                    <Grid item xs={12}>
                        {messages.map((msg, i) => (
                            <Typography
                                key={i}
                                align={'left'}
                                paragraph
                            >
                                {msg}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            align={side === 'right' ? 'right' : 'left'}
                        >
                            {date.toString()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Right Avatar */}
            {side === 'right' && (
                <Grid item {...GridItemProps} xs={1}>
                    <BackgroundLetterAvatars Name={Name} />
                </Grid>
            )}
        </StyledGrid>
    );
};

ChatMsg.propTypes = {
    avatar: PropTypes.string,
    Name: PropTypes.string,
    time: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
    side: PropTypes.oneOf(['left', 'right']),
    GridContainerProps: PropTypes.object,
    GridItemProps: PropTypes.object,
    AvatarProps: PropTypes.object,
    getTypographyProps: PropTypes.func,
};

ChatMsg.defaultProps = {
    avatar: '',
    Name: '',
    time: '',
    messages: [],
    side: 'left',
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};

export default ChatMsg;


// import React from 'react';
// import PropTypes from 'prop-types';
// import cx from 'clsx';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import Typography from '@mui/material/Typography';
// import { ColorModeContext, tokens, themeSettings } from '../../../utilityComponents/theme'

// import { withStyles } from '@mui/styles';
// import defaultChatMsgStyles from './defaultChatMsgStyles';
// import BackgroundLetterAvatars from './avatarSender';



// const ChatMsg = withStyles(defaultChatMsgStyles, { name: 'ChatMsg' })(props => {
//     const colors = tokens("dark");
//     const themeStyles = themeSettings("dark")
    

//     const {
//         classes,
//         avatar,
//         messages,
//         Name,
//         time,
//         side,
//         GridContainerProps,
//         GridItemProps,
//         AvatarProps,
//         TypographyProps,
//         ChatTypographyProps,
//         getTypographyProps,
//     } = props;
//     const date = new Date("2023-03-17 13:32:56.857862+00")
//     const attachClass = index => {
//         if (index === 0) {
//             return classes[`${side}First`];
//         }
//         if (index === messages.length - 1) {
//             return classes[`${side}Last`];
//         }
//         return '';
//     };
//     return (
// /*         <Box margin={"10px 0px 30px 00px"} 
//         sx={{borderRadius:"10px"}}> */
//             <Grid
//                 container
//                 margin={"10px 0px 30px 00px"}
//                 spacing={2}
//                 justify={side === 'right' ? 'flex-end' : 'flex-start'}
//                 alignItems={"center"}
//                 display={"flex"}
//                 {...GridContainerProps}
//                 width='inherit'
//                 backgroundColor={side === 'right' ? colors.grey[700] : colors.grey[500]}
//             >
//                 {side === 'left' && (
//                     <Grid item {...GridItemProps} xs={1}>
//                         {/*           <Avatar
//             src={avatar}
//             {...AvatarProps}
//             className={cx(classes.avatar, AvatarProps.className)}
//           /> */}

//                         <BackgroundLetterAvatars Name={Name} />
//                     </Grid>
//                 )}

//                 <Grid item xs={11}>
//                     < Grid container>
//                         <Grid item xs={12} 
//                         display={"flex"}
//                         justifyContent={side === 'left' ?  'flex-start':'flex-end' } 
//                         >
//                             {messages.map((msg, i) => {
//                                 const TypographyProps = getTypographyProps(msg, i, props);
//                                 return (
//                                      <Typography
//                                         align={'left'}
//                                         sx={themeStyles.typography.h61}
//                                         color={colors.grey[100]}
//                                         paragraph={"true"}
//                                         style={{whiteSpace: 'pre-line'}}
//                                     >
//                                         {msg}
//                                     </Typography>
                            
//                                 );
//                             })}
//                         </Grid>

//                         <Grid item xs={12} 
//                         display={"flex"}
//                         justifyContent={side === 'right' ?  'flex-start':'flex-end' } 
//                         alignItems={"end"}
//                         >
//                             <Typography
//                                 //marginBottom={"40px"}
//                                 sx={themeStyles.typography.h9}
//                                 color={colors.grey[100]}
//                                 paragraph={"true"}
//                                 align={side === 'right' ?  'right':'left' } 
//                             >
//                                {date.toString()}
//                             </Typography>
//                         </Grid>


//                     </Grid>
//                 </Grid>


//                 {side === 'right' && (
//                     <Grid item justifyContent={'flex-end'}
//                         display={"flex"}

//                         {...GridItemProps} xs={1}>
//                         <BackgroundLetterAvatars Name={Name} />
//                     </Grid>
//                 )}


//             </Grid>
//         // </Box>
//     );
// });

// ChatMsg.propTypes = {
//     avatar: PropTypes.string,
//     Name: PropTypes.string,
//     time: PropTypes.string,
//     messages: PropTypes.arrayOf(PropTypes.string),
//     side: PropTypes.oneOf(['left', 'right']),
//     GridContainerProps: PropTypes.shape({}),
//     GridItemProps: PropTypes.shape({}),
//     AvatarProps: PropTypes.shape({}),
//     getTypographyProps: PropTypes.func,
// };
// ChatMsg.defaultProps = {
//     avatar: '',
//     Name: '',
//     time: '',
//     messages: [],
//     side: 'left',
//     GridContainerProps: {},
//     GridItemProps: {},
//     AvatarProps: {},
//     getTypographyProps: () => ({}),
// };

// export default ChatMsg;