import React from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { Dialog, DialogContent, Paper, Typography, DialogTitle,DialogActions,Button } from '@mui/material';
import { styled } from '@mui/system';
import { themeSettings, tokens } from '../../../utilityComponents/theme';

// const ResizableDialog = styled(Dialog)(({ theme }) => ({
//   // position: 'relative',
//   '& .react-resizable-handle': {
//     position: 'absolute',
//     width: 20,
//     height: 20,
//     background: 'rgba(0,0,0,0.1)',
//     bottom: 0,
//     right: 0,
//     cursor: 'se-resize',
//   },
//   '& .MuiPaper-root': {
//     //background: 'none',
//     boxShadow: 'none',
//   },
// }));


export const DraggableResizableDialog = ({ open, onClose, title, children,size,disableEscapeKeyDown }) => {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(size);
// Possible sizes depending on the application
//xs, sm,md,ls,xl

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Draggable handle="#dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
          BackdropProps={{ invisible: true }}
          open={open} 
          disableEscapeKeyDown={disableEscapeKeyDown}
          //onClose={onClose} 
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          sx={{
            '& .MuiDialog-paper': {
              height: "75.88%",
              borderRadius: "8px"
            },
          }}
          scroll = {"paper"}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Typography sx={themeStyles.typography.h411}>{title}</Typography>
          </DialogTitle>


          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
          <Button onClick={onClose}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Close</Typography>
          </Button>
          
        </DialogActions>

        </Dialog>
      {/* </Resizable> */}
    </Draggable>
  );
};

