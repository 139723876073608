import React from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { tokens, themeSettings } from "../../../utilityComponents/theme";

const SignupSchema = yup.object().shape({
  title: yup.string(),
  firstname: yup.string().required("First Name is required").nullable(false),
  lastname: yup.string().required("Last Name is required").nullable(false),
  username: yup.string().required("Username is required").nullable(false),
  email: yup.string().email("Invalid email").required("Email is required").nullable(false),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  passwordconfirm: yup
    .string()
    .nullable(false)
    .required("Password confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      size="small"
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

const titleOptions = [
  { value: "", label: "" },
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
  { value: "M.D.", label: "M.D." },
  { value: "OA.", label: "OA." },
  { value: "FA.", label: "FA." },
  { value: "Dr.", label: "Dr." },
  { value: "Dr. med.", label: "Dr. med." },
  { value: "Prof.", label: "Prof." },
];

export const SignupForm = ({ handleSignUP, loading }) => {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Formik
          initialValues={{
            title: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            password: "",
            passwordconfirm: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("signedUp Values", values);
            handleSignUP(values);
            setSubmitting(false);
          }}
        >
          {({ errors, touched, handleChange, handleBlur, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Title</InputLabel>
                    <Field
                      name="title"
                      as={Select}
                      size="small"
                      label="Title"
                    >
                      {titleOptions.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Field
                    as={OutlinedInput}
                    error={errors.firstname && touched.firstname}
                    autoComplete="fname"
                    name="firstname"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="First Name"
                  />
                  <ErrorMessage name="firstname" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Field
                    as={OutlinedInput}
                    error={errors.lastname && touched.lastname}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Last Name"
                    name="lastname"
                    autoComplete="lname"
                  />
                  <ErrorMessage name="lastname" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={OutlinedInput}
                    error={errors.username && touched.username}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Username"
                    name="username"
                    autoComplete="username"
                  />
                  <ErrorMessage name="username" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={OutlinedInput}
                    error={errors.email && touched.email}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      const lowercaseEmail = e.target.value.toLowerCase();
                      handleChange({
                        target: {
                          name: 'email',
                          value: lowercaseEmail,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                  <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={OutlinedInput}
                    error={errors.password && touched.password}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={OutlinedInput}
                    error={errors.passwordconfirm && touched.passwordconfirm}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="passwordconfirm"
                    placeholder="Password Confirmation"
                    type="password"
                    autoComplete="current-passwordconfirm"
                  />
                  <ErrorMessage name="passwordconfirm" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isValid}
                    loading={loading}
                    sx={{
                      backgroundColor: colors.greenFlow[100],
                      ":hover": {
                        backgroundColor: colors.greenFlow[100],
                      },
                    }}
                    size="large"
                  >
                    <Typography sx={themeStyles.typography.h5} color={"white"}>
                      Sign Up
                    </Typography>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};
