  export const ReportInformation = {
    userName:"Dr. Markus Müller",
  
    ReportNo:"201906-28",
    local_patReportientID: "CHEST 81678",
    PatientName: "FARG MOHAMED MOHAMED",
    PatientSex: "M",
    PatientAge: "055Y",
    PatientBirthdate: "19651207",
    StudyInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120621155870700000367",
    SeriesInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120712354943800000482",
    SOPInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120712354943800000838",
    SeriesDate: "20201207",
    SeriesTime: "124805.598000",
    BodyExaminationPart: "CHEST",
    Modality: "CT",
    ViewPosition: "",
    ImageLaterality: "",
    AccessionNumber: "",
    InstitutionName: "El Salam Royal Hospital",
    ReferringPhysicianName: "",
    StationName: "CT112500",
    StudyDescription: "Chest Without_Royal(Adult)",
    InstanceOrigin: "REST_API",
    additional: {},
    organisationid: "f1d61c5a-1951-49b3-abbf-a3d59f169418",
    mainfinding:"En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
    findings:[
      {
        "No":1,
        "description":"Lesion",
        "Width":"30mm",
        "Height":"15mm",
        "Area":"25mm2",
        "comments":"En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo", 
      },
      {
        "No":2,
        "description":"Lesion2",
        "Width":"30mm",
        "Height":"15mm",
        "Area":"25mm2",
        "comments":"En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo", 
      },
      {
        "No":3,
        "description":"Lesion3",
        "Width":"30mm",
        "Height":"15mm",
        "Area":"25mm2",
        "comments":"En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo", 
      },
    ],
    AdditionalFindings:"En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo", 
   
  };
