import * as React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ColorModeContext, tokens, themeSettings } from "../../../../../utilityComponents/theme";
import { Box, Button, CssBaseline, Divider, Grid, IconButton, Menu, MenuItem, SvgIcon, Tooltip, useTheme } from "@mui/material";
import { PDFViewer, pdf, BlobProvider, PDFDownloadLink, usePDF, Page, Document, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import ReportDocument from './ReportPDF'
import ReportInformation from './ReportInformation'
import { SendPDFAPI } from '../../../../../functions/api/sendReport/sendPDF';
import ReportTitle from './ReportTitle'
import ReportNo from './ReportNo'
import PatientInformation from './PatientInformation';
import Footer from './Reportfooter';
import Header from './ReportHeader';
import ReportMainFindings from './ReportMainFindings';
import ReportFindingsTable from './ReportFindingsTable';
import ReportFinalComments from './ReportFinalComments';
import ReportSignature from './ReportSignature';
import { AssignCase2User } from '../../../../../functions/api/diagnosing/diagnosing';
import { useSelector } from 'react-redux';


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    //backgroundColor: 'rgba(196, 196, 196, 0.3)',
    backgroundColor: 'transparent',

    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "72.50%",
    height: "66.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   },
//   noticeLabel: {
//     color: colors.text[400],
//     fontStyle: "SemiBold"
//   },

// })


//
//
// Components STYLINGs END
//
//
//

//const Report = ReportInformation

// const styles = StyleSheet.create({
//   body: {
//     fontFamily: 'Helvetica',
//     fontSize: 11,
//     lineHeight: 1.5,
//     paddingTop: 35,
//     paddingBottom: 65,
//     paddingHorizontal: 35,
//     paddingLeft: 60,
//     paddingRight: 60,
//   },
// });
// const styles = StyleSheet.create({
//   body: {
//     fontFamily: "Helvetica",
//     fontSize: 11,
//     lineHeight: 1.5,
//     paddingTop: 35,
//     paddingBottom: 65,
//     paddingHorizontal: 35,
//     paddingLeft: 60,
//     paddingRight: 60,
//   },
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
//   ImageContainer: {
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     marginBottom: 10,
//   },
//   header: {
//     fontSize: 12,
//     marginBottom: 20,
//     textAlign: "center",
//     color: "grey",
//   },
//   logo: {
//     width: 74,
//     height: 74,
//     marginLeft: 400,
//     marginRight: "auto",
//   },
//   PatientTitle: {
//     fontSize: 14,
//     marginTop: 36,
//     paddingBottom: 3,
//     fontStyle: "bold",
//     fontFamily: "Helvetica-Oblique",
//   },
// });



export default function ReportPDFForm({ open, ReportStudy, currentStudy, Measurements, handleCancel, Report, user }) {
  //const stylingClasses = useStyles()

  // const MyDoc = (
  //   <Document
  //     title="MedicalReport"
  //     author={user["userFullName"]}
  //     creator="NOVU AI"
  //   >
  //     <Page size="A4" style={styles.body} >
  //       <Header />
  //       <ReportTitle title='Medical Report' />
  //       <ReportNo ReportStudy={ReportStudy} user={user} />
  //       <PatientInformation ReportStudy={ReportStudy} />
  //       <Text style={styles.PatientTitle}>Main Finding:</Text>
  //       <Text style={styles.PatientTitle}>{ReportStudy?.report?.raw ? ReportStudy.report.raw : ""}</Text>
  //       <ReportFindingsTable ReportStudy={ReportStudy} />
  //       <ReportSignature ReportStudy={ReportStudy} user={user} />
  //       <Footer />
  //     </Page>
  //   </Document>
  // )


  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });


   const handleSign = async () => {

  //   console.log("SIGN")
  //   pdf(MyDoc).toBlob()
  //     .then(result => {
  //       //console.log("Report PDF",result)
  //       blobToBase64(result)
  //         .then(result => {
  //           //console.log("Report PDF base64",result)
  //           SendPDFAPI(result, currentStudy, user, Measurements)
  //             .then(response => response.text())
  //             .then(results => {
  //               if (results === "200") {
  //                 console.log("response from API", JSON.parse(results))
  //                 //change case status to closed
  //                 changeCaseStatus()
  //                 handleCancel()
  //               }
  //               console.log("response from API2", JSON.parse(results))

  //             })

  //         })
  //     })


   }

  const handleText = async () => {
    console.log("handleText")
  }

  // Define styles
  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: 'row',
  //     backgroundColor: '#E4E4E4'
  //   },
  //   section: {
  //     margin: 10,
  //     padding: 10,
  //     flexGrow: 1
  //   }
  // });



  const changeCaseStatus = () => {
    //AssignCase2User(Item, JSON.parse(localStorage.getItem('user')),"Reported")
    AssignCase2User(currentStudy.studyUid, user.userID, "", "Reported")
      .then(response => response.text())
      .then(results => {
        if (results === "200") {
          console.log("response from API: AssignCase2User", JSON.parse(results))
        }
      })
  }


  return (

    <div>

      <BootstrapDialog
        hideBackdrop={"true"}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"85%"}
      >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          <Box width={"94%"} justifyContent={"space-between"} display={"flex"}>
            <Typography sx={themeStyles.typography.h411}>Medical Report </Typography>
          </Box>
        </BootstrapDialogTitle>


        <DialogContent>
          <PDFViewer width="100%" height="500" className="app" >
            <ReportDocument ReportStudy={ReportStudy} user={user} />
          </PDFViewer>




          <Typography
            sx={[themeStyles.typography.h6, { color: colors.text[400], fontStyle: "SemiBold" }]}
          //sx={themeStyles.typography.h71} className={stylingClasses.noticeLabel}
          >
            <InfoOutlinedIcon fontSize={"small"} sx={{ color: colors.text[400] }} /> Your report will be officially signed only if sent back to the PACS server. Once signed no changes can be applied.
          </Typography>


        </DialogContent>


        {/* <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
          </Button>
          <Button onClick={handleText} endIcon={<SendIcon sx={{color:colors.greenFlow[100]}} />} >
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Copy as Text</Typography>
          </Button>
          <Button onClick={handleSign} endIcon={<SendIcon sx={{color:colors.greenFlow[100]}} />} >
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Sign</Typography>
          </Button>
        </DialogActions> */}

        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, { color: colors.text[600] }]}>Cancel</Typography>
          </Button>
          <Button onClick={handleText} endIcon={<SendIcon sx={{ color: colors.greenFlow[100] }} />}>
            <Typography sx={[themeStyles.typography.h6, { color: colors.text[500], fontStyle: "SemiBold" }]}>Copy as Text</Typography>
          </Button>
          <Button onClick={handleSign} endIcon={<SendIcon sx={{ color: colors.greenFlow[100] }} />} >
            <Typography sx={[themeStyles.typography.h6, { color: colors.text[500], fontStyle: "SemiBold" }]}>Sign</Typography>
          </Button>
        </DialogActions>


      </BootstrapDialog>
    </div>
  );
}