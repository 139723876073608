import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

  PatientTitle: {
    fontSize: 14,
    marginTop: 36,
    paddingBottom: 3,
    fontStyle: "bold",
    fontFamily: "Helvetica-Oblique",
  },
  PatientDataContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  Data: {
    fontSize: 10,
    fontStyle: "bold",
  },
  label: {
    width: 150,
  },
});


const PatientInformation = ({ ReportStudy }) => (
  <Fragment>
    <Text style={styles.PatientTitle}>Patient Information:</Text>
    <>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Patient Name:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.PatientName}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Patient ID:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.local_patientID}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Patient Age:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.PatientAge}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Patient Sex:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.PatientSex}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Body Examination Part:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.BodyExaminationPart}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Modality:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.modality}</Text>
    </View>
    </>
  </Fragment>
);

export default PatientInformation;
