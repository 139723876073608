import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { FileHeader } from './FileHeader';
import { v4 as uuidv4 } from 'uuid';
import DirectionSnackbar from '../../snackbars/snackbar';


const SingleFileuploaderAxios = ({file,onDelete,onUpload,user,workspace}) => {
  //const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressColor,setProgressColor] = useState("secondary")
  const [cancelToken, setCancelToken] = useState(null);
  const [uploadingStatus,setUploadingStatus]=useState(null)

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })
  const [message, setMessage] = useState('');
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const formData = new FormData();

  useEffect(() => {
    handleUpload(file,onDelete,onUpload,user,workspace,formData);
  }, []);


  const LBStyles={
    '& .MuiLinearProgress-bar1Determinate':{
      backgroundColor:colors.text[500],
    },
  }

  
const handleUpload = async (file, onDelete, onUpload, user, workspace) => {
  try {
    console.log("file", file);
    const randomUUID = uuidv4();
    const newFilename = user["userID"] + '_' + workspace["defaultWorkspaceID"] + '_' + randomUUID;
    
    const chunkSize = 1 * 1024 * 1024; // 2 MB chunk size
    const totalChunks = Math.ceil(file.size / chunkSize);

    console.log("newFilename \n",newFilename)
    
    const source = axios.CancelToken.source();
    setCancelToken(source);

    for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
      const start = chunkNumber * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('filename', newFilename);
      formData.append('userID', user["userID"]);
      formData.append('organisationid', workspace["defaultWorkspaceID"]);
      formData.append('total_chunks', totalChunks); // Append total_chunks
      formData.append('current_chunk', chunkNumber); // Append current_chunk
  
      
      const url = global.config.api_fileupload;

      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          setProgress(percent);
        },
        cancelToken: source.token,
      };

      await axios.post(url, formData, options);
      let percent = Math.floor(((chunkNumber + 1) * 100) / totalChunks);
      setProgress(percent);
      console.log(`Uploaded chunk ${chunkNumber + 1}/${totalChunks}`);
    }

    console.log('Chunked upload complete!');
    
    console.log('File uploaded successfully!');
    setOpenSnackbar({ ...openSnackbar, open: true, Message: "File uploaded successfully.", severity: "success" });
    setProgress(100);
    setUploadingStatus(true);
    
  } catch (error) {
    setUploadingStatus(false);
    setOpenSnackbar({ ...openSnackbar, open: true, Message: `Uploading ${file.name} failed.`, severity: "error" });
    console.error('Error uploading file:', error);
  }
};

  // const handleUpload = async (file,onDelete,onUpload,user,workspace) => {
  //   try {
  //     console.log("file", file)
  //       const formData = new FormData();
  //       formData.append('file', file);
  //       const randomUUID = uuidv4()
  //       const newFilename= user["userID"]+'_'+workspace["defaultWorkspaceID"]+'_'+randomUUID
  //       formData.append('filename', newFilename);
  //       formData.append('userID',  user["userID"])
  //       formData.append('organisationid',  workspace["defaultWorkspaceID"])
  //       const url = global.config.api_fileupload
    
  //       const source = axios.CancelToken.source();
  //       setCancelToken(source);

  //       const options = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           console.log("Started Uploading")
  //           const {loaded, total} = progressEvent;
  //           let percent = Math.floor( (loaded * 100) / total )
  //           console.log( `${loaded}kb of ${total}kb | ${percent}%` );
  //           setProgress(percent)
  //         },
  //         cancelToken: source.token,
  //       }

  //       await axios.post(url,formData,options).then((response) => {
  //         //setMessage('File uploaded successfully!');
  //         console.log('File uploaded successfully!',response);
  //         setOpenSnackbar({ ...openSnackbar, open: true, 
  //           Message: "File uploaded successfully.", severity: "success" })
  //         setProgress(100)
  //         setUploadingStatus(true)
  //       })
  //       .catch((error) => {
  //         //setMessage('File upload failed.');
  //         setUploadingStatus(false)
  //         setOpenSnackbar({ ...openSnackbar, open: true, 
  //           Message: `Uploading ${file.name} failed.`, severity: "error" })
  //               });

        
  //   } catch (error) {
  //     setOpenSnackbar({ ...openSnackbar, open: true, 
  //       Message: "Error uploading file.", severity: "error" })
    
  //       console.error('Error uploading file:', error);
  //   }
  // };

  function StopUpload(file,cancelToken){
    if (cancelToken) {
        cancelToken.cancel('File upload canceled by user.');
        onDelete(file)
      }
}


  return (
    <>
    <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })} /><Grid item>
      <FileHeader file={file}
        uploadingStatus={uploadingStatus}
        StopUpload={StopUpload}
        cancelToken={cancelToken}
        progress={progress} />
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={LBStyles}
        color={"neutral"} 
        />
    </Grid></>
  );
};

export default SingleFileuploaderAxios;