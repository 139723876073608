import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        dark_grey:{
            100: "#C4C4C4",
            200: "#606060",
            300: "#9F9F9F",
            400: "#323232",
            500: "#252525",
            600: "#1D1D1D",
            700: "#f5f5f533",
            800: "#00000040",
            900: "#4D4D4D"
            
        },
        novuColors:{
          100: "#E36D6A",
          200: "#FE7874", 
          300: "#FDA2A0",
          400: "#FFC7C6",
          500: "#F7AB34",
          600: "#3A9DF2",
          700: "#C4C4C4",
          800: "#2AD7C1",
          900: "#24667C",
          110: "#F8A49F",
          120: "#4DCFEB",
          130: "#9ED7DA",
          140:"#CDCDCD"
        },
        text:{
            100: "#F5F5F5",
            200: "#9F9F9F",
            300: "#606060",
            400: "#C4C4C4",
            500: "#17DFE9",
            600: "#808080",
            700: "#E36D6A",
        },
        greenFlow:{
            100: "#17DFE9",
            200: "#267589",
            300: "#4DCFEB"
        },
        avatar: {
          100: "#44b700",
          200: "#E36D6A",
        },
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
                main: colors.dark_grey[500],
              },
              secondary: {
                main: colors.greenFlow[100],
              },
              neutral: {
                dark: colors.grey[700],
                main: colors.grey[500],
                light: colors.grey[100],
              },
              text:{
                primary: colors.text[100],
                secondary: colors.text[500]
              },
              background: {
                default: colors.dark_grey[500],
              },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h0: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 64,
        fontStyle: "normal",
        fontWeight: 400,
        ////lineHeight: 40,
        letterSpacing: -0.015
        
      },
      h01: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 48,
        fontStyle: "normal",
        fontWeight: 400,
        ////lineHeight: 40,
        letterSpacing: -0.015
        
      },
      h1: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 40,
        
      },
      h2: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 32,
      },
      h21: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 28,
      },
      h3: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 24,
      },
      h31: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: 24,
        letterSpacing: -0.01
      },
      h4: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 20,
      },
      h40: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 700,
        //lineHeight: 24,
        letterSpacing: -0.01
      },
      h41: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 600,
        ////lineHeight: 20,
        letterSpacing: -0.01
      },
      h411: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        //fontStyle: "SemiBold",
        fontWeight: 600,
        ////lineHeight: 20,
        letterSpacing: -0.01
      },
      h4111: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        //fontStyle: "SemiBold",
        fontWeight: 700,
        ////lineHeight: 20,
        letterSpacing: -0.01
      },
      h42: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: "20px",
        letterSpacing: -0.015
      },
      h43: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 300,
        //lineHeight: 24,
        letterSpacing: -0.015
      },

      h5: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 600,
        //lineHeight: 20,
        letterSpacing: -0.015
      },
      h51: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        ////lineHeight: 20,
        letterSpacing: -0.015
      },
      h52: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 300,
        //lineHeight: 20,
        letterSpacing: -0.015
      },
      h60: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 15,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: 20,
        letterSpacing: -0.015
      },
      h6: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 14,
        //fontStyle: "normal",
        fontWeight: 600,
        letterSpacing: -0.015
      },
      h61: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: -0.015
      },
      h62: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 300,
        letterSpacing: -0.015
      },
      h7: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 13,
        fontStyle: "normal",
        fontWeight: 600,
        letterSpacing: -0.015
      },
      h71: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 13,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: 24,
        letterSpacing: -0.015
      },
      h8: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: 20,
        letterSpacing: -0.015
      },
      h9: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 10,
        fontStyle: "normal",
        fontWeight: 400,
        //lineHeight: 20,
        letterSpacing: -0.015
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: 56,
            backgroundColor: colors.dark_grey[600]
          },
        },
      },
      MuiToolbar: {
          styleOverrides: {
              dense: {
                  height: 56,
                  minHeight: 56
              }
          }
      }
  },

  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};