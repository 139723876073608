import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react'

import io from 'socket.io-client'
import { useSelector } from "react-redux";

export const SocketContext = createContext()

export const SocketProvider = ({ children, store }) => {

  const [isConnected, setConnected] = useState(false)
  const user = useSelector((state)=>state.user)
  const workspace=useSelector((state)=>state.Defaultworkspace)
  const workspaces = user["userWorkspaces"]
  const socket = useRef(null)

  const handleOnMessage = message => {
    console.log("socketProvider",message)
    // store.dispatch here
  }

  useEffect(() => {
    if (user.userLoggedIN) {
      socket.current = io({
        auth: {
          userID: user.userID,
          userLoginToken: user.userLoginToken,
          organisationid: user.organisationid
        }
      });

      socket.current.on("connect", () => {
        //Sucessfullynotify("Successfully connected to live server.")

        //socket.current.emit('join-team', user.organisationid)
        user["userWorkspaces"].map((team) => (
          socket.current.emit('join-team', team.organisationid)
        ))
        //socket.current.emit('userNotifications', JSON.stringify({"test":"Test"}) )


      })

      socket.current.on('FromAPI', function () {
        //handleGetCasesByDate(selectedDate)
        //Sucessfullynotify('New cases has been added.')
      })

      socket.current.on('notifications', function (message) {
        //handleGetCasesByDate(selectedDate)
        console.log("got nottification",message)
        //getNotifications()
        //Sucessfullynotify('New Notifications.')
      })

      socket.current.on('message-from-server', (message) => {
        //Sucessfullynotify(message)
      })

      socket.current.on('workspace', (message) => {
        //Sucessfullynotify(message)
      })

      socket.current.on("connect_error", (err) => {
        //Errornotify("Live Server: ", err.message)
        console.log(err.message); // prints the message associated with the error
      });

      socket.current.on('error', err => {
        console.log('socket.current Error:', err.message)
      })

      socket.current.on('message', handleOnMessage)
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect()
      }
    }
  }, [])

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => useContext(SocketContext)