//import React from 'react'
import * as React from 'react';
import { useState, useEffect, useRef, useMemo,useCallback } from 'react';
import _ from 'lodash';


import screenfull from 'screenfull';
import { useNavigate, useLocation } from "react-router-dom";

import { AppBar, Box, Button, Grid, Menu, MenuItem, Toolbar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import ViewerToolBar from '../../global/Viewer/ToolBar/ViewerToolBar';
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import './css/Index.css'

// Import the circular menu
import { CircleMenu, CircleMenuItem, TooltipPlacement, } from "react-circular-menu";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";

// MedDream Custom components
//import './css/Demo.css';
import 'viewports-core';
import LoadInstanceSpeedDial from './components/speeddial/loadStudySpeedDial'
import StudiesMenu from './StudiesMenu/StudiesMenu';
import LeftMenu from './LeftMenu/LeftMenu';
import ViewportsLayout from './ViewportsLayout/ViewportsLayout';
import { findings } from './findings';

import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

import { ViewerMenuContext } from '../../subComponents/ViewerContextMenu';
import { usePopupState, bindContextMenu, bindMenu } from 'material-ui-popup-state/hooks'
import { DummyDicom } from '../../../functions/api/data/DicomData';
import { extractAIFindings } from '../../../functions/api/data/extractdata';
import ViewerSidebar from '../../global/Viewer/Sidebar/ViewerSidebar';
//REDUX
import { useSelector, useDispatch } from 'react-redux'
import { updateFindings } from "../../../states/slices/aiFindings/aifindingsSlice"
import { getStudyInfo } from '../../../functions/api/data/viewerAPIs/getStudyInfo';
import { SterlizeDicomResults_updated_series } from '../../../functions/api/data/viewerAPIs/sterlizeDicomResults';
//import { FindInPageDimensions } from '@styled-icons/material/FindInPage';
import { deletefindings, addfindings, updateSterlizedCases, SterlizedCases, updateSterlizedCasesComments, updatefindings, resetfindings, bulkUpdateFindings, resetReport, updateReportState, updateActiveStudiesInViewer, resetActiveStudiesInViewer, updateCurrentInstanceOpenedInViewer, resetCurrentInstanceOpenedInViewer, removeColumnCurrentInstanceOpenedInViewer, removeRowCurrentInstanceOpenedInViewer, } from '../../../states/slices/cases/casesSlice';
import AddFindingAPI from '../../../functions/api/diagnosing/addFinding';
import { ImageAspectRatioRounded } from '@mui/icons-material';
import { useResponsiveQueries } from '../../../utilityComponents/device';
import { Viewerconfiguration } from './controllers/Viewerconfigurations';
import { getMaxWidthAndHeight } from './controllers/measurements';
import SegmentationModal from './Segmentation/SegmentationModal';
import DeepLookFunction from './controllers/DeepLookFunction';


const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    "padding-bottom": "0px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      "padding-bottom": "0px",
    }),
  })
);


const activeContainerIdArray = [
  {
    ContainerID: 'viewport-container-1-1'
  },
  {
    ContainerID: 'viewport-container-1-2'
  },
  {
    ContainerID: 'viewport-container-2-1'
  },
  {
    ContainerID: 'viewport-container-2-2'
  }
]

export function ViewerUpdated({ props, route, navigate }) {
  const theme = useTheme();
  const colors = tokens("dark");
  const dispatch = useDispatch();
  const themeStyles = themeSettings("dark")
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })

  const [aiFindings, setAiFindings] = useState([])
  const [manualfindings, setManualfindings] = useState([])
  const [measurementsSet, setMeasurementsSet] = useState({})
  const [measurements, setMeasurements] = useState({})
  const [showSegmentationModal, setShowSegmentationModal] = useState(false);


  //REDUX
  const aiFindingsState = useSelector(state => state.aiFindings)
  const Case = useSelector(state => state.cases)
  const ActiveStudiesInViewer = useSelector((state) => state.cases.ActiveStudiesInViewer)
  const CurrentInstanceOpenedInViewer = useSelector((state) => state.cases.CurrentInstanceOpenedInViewer)
  const CaseFindings = useSelector(state => state.cases.findings)
  const user = useSelector(state => state.user)
  const SterlizedCases = useSelector(state => state.cases.SterlizedCases)

  //NAVIGATION
  const location = useLocation();
  const StudyInstanceUID = localStorage.getItem("studyUID")



  const [timer, setTimer] = useState(null)
  const [layoutRows, setLayoutRows] = useState(1)
  const layoutRowsObjectsRef = useRef([]);
  const [layoutColumns, setLayoutColumns] = useState(1)
  const layoutColumnsObjectsRef = useRef([]);
  const [activeContainerId, setActiveContainerId] = useState('viewport-container-1-1')
  const [selectedContainerId, setSelectedContainerId] = useState('')
  const [contextMenuState, setContextMenuState] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false);
  const focusWindowRef = useRef(false);
  const [hangingProtocoll, setHangingProtocoll] = useState("ALL")
  const [nextHangingProtocoll, setNextHangingProtocoll] = useState("CC")
  const [loadingData, setLoadingData] = useState(true);
  const [currentStudy, setCurrentStudy] = useState();
  const [currentLayout, setCurrentLayout] = useState({});
  const previousAddedObjectsRef = useRef([]);
  const previousUpdatedObjectsRef = useRef([]);
  const previousLayoutObjectsRef = useRef([]);
  const LoadedInstancesObjectsRef = useRef([]);
  const { isMobile } = useResponsiveQueries();
  const [customizedMobileDevice, setCustomizedMobileDevice] = useState(false)
  const [pressedKeys, setPressedKeys] = useState([]);
  const [viewCounter, setViewCounter] = useState(1)
  const [availablePorts, setAvailablePorts] = useState([])

  //     Handling Viewerport height
  const [remainingHeight, setRemainingHeight] = useState(0);
  const viewerRef = useRef(null);
  const [componentsMounted, setComponentsMounted] = useState(false);

  /////////////////////////////////////////////////
  //
  //
  //
  //     Handling Viewerport height
  //  get the height of the topbar and the toolbar
  //
  //
  //////////////////////////////////////////////////


  useEffect(() => {
    //console.log("topbarElement")
    function handleResize() {
      const topbarElement = document.getElementById('GlobalTopbar'); // Get Topbar element
      const toolbarElement = document.getElementById('viewerToolBar'); // Get ViewerToolBar element
      //console.log("topbarElement", topbarElement.offsetHeight)
      //console.log("toolbarElement", toolbarElement.offsetHeight)

      if (topbarElement && toolbarElement) {
        //console.log("topbarElement", topbarElement.offsetHeight)
        //console.log("toolbarElement", toolbarElement.offsetHeight)
        const topbarHeight = topbarElement.offsetHeight; // Get height of Topbar
        const toolbarHeight = toolbarElement.offsetHeight; // Get height of ViewerToolBar
        const totalHeight = window.innerHeight;
        const newHeight = totalHeight - topbarHeight - toolbarHeight - 10;
        setRemainingHeight(newHeight);
        //console.log("newHeight", newHeight)
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on initial render

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentsMounted]); // Depend on componentsMounted state

  // useEffect to track when components are mounted
  useEffect(() => {
    setComponentsMounted(true);
  }, []);


  /////////////////////////////////////////////////
  //
  //
  //
  //     Handling Touchscreen
  //
  //
  //
  //////////////////////////////////////////////////

  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const MOUSE_BUTTTON = {
    LEFT_CLICK: 0,
    MIDDLE_CLICK: 1,
    RIGHT_CLICK: 2
  }
  const VIEWPORT_FUNCTIONS = {
    NONE: 0,
    WL: 1,
    ZOOM: 2,
    PAN: 3,
    SCROLL: 4,
    MEASURE: 5,
    ROTATE: 6,
    CROSSHAIR: 8,
    SEGMENTATION_TOOLS: 13
  };


  //   const startXRef = useRef(null);
  //   const startYRef = useRef(null);
  //   const startX1Ref = useRef(null);
  //   const startX2Ref = useRef(null);
  //   const startY1Ref = useRef(null);
  //   const startY2Ref = useRef(null);
  //   const startDistanceRef = useRef(null);

  //   const handleTouchStart = (e) => {
  //     if (e.touches.length === 1) {
  //       startXRef.current = e.touches[0].clientX;
  //       startYRef.current = e.touches[0].clientY;
  //     } else if (e.touches.length === 2) {
  //       startX1Ref.current = e.touches[0].clientX;
  //       startY1Ref.current = e.touches[0].clientY;
  //       startX2Ref.current = e.touches[1].clientX;
  //       startY2Ref.current = e.touches[1].clientY;
  //       const distance = Math.sqrt(
  //         (startX1Ref.current - startX2Ref.current) ** 2 +
  //           (startY1Ref.current - startY2Ref.current) ** 2
  //       );
  //       startDistanceRef.current = distance;
  //     }
  //   };

  //   const handleTouchMove = (e) => {
  //     if (e.touches.length === 1) {
  //       const endX = e.touches[0].clientX;
  //       const endY = e.touches[0].clientY;
  //       handleSingleSwipeHorizontal(startXRef.current, endX);
  //       handleSingleSwipeVertical(startYRef.current, endY);
  //     } else if (e.touches.length === 2) {
  //       const endX1 = e.touches[0].clientX;
  //       const endY1 = e.touches[0].clientY;
  //       const endX2 = e.touches[1].clientX;
  //       const endY2 = e.touches[1].clientY;
  //       const distance = Math.sqrt(
  //         (endX1 - endX2) ** 2 +
  //           (endY1 - endY2) ** 2
  //       );
  //       handleTwoFingersSwipeHorizontal(startX1Ref.current, endX1, startX2Ref.current, endX2);
  //       handleTwoFingersSwipeVertical(startY1Ref.current, endY1, startY2Ref.current, endY2);
  //       handleTwoFingersZoom(startDistanceRef.current, distance);
  //     }
  //   };

  //   // Single swipe in a horizontal direction
  // function handleSingleSwipeHorizontal(startX, endX) {
  //   const deltaX = endX - startX;
  //   // Adjust this threshold as needed
  //   if (Math.abs(deltaX) > 10) {
  //     // Horizontal swipe detected
  //     console.log('Single swipe in horizontal direction');
  //     // Your logic for handling horizontal swipe here
  //   }
  // }

  // // Single swipe in a vertical direction
  // function handleSingleSwipeVertical(startY, endY) {
  //   const deltaY = endY - startY;
  //   // Adjust this threshold as needed
  //   if (Math.abs(deltaY) > 10) {
  //     // Vertical swipe detected
  //     console.log('Single swipe in vertical direction');
  //     // Your logic for handling vertical swipe here
  //   }
  // }

  // // Two fingers horizontal swipe
  // function handleTwoFingersSwipeHorizontal(startX1, endX1, startX2, endX2) {
  //   const deltaX1 = endX1 - startX1;
  //   const deltaX2 = endX2 - startX2;
  //   // Adjust this threshold as needed
  //   if (Math.abs(deltaX1) > 10 || Math.abs(deltaX2) > 10) {
  //     // Two fingers horizontal swipe detected
  //     console.log('Two fingers swipe in horizontal direction');
  //     // Your logic for handling two fingers horizontal swipe here
  //   }
  // }

  // // Two fingers vertical swipe
  // function handleTwoFingersSwipeVertical(startY1, endY1, startY2, endY2) {
  //   const deltaY1 = endY1 - startY1;
  //   const deltaY2 = endY2 - startY2;
  //   // Adjust this threshold as needed
  //   if (Math.abs(deltaY1) > 10 || Math.abs(deltaY2) > 10) {
  //     // Two fingers vertical swipe detected
  //     console.log('Two fingers swipe in vertical direction');
  //     // Your logic for handling two fingers vertical swipe here
  //   }
  // }

  // // Two fingers zooming
  // function handleTwoFingersZoom(startDistance, endDistance) {
  //   const deltaDistance = endDistance - startDistance;
  //   // Adjust this threshold as needed
  //   if (Math.abs(deltaDistance) > 10) {
  //     // Zoom detected
  //     console.log('Two fingers zooming');
  //     // Your logic for handling zooming here
  //   }
  // }


  const isMobileDevice = () => {
    //return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    return window.matchMedia("(pointer: coarse)").matches;
  }
  // Check if the user is on an iPad
  const isIPad = () => {
    return navigator.userAgent.indexOf('iPad') !== -1;
  }

  //Initialization of Viewport

  class MultiFrameController {
    create() {
      // Called when viewport creates the controller.
      console.log("create Triggered")
    }

    playTrigger() {
      // Called when viewport starts playing frames.
      console.log("Play Triggered")
    }

    pauseTrigger() {
      // Called when viewport stops playing frames.
      console.log("Pause Triggered")
    }

    frameChangedTrigger(newFrame) {
      // Called when new frame is opened to viewport. Provides new frame number.
    }

    frameBufferChangedTrigger(buffer) {
      // Called when new frame is loaded to buffer. Provides information about loaded buffer parts.
    }
  }

  /////////////////////////////////////////////////
  //
  //
  //
  //      Initialization of Viewport and loading studies & series
  //
  //
  //
  //////////////////////////////////////////////////


  useEffect(() => {
    try {
      global.viewportsCore.create(Viewerconfiguration);
      console.log("isMobile", isMobileDevice(), isIPad())

      if (isMobileDevice() || isIPad()) {
        setLayoutRows(1)
        setLayoutColumns(1)
        layoutRowsObjectsRef.current = 1
        layoutColumnsObjectsRef.current = 1
        setCustomizedMobileDevice(true)

        viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);

      } else {
        setLayoutRows(2)
        setLayoutColumns(2)
        layoutRowsObjectsRef.current = 2
        layoutColumnsObjectsRef.current = 2
        viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);
        viewportsCore.layoutController.addLayoutContainer('viewport-container-1-2', false);
        viewportsCore.layoutController.addLayoutContainer('viewport-container-2-1', false);
        viewportsCore.layoutController.addLayoutContainer('viewport-container-2-2', false);
      }

      } catch (e) {
      console.log("viewportInitialization error", e)
    }

    return () => {

      //global.viewportsCore.unregisterEvent(global.viewportsCore.getConstants().CORE_EVENTS.ACTIVE_CONTAINER_CHANGED, activeContainerChangedHandler);

      viewportsCore.removeEvent('multi-frame-controller', MultiFrameController);
      viewportsCore.removeEvent('mg-lesion-selected', (data) => mgLesionSelected(data));
      viewportsCore.removeEvent('active-container-changed', (containerId) => activeContainerChanged(containerId));
      viewportsCore.removeEvent('viewport-instance-changed', (viewportId, instanceUid) => InstancesChanged(viewportId, instanceUid));
      viewportsCore.removeEvent('viewport-series-changed', ({ containerId, previousSeriesUid, currentSeriesUid, currentStudyUid }) => SeriesChanged(containerId, currentSeriesUid, currentStudyUid));
      viewportsCore.removeEvent('measurement-created', (data) => measurementCreated(data))
      viewportsCore.removeEvent('measurement-updated', (data) => measurementsChanged(data));
      viewportsCore.removeEvent('measurement-deleted', (data) => measurementDeleted(data));
 
    }
  }, [])

  // useEffect(() => {
  //   console.log("Active Container Changed",activeContainerId)
  
  // }, [activeContainerId])
  
const RegisterViewerEvents = ()=>{
  global.viewportsCore.infoLabelsController.enabled = true; //To enable or disable labels.
    viewportsCore.disableScrollLock();
    viewportsCore.enableViewportLoaders();
    //viewportsCore.registerEvent('viewport-loader', (object) => console.log('viewport-loader', object));
    //viewportsCore.registerEvent('progress-loader', (object) => console.log('progress-loader', object));
    viewportsCore.registerGuiController('multi-frame-controller', MultiFrameController);
    viewportsCore.registerEvent('mg-lesion-selected', (data) => mgLesionSelected(data));
    viewportsCore.registerEvent('active-container-changed', (containerId) => activeContainerChanged(containerId));
    viewportsCore.registerEvent('viewport-instance-changed', (viewportId, instanceUid) => InstancesChanged(viewportId, instanceUid));
    viewportsCore.registerEvent('viewport-series-changed', ({ containerId, previousSeriesUid, currentSeriesUid, currentStudyUid }) => SeriesChanged(containerId, currentSeriesUid, currentStudyUid));
    viewportsCore.registerEvent('measurement-created', (data) => measurementCreated(data))
    viewportsCore.registerEvent('measurement-updated', (data) => measurementsChanged(data));
    viewportsCore.registerEvent('measurement-deleted', (data) => measurementDeleted(data));

}

  const Initialization = useMemo(async () => {
    console.log("Viewport initialization", layoutColumns, layoutRows);
    try {   
        ViewerPageInitializationFunction()
    } catch (e) {
      console.error("Viewport initialization failed with error:", e);
      //console.log("error", e)
    }
  }, [])

useEffect(() => {
    if (currentLayout.length === 0) {
      console.log("Updating CurrentLayout first time")
      getCurrentLayout()
    }
    console.log("currentLayout", currentLayout)
  }, [currentLayout])


  async function ViewerPageInitializationFunction() {
    console.log("Running ViewerPageInitializationFunction")
    RegisterViewerEvents()
    
    //setLoadingData(true)
    setOpenSnackbar({
      ...openSnackbar, open: true,
      Message: "Started loading your Study.", severity: "info"
    })

    getStudyInfo(StudyInstanceUID, user["DefaultWorkspaceID"])
      .then(response => response.json())
      .then(res => {
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Loaded your Study Information successfully.", severity: "success" })

        const unsterlizedStudy = res["body"]
        const sterlizedStudy = SterlizeReturnedData(unsterlizedStudy, layoutRowsObjectsRef.current, layoutColumnsObjectsRef.current)


        //localStorage.setItem('Study', JSON.stringify(sterlizedStudy))
        //console.log("sterlizedStudy study ", sterlizedStudy)


        //viewportInitializationUpdated().then((res) => {
        //if (res === 200) {
        //console.log("sterlizedStudy.instances", sterlizedStudy.instances)
        
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Downloading your Study...", severity: "info"
        })

        try {

          //Load study in Viewport
          global.viewportsCore.loadStudiesWithHIS(sterlizedStudy.instances).then((studyData) => {
            //console.log("studyData", studyData)
            setOpenSnackbar({...openSnackbar, open: true,Message: "Loaded your study successfully2." + studyData, severity: "info"})
            //viewportsCore.cacheStudy(sterlizedStudy.study, sterlizedStudy.study);
            //global.viewportsCore.cacheStudy(global.viewportsCore.getActiveViewportStudy().uid, 'Orthanc');

          }
          ).catch(error => {
            // Handle the error here
            setOpenSnackbar({
              ...openSnackbar, open: true,
              Message: "Error opening your study." + error, severity: "error"
            })

            console.error('Error fetching data:', error);
          })

        } catch (e) {
          console.log("loadStudiesWithHIS error", e)
        }

        //}
      }
      )
      //})
      .then(() => {
        setLoadingData(false)
        //sterlizeFindingsFunction()
      }).catch(() => {
        setLoadingData(false)
      })


  }

function SterlizeReturnedData(unsterlizedStudy, layoutRows, layoutColumns) {
    console.log("SterlizeReturnedData", layoutRows, layoutColumns)
    const sterlizedStudy = SterlizeDicomResults_updated_series(unsterlizedStudy, layoutRows, layoutColumns)

    setCurrentStudy(sterlizedStudy)
    LoadedInstancesObjectsRef.current = sterlizedStudy
    dispatch(updateSterlizedCases(sterlizedStudy))
    dispatch(resetActiveStudiesInViewer())
    //dispatch(resetReport())
    dispatch(resetCurrentInstanceOpenedInViewer())
    dispatch(updateReportState(sterlizedStudy.report.body))
    // Manage Findings
    dispatch(resetfindings())
    dispatch(bulkUpdateFindings(sterlizedStudy.findings))
    sterlizeFindingsFunction()

    return sterlizedStudy
  }



  function openStudyToViewport(studies) {

    return Promise.resolve(
      studies.instances.map((instance, index) => {
        if (!viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"])) {
          console.log("container Unavailable?\n", viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"]))
          viewportsCore.layoutController.addLayoutContainer(activeContainerIdArray[index]["ContainerID"]);
        }
        //  console.log("instance",instance)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          instance["ContainerID"],
          instance.uid);
      })

    )
  }

  function SeriesChanged(containerId, currentSeriesUid, currentStudyUid) {
    //console.log("viewport-series-changed",containerId, currentSeriesUid,currentStudyUid)

    dispatch(updateActiveStudiesInViewer({ ActiveContainer: containerId, SeriesInstanceUID: currentSeriesUid }))

  }

  function findInstanceByInstanceUID(instanceUID) {
    console.log("currentStudy", LoadedInstancesObjectsRef)
    const instance = LoadedInstancesObjectsRef.current.instances.filter(inst => inst.instanceUID === instanceUID)[0];
    return instance ? instance : null;
  }

  function InstancesChanged(viewportId, instanceUid) {

    if (instanceUid !== null && instanceUid !== undefined && !focusWindowRef.current) {
      const viewPortObj = viewportsCore.controller.getViewport(viewportId)
      //   const instance = findInstanceByInstanceUID(instanceUid)
      //   console.log("instance",instance)
      //   if(instance){
      //   dispatch(updateCurrentInstanceOpenedInViewer({DefaultActiveContainer:instance.DefaultActiveContainer,study:instance.study, containerId: viewPortObj["containerId"], instanceUID: instanceUid, SeriesInstanceUID: viewPortObj["instance"]["seriesDbUid"] }))
      // }
    }

  }



  function handlelandmark(landmarkData) {
    console.log("Function handleLandMark", landmarkData)
  }

  function activeContainerChanged(containerId) {
    console.log("activeContainerChanged", containerId)
    setSelectedContainerId(containerId)
  }
  


  /////////////////////////////////////////////////
  //
  //
  //
  //      Handling Keyboard inputs
  //
  //
  //
  //////////////////////////////////////////////////



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!pressedKeys.includes(event.key)) {
        setPressedKeys(prevKeys => [...prevKeys, event.key]);
        detectKeyDown(event)
      }
    };

    const handleKeyUp = (event) => {
      setPressedKeys(prevKeys => prevKeys.filter(key => key !== event.key));
    };

    const handleKeyCombination = () => {
      if (pressedKeys.includes('r') && pressedKeys.includes('+')) {
        console.log("r+ combination pressed");
        // Handle r+ combination
        onLayoutRowsChanged("add")
      } else if (pressedKeys.includes('r') && pressedKeys.includes('-')) {
        console.log("r- combination pressed");
        // Handle r- combination
        onLayoutRowsChanged()
      }
      else if (pressedKeys.includes('c') && pressedKeys.includes('+')) {
        console.log("c+ combination pressed");
        // Handle r- combination
        onLayoutColumnsChanged("add")
      }
      else if (pressedKeys.includes('c') && pressedKeys.includes('-')) {
        console.log("c- combination pressed");
        // Handle r- combination
        onLayoutColumnsChanged()
      }
      else if (pressedKeys.includes('h') && pressedKeys.includes('c')) {
        console.log("hc combination pressed");
        //changeHangingProtocol(currentStudy, "CC")
      }
      else if (pressedKeys.includes('h') && pressedKeys.includes('m')) {
        console.log("hm combination pressed");
        //changeHangingProtocol(currentStudy, "MLO")
      }
      else if (pressedKeys.includes('h') && pressedKeys.includes('a')) {
        console.log("ha combination pressed");
        //changeHangingProtocol(currentStudy, "ALL")
      }
      else if (pressedKeys.includes('h') && pressedKeys.includes('c')) {
        console.log("hc combination pressed");
        //changeHangingProtocol(currentStudy, "CC")
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    handleKeyCombination();

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [pressedKeys]);


  function detectKeyDown(e) {



    switch (e.key) {
      case "0":
        //Soft Tissue
        console.log("0")
        viewportsCore.getActiveViewport().applyDefaultWL();
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Default Windowing.", severity: "info"
        })
        break;
      case "1":
        //Brain
        viewportsCore.getActiveViewport().applyWL(80, 40);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Brain tissue Windowing (W:80, L:40).", severity: "info"
        })
        break;

      case "2":
        //Liver
        viewportsCore.getActiveViewport().applyWL(150, 90);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Liver tissue Windowing (W:150, L:90).", severity: "info"
        })
        break;
      case "3":
        //Liver
        viewportsCore.getActiveViewport().applyWL(250, 80);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Posterior Fossa Windowing (W:250, L:80).", severity: "info"
        })
        break;
      case "4":
        //Soft Tissue
        //console.log("1")
        viewportsCore.getActiveViewport().applyWL(400, 40);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Soft tissue Windowing (W:400, L:40).", severity: "info"
        })
        break;
      case "5":
        //Lung
        //console.log("2")
        viewportsCore.getActiveViewport().applyWL(1500, -600);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Lung tissue Windowing (W:1500, L:-600).", severity: "info"
        })
        break;

      case "6":
        //Bone
        //console.log("4")
        viewportsCore.getActiveViewport().applyWL(2500, 480);
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Bone tissue Windowing (W:2500, L:480).", severity: "info"
        })
        break;

      case "7":
        console.log("AUTO")
        viewportsCore.getActiveViewport().applyAutoWL();
        setOpenSnackbar({
          ...openSnackbar, open: true,
          Message: "Auto Windowing.", severity: "info"
        })
        break;

      case "Escape":
        handleEscapeButton()
        break;
      case "l":
        handleMouseButtonFunction(1)
        break;
      case "z":
        handleMouseButtonFunction(2)
        break;
      case "p":
        handleMouseButtonFunction(3)
        break;
      case "s":
        handleMouseButtonFunction(4)
        break;
      case "r":
        handleMouseButtonFunction(6)
        break;

      /*       case "c":
            handleMouseButtonFunction(8)
          break; */

      case "q":
        resetAllViewports()
        break;
      default:
        handleMouseButtonFunction(0)
        break;
    }

  }

  function handleEscapeButton() {
    global.viewportsCore.setMouseButtonFunction(1, 1);
  }


  function handleMouseButtonFunction(value) {
    global.viewportsCore.setMouseButtonFunction(1, value);
    //global.viewportsCore.measurementsController.setActiveMeasurementType('none');

  }

  function resetAllViewports() {
    global.viewportsCore.resetAllViewportsRenders();
  }




  /////////////////////////////////////////////////
  //
  //
  //
  //      Handling Measurements and AI Findings
  //
  //
  //
  //////////////////////////////////////////////////

  //const sterlizeAIFindings=useMemo(() => sterlizeAIFindingsFunction(), [])
  //const [myFindingsArray, setMyFindingsArray] = useState([]);



  function sterlizeFindingsFunction() {
    // Here check if there are any AI findings returned by the API call for the current study and
    // run a loop to insert them one by one in the redux state and 
    console.log("CaseFindings", CaseFindings)



    //dispatch(bulkUpdateFindings(sterlizedStudy.findings))
    //viewportsCore.mgLesionsController.createMGLesion(containerId, findings);

    //   const measurementsData={
    //     "id": "0afc97d8-1390-433e-ab07-d6f913cbf8d2",
    //     "type": "oval",
    //     //"containerId": "viewport-container-1-1",
    //     "studyUid": "1.3.12.2.1107.5.1.7.112500.30000021022209071218000000343",
    //     "seriesUid": "1.3.12.2.1107.5.1.7.112500.30000021022209065918000003726",
    //     "instanceUid": "1.3.12.2.1107.5.1.7.112500.30000021022209065918000003739",
    //     "colors": {
    //         "regularColor": "#0AA500",
    //         "activeColor": "#33CCFF",
    //         "markedColor": "#009BFF",
    //         "activeLabelColor": "#FFF"
    //     },
    //     "data": {
    //         "centerPosition": [
    //             5.660220609234841,
    //             -175.11461356783892,
    //             -1021.461041440741
    //         ],
    //         "xRadius": 110.70161652565002,
    //         "yRadius": 66.83870887756348,
    //         //"ovalValues": "Mean: 35.80 HU  SD: 5.72 HU\r\nMin: 7.00 HU  Max: 74.00 HU\r\nArea: 32.57 cm² (W: 8.29 cm H: 5.00 cm)",
    //         //"width": "8.29 cm",
    //         //"height": "5.00 cm",
    //         //"area": "32.57 cm²"
    //     }
    // }

    // const containerId="viewport-container-1-1"
    //   viewportsCore.measurementsController.createNewMeasurements(containerId, [measurementsData]);

  }

  const measurementCreated = (data) => {
    console.log("measurementCreated", data)
    if (data.type=="oval"||data.type=="polyline"||data.type=="area"){addFinding(data)}
    
  }

  const measurementsChanged = data => {
    if (data.type=="oval"||data.type=="polyline"||data.type=="area"){updateFinding(data)}
    
  }

  function measurementDeleted(data) {
    console.log("measurementDeleted", data)
  }

  //const measurementsList = useMemo(() => { UpdateMeasurement(measurements)}, [measurements])


  function addFinding(measurement) {
    const updatedMeasurementsValues = updateMeasurementsValues(measurement)
    const isObjectExists = previousAddedObjectsRef.current.some(obj => obj.id === updatedMeasurementsValues.id);
    //console.log("existingObjectIndex 1", isObjectExists)

    if (!isObjectExists) {
      //console.log("CaseFindings", CaseFindings)
      const existingObjectIndex = CaseFindings.findIndex(obj => obj.id === updatedMeasurementsValues["id"]);
      //console.log("existingObjectIndex2 ", existingObjectIndex)

      if (existingObjectIndex === -1) {
        //console.log("existingObjectIndex 3", existingObjectIndex)
        const FinalMeasurementsValues = addMeasurementsUserData(updatedMeasurementsValues, user, 0, 1)
        //console.log("updatedMeasurementsValues from addFinding", updatedMeasurementsValues)
        dispatch(addfindings(FinalMeasurementsValues))
        AddFindingsToBackend(FinalMeasurementsValues, user, 0, 1)

        //DEEPLOOK INtegration
        Deeplook(measurement)
        //  previousAddedObjectsRef.current = [...previousAddedObjectsRef.current, updatedMeasurementsValues];
      }
      previousAddedObjectsRef.current = [...previousAddedObjectsRef.current, updatedMeasurementsValues]
    }


  }

  function updateFinding(data) {
    const updatedMeasurementsValues = updateMeasurementsValues(data)
    const areObjectsEqual = _.isEqual(updatedMeasurementsValues["data"], previousUpdatedObjectsRef.current["data"]);

    if (!areObjectsEqual) {
      console.log("updateFinding different", updatedMeasurementsValues)

      const existingObjectIndex = CaseFindings.findIndex(obj => obj.id === updatedMeasurementsValues["id"]);
      if (existingObjectIndex !== -1) {

        const NewUpdatedMeasurements = { ...CaseFindings[existingObjectIndex], data: updatedMeasurementsValues["data"] }
        console.log("updateFinding different2", existingObjectIndex, NewUpdatedMeasurements)
        AddFindingsToBackend(NewUpdatedMeasurements, user, 0, 1)
        dispatch(updatefindings({ id: updatedMeasurementsValues["id"], updatedValues: { data: updatedMeasurementsValues["data"] } }))
        previousUpdatedObjectsRef.current = updatedMeasurementsValues;
      }

    }
  }


  function AddFindingsToBackend(measurementsValues, user, AIFinding, confirmed) {
    AddFindingAPI(measurementsValues, user, AIFinding, confirmed)
      .then(response => response.text())
      .then(results => {
        console.log(results)
        const res = JSON.parse(Object.assign(results))
        if (res.statusCode === 200) {
          // The API got a 200 response
          setOpenSnackbar({
            ...openSnackbar, open: true,
            Message: "Updated your measurements successfully.", severity: "success"
          })
        } else {
          // The API got a different response code
          setOpenSnackbar({
            ...openSnackbar, open: true,
            Message: "Failed to update your new measurements.", severity: "error"
          })
        }

      })
  }


  function addMeasurementsUserData(measurements, user, AIFInding, confirmed) {
    try {
      let newMeasurements = measurements
      const ActiveContainer = viewportsCore.layoutController.getActiveContainer();
      const pixelSpacing= ActiveContainer["viewport"]["instance"]["pixelSpacing"]
      const window= ActiveContainer["viewport"]["instance"]["windowWidth"][0]
      const level = ActiveContainer["viewport"]["instance"]["windowCenter"][0]
      console.log(pixelSpacing,"pixelSpacing")
      console.log(window, level,"window level")
      Object.assign(newMeasurements, {
        "user": {
          "FullName": user["userFullName"],
          "avatar": user["avatarUrl"]
        },
        "organisationid": user["DefaultWorkspaceID"],
        "AIFinding": AIFInding,
        "confirmed": confirmed,
        "timestamp": new Date(),
        "show": true,
        "pixelSpacing":pixelSpacing,
        "window":window,
        "level":level,
        "structureReporting": {
          "name": "",
          "description": ""
        }
      })

      return newMeasurements
    }
    catch (error) {
      console.log(error)
      return measurements
    }
  }


  function updateMeasurementsValues(measurements, user, AIFInding, confirmed) {
    console.log("updateMeasurementsValues", measurements)
    let newMeasurements = measurements
    let diagonals = {}
    switch (newMeasurements.type) {
      case "polyline":
        diagonals = getMaxWidthAndHeight(newMeasurements.data.points)

        if (typeof newMeasurements["data"]["width"] !== 'undefined') {
          const updatedMeasurements = {
            ...measurements, data: {
              ...measurements.data,
              "width": diagonals["width"],
              "height": diagonals["height"],
              "area": diagonals["width"] * diagonals["height"]
            }
          }

          return updatedMeasurements
        }
        else {
          // First Call. Create the variables
          Object.assign(newMeasurements.data, { "width": diagonals["width"], "height": diagonals["height"], "area": diagonals["width"] * diagonals["height"] })
          return newMeasurements
        }


        break;
      case "area":
        diagonals = getMaxWidthAndHeight(newMeasurements.data.points)

        if (typeof newMeasurements["data"]["width"] !== 'undefined') {
          const updatedMeasurements = {
            ...measurements, data: {
              ...measurements.data,
              "width": diagonals["width"],
              "height": diagonals["height"],
              "area": newMeasurements["data"]["area"]
            }
          }

          return updatedMeasurements
        }
        else {
          // First Call. Create the variables
          Object.assign(newMeasurements.data, { "width": diagonals["width"], "height": diagonals["height"], "area": newMeasurements["data"]["area"] })
          return newMeasurements
        }

        break;
      case "oval":
        console.log("updateMeasurementsValues Oval 1", newMeasurements)
        const ovalValues = newMeasurements.data.ovalValues.split(" ")
        //console.log(ovalValues)
        const width = ovalValues[16] + " " + ovalValues[17]
        const height = ovalValues[19] + " " + ovalValues[17]
        const area = ovalValues[13] + " " + ovalValues[14]

        // const width = ovalValues[16]?ovalValues[16]:"" + " " + ovalValues[17]?ovalValues[17]:""
        // const height = ovalValues[19]?ovalValues[19]:"" + " " + ovalValues[17]?ovalValues[17]:""
        // const area = ovalValues[13]?ovalValues[13]:"" + " " + ovalValues[14]?ovalValues[14]:""

        console.log("updateMeasurementsValues Oval 11 ", newMeasurements["data"]["width"])
        // Due to the nature of multiple calling possibility of the function 
        // Here is a check if the width and etc variables should be created or updated

        // Second Call. Update the variables
        if (typeof newMeasurements["data"]["width"] !== 'undefined') {
          console.log("updateMeasurementsValues Oval 2 ", newMeasurements)

          const updatedMeasurements = {
            ...measurements, data: {
              ...measurements.data,
              "width": width,
              "height": height,
              "area": area
            }
          }

          console.log("updateMeasurementsValues Oval 22", updatedMeasurements)

          return updatedMeasurements
        }
        else {
          // First Call. Create the variables
          console.log("updateMeasurementsValues Oval 3", newMeasurements)
          Object.assign(newMeasurements.data, { "width": width, "height": height, "area": area })
          return newMeasurements
        }


        break;

      default:
        break;
    }

  }


/////////////////////////////////////////
//
//
//      Deeplook(measurement)
//
//
/////////////////////////////////////////

function Deeplook(measurement){
  const ActiveContainer = viewportsCore.layoutController.getActiveContainer();
  const pixelSpacing= ActiveContainer["viewport"]["instance"]["pixelSpacing"]

  // console.log(measurement,"Deeplook")
  // console.log(measurement["data"]["centerPosition"],"Deeplook")
  console.log(ActiveContainer["viewport"]["instance"],"Deeplook")
  
  //downloadPixels(ActiveContainer["viewport"]["instance"]["pixels"])
  const centerPosition= measurement["data"]["centerPosition"]
  const xRadius= measurement["data"]["xRadius"]
  const yRadius= measurement["data"]["yRadius"]
  const imagePixels= ActiveContainer["viewport"]["instance"]["pixels"]
  
  const imageWidth=ActiveContainer["viewport"]["width"]

  const BackendValues = {
    "centerPosition": centerPosition,
    "xRadius": xRadius,
    "yRadius": yRadius,
    "ovalValues": measurement["data"]["ovalValues"]
  }
  console.log(BackendValues, "BackendValues")
  

  //DeepLookFunction(centerPosition, xRadius, yRadius, imagePixels,imageWidth)

}


  function mgLesionSelected(data) {
    //handleMouseButtonFunction(0)
    console.log("mgLesionSelected", data)

    /* let obj = studies.instances.findings.lesions.uid.find(o => o.uid === data["uid"]);
    console.log(obj) */
  }


  function editAIResults() {

    console.log("Change Mammila visibility \n")
    global.viewportsCore.mgLesionsController.changeMamillaIsVisibleStatus(
      'viewport-container-2-2', true);
  }



  /////////////////////////////////////////////////
  //
  //
  //
  //      Handling  Double Clicks and focus window
  //
  //
  //
  //////////////////////////////////////////////////

  async function handleDoubleClick() {
    if (focusWindowRef.current) {
      console.log("handleDoubleClick: reset to the old state", currentLayout);


      resetCurrentLayoutToPrev();
    } else {
      console.log("handleDoubleClick:start focus view");
      focusWindowRef.current = true;
      getCurrentLayout();

      const ActiveContainer = await viewportsCore.layoutController.getActiveContainer();

      console.log("doubleClick", ActiveContainer, ActiveContainer["viewport"]["instance"]["uid"])

      if (ActiveContainer["viewport"]["instance"]["uid"] !== null) {
        await FocusSeriesinViewer(ActiveContainer["viewport"]["instance"]["uid"])
      }


    }
  }

  async function resetCurrentLayoutToPrev() {
    console.log(" resetCurrentLayoutToPrev")
    console.log(" container", previousLayoutObjectsRef.current, Object.keys(previousLayoutObjectsRef.current["CurrentInstanceOpenedInViewer"]))
    console.log(" previousLayoutObjectsRef.current[LayoutColumns]", previousLayoutObjectsRef.current["LayoutColumns"])
    console.log(" previousLayoutObjectsRef.current[LayoutRows]", previousLayoutObjectsRef.current["LayoutRows"])

    setLayoutColumns(previousLayoutObjectsRef.current["LayoutColumns"])
    setLayoutRows(previousLayoutObjectsRef.current["LayoutRows"])

    //Creating Containers
    Object.keys(previousLayoutObjectsRef.current["CurrentInstanceOpenedInViewer"]).map(async (key, index) => {
      // First Add LayoutContainer
      console.log("Creating containers", key)
      await viewportsCore.layoutController.addLayoutContainer(key, false)
    })

    // Transform te CurrentInstanceOpenedInViewer to an Array to pass tp LoadStudies with HIS
    const CurrentInstanceOpenedInViewerArray = Object.keys(CurrentInstanceOpenedInViewer).map(key => {
      return {
        callback: () => global.viewportsCore.layoutController.openInstanceToViewportContainer(key, CurrentInstanceOpenedInViewer[key].instanceUID),
        DefaultActiveContainer: key,
        study: CurrentInstanceOpenedInViewer[key].study,
        SeriesInstanceUID: CurrentInstanceOpenedInViewer[key].SeriesInstanceUID,
        instanceUID: CurrentInstanceOpenedInViewer[key].instanceUID
      };
    });

    console.log(CurrentInstanceOpenedInViewerArray)
    console.log(currentStudy.instances)
    global.viewportsCore.loadStudiesWithHIS(CurrentInstanceOpenedInViewerArray)
    //global.viewportsCore.loadStudiesWithHIS(SterlizedCases.instances)

    // CurrentInstanceOpenedInViewerArray.map((instance,index)=>{
    //   console.log("Loading data",instance)
    //   global.viewportsCore.layoutController.openInstanceToViewportContainer(
    //     instance.DefaultActiveContainer,instance.instanceUID)
    // })

    //   Object.keys(previousLayoutObjectsRef.current["CurrentInstanceOpenedInViewer"]).map(async (key,index)=>{
    //  // First Add LayoutContainer
    //  console.log("Loading data",key,previousLayoutObjectsRef.current["CurrentInstanceOpenedInViewer"][key]["instanceUid"])
    // // Load Series in that Container
    //   await global.viewportsCore.layoutController.openInstanceToViewportContainer(
    //     key,
    //     previousLayoutObjectsRef.current["CurrentInstanceOpenedInViewer"][key]["instanceUid"])
    //  } )

    //global.viewportsCore.loadStudiesWithHIS(currentStudy.instances)

    focusWindowRef.current = false
    //  console.log("resetCurrentLayoutToPrev",previousLayoutObjectsRef.current)
  }



  async function FocusSeriesinViewer(Instance) {
    setLayoutColumns(1)
    setLayoutRows(1)
    global.viewportsCore.layoutController.openInstanceToViewportContainer("viewport-container-1-1", Instance)
  }




  function getCurrentLayout() {
    const CurrentLayout = {
      "LayoutColumns": layoutColumns,
      "LayoutRows": layoutRows,
      "ActiveStudiesInViewer": ActiveStudiesInViewer,
      "CurrentInstanceOpenedInViewer": CurrentInstanceOpenedInViewer
    }
    setCurrentLayout(CurrentLayout)

    previousLayoutObjectsRef.current = CurrentLayout
    console.log("getCurrentLayout", CurrentLayout)
  }

  function changeHangingProtocol(studies, view) {
    console.log("changeHangingProtocol triggered", studies, view)

    let newSTUDIES = {
      studyName: currentStudy["Type"],
      studyUid: currentStudy.studyUid,
      instances: []
    }
    console.log("changeHangingProtocol newSTUDIES", newSTUDIES)

    currentStudy.instances.map((instance, index) => {

      if (instance.viewPosition === view) {
        console.log(instance)
        newSTUDIES.instances.push(instance)
      }
    })


    console.log("newSTUDIES", newSTUDIES)

    switch (view) {
      case "CC":
        setLayoutRows(1)
        setHangingProtocoll("CC")

        //openStudyToViewport(newSTUDIES)
        //setNextHangingProtocoll("MLO")

        break;

      case "MLO":
        setLayoutRows(1)
        setHangingProtocoll("MLO")

        //openStudyToViewport(newSTUDIES)
        //setNextHangingProtocoll("ALL")

        break;

      case "ALL":
        setLayoutRows(2)
        setHangingProtocoll("ALL")
        //openStudyToViewport(STUDIES)
        //setNextHangingProtocoll("CC")
        break;

      default:
        break;
    }

  }
  
  
  /////////////////////////////////////////////////
  //
  //
  //
  //      Handling  Columns and Rows Changes
  //
  //
  //
  //////////////////////////////////////////////////

  function onLayoutColumnsChanged(type) {
    //console.log("onLayoutColumnsChanged triggered",layoutColumns,type)
    //setLayoutColumns(number)  
    switch (type) {
      case "add":
        //  console.log("onLayoutColumnsChanged add",layoutRows)
        setLayoutColumns(layoutColumns + 1)
        break;

      default:
        //  console.log("onLayoutColumnsChanged add",layoutRows)
        if (layoutColumns != 1) {
          dispatch(removeColumnCurrentInstanceOpenedInViewer({ column: layoutColumns - 1 }))
          setLayoutColumns(layoutColumns - 1)

          break;
        }
    }
    getCurrentLayout()
  }

function onLayoutRowsChanged(type) {
    console.log("onLayoutRowsChanged triggered", layoutRows, type)

    switch (type) {
      case "add":
        //console.log("layoutRows add",layoutRows)
        setLayoutRows(layoutRows + 1)

        break;

      default:
        //console.log("layoutRows default",layoutRows)
        if (layoutRows != 1) {
          dispatch(removeRowCurrentInstanceOpenedInViewer({ row: layoutRows - 1 }))
          setLayoutRows(layoutRows - 1)

          // remove from CurrentInstancesOpenedInViewer
          break;
        }
    }
    getCurrentLayout()
    //setLayoutRows(number)
  }

  /////////////////////////////////////////////////
  //
  //
  //
  //      Handling  Hanging Protocol 
  //
  //
  //
  //////////////////////////////////////////////////

  // const findKeyByValue = (dataObj, targetValue) => {
  //   console.log("findKeyByValue",dataObj, targetValue)
  //   for (const key in dataObj) {
  //     if (dataObj.hasOwnProperty(key) && dataObj[key] === targetValue) {
  //       return key;
  //     }
  //   }
  //   return null; // Return null if the value is not found in any key-value pair
  // };

  function DragandDropSeries(event, series) {
    console.log("From ViewerNews DragandDropSeries", event, series)
  }

  function changeHangingProtocol2(studies) {

    if (studies.type === "MG") {
      const availableHangingProtocols = ["CC", "MLO", "ALL"]
      const currentHangingProtocolIndex = availableHangingProtocols.indexOf(hangingProtocoll)
      console.log("currentHangingProtocolIndex", currentHangingProtocolIndex, hangingProtocoll)
      //setNextHangingProtocoll("MLO")
      if (currentHangingProtocolIndex === availableHangingProtocols.length + 1) {
        setHangingProtocoll(availableHangingProtocols[0])
      }
      else {
        setHangingProtocoll(availableHangingProtocols[currentHangingProtocolIndex + 1])
      }
    }
  }


  useEffect(() => {
    if (currentStudy) {
      console.log("hangingProtocoll changed", hangingProtocoll)
      //console.log("NextHangingProtocol changed", nextHangingProtocoll)
      loadCustomView(currentStudy, hangingProtocoll)
    }
  }, [hangingProtocoll])


  async function loadCustomView(studies, view) {

    let newSTUDIES = {
      studyName: studies["Type"],
      studyUid: studies.studyUid,
      instances: []
    }

    studies.instances.map((instance, index) => {

      if (instance.viewPosition === view) {
        newSTUDIES.instances.push(instance)
      }
    })


    console.log("loadCustomView", view)
    switch (view) {
      case "CC":
        setLayoutRows(1)
        console.log("CC VIEW")
        //setStudy2Load(newSTUDIES)

        //openStudyToViewport(newSTUDIES)

        /* global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[0].ContainerID,
          studies.instances[0].uid)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
            activeContainerIdArray[1].ContainerID,
            studies.instances[1].uid)
            console.log("Avaibale ports \n",global.viewportsCore.layoutController.getLayoutContainers())
         */
        setNextHangingProtocoll("MLO")

        break;
      case "MLO":
        //setLayoutRows(1)
        console.log("MLO VIEW")
        /*  global.viewportsCore.layoutController.openInstanceToViewportContainer(
             activeContainerIdArray[0].ContainerID,
             studies.instances[2].uid)
         global.viewportsCore.layoutController.openInstanceToViewportContainer(
               activeContainerIdArray[1].ContainerID,
               studies.instances[3].uid)
               console.log("Avaibale ports \n",global.viewportsCore.layoutController.getLayoutContainers())
                */
        //openStudyToViewport(newSTUDIES)
        setNextHangingProtocoll("ALL")
        break;

      case "ALL":
        setLayoutRows(2)

        //openStudyToViewport(studies)
        /*  global.viewportsCore.layoutController.openInstanceToViewportContainer(
           activeContainerIdArray[0].ContainerID,
           studies.instances[0].uid)
         global.viewportsCore.layoutController.openInstanceToViewportContainer(
             activeContainerIdArray[1].ContainerID,
             studies.instances[1].uid)
         global.viewportsCore.layoutController.openInstanceToViewportContainer(
           activeContainerIdArray[2].ContainerID,
           studies.instances[2].uid)
             global.viewportsCore.layoutController.openInstanceToViewportContainer(
               activeContainerIdArray[3].ContainerID,
               studies.instances[3].uid)
                */
        setNextHangingProtocoll("CC")
        break;

      default:
        console.log("No changes ")
        break;
    }
  }


  // function selectViewportContainer(containerId) {
  //   setActiveContainerId(containerId);
  // }
  const selectViewportContainer = useCallback((containerId) => {
    setActiveContainerId(containerId);
}, []);



  const handleDragOver = (event) => {
    event.preventDefault(); // Allow dropping
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const UID = event.dataTransfer.getData("uid");
    const series = event.dataTransfer.getData("series");
    //Calculate the position within the iframe's grid
    const iframeRect = event.currentTarget.getBoundingClientRect();
    const columnWidth = iframeRect.width / layoutColumns; // Assuming 2 columns
    const rowHeight = iframeRect.height / layoutRows; // Assuming 2 rows
    const column = Math.floor((event.clientX - iframeRect.left) / columnWidth) + 1;
    const row = Math.floor((event.clientY - iframeRect.top) / rowHeight) + 1;
    const containerID = `viewport-container-${row}-${column}`
    console.log("imageID", containerID, UID)

    global.viewportsCore.layoutController.openInstanceToViewportContainer(containerID, UID).then(() => {
      console.log("Loaded Instance To ViewPortContainer Callback")
    })
    dispatch(updateActiveStudiesInViewer({ ActiveContainer: containerID, SeriesInstanceUID: series["SeriesInstanceUID"] }))


    // setImagePosition({ column, row });

    // Now you can use the imageId, column, and row as needed.
  };


  /////////////////////////////////////////////////
  //
  //
  //
  //      Handle Segmentation
  //
  //
  //
  //////////////////////////////////////////////////

  const showSegmentationModalFunc = () => {
    //global.viewportsCore.generateMpr(activeContainerId, ['viewport-container-1-1', 'viewport-container-1-2', 'viewport-container-2-1']);
    global.viewportsCore.preloadSegmentingAnnotationsController().then(() => setShowSegmentationModal(true));
    global.viewportsCore.setMouseButtonFunction(0, 13);
    global.viewportsCore.setMouseButtonFunction(1, 13);

};

const hideSegmentationModalFunc = () => {
  setShowSegmentationModal(false)
  global.viewportsCore.setMouseButtonFunction(0, 1);
  global.viewportsCore.setMouseButtonFunction(1, 1);

};




  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar customizedMobileDevice={customizedMobileDevice} id="GlobalTopbar" />
      {!loadingData ?
        <ViewerSidebar
          currentStudy={currentStudy}
          airesults={currentStudy}
          DragandDropSeries={DragandDropSeries}
        /> : ""}

      {/*  <ViewerToolBar/> */}

      <Main id="mainviewport" style={{ flex: 1, }}  >

        <ViewerToolBar
          id="viewerToolBar"
          customizedMobileDevice={customizedMobileDevice}
          //sterlizeAIFindingsFunction={sterlizeFindingsFunction}
          loadingData={loadingData}
          //caseComments={caseComments}
          //addcomments={addcomments}
          manualfindings={manualfindings}
          currentStudy={currentStudy}
          showSegmentationModalFunc={()=>setShowSegmentationModal(true)}
          onLayoutColumnsChanged={(number) => { onLayoutColumnsChanged(number) }}
          onLayoutRowsChanged={(number) => { onLayoutRowsChanged(number) }}
          function1={() => { changeHangingProtocol(currentStudy, "CC") }}
          function2={() => { changeHangingProtocol(currentStudy, "MLO") }}
          function3={() => { changeHangingProtocol(currentStudy, "ALL") }}
        />


        {/*     //START ADDING ALL PAGE CONTENTS HERE
 
*/}
        <LoadInstanceSpeedDial currentStudy={currentStudy} />
        <Box
          ref={viewerRef}
          sx={{
            display: "flex", flexDirection: "column",
            height: remainingHeight
          }}>
          <div

            //onTouchStart={handleTouchStart}
            //onTouchMove={handleTouchMove}

            //onTouchStart={handleTouchStart} 
            //onMouseDown={handleSlide}
            //onTouchStart={handleTouchStart} 
            //onTouchMove={onTouchMove}
            //className="viewportDiv" 
            onDoubleClick={() => handleDoubleClick()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{

              //height:"745px",
              margin: "-10px -24px 0px -24px",
              //  height: "87vh"
            }}
            //sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
            id="viewer"
            //className="viewer-menu-container"
            {...bindContextMenu(popupState)}>

            <ViewportsLayout
              layoutRows={layoutRows}
              layoutColumns={layoutColumns}
              activeContainerId={activeContainerId}
              patientInformation="Andrea Müller"
              showpatientInformation={false}

            />
            <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}
            />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingData}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Menu
              {...bindMenu(popupState)}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "transparent",
                  backgroundImage: "none",
                  boxShadow: "none",
                  borderRadius: "50%",
                }
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}>

              <ViewerMenuContext />

            </Menu>


            <SegmentationModal show={showSegmentationModal} onClose={hideSegmentationModalFunc}/>

          </div>
        </Box>


        {/*     //
        //
        // END PAGE CONTENTS HERE
        //
        // 
*/}



      </Main>
    </Box>
  );
};

