import React from 'react'

export function logout_rc() {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        
        //console.log("login Info",raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
          };

          //return fetch("auth2/api/v1/user/login", requestOptions)
          return fetch(global.config.api_logout_user, requestOptions)
}
