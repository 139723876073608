import { Typography } from '@mui/material';
import React from 'react';
import { themeSettings, tokens } from '../../../utilityComponents/theme';

const WelcomeMessage = ({ message, submessage }) => {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")


    const welcomeMessage = "Welcome to NOVU Medical GenAI assistance";
    return (
        <Typography sx={styles.welcomeMessage}>{welcomeMessage}</Typography>
        
    );
};

const styles = {
    welcomeMessage: {
        fontFamily: 'Open Sans',
        fontSize: '64px',
        fontWeight: 400,
        lineHeight: '72px',
        letterSpacing: '-0.015em',
        textAlign: 'center',
        margin: '20px 0',
        padding: '20px', // Add padding for better appearance
        borderRadius: '10px', // Add border radius for rounded corners
        background: 'linear-gradient(180deg, #17DFE9 0%, #3A9DF2 100%)', // Set gradient background
        WebkitBackgroundClip: 'text', // Clip background to text
        backgroundClip: 'text', // Clip background to text,
        color:"#4DCFEB"
    },
};

export default WelcomeMessage;
