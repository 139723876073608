import { SvgIcon } from "@mui/material";

export const Test = <svg width="15" height="15" viewBox="0 0 15 15"  xmlns="http://www.w3.org/2000/svg">
<path d="M14.0332 8.16797H8.0332V14.168H6.0332V8.16797H0.0332031V6.16797H6.0332V0.167969H8.0332V6.16797H14.0332V8.16797Z" />
</svg>

export function Backarrow(props) {
    return (
      <SvgIcon {...props}>
            <path d="M7.29492 1.12109L1.29492 8.12109L7.29492 15.1211" stroke="#9F9F9F" strokeLinecap="round"/>
            <path d="M7.29492 1.12109L1.29492 8.12109L7.29492 15.1211" stroke="#4DCFEB" strokeLinecap="round"/>
      </SvgIcon>
    );
  }

  export function ReportIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M9.81836 7.01172H15.3184L9.81836 1.51172V7.01172ZM2.81836 0.0117188H10.8184L16.8184 6.01172V18.0117C16.8184 18.5422 16.6076 19.0509 16.2326 19.4259C15.8575 19.801 15.3488 20.0117 14.8184 20.0117H2.81836C1.70836 20.0117 0.818359 19.1117 0.818359 18.0117V2.01172C0.818359 0.901719 1.70836 0.0117188 2.81836 0.0117188ZM3.81836 18.0117H5.81836V12.0117H3.81836V18.0117ZM7.81836 18.0117H9.81836V10.0117H7.81836V18.0117ZM11.8184 18.0117H13.8184V14.0117H11.8184V18.0117Z" />
        </SvgIcon>
    );
  }

  export function Rotate(props) {
    return (
      <SvgIcon {...props}>
    <path d="M13.6832 2.35C12.2332 0.9 10.2432 0 8.0332 0C5.91147 0 3.87664 0.842855 2.37635 2.34315C0.876058 3.84344 0.0332031 5.87827 0.0332031 8C0.0332031 10.1217 0.876058 12.1566 2.37635 13.6569C3.87664 15.1571 5.91147 16 8.0332 16C11.7632 16 14.8732 13.45 15.7632 10H13.6832C12.8632 12.33 10.6432 14 8.0332 14C6.4419 14 4.91578 13.3679 3.79056 12.2426C2.66534 11.1174 2.0332 9.5913 2.0332 8C2.0332 6.4087 2.66534 4.88258 3.79056 3.75736C4.91578 2.63214 6.4419 2 8.0332 2C9.6932 2 11.1732 2.69 12.2532 3.78L9.0332 7H16.0332V0L13.6832 2.35Z" />
        </SvgIcon>
    );
  }

  export function Fullscreen(props) {
    return (
      <SvgIcon {...props}>
        <path d="M6.5332 10.09L7.9432 11.5L3.4432 16H7.0332V18H0.0332031V11H2.0332V14.59L6.5332 10.09ZM7.9432 6.5L6.5332 7.91L2.0332 3.41V7H0.0332031V0H7.0332V2H3.4432L7.9432 6.5ZM11.5332 10.09L16.0332 14.59V11H18.0332V18H11.0332V16H14.6232L10.1232 11.5L11.5332 10.09ZM10.1232 6.5L14.6232 2H11.0332V0H18.0332V7H16.0332V3.41L11.5332 7.91L10.1232 6.5Z"/>
        </SvgIcon>
    );
  }

  export function Zoom(props) {
    return (
      <SvgIcon {...props}>
<path d="M12.5332 11L17.5332 16L16.0332 17.5L11.0332 12.5V11.71L10.7632 11.43C9.6232 12.41 8.1432 13 6.5332 13C4.8093 13 3.156 12.3152 1.93701 11.0962C0.718022 9.87721 0.0332031 8.22391 0.0332031 6.5C0.0332031 4.77609 0.718022 3.12279 1.93701 1.90381C3.156 0.684819 4.8093 0 6.5332 0C8.25711 0 9.91041 0.684819 11.1294 1.90381C12.3484 3.12279 13.0332 4.77609 13.0332 6.5C13.0332 8.11 12.4432 9.59 11.4632 10.73L11.7432 11H12.5332ZM6.5332 11C9.0332 11 11.0332 9 11.0332 6.5C11.0332 4 9.0332 2 6.5332 2C4.0332 2 2.0332 4 2.0332 6.5C2.0332 9 4.0332 11 6.5332 11ZM9.0332 7H7.0332V9H6.0332V7H4.0332V6H6.0332V4H7.0332V6H9.0332V7Z"/>
        </SvgIcon>
    );
  }

  export function Vertical(props) {
    return (
      <SvgIcon {...props}>
<path d="M17.0332 20H6.0332V6H17.0332V20ZM17.0332 4H6.0332C5.50277 4 4.99406 4.21071 4.61899 4.58579C4.24392 4.96086 4.0332 5.46957 4.0332 6V20C4.0332 20.5304 4.24392 21.0391 4.61899 21.4142C4.99406 21.7893 5.50277 22 6.0332 22H17.0332C17.5636 22 18.0723 21.7893 18.4474 21.4142C18.8225 21.0391 19.0332 20.5304 19.0332 20V6C19.0332 5.46957 18.8225 4.96086 18.4474 4.58579C18.0723 4.21071 17.5636 4 17.0332 4ZM14.0332 0H2.0332C1.50277 0 0.994062 0.210714 0.618989 0.585786C0.243917 0.960859 0.0332031 1.46957 0.0332031 2V16H2.0332V2H14.0332V0Z"/>
        </SvgIcon>
    );
  }

  export function Horizontal(props) {
    return (
      <SvgIcon {...props}>
<path d="M16.0332 14V16H2.0332C1.50277 16 0.994062 15.7893 0.618989 15.4142C0.243917 15.0391 0.0332031 14.5304 0.0332031 14V4H2.0332V14H16.0332ZM20.0332 2V10C20.0332 10.5304 19.8225 11.0391 19.4474 11.4142C19.0723 11.7893 18.5636 12 18.0332 12H6.0332C5.50277 12 4.99406 11.7893 4.61899 11.4142C4.24392 11.0391 4.0332 10.5304 4.0332 10V2C4.0332 1.46957 4.24392 0.960859 4.61899 0.585786C4.99406 0.210714 5.50277 0 6.0332 0H18.0332C18.5636 0 19.0723 0.210714 19.4474 0.585786C19.8225 0.960859 20.0332 1.46957 20.0332 2ZM18.0332 2H6.0332V10H18.0332V2Z" />
        </SvgIcon>
    );
  }

  export function MedicalProfile(props) {
    return (
      <SvgIcon {...props}>
  <path d="M9.00016 0L7.00016 2V4H4.00017C2.85017 4 2.12017 5 2.00017 6L1.00017 16C0.880165 17 1.54017 18 3.00017 18H19.0002C20.4602 18 21.1202 17 21.0002 16L20.0002 6C19.8802 5 19.0602 4 18.0002 4H15.0002V2L13.0002 0H9.00016ZM9.00016 2H13.0002V4H9.00016V2ZM10.0002 7H12.0002V10H15.0002V12H12.0002V15H10.0002V12H7.00016V10H10.0002V7Z"/>
      </SvgIcon>
    );
  }

  export function ROI(props) {
  //  
    return (
      <SvgIcon {...props}>
<path d="M9.23418 2C7.97418 2.12 6.75418 2.53 5.67418 3.2L4.67418 1.47C5.98418 0.64 7.48418 0.13 9.03418 0L9.23418 2ZM15.5642 4.25C14.6542 3.39 13.5642 2.73 12.3742 2.33L13.0342 0.39C14.5342 0.84 15.9242 1.66 17.0342 2.78L15.5642 4.25ZM3.37418 5.41C2.67418 6.44 2.22418 7.6 2.03418 8.83L0.0341797 8.55C0.23418 7 0.82418 5.5 1.73418 4.23L3.37418 5.41ZM20.0342 10V10.66L18.0342 10.5V10C18.0342 8.92 17.8442 7.86 17.4242 6.86L19.2542 6.06C19.7842 7.31 20.0342 8.65 20.0342 10ZM4.03418 15.3L2.53418 16.61C1.50418 15.43 0.75418 14.04 0.33418 12.53L2.20418 12C2.56418 13.22 3.19418 14.35 4.03418 15.3ZM10.1742 20H10.0342C8.53418 20 7.03418 19.68 5.67418 19.07L6.56418 17.24C7.65418 17.75 8.83418 18 10.0342 18H10.2242L10.1742 20ZM15.0342 19H13.0342V13H19.0342V15H16.4542L19.1742 17.76L17.7642 19.17L15.0342 16.5" />
        </SvgIcon>
    );
  }

  export function Unknown7(props) {
    return (
      <SvgIcon {...props}>
<path d="M14.0332 8.16797H8.0332V14.168H6.0332V8.16797H0.0332031V6.16797H6.0332V0.167969H8.0332V6.16797H14.0332V8.16797Z" />
        </SvgIcon>
    );
  }