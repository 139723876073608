import React, { useEffect, useState ,useContext} from "react";
//import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import List from "@mui/material/List";
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import ProfileIcon from "../../../utilityComponents/images/ProfileIcon";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { useSocket } from "../../subComponents/context/socket";

//Custom Components
import TeamDialoge from "../../subComponents/forms/Team/team"
import changeMemberStatusAPI from "../../../functions/api/workspace/updateStatus";
import { SocketContext } from "../../subComponents/context/SocketContext";

// const styles = {
//   avatarStyles: {
//     width: 24,
//     height: 24,
//   }
// }


// const useStyles = makeStyles({
//   Menu_icons: {
//     fill: "#F5F5F5",
//     color: "#F5F5F5",
//   },
//   Menu_Text: {}
// })




export default function NotificationsMenu({newNotiffications,readNotifications}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTeam, setOpenTeam] = useState(false)
  const themeStyles = themeSettings("dark")
  const colors = tokens("dark");
  //const socket = useSocket()
  const socket = useContext(SocketContext);
  //const classes = useStyles()
  let navigate = useNavigate();
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const notifications = useSelector((state) => state.notifications)
  const user = useSelector((state) => state.user)

  // useEffect(() => {
  //   console.log("notifications/user from Topbar", notifications, user)
  // }, [notifications, user])


  const openTeamDialoge = () => {
    setOpenTeam(true)
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    var notificationsIDs = notifications["notifications"].map(n => n.id);
    console.log("notifications array", notificationsIDs)
    readNotifications(notificationsIDs)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  function sterlize_notification(notification) {

    switch (notification["type"]) {
      case "join_request":
        if (notification["metadata"] !== null) {
          const SterlizednotificationText = notification["metadata"]["WorkspaceContactPerson"] + " invited you to join workspace: " + notification["metadata"]["WorkspaceName"]
          //console.log("SterlizednotificationText", SterlizednotificationText)
          return (SterlizednotificationText)
        }
        break;

        case "Workspace_removed":
          if (notification["metadata"] !== null) {
            const SterlizednotificationText = "You are removed from a workspace."
            //console.log("SterlizednotificationText", SterlizednotificationText)
            return (SterlizednotificationText)
          }
          break;


      default:
        break;
    }
  }

  function handleNotification(notification, user,status) {
    switch (notification["type"]) {
      case "join_request":
          changeMemberStatusAPI(status, notification["metadata"]["WorkspaceID"], user["userID"])
          .then(response => response.text())
          .then(result => {
            const respond = JSON.parse(Object.assign(result))
            //console.log("notifications", respond)
            if (respond["statusCode"] === 200) { 
              const data ={
                "status":status,
                "WorkspaceID":notification["metadata"]["WorkspaceID"],
                "WorkspaceName":notification["metadata"]["WorkspaceName"],
                "user":user["userFullName"],
                "type":"join_request"
              }
              //console.log("notifications websocket", data)
              socket.emit('userNotifications', JSON.stringify(data) )
              //     console.log("notifications body", respond["body"])

            }
          }).catch(error => {
            //setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while getting your notifications.", severity: "warning" })
          }
          )


        break;

      default:
        break;
    }
  }


  return (
    <React.Fragment>
      {/* <TeamDialoge handleClickOpen={openTeamDialoge} open={openTeam} handleCancel={() => (setOpenTeam(false))} /> */}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            //sx={{ mr: "24px" }}
            aria-controls={open ? 'Notifications-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
          <Badge color="secondary" variant="dot" invisible={!newNotiffications}>
          <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      {notifications["notifications"] !== undefined ?
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          color="white"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          PaperProps={{
            style: {
              width: "20%",
            },
          }}
          // PaperProps={{
          //   elevation: 0,
          //   style: {
          //     maxHeight: '100px',
          //     width: '10%',
          //   },
          //     sx: {
          //     overflow: 'visible',
          //     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          //     mt: 1.5,
          //     color: "white",
          //     '& .MuiList-root': {
          //       backgroundColor: colors.dark_grey[400]
          //     },
          //     '& .MuiAvatar-root': {
          //       width: 32,
          //       height: 32,
          //       ml: -0.5,
          //       mr: 1,
          //     },
          //     '&:before': {
          //       content: '""',
          //       display: 'block',
          //       position: 'absolute',
          //       top: 0,
          //       right: 14,
          //       width: 10,
          //       height: 10,
          //       bgcolor: 'background.paper',
          //       transform: 'translateY(-50%) rotate(45deg)',
          //       zIndex: 0,
          //     },
          //   },
          // }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          {notifications["notifications"].length > 0 ?
            notifications["notifications"].map((notification) => {
              //console.log("notification", notification)
              return (
                <>
                  <Box sx={{
                    width: "inherit",
                    margin: '5px 5px 5px 5px'
                  }}>

                    <Typography sx={themeStyles.typography.h71}>
                      {sterlize_notification(notification)}
                    </Typography>
                    <IconButton sx={{ color: colors.greenFlow[100] }} aria-label="add to shopping cart"
                      onClick={()=>handleNotification(notification, user,"Joined")}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton sx={{ color: colors.greenFlow[100] }} aria-label="add to shopping cart"
                    onClick={()=>handleNotification(notification, user,"Rejected")}
                    >
                      <CloseIcon />
                    </IconButton>




                    {/* <MenuItem key={notification}
          //onClick={Item.action}
          >

                {sterlize_notification(notification)}
            </MenuItem>
             */}
                    <Divider />
                  </Box>

                </>
              )
            })
            : ""
          }





          {/* <MenuItem onClick={handleClose}>
          <ListItemIcon sx={{ color: "#F5F5F5", fill: "#F5F5F5" }}>
            <ProfileIcon fontSize="small" sx={{ color: "#F5F5F5", fill: "#F5F5F5" }} />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={openTeamDialoge}>
          <ListItemIcon>
            <GroupsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Team
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem> */}
        </Menu> : ""}
    </React.Fragment>
  );
}