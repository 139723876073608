import React, { useState } from "react";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SlidesDrawer from '../SlidesDrawer';
import { ColorModeContext, tokens, themeSettings } from "../../../../../utilityComponents/theme"
import { Divider } from '@mui/material';
import { ImageActions } from './ImageActions';
import { ManipulationActions } from './ManipulationActions';
import { MeasurmentsActions } from "./measurementsActions";




const actions = [

//  { icon: <PhotoOutlinedIcon />, name: 'Instance' },
  { icon: <CollectionsOutlinedIcon />, name: 'Series' },
  { icon: <FilterNoneOutlinedIcon />, name: 'Study'},
];





export default function LoadInstanceSpeedDial({currentStudy}) {
    const [openDrawerState,setOpenDrawerState] = useState (false)
    const [openSpeedDial,setOpenSpeedDial] = useState(false)
    const [openSubSpeedDial,setOpenSubSpeedDial] = useState(false)
    const [type,setType] = React.useState('')
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")

    const handleOpen = () => setOpenSpeedDial(true);
    const handleClose = () => setOpenSpeedDial(false);

    const handleSubOpen = () => setOpenSubSpeedDial(true);
    const handleSubClose = () => setOpenSubSpeedDial(false);
    
    function openDrawer(type){
        console.log("open Drawer function", type)
        setType(type)
        setOpenDrawerState(true)
    }
    
    function closeDrawer(){
    setOpenDrawerState(false)
    }


  return (
    // <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
    //<Box sx={{ height: 330, transform: 'translateY(0px)', flexGrow: 1 }}>
      <React.Fragment key={'bottom'}>
          <Box sx={{ 
                //'& .MuiFab-primary': {backgroundColor: colors.novuColors[120], color: 'transparent' },
                    position: 'absolute',
                    bottom: 70, 
                    right: 16,
                    //borderRadius:0 
                }} flexDirection={"column"}>
                     <SpeedDial
          sx={{margin:"10px"}}
          ariaLabel="SpeedDial basic example"
          icon={<AddPhotoAlternateIcon />}
          direction = {'left'}
          onClose={handleClose}
          onOpen={handleOpen}
          open={openSpeedDial}
      >
          {actions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={() => openDrawer(action.name)}

              />
          ))}
          <Divider/>
          {ImageActions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={action.functionname}

              />
          ))}
          {MeasurmentsActions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={action.functionname}

              />
          ))}


      </SpeedDial>
      <SpeedDial
          sx={{margin:"10px"}}
          ariaLabel="SpeedDial basic example"
          icon={<AddPhotoAlternateIcon />}
          direction = {'left'}
          onClose={handleSubClose}
          onOpen={handleSubOpen}
          open={openSubSpeedDial}
      >
          {actions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={() => openDrawer(action.name)}

              />
          ))}
          <Divider/>
          {ImageActions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={action.functionname}

              />
          ))}
          <Divider/>
          {/* {ManipulationActions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={action.functionname}

              />
          ))} */}
          <Divider/>
          {MeasurmentsActions.map((action) => (
              <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  //tooltipOpen
                  onClick={action.functionname}

              />
          ))}


      </SpeedDial>

        


          <SlidesDrawer currentStudy={currentStudy} openDrawerState={openDrawerState} type={type} closeDrawer={closeDrawer} />
          </Box>
      </React.Fragment>
    //</Box>
  );
}