import React from 'react'

export function ListAllTeams(values) {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-Auth-Token", sessionStorage.getItem("Meteor.loginToken"));
  myHeaders.append("X-User-Id", sessionStorage.getItem("Meteor.userId"));


  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(global.config.api_rc_base + "api/v1/teams.list", requestOptions)

}


export function SearchMembersAPI(values) {
  //console.log("FROM API", values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-Auth-Token", sessionStorage.getItem("Meteor.loginToken"));
  myHeaders.append("X-User-Id", sessionStorage.getItem("Meteor.userId"));

  const query = `?query={ \"name\" : { \"$regex\": \"${values}\" , "$options" : "i"  }}`

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(global.config.api_search_users + query, requestOptions)

}

export function getUserAvatar(values) {
  //console.log("FROM API", values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("X-Auth-Token", sessionStorage.getItem("Meteor.loginToken"));
  myHeaders.append("X-User-Id", sessionStorage.getItem("Meteor.userId"));

  const query = `?username=${values}`

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(global.config.api_get_users_avatar + query, requestOptions)

}

export function RegisterNewUserAPI(values) {
  //console.log("FROM RegisterNewUserAPI API", values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ values });
  console.log("FROM API", raw)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(global.config.api_register_new_user, requestOptions)

}


export function RegisterNewTeamAPI(values) {
  //console.log("FROM API", values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ values });
  //console.log("FROM API", raw)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(global.config.api_register_Team, requestOptions)

}

export function GetTeamMembersAPI(values) {

  //console.log("FROM API", values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ values });
  //console.log("FROM API", raw)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(global.config.api_get_team, requestOptions)
}


function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export function GetTeamMembers(values) {
  return wrapPromise(GetTeamMembersAPI(values))
}
