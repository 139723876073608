import * as React from 'react';
import { useState,useMemo } from 'react';
import { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme"

import { useSelector, useDispatch } from 'react-redux'
import { updateFindings } from "../../../../states/slices/aiFindings/aifindingsSlice"


// ICONS
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Backarrow, ReportIcon, Test, Rotate, Fullscreen, Zoom, Unknown4, Unknown5, ROI, Unknown7 } from "../../../../utilityComponents/images/viewerIcons";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AiConfirmResults from '../../../subComponents/forms/AiResults/confirmForm';
import AiDeleteResults from '../../../subComponents/forms/AiResults/deleteFinding';
import { updatefindings } from '../../../../states/slices/cases/casesSlice';
import BadgeAvatars from '../../../subComponents/avatar/avatar';
import EditFindings from '../../../subComponents/forms/findings/editFindings';
import { AItools } from '../ToolBar/AItools';


const Accordion = styled((props) => (
  <MuiAccordion
    //disableGutters 
    elevation={0}
    //square 
    {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  /*  '&:not(:last-child)': {
     borderBottom: 0,
   },
   '&:before': {
     display: 'none',
   }, */
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));





export default function ResultsAccordion({ airesults, maxwidth, actions, SideBaropened, openSidebar }) {

  const [expanded, setExpanded] = useState('panel1');
  const [openAIResults, setOpenAIResults] = useState(true)
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const ResultWidth = maxwidth - 20

  //REDUX
  const dispatch = useDispatch();
  const aiFindingsState = useSelector((state) => state.aifindings.findings)
  const [aiFindings, setAiFindings] = useState([])
  const ConfirmedFindings = useSelector((state) => state.cases.findings)
  const ActiveStudiesInViewer = useSelector((state) => state.cases.ActiveStudiesInViewer)
  const ConfirmedFindingsChanged = useMemo(() => console.log("ConfirmedFindings changed",ConfirmedFindings), [ConfirmedFindings])



  const ResultsStyle = {
    marginTop: "20px",
    marginBottom: "20px",
  }

  useEffect(() => {
    console.log("ConfirmedFindings",ConfirmedFindings)
    
  }, [ConfirmedFindings])

  useEffect(() => {
    //console.log("SideBaropened",SideBaropened)
    if (!SideBaropened) {
      setOpenAIResults(false)
    } else { setOpenAIResults(false) }
  }, [SideBaropened])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /*      useEffect(() => {
        console.log("aiFindingsState",aiFindingsState)
        extractAIFindings(aiFindingsState)
      }, [aiFindingsState])  */


  // useEffect(() => {
  //   if (airesults) {
  //     extractAIFindings(airesults)
  //   }
  // }, [airesults])


  /*      useEffect(() => {
        console.log("aiFindings changed",aiFindings)
        setAiFindings(aiFindings)
      }, [aiFindings])   */


  function extractAIFindings(airesults) {
    console.log("airesults", airesults)

    let results = []
    airesults.instances.map((instance, index) => {

      if ('findings' in instance) {

        if (instance.findings.lesions.length > 0) {
          instance.findings.lesions.map((finding) => {

            finding = {
              "name": instance.findingName,
              "percentage": instance.findingPercentage,
              "uid": finding.uid,
              "ContainerID": instance.ContainerID,
              "isVisible": finding.isVisible,
              "isEditable": finding.isEditable
            }
            results.push(finding)
          })
        }
      }
      setAiFindings(results)
    })
  }


  function handleAIAccordion(openAIResults, SideBaropened) {

    if (SideBaropened) { setOpenAIResults(!openAIResults) }
    else { setOpenAIResults(false) }

    // Close Sub accordions if the main AI results Accordioni is closed
    if (!openAIResults) {
      setExpanded(false)
    }

  }

  /* const AccordinButtons = [
      {
        title:"Confirm",
        icon: <CheckOutlinedIcon/>,
        action: confirmResults
      },
      {
        title:"Edit",
        icon: <EditOutlinedIcon/>,
        action: editResults
      },
      {
        title:"Show",
        icon: <VisibilityOutlinedIcon/>,
        action: showResults
      },
  
      {
        title:"Delete",
        icon: <ClearOutlinedIcon/>,
        action: deleteResults
      },
    ]
  
    function IconsMenu(AccordinButtons,uid) {
      return (
        <>
        <Divider/>
        <Grid container spacing={2} sx={{marginTop:"0px",marginBottom:"10px",
          width:"220px"}} 
        //justifyContent={"space-between"}
        >
          {AccordinButtons.map((Item) => (
            <Grid item sx={{paddingLeft:"0px",}}>
              <Tooltip title={Item.title}>
                <IconButton 
                //onClick={Item.action}
                //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                >
                  {Item.icon}
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        </>
      );
    }
   */
  function confirmResults(uid) {
    console.log("confirmResults", uid)
    AiConfirmResults()

  }

  // function deleteResults(uid) {
  //   console.log("deleteResults")
  // }

  // function handleDeleteFinding(data){
  //   const ConfirmedFindingsCopy = ConfirmedFindings.filter(finding => finding.id !== data["id"]);
  //   dispatch(updatefindings(ConfirmedFindingsCopy))

  // }

  function editResults(uid) {
    console.log("editResults")

    let obj = aiFindings.find(o => o.uid === uid);
    let EditableState = !obj.isEditable

    global.viewportsCore.mgLesionsController.changeLesionIsEditableStatus(obj.ContainerID, obj.uid, EditableState);

    setAiFindings(
      aiFindings.map(finding =>
        finding.uid === uid
          ? { ...finding, isEditable: EditableState }
          : finding
      ))

    console.log("Change isEditable to: ", uid, EditableState)
  }


  function showResults(finding) {
    console.log("Show Results", 
    finding,
    finding["ActiveContainer"],
    finding["instanceUid"])

    if(finding["show"]){
      viewportsCore.measurementsController.hideMeasurementById(finding["id"]);
      dispatch(updatefindings({ id: finding["id"], updatedValues: { "show": !finding["show"] } }))

    }else{

      Object.keys(ActiveStudiesInViewer).map((seriesUID)=>{
        if (seriesUID===finding["seriesUid"]){
         global.viewportsCore.layoutController.openInstanceToViewportContainer(ActiveStudiesInViewer[seriesUID], finding["instanceUid"])  
         viewportsCore.measurementsController.createNewMeasurements(ActiveStudiesInViewer[seriesUID], [finding]);
         dispatch(updatefindings({ id: finding["id"], updatedValues: { "show": !finding["show"] } }))
        }
     })

    
  }
    
    //let obj = aiFindings.find(o => o.uid === finding.uid);
    //let VisibleState = !obj.isVisible

    
    //global.viewportsCore.mgLesionsController.changeLesionIsVisibleStatus(obj.ContainerID, obj.uid, VisibleState);

    // setAiFindings(
    //   aiFindings.map(finding =>
    //     finding.uid === finding.uid
    //       ? { ...finding, isVisible: VisibleState }
    //       : finding
    //   ))

    //console.log("Change to: ", uid, VisibleState)
  }

  // function hideResults(uid) {
  //   console.log("hideResults")
  // }


  function handleMeasurementChange(tool) {
    global.viewportsCore.setMouseButtonFunction(1, 5);
    global.viewportsCore.measurementsController.setActiveMeasurementType(tool);
  }


  
  return (


    <Accordion expanded={openAIResults} square
      sx={{
        "&.expanded": {
          color: "red"
        }
      }}
      onChange={() => (handleAIAccordion(openAIResults, SideBaropened))}
    >
      <AccordionSummary sx={{ height: SideBaropened ? "50px" : "100px", "overflow-x": "hidden" }}
        expandIcon={SideBaropened ? <ExpandMoreIcon /> : ""}>
        {SideBaropened ?
          <Typography color={colors.text[100]}
            sx={themeStyles.typography.h52}>Findings</Typography>
          :
          <Button onClick={() => { openSidebar() }}>
            <Typography color={colors.novuColors[120]}
              sx={[themeStyles.typography.h5, { transform: 'rotate(-90deg)' }]}>Findings</Typography>
          </Button>
        }

      </AccordionSummary>
      <AccordionDetails>
        {ConfirmedFindings.length>0?
        <Typography color={colors.text[100]}sx={themeStyles.typography.h52}>Confirmed Results</Typography>
          :""}
        {ConfirmedFindings.map((result, index) => {
          //console.log("finding result", result, index)
          return (
          <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <Box justifyContent={"space-between"} display={"flex"} width={"100%"}>
              <Typography color={colors.text[100]}
                sx={themeStyles.typography.h52}>{index + 1}. {result.name ? result.name : "Result"} 
                </Typography>
               
                <BadgeAvatars size="sm" alt={result.user.FullName} src={result.user.avatar} />

              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Box sx={{ width: ResultWidth }} id={result.name}>
                <Grid container spacing={2}>

                  <Grid item xl={9}>
                    <Typography noWrap variant='' display="block"
                      color={colors.text[100]}
                      sx={[themeStyles.typography.h4, ResultsStyle]}
                      style={{ wordWrap: "break-word" }}>
                      {result["structureReporting"]? result["structureReporting"]["name"] : "Finding"}
                    </Typography>
                  </Grid>

                  <Grid item xl={3}>
                    <Typography variant='' display="block"
                      color={colors.text[100]}
                      sx={[themeStyles.typography.h4, ResultsStyle]}
                      style={{ wordWrap: "break-word" }}>
                      {result.percentage ? result.percentage : ""}
                    </Typography>
                  </Grid>

                </Grid>
                <Divider />

                <Grid container
                  justifyContent={'space-between'} 
                  spacing={2}
                  sx={{ marginTop: "0px", marginBottom: "10px", width: { ResultWidth } }}
                //justifyContent={"space-between"}
                >

                  <Grid item sx={{ "padding-left": "0px", }}>

                    <EditFindings  finding={result} />
                  </Grid>

                  {/* <Grid item sx={{ paddingLeft: "0px", }}>
                    <Tooltip title={"Zoom"}>
                      <IconButton
                        onClick={() => { confirmResults(result.uid) }}
                      //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                      >
                        <Zoom />
                      </IconButton>
                    </Tooltip>
                  </Grid> */}


{/* 
                  <Grid item sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Tooltip title={result.isEditable ? "" : "Edit"}>
                      <IconButton
                        onClick={() => { editResults(result.uid) }}
                      //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                      >
                        {result.isEditable ? <EditOffOutlinedIcon /> : <EditOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid> */}

                  <Grid item sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Tooltip title={result["show"] ? "Hide" : "Show"}>
                      <IconButton
                        onClick={() => { showResults(result) }}
                      //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                      >
                        {result["show"] ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  

                  <Grid item sx={{ paddingLeft: "0px", }}>
                  <AItools finding={result}/>
                  </Grid>

                  <Grid item sx={{ paddingLeft: "0px", }}>
                    <AiDeleteResults finding={result} /> 
                  </Grid>

                </Grid>

              </Box>

            </AccordionDetails>
          </Accordion>)
        })}

        {ConfirmedFindings.length>0?<Divider />:""}

        <Typography color={colors.text[100]}
          sx={themeStyles.typography.h52}>AI Findings</Typography>

        {/* {aiFindings.map((result, index) => (

          <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <Typography color={colors.text[100]}
                sx={themeStyles.typography.h52}>Result {index + 1}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box sx={{ width: ResultWidth }} id={result.name}>
                <Grid container spacing={2}>

                  <Grid item xl={9}>
                    <Typography noWrap variant='' display="block"
                      color={colors.text[100]}
                      sx={[themeStyles.typography.h4, ResultsStyle]}
                      style={{ wordWrap: "break-word" }}>
                      {result.name}
                    </Typography>
                  </Grid>

                  <Grid item xl={3}>
                    <Typography variant='' display="block"
                      color={colors.text[100]}
                      sx={[themeStyles.typography.h4, ResultsStyle]}
                      style={{ wordWrap: "break-word" }}>
                      {result.percentage}
                    </Typography>
                  </Grid>

                </Grid>
                <Divider />

                <Grid container
                  spacing={2}
                  sx={{ marginTop: "0px", marginBottom: "10px", width: { ResultWidth } }}
                >

                  <Grid item sx={{ "padding-left": "0px", }}>

                    <AiConfirmResults findingUID={result} />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "0px", }}>
                    <Tooltip title={"Zoom"}>
                      <IconButton
                        onClick={() => { confirmResults(result.uid) }}
                      >
                        <Zoom />
                      </IconButton>
                    </Tooltip>
                  </Grid>



                  <Grid item sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Tooltip title={result.isEditable ? "" : "Edit"}>
                      <IconButton
                        onClick={() => { editResults(result.uid) }}
                      //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                      >
                        {result.isEditable ? <EditOffOutlinedIcon /> : <EditOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Tooltip title={result.isVisible ? "Hide" : "Show"}>
                      <IconButton
                        onClick={() => { showResults(result.uid) }}
                      //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                      >
                        {result.isVisible ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item sx={{ paddingLeft: "0px", }}>
                    <AiDeleteResults findingUID={result} />
                  </Grid>

                </Grid>

              </Box>

            </AccordionDetails>
          </Accordion>
        ))} */}

        <Divider />

        <Box justifyContent={"center"} alignItems={"center"} display="flex">

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Button sx={{
                borderColor: colors.text[100],
                width: "100%",
                height: "50px",
                marginTop: "20px",
                backgroundColor: "transparent"
              }}>
                <AddOutlinedIcon sx={{ fill: colors.text[100] }} />
                <Typography color={colors.text[100]}
                  sx={themeStyles.typography.h52}>
                  Additional findings
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title={"Distance"} >
                <IconButton onClick={(event) => { handleMeasurementChange('ruler') }}>
                  <HorizontalRuleOutlinedIcon sx={{ fill: colors.text[500] }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title={"Angle"} >
                <IconButton onClick={(event) => { handleMeasurementChange('angle') }}>
                  <ChangeHistoryOutlinedIcon sx={{ fill: colors.text[500] }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={"Polyline"} >
                <IconButton onClick={(event) => { handleMeasurementChange('polyline') }}>
                  <PolylineOutlinedIcon sx={{ fill: colors.text[500] }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={"Ellipse"} >
                <IconButton onClick={(event) => { handleMeasurementChange('oval') }}>
                  <CircleOutlinedIcon sx={{ fill: colors.text[500] }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>




            {/*   <Grid item>

  </Grid> */}

          </Grid>

        </Box>


      </AccordionDetails>
    </Accordion>



  );
}