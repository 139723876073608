//React Components
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import screenfull from 'screenfull';
import 'viewports-core';


// MUI Components
import { Box, Button, CssBaseline, Divider, Grid, IconButton, Menu, MenuItem, SvgIcon, Tooltip, useTheme } from "@mui/material";
import { styled } from '@mui/system';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PatientsIcon from "../../../../utilityComponents/images/PatientsIcon";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import { medicalProfileSymbol } from "../../../../utilityComponents/images/medicalprofile";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
// Custom components
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { MedicalProfile, Backarrow, ReportIcon, Test, Rotate, Fullscreen, Zoom, ROI, Unknown7, Horizontal, Vertical } from "../../../../utilityComponents/images/viewerIcons";

// Custom functions
import { useSelector, useDispatch } from 'react-redux';
import BasicMenu from "./menuMuster";
import { ImageToolsMenu } from "./imageToolsMenu";
import { HangingProtocol } from "./hangingProtocol";
import { RoiMenu } from "./roiMenu";
import { Measurements } from "./measurements";
import ReportCase from "./report";
import { Manipulation } from "./manipulation";
import { TempAIfindings } from "../../../../functions/api/data/viewerAPIs/sterlizeDicomResults";
import LesionDialog from "../../../subComponents/viewer/Lesion3d/LesionDialog";
import ThreeDObject from "../../../subComponents/viewer/Lesion3d";
import DeepLookSlidesDrawer from "../../../pages/viewer2.0/components/sliders/deeplookSlider";
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import { AItools } from "./AItools";
//const colors = tokens("dark");


// const NovuIconButton = withStyles((theme) => ({
//   ":hover": {
//     backgroundColor: "transparent"
//   },
//   root: {
//     "&. MuiIconButton-root, &.MuiButtonBase-root ":
//     {
//       ":hover": {
//         backgroundColor: "transparent"
//       }
//     }
//   }
// }))(IconButton);

const NovuIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiIconButton-root, &.MuiButtonBase-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));


const ViewerToolBar = ({ showSegmentationModalFunc, customizedMobileDevice, externalviewer, sterlizeAIFindingsFunction, loadingData, function1, function2, function3, onLayoutColumnsChanged, onLayoutRowsChanged, currentStudy, manualfindings }) => {
  const theme = useTheme();
  //const stylingClasses = useStyles()
  const colors = tokens("dark");
  const colorMode = useContext(ColorModeContext);
  const iconColor = colors.text[500]
  const AppBarBgColor = colors.dark_grey[600]
  const themeStyles = themeSettings("dark")

  let navigate = useNavigate();
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)


  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openReport, setOpenReport] = useState(false);


  const [viewPort1, setViewPort1] = useState()
  const [viewPort2, setViewPort2] = useState()
  const [viewPort3, setViewPort3] = useState()
  const [viewPort4, setViewPort4] = useState()
  const [isDialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);



  function SecondMonitor() {
    console.log("Second Monitor")
    const win = window.open("/viewernew");
    win.focus();

  }

  function StartFullscreen() {
    console.log("Full Screen")
  }

  function customFunction() {
    console.log("from parent")
  }


  function startFullSCreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    } else {
      // Ignore or do something else
    }
  }

  function resetAllViewports() {
    global.viewportsCore.resetAllViewportsRenders();
  }

  function showAiResults() {

    console.log("show AI results")
    const findingsmamilla = {
      mamilla: {
        point: { x: 1000, y: 1000 },
        radius: 200,
        isEditable: false,
        isVisible: true,
        style: {
          color: "#E72828",
          lineWidth: 2,
        },
      }
    }

    const mamillaData = { point: { x: 1000, y: 1500 } }
    const containerId = "viewport-container-2-1"

    viewportsCore.mgLesionsController.createMGLesion(containerId, TempAIfindings);
    //viewportsCore.mgLesionsController.updateMamillaData(containerId, mamillaData);
  }

  function Lesion3DRepresentationFun() {
    // Generate random poly points
    const generateRandomPolyPoints = (numPoints,radius) => {
      const polyPoints = [];
    const phi = Math.PI * (3 - Math.sqrt(5)); // Golden Ratio
    for (let i = 0; i < numPoints; i++) {
        const y = 1 - (i / (numPoints - 1)) * 2; // y goes from 1 to -1
        const radiusAtY = Math.sqrt(1 - y * y) * radius; // Radius at this y height
        const theta = phi * i; // Golden angle increment
        const x = Math.cos(theta) * radiusAtY;
        const z = Math.sin(theta) * radiusAtY;
        polyPoints.push({ x, y, z });
    }
    return polyPoints;
    };
    // Usage
    const numPoints = 100; // Adjust as needed
    const sphereRadius = 5; // Adjust as needed
    const dummyPolyPoints = generateRandomPolyPoints(numPoints,sphereRadius);

    return dummyPolyPoints
  }

  const dummyPolyPoints = Lesion3DRepresentationFun()

  return (

    <Toolbar sx={{
      paddingRight: "0px",
      paddingLeft: "25px",
      marginBottom: "10px",
      marginTop: externalviewer ? "0" : "32px",
      marginLeft: "-25px",
      marginRight: "-25px",

      backgroundColor: colors.dark_grey[900],
      //zIndex: (theme) => theme.zIndex.drawer + 2,

    }}
      id="viewerToolBar"
    >
      <Grid container
        spacing={2}
        display="flex"
        alignContent={"center"}

      >

        <Grid item xs={2} xl={2} justifyContent={"left"} display="flex">
          <IconButton onClick={() => { navigate("/demo") }}
            sx={{ fill: "transparent", color: colors.novuColors[120], ":hover": { backgroundColor: "transparent" } }} >
            {/*  <PatientsIcon /> */}
            <Backarrow
              inheritViewBox={true}
              //viewBox="0 0 16 32"
              sx={{
                fill: "transparent",
                ":hover": { backgroundColor: "transparent" }
              }} />
            <Typography style={{ marginTop: "-5px", marginLeft: "-5px" }}
              sx={themeStyles.typography.h51}>
              Back
            </Typography>
          </IconButton>

        </Grid>



        {/*Tools Part*/}
        <Grid item xs={8} xl={8}
          alignContent={"end"}
          justifyContent={"center"} display="flex">
          <Box width={"100%"} justifyContent={"center"} display="flex">

            <Tooltip title={"Medical Profile"}>
              <NovuIconButton 
              //sx={{ marginRight: "15px" }}
                onClick={() => (console.log("medical Profile"))}>
                <MedicalProfile fontSize="large" />
              </NovuIconButton>
            </Tooltip>
            <Divider orientation="vertical" sx={{ marginLeft: "15px",marginRight: "15px"  }} />


            <ImageToolsMenu />



            <Manipulation />


            <Measurements />

            <RoiMenu />

            {/* <Tooltip title={"Rows/Column"}>
  <IconButton onClick={resetAllViewports}>
      <GridViewOutlinedIcon fontSize="large"/>
  </IconButton>
</Tooltip> */}

            <HangingProtocol
              onLayoutColumnsChanged={onLayoutColumnsChanged}
              onLayoutRowsChanged={onLayoutRowsChanged}
              function1={function1}
              function2={function2}
              function3={function3} />


{/*             <Tooltip title={"Segmentation"}>
              <IconButton onClick={showSegmentationModalFunc}>
                <BlurCircularIcon fontSize="large" />
              </IconButton>
            </Tooltip>  */}


            <Tooltip title={"Reset"}>
              <IconButton onClick={resetAllViewports}>
                <RestartAltOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>

            {/*     Show AI findings     
           <Tooltip title={"Show Finding"}>
              <IconButton onClick={showAiResults}>
                <RestartAltOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
 */}

            {/* Show 3d Lesion representation */}
{/*             <Tooltip title={"3d"}>
              <IconButton onClick={handleOpenDialog}>
                <RestartAltOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip> */}





{/*             <Divider orientation="vertical" sx={{ marginLeft: "15px",marginRight: "15px"  }} />
            <AItools currentStudy={currentStudy}/> */}

 





            <Divider orientation="vertical" sx={{ marginLeft: "15px",marginRight: "15px" }} />

            <Tooltip title={"Fullscreen"}>
              <IconButton onClick={startFullSCreen} 
              //sx={{ marginLeft: "30px" }}
              >
                <FullscreenOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Second Monitor"}>
              <IconButton onClick={SecondMonitor}>
                <QueuePlayNextOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>


          </Box>
        </Grid>



        {/*Reporting Part*/}
        <Grid item xs={2} xl={2} justifyContent={"right"} display="flex">

          {/* {customizedMobileDevice?
          <IconButton
            sx={{ fill: "transparent", color: colors.novuColors[120], ":hover": { backgroundColor: "transparent" } }}
            onClick={() => setOpenReport(true)}>
         
            <AssignmentIndIcon
              sx={{
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }} />

          </IconButton>
          :
          <Button variant="text"
            onClick={() => sterlizeAIFindingsFunction()}
          >
            <Typography style={{ marginTop: "-5px", marginRight: "25px" }}
              color={colors.novuColors[120]}
              sx={themeStyles.typography.h51}>
              Request support
            </Typography>
          </Button> 
          
        }*/}
          <IconButton
            sx={{ fill: "transparent", color: colors.novuColors[120], ":hover": { backgroundColor: "transparent" } }}
            onClick={() => setOpenReport(true)}>
            {/*  <PatientsIcon /> */}
            <ReportIcon
              sx={{
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }} />
            <Typography style={{ marginTop: "-5px" }}
              sx={themeStyles.typography.h51}>
              Report
            </Typography>
          </IconButton>
        </Grid>

        {
          !loadingData ?
            <ReportCase
              open={openReport}
              //caseComments={caseComments}
              //addcomments={addcomments}
              //manualfindings={manualfindings}
              handleCancel={() => setOpenReport(false)}
              currentStudy={currentStudy}
            /> : ""
        }



      </Grid>

{/*       <ThreeDObject open={isDialogOpen} onClose={handleCloseDialog} polyPoints={dummyPolyPoints} />
 */}    </Toolbar>

  );
};

export default ViewerToolBar;