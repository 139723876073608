import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ChatMsg from './messages';



export default function MessagesFlow({messagesFlow}) {
    console.log("from  MessagesFlow component", messagesFlow)
  return (
    <Box sx={{ width: 'inherit', height: 'inherit'}}>
        
        {messagesFlow.map((message, index) => {
            console.log(message)
            const side = (index % 2  == 0) ? "left" : "right";
            return (
            <ChatMsg
            side={side}
            avatar={''}
            Name={message["from"]}
            time={message["timestamp"]}
            messages={[message["comment"]]}
            ChatTypographyProps={{ backgroundColor: "inherit" }}
            />
            )

        }) }

{/*      <ChatMsg
            avatar={''}
            userFirstName={'Sherief'}
            userLastName={'Emam'}
            time={'16/03/2023, 4:32pm'}
            messages={[
              'Hi Jenny, How r u today?',
              'Did you train yesterday',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat lacus laoreet non curabitur gravida.',
            ]}
            ChatTypographyProps={{ backgroundColor: "inherit" }}
          />
          <ChatMsg
            side={'right'}
            messages={[
              "Great! What's about you?",
              'Of course I did. Speaking of which check this out',
            ]}
          />
          <ChatMsg avatar={''} messages={['Im good.', 'See u later.']} /> */}
    </Box>
  );
}