import React from 'react'
import {Text, View, Image,StyleSheet} from '@react-pdf/renderer';


const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald'
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 8,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    footer: {
      position: 'absolute',
      fontSize: 8,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

function Footer() {
  return (
    <>
   <Text style={styles.footer} render={({ pageNumber, totalPages }) => (
      ` This Report has been automatically generated using NOVU AI and requires no manual signature.`
    )} fixed />

    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
      ` ${pageNumber} / ${totalPages}`
    )} fixed />

   

  
    
{/*     <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
      `This Report has been automatically generated using NOVU AI`
    )} fixed />  */}
    </>


  )
}

export default Footer