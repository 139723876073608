import React from 'react'
import { useEffect, Suspense, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import { Box, Toolbar } from "@mui/material";
import Paper from '@mui/material/Paper';
import {  useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
//import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import {HealthCheckAll } from "../../../functions/api/data/settings";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SystemHeathcheck from './heathcheck';
import { RigesterOrganisationForm } from './RigesterOrganisation';
import { RigesterTeamMemberForm } from './RigesterTeamMember';
import AdminIframe from './admin';
import { ViewerSettings } from './viewersettings';

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


// const useStyles = makeStyles((theme) => ({
//     backdrop: {
//       zIndex: theme.zIndex.drawer + 1,
//       color: '#fff',
//     },
//   }));


//const resource = fetchData();

export function Settings(){
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles();
  const [value, setValue] = React.useState('1');


  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main>
      <Toolbar variant="dense" />
      <Box >

{/*     //
        //
        // START ADDING ALL PAGE CONTENTS HERE
        //
        // 
*/}

        <>


    <Box sx={{ width: '100%'}}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={TabhandleChange} aria-label="lab API tabs example" 
          textColor="secondary"
          indicatorColor="secondary">
            <Tab label="System Check" value="1" />
            <Tab label="Viewer Settings" value="5" />
            <Tab label="Organisation" value="2" />
            <Tab label="Add Team member" value="3" />
            <Tab label="Admin" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><SystemHeathcheck/></TabPanel>
        <TabPanel value="2"><RigesterOrganisationForm /></TabPanel>
        <TabPanel value="3"><RigesterTeamMemberForm /></TabPanel>
        <TabPanel value="4"><AdminIframe /></TabPanel>
        <TabPanel value="5"><ViewerSettings  /></TabPanel>
      </TabContext>
    </Box>





      </>


{/*     //
        //
        // END PAGE CONTENTS HERE
        //
        // 
*/}

        </Box>
      </Main>
    </Box>
  );
};

//export default Settings;
