import React, { useState } from 'react'

import { Divider, Grid, TextField, Typography } from '@mui/material'
import { MenuItem, FormControl, InputLabel, Select, Button } from '@mui/material';
import { Field, Form, ErrorMessage } from 'formik'
import { countrieslist } from './countries-list';


const countryOptions = Object.values(countrieslist)
    .map((country) => country.label)
    .sort((a, b) => a.localeCompare(b));


function License({ errors, touched }) {

    const manufacturerFields = [
        {
            "name": "General.manufacturer.Name",
            "label": "Name"
        },
        {
            "name": "General.manufacturer.Address.Street",
            "label": "Street"
        },
        {
            "name": "General.manufacturer.Address.City",
            "label": "City"
        },
        {
            "name": "General.manufacturer.Address.ZipCode",
            "label": "ZipCode"
        },
        {
            "name": "General.manufacturer.Address.Country",
            "label": "Country"
        },
        {
            "name": "General.manufacturer.Email",
            "label": "Email"
        }

    ]


    return (
        <><Grid item xs={12} padding={"20px"}>
            <Field
                as={TextField}
                name="General.Productname"
                label="Product Name"
                fullWidth
                variant="outlined" />


        </Grid>
            <Grid item xs={12} padding={"20px"}>
                <Typography >Manufacturer Details: </Typography>
            </Grid>


            <Grid item xs={12} marginLeft={"20px"} padding={"20px"}>

                {manufacturerFields.map((field, index) => (
                    field["label"] == "Country" ?
                        <FormControl fullWidth>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Field
                                as={Select}
                                name="General.manufacturer.Address.Country"
                                labelId="country-label"
                                label="Country"
                                style={{ marginBottom: '10px' }}
                            >
                                {countrieslist.map((country, index) => (
                                    <MenuItem key={index} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                        :
                        
                            <Field
                                as={TextField}
                                name={field.name}
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                style={{ paddingBottom: '10px' }}
                            />
                           

                ))}
                
                {errors ? console.log("errors", errors) : ""}







            </Grid>
            <Grid item xs={12}>
                {
                    typeof errors === 'object'
                        && Object.keys(errors).length === 0 
                        ?
                        null: 
                        Object.keys(errors).map((error)=>(
                            <Typography>{errors[error]}</Typography>))
                }
            </Grid>
        </>
    )
}

export default License