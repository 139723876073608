import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { themeSettings, tokens } from "../../../../../../utilityComponents/theme";
import DirectionSnackbar from "../../../../../subComponents/snackbars/snackbar";
import DeepLookSegmentationPostAPI from "../../../../../../functions/api/AItoolsPartners/DeepLook";
import Slider from "@mui/material/Slider";
import DeepLookSegmentationImage from "./SampleImage";

// const TempAIfindings = {
//   mamilla: {
//     point: { x: 1529, y: 1823 },
//     radius: 40,
//     isEditable: false,
//     isVisible: true,
//     style: {
//       color: '#E72828',
//       lineWidth: 2
//     }
//   },
//   muscleLine: {
//     startPoint: { x: 2043, y: 820 },
//     endPoint: { x: 2393, y: 1356 },
//     isEditable: false,
//     isVisible: true,
//     style: {
//       color: '#E72828',
//       lineWidth: 2,
//       dashedLine: false
//     }
//   },

//   regionLines: [
//     {
//       uid: 'region-line-1',
//       startPoint: { x: 1476, y: 1097 },
//       middlePoint: { x: 2149, y: 1601 },
//       endPoint: { x: 2021, y: 2242 },
//       isEditable: false,
//       isVisible: true,
//       style: {
//         color: '#0D88B5',
//         lineWidth: 1,
//         dashedLine: true
//       }
//     },
//     {
//       uid: 'region-line-2',
//       startPoint: { x: 1528, y: 921 },
//       middlePoint: { x: 2294, y: 1564 },
//       endPoint: { x: 2230, y: 2279 },
//       isEditable: false,
//       isVisible: true,
//       style: {
//         color: '#0D88B5',
//         lineWidth: 2,
//         dashedLine: false
//       }
//     }
//   ],
//   lesions: [
//     {
//       uid: 'lesion-1-1',
//       type: 'ellipse-lesion',
//       point: { x: 1857, y: 896 },
//       xRadius: 135,
//       yRadius: 75,
//       rotationAngle: 25,
//       isEditable: false,
//       isVisible: true,
//       style: {
//         color: '#0E5715',
//         fillColor: '#0E5715',
//         fillOpacity: 0.2,
//         dashedLine: true
//       }
//     },
//     {
//       uid: 'lesion-1-2',
//       type: 'polyline-lesion',
//       isEditable: false,
//       isVisible: true,
//       points: [
//         { x: 1624, y: 1026 },
//         { x: 1722, y: 969 },
//         { x: 1815, y: 1033 },
//         { x: 1752, y: 1107 },
//         { x: 1707, y: 1087 },
//         { x: 1663, y: 1106 }
//       ],
//       style: {
//         color: '#C6C413',
//         fillColor: '#C6C413',
//         fillOpacity: 0.2
//       }
//     }
//   ]
// }
function DeeplookContent({ finding, size }) {
  console.log("DeeplookContent", finding)
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })
  const [tempAIfindings, setTempAIfindings] = useState({
      "mamilla": {
        point: { x: 1529, y: 1823 },
        radius: 40,
        isEditable: false,
        isVisible: true,
        style: {
          color: '#E72828',
          lineWidth: 2
        }
      },
      // "muscleLine": {
      //   startPoint: { x: 2043, y: 820 },
      //   endPoint: { x: 2393, y: 1356 },
      //   isEditable: false,
      //   isVisible: true,
      //   style: {
      //     color: '#E72828',
      //     lineWidth: 2,
      //     dashedLine: false
      //   }
      // },
      "muscleLine": {
        startPoint: { x: 750, y: 1031 },
        endPoint: { x: 762, y: 1041 },
        isEditable: false,
        isVisible: true,
        style: {
          color: '#E72828',
          lineWidth: 2,
          dashedLine: false
        }
      },
    
      "regionLines": [
        {
          uid: 'region-line-1',
          startPoint: { x: 1476, y: 1097 },
          middlePoint: { x: 2149, y: 1601 },
          endPoint: { x: 2021, y: 2242 },
          isEditable: false,
          isVisible: true,
          style: {
            color: '#0D88B5',
            lineWidth: 1,
            dashedLine: true
          }
        },
        {
          uid: 'region-line-2',
          startPoint: { x: 1528, y: 921 },
          middlePoint: { x: 2294, y: 1564 },
          endPoint: { x: 2230, y: 2279 },
          isEditable: false,
          isVisible: true,
          style: {
            color: '#0D88B5',
            lineWidth: 2,
            dashedLine: false
          }
        },

      ],
      "lesions": [
        {
          uid: 'lesion-1-1',
          type: 'ellipse-lesion',
          point: { x: 1857, y: 896 },
          xRadius: 135,
          yRadius: 75,
          rotationAngle: 25,
          isEditable: false,
          isVisible: true,
          style: {
            color: '#0E5715',
            fillColor: '#0E5715',
            fillOpacity: 0.2,
            dashedLine: true
          }
        },
        {
          uid: 'lesion-1-2',
          type: 'polyline-lesion',
          isEditable: false,
          isVisible: true,
          points: [
            { x: 1624, y: 1026 },
            { x: 1722, y: 969 },
            { x: 1815, y: 1033 },
            { x: 1752, y: 1107 },
            { x: 1707, y: 1087 },
            { x: 1663, y: 1106 }
          ],
          style: {
            color: '#C6C413',
            fillColor: '#C6C413',
            fillOpacity: 0.2
          }
        }
      ]
    })

  const [loadingData, setLoadingData] = React.useState(false);
  const [SegmentationResponse, setSegmentationResponse] = useState(null)
  const [margin, setMargin] = useState(null)
  const [marginImage, setMarginImage] = useState("")
  const [dimensions, setDimensions] = useState({})
  const [defaultMargin, setDefaultMargin] = useState(5)
  const [maxMargin, setMaxMargin] = useState(10);
  const values = {
    "instanceuid": finding?.instanceUid,
    "centerPosition": {
      "x": finding?.data.centerPosition.x,
      "y": finding?.data.centerPosition.y
    },
    "xRadius": finding?.data.xRadius,
    "yRadius": finding?.data.yRadius,
    "spacing": finding?.pixelSpacing,
    "window":finding?.window,
    "level": finding?.level,
    "margin": "all"
  }

  useMemo(() => {
    GetDefaultSegmentation()
    //GetAllSegmentation()
  }, [])

  useEffect(() => {
    if (margin) {
      updateMeasurementsinViewer(margin, dimensions)
    }

  }, [margin, dimensions])

  function GetDefaultSegmentation() {
    setLoadingData(true)

    try {
      DeepLookSegmentationPostAPI(values, "default").then(response => response.json()).then((segmentation) => {
        //handleReturnedValues(segmentation)
        console.log(segmentation, "segmentation")
        setDimensions(segmentation["dimensions"])
        const SegmentationResponse = JSON.parse(segmentation["SegmentationResponse"])
        console.log(SegmentationResponse, "SegmentationResponse")
        HandleDLPreciseResponse(SegmentationResponse)
        setLoadingData(false)
        
        //updateMeasurementsinViewer(SegmentationResponse["margins"][SegmentationResponse["default_margin_index"]], segmentation["dimensions"])
        //viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', tempAIfindings)
        const measurementData = {
          id: 'ruler-id-1',
          type: 'ruler',
          containerId: 'viewport-container-1-1',
          studyUid: 'study-uid-1',
          seriesUid: 'series-uid-1',
          instanceUid: 'instance-uid-1',
          colors: {
              regularColor: '#FFA500',
              activeColor: '#33CCFF',
              markedColor: '#009BFF',
              activeLabelColor: '#FFF'
          },
          data: {
              startPosition: [25, 0, 0],
              endPosition: [75, 50, 0],
          },
          values: {
              length: '70.71 mm'
          }
      };
      
        //viewportsCore.measurementsController.createNewMeasurement(containerId, measurementData);

      }).catch(error => {
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while calling DeepLook Endpoint.", severity: "error" })
        setLoadingData(false)
        console.log(error)
      })
    } catch (error) {
      setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while calling DeepLook Endpoint.", severity: "error" })
      setLoadingData(false)
    }
  }

  function GetAllSegmentation() {
    setLoadingData(true)

    try {
      DeepLookSegmentationPostAPI(values, "all").then(response => response.json()).then((segmentation) => {
        //handleReturnedValues(segmentation)
        console.log(segmentation, "segmentation")
        setDimensions(segmentation["dimensions"])
        const SegmentationResponse = JSON.parse(segmentation["SegmentationResponse"])
        console.log(SegmentationResponse, "SegmentationResponse")
        HandleDLPreciseResponse(SegmentationResponse)
        setLoadingData(false)
        //updateMeasurementsinViewer(SegmentationResponse["margins"][SegmentationResponse["default_margin_index"]], segmentation["dimensions"])
        //viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', tempAIfindings)

      }).catch(error => {
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while calling DeepLook Endpoint.", severity: "error" })
        setLoadingData(false)
        console.log(error)
      })
    } catch (error) {
      setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while calling DeepLook Endpoint.", severity: "error" })
      setLoadingData(false)
    }
  }

  function HandleDLPreciseResponse(SegmentationResponse){

    setSegmentationResponse(SegmentationResponse)
    setDefaultMargin(SegmentationResponse["default_margin_index"])
    setMaxMargin(SegmentationResponse.margins.length)
    setMargin(SegmentationResponse["margins"][SegmentationResponse["default_margin_index"]])
    setMarginImage(SegmentationResponse["margins"][SegmentationResponse["default_margin_index"]]["rendered_image_data"])
  }


  function updateMeasurementsinViewer(margin, dimensions) {
    console.log("handleMeasurementinViewer triggered", dimensions, margin.major_axis, margin.minor_axis)
    // //const minor_axis =
    // const updatedTempAIfindings = { ...tempAIfindings };

    // //Update Major access

    // // Update startPoint with begin array values
    // updatedTempAIfindings.regionLines[0].startPoint = {
    //   x: margin.major_axis.begin[0]+dimensions.left,
    //   y: margin.major_axis.begin[1]+dimensions.top
    // };

    // // Update endPoint with end array values
    // updatedTempAIfindings.regionLines[0].endPoint = {
    //   x: margin.major_axis.end[0]+dimensions.left,
    //   y: margin.major_axis.end[1]+dimensions.top
    // };

    // // Calculate middlePoint for a straight line
    // const startX = updatedTempAIfindings.regionLines[0].startPoint.x;
    // const startY = updatedTempAIfindings.regionLines[0].startPoint.y;
    // const endX = updatedTempAIfindings.regionLines[0].endPoint.x;
    // const endY = updatedTempAIfindings.regionLines[0].endPoint.y;

    // const middleX = (startX + endX) / 2;
    // const middleY = (startY + endY) / 2;

    // updatedTempAIfindings.regionLines[0].middlePoint = {
    //   x: middleX,
    //   y: middleY
    // };

    // // Update the state with the modified object
    // //setTempAIfindings(updatedTempAIfindings);
    // console.log(tempAIfindings,"TempAIfindings")
    // console.log(updatedTempAIfindings,"updatedTempAIfindings")

    console.log(tempAIfindings,"TempAIfindings before editing")
    // Make sure to use the functional form of setState if your state update depends on the previous state
setTempAIfindings(prevState => {
  // Create a new object with the updated values
  const updatedTempAIfindings = { ...prevState };
  // Update the object with the new values
  updatedTempAIfindings.regionLines[0].startPoint = { x: Math.round(margin.major_axis.begin[0] + dimensions.left), y: Math.round(margin.major_axis.begin[1] + dimensions.top) };
  updatedTempAIfindings.regionLines[0].endPoint = { x: Math.round(margin.major_axis.end[0] + dimensions.left), y: Math.round(margin.major_axis.end[1] + dimensions.top) };

  // Calculate middlePoint for a straight line
  const startX = updatedTempAIfindings.regionLines[0].startPoint.x;
  const startY = updatedTempAIfindings.regionLines[0].startPoint.y;
  const endX = updatedTempAIfindings.regionLines[0].endPoint.x;
  const endY = updatedTempAIfindings.regionLines[0].endPoint.y;
  const middleX = (startX + endX) / 2;
  const middleY = (startY + endY) / 2;
  updatedTempAIfindings.regionLines[0].middlePoint = { x: middleX, y: middleY };

  // Log the updated state to verify the changes
  console.log("Updated tempAIfindings:", updatedTempAIfindings);
  //viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', tempAIfindings);
  // Return the updated state
  return updatedTempAIfindings;
}, () => {
  // Callback function after state has been updated
  //viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', tempAIfindings);
  console.log(tempAIfindings,"TempAIfindings after editing")
  // You can perform further operations here, such as triggering other functions or updating other state variables
});

    //viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', updatedTempAIfindings);

  }

  
  const handleChangeMargin = (event, newValue) => {
    //setValue(newValue);
    setDefaultMargin(newValue);
    setMarginImage(SegmentationResponse.margins[newValue].rendered_image_data);
    setMargin(SegmentationResponse.margins[newValue])
    //    console.log("Slider value changed to:", newValue);
    handleMeasurements(SegmentationResponse.margins[newValue])
  };

  function handleMeasurements(margins) {

  }

  const list = () => (

    <Grid container spacing={2}
      height={"35vh"}
      justifyContent={"center"}
      display={"flex"} alignItems={"center"} >
      <Grid item xs={12} justifyContent={"center"} display={"flex"}>
        <Box width="90%" >
           <Button sx={{ color: colors.novuColors[120], width: "inherit" }}
              onClick={GetAllSegmentation}>Get Additional Margins</Button> 

          <Typography gutterBottom>Margins</Typography>
          <Slider
            defaultValue={defaultMargin}
            //sx={{}}
            color="secondary"
            value={defaultMargin}
            disabled={SegmentationResponse ? false : true}
            step={1}
            marks
            min={0}
            max={maxMargin - 1}
            onChange={handleChangeMargin}
            //getAriaValueText={valuetext}
            aria-label="Margins"
          />
        </Box>

      </Grid>

      <Grid item xs={12} justifyContent={"flex-start"} display={"flex"} alignItems={"center"} >
    
          <Typography sx={themeStyles.typography.h411}>
            Region of Interest:
          </Typography>
      
          </Grid>
      <Grid item xs={12} justifyContent={"center"} display={"flex"} alignItems={"center"} >
        <DeepLookSegmentationImage image_data={marginImage} size={size} 
        //window="580" level="540" 
        />
      </Grid>
      <Grid item xs={6} justifyContent={"center"} display={"flex"}>
        <Box width="90%">
          <Typography sx={themeStyles.typography.h411}>
            LD: {margin ? `${margin["major_axis"]["length_mm"].toFixed(2)/10} mm` : ""}
          </Typography>
          </Box>
      </Grid>
      <Grid item xs={6} justifyContent={"center"} display={"flex"}>
        <Box width="90%">
          <Typography Typography sx={themeStyles.typography.h411}>
            SD: {margin ? `${margin["minor_axis"]["length_mm"].toFixed(2)/10} mm` : ""}
          </Typography>
          </Box>
      </Grid>

      <Grid item xs={12} justifyContent={"center"} display={"flex"}>
        <Box width="90%">
          <Typography>
            Segmentation Version: {SegmentationResponse ? SegmentationResponse.segmentation_version : ""}
          </Typography>
          <Typography>
            Pixels per mm: {SegmentationResponse ? SegmentationResponse.pixels_per_mm : ""}
          </Typography>
          <Typography>Margin: {defaultMargin}</Typography>
          <Typography>
            Area mm:{" "}
            {SegmentationResponse?.margins[defaultMargin].outline.area_mm}
          </Typography>
          <Typography>
            Area Pixels:{" "}
            {SegmentationResponse?.margins[defaultMargin].outline.area_pixels}
          </Typography>
          <Typography>
            Spiculation:{" "}
            {SegmentationResponse?.margins[defaultMargin]?.outline.spiculation}
          </Typography>

        </Box>
      </Grid>
    </Grid>


  );

  return (
    <>
      <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })} />

      {loadingData ?
        <Box sx={{ width: "100%", height: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
        >
          <CircularProgress color="inherit" />
        </Box>
        :
        list()
      }
    </>



  )
}

export default DeeplookContent

