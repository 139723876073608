import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';


function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

const Alert = React.forwardRef(function Alert(props, ref) {
return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

  
export default function DirectionSnackbar({props,handleClose}) {
  const [transition, setTransition] = React.useState(TransitionUp());
  
  const {open,Message, severity } = props;

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
        <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={ {
            'vertical':'bottom',
            'horizontal':'right'
        }}
        message="Added Team member to your workspace."
        action={action}
      >
         <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
    {Message}
  </Alert>
  </Snackbar>
    </div>
  );
}