import React, { useState } from "react";
//import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import List from "@mui/material/List";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import ProfileIcon from "../../../utilityComponents/images/ProfileIcon";
import WorklistIcon from "../../../utilityComponents/images/WorklistIcon";
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

//Custom Components
import TeamDialoge from "../../subComponents/forms/Team/team"
import { Globallogout } from "../../../App";

const styles = {
  avatarStyles: {
    width: 24,
    height: 24,
  }
}


// const useStyles = makeStyles({
//   Menu_icons: {
//     fill: "#F5F5F5",
//     color: "#F5F5F5",
//   },
//   Menu_Text: {}
// })

const Menu_icons= {
  fill: "#F5F5F5",
  color: "#F5F5F5",
}



export default function AccountMenu({ FullName, user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTeam, setOpenTeam] = useState(false)
  //const classes = useStyles()
  let navigate = useNavigate();

  const open = Boolean(anchorEl);
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")

  const openTeamDialoge = () => {
    setOpenTeam(true)
  }

  ////////////////////////////////////////
  //
  // Functions 
  //
  /////////////////////////////////////////

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  ////////////////////////////////////////
  //
  // Lists structure
  //
  /////////////////////////////////////////

  const MenuItem_1 = [
    {
      text: "Worklist",
      icon: <WorklistIcon fontSize="small" sx={Menu_icons} />,
      action: () => (navigate("/demo")),
    },
    {
      text: "Profile",
      icon: <ProfileIcon fontSize="small" sx={Menu_icons} />,
      action: () => (navigate("/profile")),
    },
    {
      text: "My Workspace",
      icon: <GroupsOutlinedIcon fontSize="small" sx={Menu_icons} />,
      action: () => (navigate("/teams")),
    },
    {
      text: "Messages",
      icon: <ModeCommentOutlinedIcon fontSize="small" sx={Menu_icons} />,
      action: () => (navigate("/chat")),
    },

  ];

  const MenuItem_2 = [

    {
      text: "Settings",
      icon: <Settings fontSize="small" sx={Menu_icons} />,
      action: () => (navigate("/settings")),
    },
    {
      text: "Logout",
      icon: <Logout fontSize="small" sx={Menu_icons} />,
      action: () => (Globallogout()),
    }

  ];


  return (
    <React.Fragment>
      <TeamDialoge handleClickOpen={openTeamDialoge} open={openTeam} handleCancel={() => (setOpenTeam(false))} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ mr: "24px" }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={styles.avatarStyles} alt={FullName} src={user.avatarUrl} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        color="white"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            color: "white",
            '& .MuiList-root': {
              backgroundColor: colors.dark_grey[400]
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        {MenuItem_1.map((Item,index) => (
          <MenuItem onClick={Item.action} key={index} >
            <ListItemIcon sx={{ color: "#F5F5F5", fill: "#F5F5F5" }}>
              {Item.icon}
            </ListItemIcon>

            <Typography sx={themeStyles.typography.h71} color="#F5F5F5" >
              {Item.text}
            </Typography>

          </MenuItem>
        ))}

        <Divider />

        {MenuItem_2.map((Item, index) => (
          <MenuItem onClick={Item.action} key={index}>
            <ListItemIcon sx={{ color: "#F5F5F5", fill: "#F5F5F5" }}>
              {Item.icon}
            </ListItemIcon>
            <Typography sx={themeStyles.typography.h71} color="#F5F5F5" >
              {Item.text}
            </Typography>
          </MenuItem>
        ))}

        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon sx={{ color: "#F5F5F5", fill: "#F5F5F5" }}>
            <ProfileIcon fontSize="small" sx={{ color: "#F5F5F5", fill: "#F5F5F5" }} />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={openTeamDialoge}>
          <ListItemIcon>
            <GroupsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Team
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem> */}
      </Menu>
    </React.Fragment>
  );
}