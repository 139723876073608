import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';


export default function BarChartKPI() {
  const valueFormatter = (value) => `${value}`;
  const chartSetting = {
    yAxis: [
      {
        label: 'Total Number of Cases',
      },
    ],
    //width: 500,
    height: 500,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
    margin:{ top: 10, bottom: 30, left: 40, right: 10 }
  };
  const dataset = [
    {
      CT:59,
      MR:57,
      XRAY:86,
      MG: 21,
      quarter: 'Q1',
    },
    {
      CT:50,
      MR:52,
      XRAY:78,
      MG: 28,
      quarter: 'Q2',
    },
    {
      CT:47,
      MR:53,
      XRAY:106,
      MG: 41,
      quarter: 'Q3',
    },
    {
      CT:54,
      MR:56,
      XRAY:92,
      MG: 73,
      quarter: 'Q4',
    },
    // {
    //   CT:57,
    //   MR:69,
    //   XRAY:92,
    //   MG: 99,
    //   quarter: 'May',
    // },
    // {
    //   CT:60,
    //   MR:63,
    //   XRAY:103,
    //   MG: 144,
    //   quarter: 'June',
    // },
    // {
    //   CT:59,
    //   MR:60,
    //   XRAY:105,
    //   MG: 319,
    //   quarter: 'July',
    // },
    // {
    //   CT:65,
    //   MR:60,
    //   XRAY:106,
    //   MG: 249,
    //   quarter: 'Aug',
    // },
    // {
    //   CT:51,
    //   MR:51,
    //   XRAY:95,
    //   MG: 131,
    //   quarter: 'Sept',
    // },
    // {
    //   CT:60,
    //   MR:65,
    //   XRAY:97,
    //   MG: 55,
    //   quarter: 'Oct',
    // },
    // {
    //   CT:67,
    //   MR:64,
    //   XRAY:76,
    //   MG: 48,
    //   quarter: 'Nov',
    // },
    // {
    //   CT:61,
    //   MR:70,
    //   XRAY:103,
    //   MG: 25,
    //   quarter: 'Dec',
    // },
  ];

  return (
    <BarChart
    dataset={dataset}
    xAxis={[{ scaleType: 'band', dataKey: 'quarter' }]}
    series={[
      { dataKey: 'CT', label: 'CT', valueFormatter },
      { dataKey: 'MR', label: 'MR', valueFormatter },
      { dataKey: 'XRAY', label: 'X-RAY', valueFormatter },
      { dataKey: 'MG', label: 'MG', valueFormatter },
    ]}
      // series={[
      //   { data: [35, 44, 24, 34] },
      //   { data: [51, 6, 49, 30] },
      //   { data: [15, 25, 30, 50] },
      //   { data: [60, 50, 15, 25] },
      // ]}
      {...chartSetting}
      //height={500}
      //xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
      
    />
  );
}
