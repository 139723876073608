import React,{ useState, useEffect } from "react";
import { IconButton,Box, Paper,Collapse, TableContainer,TableCell, Table, TableRow, Typography } from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";


export default function SubTable({row}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")
    const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
        <TableContainer 
        component={Paper}
        >
      <Table size="small" aria-label="a dense table" >
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell align="center">Series No.</TableCell>
            <TableCell align="center">Series Description</TableCell>
            <TableCell align="center">Modality</TableCell>
            <TableCell align="center">Body part examined</TableCell>
            <TableCell align="center"># Instances</TableCell>

            {/* <TableCell align="right">Area&nbsp;(cm2)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          

        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* <TableCell >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
{/*         <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="center">{row["PatientMainDicomTags"]["PatientBirthDate"]?row["PatientMainDicomTags"]["PatientBirthDate"]:""}</TableCell>
        <TableCell align="center">{row["PatientMainDicomTags"]["PatientName"]?row["PatientMainDicomTags"]["PatientName"]:""}</TableCell>
        <TableCell align="center">{row["PatientMainDicomTags"]["PatientID"]?row["PatientMainDicomTags"]["PatientID"]:""}</TableCell>
        <TableCell align="center">{row["MainDicomTags"]["StudyDescription"]?row["MainDicomTags"]["StudyDescription"]:""}</TableCell>
        <TableCell align="center">{row["Series"].length}</TableCell>

      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box width={"100%"} sx={{ margin: 1 }}
            //width={"100%"} display={"flex"} 
            >
                
              <Typography variant="h6" gutterBottom component="div">
                Description:
              </Typography>


              <Typography
              sx={{ width: "100%" }}
              fullWidth
              id="outlined-multiline-static"
              //placeholder="Describe your finding"
              multiline
              rows={4}
              
              //value={row["structureReporting"]["description"]?row["structureReporting"]["description"]:""}
              //defaultValue="Default Value"
              //variant="outlined"
              //onChange={(newValue) => {AddComments2Finding(newValue.target.value)}}
              > {row["MainDicomTags"]["StudyDescription"]?row["MainDicomTags"]["StudyDescription"]:""}</Typography>        

            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}




        </TableBody>
      </Table>
    </TableContainer>



    </React.Fragment>
  );
}
