import { ContentCopy, ContentCopyOutlined, ContentCut } from '@mui/icons-material';

export const MenuListImageTools=[
    {
        icon: <ContentCut fontSize="small" />,
        name:"Cut",        
        shortcut:"⌘X",
        //functionname:customFunction
    },
    {
        icon: <ContentCopyOutlined fontSize="small" />,
        name:"COPY",        
        shortcut:"⌘Y",
        //functionname:customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        //functionname:customFunction
    }
]