export default function AddUserToWorkspace(newUser,workspaceDetails){
   // console.log("from AddUserToWorkspace", newUser)
    const values = {
        "employeeid": newUser["newUserID"],
        "WorkspaceRole": newUser["role"],
        "WorkspaceOwnerID": newUser["WorkspaceOwnerID"],
        "WorkspaceID": newUser["WorkspaceID"],
        "WorkspaceRights": newUser["rights"],
        "WorkspaceStatus": newUser["status"],
        "organisationid": newUser["organisationid"],
        "metadata":workspaceDetails
    }

    //console.log("AddUserToWorkspace", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.add_users_to_workspace, requestOptions)

}