import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Card, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { registerNewAi } from "../../../functions/api/ai/registerNewAi";

import {aisampledata} from "./sampledata"
import AICard from "./AICard";



// const useStyles = makeStyles(theme => ({
//     paper: {
//         //marginTop: theme.spacing(8),
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center"
//     },

//     form: {
//         width: "100%", // Fix IE 11 issue.
//         marginTop: theme.spacing(3)
//     },
//     submit: {
//         //margin: theme.spacing(3, 0, 2)
//     },
//     textField: {
//         backgroundColor: "black",
//         "& .MuiFormHelperText-root": {
//             backgroundColor: "transparent"
//         },
//         ":&.hover": {

//         }
//     }

// }));

export const AIProducts = () => {
    //const classes = useStyles();
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark");
    const fieldBackgroundColor = colors.greenFlow[200]

    //      "name": "ai_breast",
    //     "image_location": "ecr",
    //     "path": "/ai",
    //     "level": "information",
    //     "type": "classify",
    //     "modality": "MG",
    //     "bodyexaminationpart": "BREAST",
    //     "outcome_name": "Lesion",
    //     "outcome_values": "{'positive':'Lesion','negative':'NO Lesion'}",
    //     "negative_case": "NO Lesion",
    //     "manufacturer": "NOVU"



    return (
        <Grid container spacing={2}>
            {aisampledata.map((aiproduct,index)=>(
              <Grid Item xs={12} margin={"20px"}>
                <AICard aiproduct={aiproduct}/>
              </Grid>
            ))}

        </Grid>
    );
};
