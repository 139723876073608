//React components
import React, { useState, useEffect, useRef, useMemo } from "react";

//MUI Components
import { Box, IconButton, Table, TableContainer, Toolbar } from "@mui/material";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from "@mui/material/styles";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Grow from '@mui/material/Grow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

// Custom Components
import SearchBar from '../../../components/subComponents/searchbar/Searchbar'
import SearchBarWithChips from '../../../components/subComponents/searchbar/SearchbarWithChips'
import Stack from '@mui/material/Stack';
import TableHead_Team from './TableHead_Cases'
import TableBody_Team from './TableBody_Cases'
import TeamSettings from '../../../components/subComponents/forms/Team/teamSettings'
import SearchTeamMember from '../../subComponents/forms/Team/SearchTeamMember'
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";


// Custom functions
import { SearchMembersAPI, getUserAvatar, ListAllTeams } from '../../../functions/api/organisational/registerTeam'
import AddUserToWorkspace from '../../../functions/api/workspace/addUser'
import GetWorkspaceUsers from '../../../functions/api/workspace/getUsers'
import deleteUserFromWorkspace from '../../../functions/api/workspace/deleteUser'
import updateWorkspaceNameAPI from '../../../functions/api/workspace/updateWorkspaceInformation'
import { GetUserInformation } from "../../../functions/api/organisational/getUserInformation";
import AutoSaveComponent from "./Autosave";
import changeMemberAPI from "../../../functions/api/workspace/updateUser";
import { useSelector } from "react-redux";
import Calendar from "../../subComponents/Calender/Calendar";
import TableHead_Cases from "./TableHead_Cases";
import TableBody_Cases from "./TableBody_Cases";





//
//
// Components STYLINGs START
//
//
//
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    margin: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,

    }),
  })
);


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

//
//
// Components STYLINGs END
//
//
//

// const useStyles = makeStyles({
//   underline: {
//     "&&&:before": {
//       borderBottom: "none"
//     },
//     "&&:after": {
//       borderBottom: "none"
//     }
//   },
//   resize: {
//     fontSize: 50
//   },
// });



function search(nameKey, myArray) {
  for (let i = 0; i < myArray.length; i++) {
    console.log("search employeeid", myArray[i].employeeid, nameKey)
    if (myArray[i].employeeid === nameKey) {
      return false;
    } else {
      return true
    }
  }
}



const Cases = () => {
  //Styles 
  //const classes = useStyles();
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const [inputValue, setInputValue] = useState('')
  const [members, setMembers] = useState([])
  const [teammembers, setTeamMembers] = useState([])
  const [checked, setChecked] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  const [workspaceDetails, setWorkspaceDetails] = useState({
    WorkspaceName: "",
    WorkspaceContactPerson: "",
    WorkspaceEmail: ""
  })
  const [teams, setTeams] = useState([])
  const [timer, setTimer] = useState(null)
  const [openSettings, setOpenSettings] = useState(false)
  const [openTeamMembers, setOpenTeamMembers] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })
  const [loadingData, setLoadingData] = useState(false);

  //const user = JSON.parse(localStorage.getItem("user"))
  const user = useSelector((state) => state.user)

  const { WorkspaceName, WorkspaceContactPerson, WorkspaceEmail } = workspaceDetails

  const WorkspaceUsersData = useMemo(() => {
    const abortController = new AbortController();
    GetWorkspaceUsersFunction()
    return () => {
      abortController.abort()

    }
  }, [])


  function TeamChanged(NewMembers) {

    NewMembers.map((newMember, index) => {
      const resultObject = search(newMember["_id"], teammembers);
      console.log("resultObject", resultObject)
      if (resultObject) {
        let newUserObj = {
          "newUserID": newMember["_id"],
          "memberName": newMember["name"],
          "Useravatar": newMember["avatar"],
          "role": "Radioligist",
          "WorkspaceOwnerID": user["userID"],
          "rights": {
            "Access": false,
            "Appointments": false,
            "Upload": false,
            "Review": false,
            "ViewReport": false,
            "SignReport": false
          },
          "WorkspaceID": user["organisationid"],
          "organisationid": user["organisationid"],
          "status": "Pending",

        }

        setTeamMembers([...teammembers, newUserObj])
        AddUserToWorkspaceFunction(newUserObj)

      }
    })
  }

  function GetWorkspaceUsersFunction() {
    setLoadingData(true)
    GetWorkspaceUsers(user["userID"], user["organisationid"])
      .then(response => response.text())
      .then(result => {
        const respond = JSON.parse(Object.assign(result))
        console.log("Users in My workspace", respond["statusCode"])
        console.log("Users in My workspace", respond)

        if (respond["statusCode"] === 200) {
          console.log("Mapping Users in My workspace", respond["statusCode"])
          respond["body"].map((member) => {
            // assign Avatars and Name to teams
            const memberName = member.firstname + ' ' + member.lastname
            const Useravatar = global.config.api_get_users_avatar + "?userId=" + member.employeeid
            Object.assign(member, { "memberName": memberName });
            Object.assign(member, { "Useravatar": Useravatar });

            if (member.role === "Owner") {
              setWorkspaceDetails({ ...workspaceDetails, WorkspaceName: member.name, WorkspaceContactPerson: memberName, WorkspaceEmail: user["userEmail"], WorkspaceID: user["organisationid"] })
            }
          })

          //console.log("Mapping Users in My workspace transoformed", respond["body"])
          setTeamMembers(respond["body"])
          setChecked(true)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Occurred an Error. Please wait a moment and refresh the page.", severity: "warning" })
        }


      })
      .catch(error => {
        setLoadingData(false)
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Occurred an Error. Please wait a moment and refresh the page.", severity: "warning" })

      }
      );
  }

  function AddUserToWorkspaceFunction(newUser) {
    console.log("Adding user to workspace...", newUser)

    AddUserToWorkspace(newUser, workspaceDetails)
      .then(response => response.text())
      .then(result => {
        console.log("Added to workspace")
        //setTeamMembers(currentMembers)
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Added User to Workspace.", severity: "success" })
        //setOpenSnackbar({ open: true, ...openSnackbar })
      })
      .catch(error =>
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to add User to your Workspace.", severity: "error" })
      );
  }

  const deleteUserFromWorkspaceFunction = (user) => {
    deleteUserFromWorkspace(user)
      .then(response => response.text())
      .then(result => {
        //console.log("Deleted from workspace")
        const respond = JSON.parse(result)
        if (respond["statusCode"] === 200) {
          setTeamMembers(teammembers.filter(item => item["employeeid"] !== user["employeeid"]));
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Deleted User from your Workspace.", severity: "info" })
        }
        else {
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to deleted User from your Workspace.", severity: "error" })

        }
      })
      .catch(error =>
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to deleted User from your Workspace.", severity: "error" })
      );
  }

  function updateWorkspaceName(form) {
    updateWorkspaceNameAPI(user, form)
      .then(response => response.text())
      .then(result => {
        console.log("return from update", result)
        const respond = JSON.parse(Object.assign(result))
        if (respond["statusCode"] === 200) {
          console.log("successfully changed workspace name", form.name)
          //setTeamMembers(teammembers.filter(item => item["employeeid"] !== user["employeeid"]));
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Updated Workspace Information", severity: "success" })
          setWorkspaceDetails({ ...workspaceDetails, WorkspaceName: form.name })
        }
      })
      .catch(error =>
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update Workspace Information.", severity: "error" })
      );
  }

  const boxstyles = {
    "border": "0",
    "height": "500",
    "left": "0",
    //"position": "absolute",
    "top": "0",
    "width": "100%",
    "display": "flex"
  }


  const saveUser = (teammember) => {
    SendchangeMember(teammember)
  }

  function changeMember(type, newSet, employeeid) {

    console.log("Current members", teammembers)

    if (type === "rights") {
      const updatedTeamMembers = teammembers.map((teammember) => {
        if (teammember.employeeid === employeeid) {
          const newTeammemberObj = { ...teammember, "rights": { ...teammember["rights"], [newSet["name"]]: newSet["value"] } }
          if (autoSave) {
            console.log("newTeammemberObj", newTeammemberObj)
            SendchangeMember(newTeammemberObj)
          }
          return newTeammemberObj;

        }
        return teammember;
      })
      setTeamMembers(updatedTeamMembers);
    }

    if (type === "role") {
      const updatedTeamMembers = teammembers.map((teammember) => {
        if (teammember.employeeid === employeeid) {
          const newTeammemberObj = { ...teammember, [newSet["name"]]: newSet["value"] }

          if (autoSave) {
            SendchangeMember(newTeammemberObj)
          }

          return newTeammemberObj;

        }
        return teammember;
      })

      setTeamMembers(updatedTeamMembers);
    }



  }

  function autoSaveChangesFunction() {
    setAutoSave(!autoSave)
  }

  function SendchangeMember(teammember) {
    changeMemberAPI(teammember)
      .then(response => response.text())
      .then(result => {
        console.log("return from changeMemberAPI", result)
        const respond = JSON.parse(Object.assign(result))
        if (respond["statusCode"] === 200) {
          console.log("successfully changed Member", teammember)
          //setTeamMembers(teammembers.filter(item => item["employeeid"] !== user["employeeid"]));
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Updated Workspace Information", severity: "success" })
          //setWorkspaceDetails({...workspaceDetails,WorkspaceName:form.name})
        }
      })
      .catch(error =>
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update Workspace Information.", severity: "error" })
      );
  }


  return (
    <Box
      //sx={boxstyles} 
      id={"CHatBox"}
      sx={{ display: "flex" }}
    >
      <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })} />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main styled={{ backgroundColor: "inherit" }}
        id={"MainContentChat"}>
        <Toolbar variant="dense" />
        <Box backgroundColor="inherit"
          sx={{ margin: "-24px -24px -24px -24px" }}
        >
          {/*     //
        //
        // START ADDING ALL PAGE CONTENTS HERE
        //
        // 
        */}

          <Grid container spacing={2} sx={{ justifyContent: "center", display: "flex", marginBottom: "56px" }}>
            <Grid item xs={12} md={12}>
              <Calendar
              //calendarSelectedDate={calendarSelectedDate} 
              />
              {/* <CalenderRange /> */}
            </Grid>
          </Grid>

          <Box backgroundColor="inherit" justifyContent={"space-between"} display="flex" alignItems={"center"}
            sx={{ margin: "5.5% 24px 4% 24px" }}
          >
            <Typography
              align="left"
              //onClick={()=>setChecked(true)}
              sx={themeStyles.typography.h31}
              color={colors.text[100]}>
              Medical Clinic LA
            </Typography>
          </Box>

          {loadingData ? "" :
            <Box backgroundColor="inherit" justifyContent={"space-between"} alignItems={"center"} display={"flex"}
              sx={{ margin: "24px 24px 24px 24px" }}
            >

              {/* <Typography
                align="left"
                //onClick={()=>setChecked(true)}
                sx={themeStyles.typography.h42}
                color={colors.text[100]}>
                List of Cases
              </Typography> */}

              {/* <AutoSaveComponent autoSaveChangesFunction={autoSaveChangesFunction} autoSave={autoSave} /> */}



            </Box>

          }



          {teammembers.length > 0 ?

            <TableContainer
              sx={{ margin: "24px 24px 24px 24px", width: "inherit" }}
            //component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead_Cases />
                <TableBody_Cases
                  data={teammembers}
                  deletUser={deleteUserFromWorkspaceFunction}
                  saveUser={saveUser}
                  checked={checked}
                  changeMember={changeMember} />
              </Table>
            </TableContainer>
            : ""}






          {/*     //
        //
        // STOP PAGE CONTENTS HERE
        //
        // 
*/}
        </Box>
      </Main >
    </Box >
  );
};

export default Cases;
