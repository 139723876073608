export const HealthCheckAll = async() =>{
    try{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          //let  sterilizedData = {}
          const res = await fetch(global.config.api_healthCheck_all, requestOptions)
          const jsonRes = await res.json()
          //console.log("From HealthCheck",jsonRes)
          return jsonRes

    }catch(err){
        console.log("Error in fetching Data",err)
    }
}

export const HealthCheckBackend = async() =>{
    try{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          //let  sterilizedData = {}
          const response = await fetch(global.config.api_healthCheck_backend, requestOptions)

          return response

    }catch(err){
        console.log("Error in fetching Data",err)
        return err
    }
}

