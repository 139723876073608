// REACT Components
import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { ColorModeContext, tokens } from "../../../../utilityComponents/theme"

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import DrawerIconLeft from "../../../../utilityComponents/images/DrawerIconLeft";
import DrawerIconRight from "../../../../utilityComponents/images/DrawerIconRight";
import WorklistIcon from "../../../../utilityComponents/images/WorklistIcon";
import PatientsIcon from "../../../../utilityComponents/images/PatientsIcon";
import AppointementsIcon from "../../../../utilityComponents/images/AppointementsIcon";
import ProfileIcon from "../../../../utilityComponents/images/ProfileIcon";
import AppStoreIcon from "../../../../utilityComponents/images/AppStoreIcon";
import Tooltip from '@mui/material/Tooltip';
import ResultsAccordion from "./FindingsAccordion";

// ICONS
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Accordion, Grid } from "@mui/material";
import StudiesSeries from "./studiesAndSeries";


//import {MenuItem_1,MenuItem_2,MenuItem_3} from "./SidebarItems";


//const drawerWidth = "18.75%";
const drawerWidth = "320px";
const colors = tokens("dark");
const NavBarBgColor = colors.dark_grey[400];



////////////////////////////////////////
//
// Menu Items
//
/////////////////////////////////////////

/* 
const MenuItem_1 = [
    {
      text: "Worklist TEST",
      icon: <WorklistIcon />,
      path: "/worklist",
    },
    {
      text: "Appointments",
      icon: <AppointementsIcon />,
      path: "/appointments",
    },
    {
      text: "Patients",
      icon: <PatientsIcon />,
      path: "/patients",
    },
  ];
  
  const MenuItem_2 = [
    {
      text: "Profile",
      icon: <ProfileIcon />,
      path: "/profile",
    },
  ];
  
  const MenuItem_3 = [
    {
      text: "App Store",
      icon: <AppStoreIcon />,
      path: "/appstore",
    },
    {
      text: "Viewer",
      icon: <AppointementsIcon />,
      path: "/viewer",
    },
    {
      text: "ViewerNew",
      icon: <ProfileIcon />,
      path: "/viewerNew",
    },
  ];
  
 */

////////////////////////////////////////
//
// Drawer Styles when opened and Closed
//
/////////////////////////////////////////

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  
});

const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //width:"2.89%",
   [theme.breakpoints.up("xl")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  }, 
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    
    
  }),
}));


////////////////////////////////////////
//
//  Styles
//
/////////////////////////////////////////

const RootDrawer = styled('div')(({ theme }) => ({
  '& .MuiDrawer-paper, & .MuiListItem-root': {
    padding: "0px",
    boxSizing: 'border-box',
    backgroundColor: NavBarBgColor,
    overflow: "hidden",
    fill: "white",
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.015em",
    color: "#F5F5F5",
  },
  "& .MuiListItemIcon-root": {
    fill: "inherit"
  },
  "& .Mui-selected , &.MuiListItemText-primary": {
    padding: "0px",
    color: "#4DCFEB",
    fill: "#4DCFEB",
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.015em",
  },
}));

// const useStyles = makeStyles({

//     root: {
//         '& .MuiDrawer-paper, & .MuiListItem-root': {
//             //width: 320,
//             padding:"0px",
//             boxSizing: 'border-box',
//             backgroundColor: NavBarBgColor,
//             //color: 'white',
//             overflow:"hidden",
//             fill:"white",
//             fontFamily: 'Open Sans',
//             fontStyle: "normal",
//             fontWeight: "300",
//             fontSize: "18px",
//             lineHeight: "24px",
//             letterSpacing: "-0.015em",
//             color: "#F5F5F5",

//         },
//         "&.MuiListItemIcon-root":{
//             fill:"inherit"
//         },

//         "& .Mui-selected , &.MuiListItemText-primary":{
//             padding:"0px",
//             color:"#4DCFEB",
//             fill:"#4DCFEB",

//             fontFamily: 'Open Sans',
//             fontStyle: "normal",
//             fontWeight: "700",
//             fontSize: "18px",
//             lineHeight: "24px",
//             letterSpacing: "-0.015em",
//         },        
//       },
//     selected: {},
//     typographyStyle:{
//         fontSize:"inherit"    
//     } 
//   });



export default function ViewerSidebar({airesults,currentStudy,DragandDropSeries}) {
  const theme = useTheme();
  //const styles = useStyles();
  //const classes = useStyles()
  let navigate = useNavigate();
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const colors = tokens("dark");
  const colorMode = useContext(ColorModeContext);
  const NavBarBgColor = colors.dark_grey[600];

  function openSidebar(){
    setOpen(true)
  }



////////////////////////////////////////
//
// Drawer Component
//
/////////////////////////////////////////

  return (
    
      
      <Drawer variant="permanent" open={open} 
      className={RootDrawer}>
        {/* Placeholder under the Appbar */}
        <DrawerHeader />

        {/* Open/Close Drawer */}

        <Box sx={{ overflow: "auto" }}>
          {/* Flip the state of the Drawer */}
          <Box
            display="flex"
            justifyContent="end"
            sx={{height:"56px"}}
            onClick={() => {setOpen(!open)}}
          >
            {open ? (
              <IconButton><DrawerIconLeft /></IconButton>
            ) : (
              <IconButton><DrawerIconRight /></IconButton>
            )}
          </Box>


            <StudiesSeries  
            DragandDropSeries={DragandDropSeries}
            currentStudy={currentStudy}
            maxwidth={drawerWidth}
            airesults={airesults}
            //actions={IconsMenu(AccordinButtons)}
            SideBaropened={open}
            openSidebar={openSidebar}
            />
            
            <Divider />

            <ResultsAccordion 
            maxwidth={drawerWidth}
            airesults={airesults}
            //actions={IconsMenu(AccordinButtons)}
            SideBaropened={open}
            openSidebar={openSidebar}
            />

     




{/*           {ListStructure(MenuItem_1)}
          <Divider />
          {ListStructure(MenuItem_2)}
          <Divider />
          {ListStructure(MenuItem_3)} */}
          
        </Box>
      </Drawer>

    
  );
}
