// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login--root {
  background: linear-gradient(-360deg, #4dcfeb 3.9%, #24667c 96.09%);
  /* transform: rotate(-90deg); */
  height: 100vh;
}

.login--box {
/*   width: 43%;
  height: 55%; */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 64px;
  margin: auto;
  /*color: #fff;*/
}

.login--box .MuiFormControl-root {
  background: #267589;
  border-radius: 10px;
}
.login--box .MuiInputLabel-outlined,
.login--box .username-label {
  color: #17dfe9 !important;
}
.login--box :focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #267589 !important;
  border-radius: 10px;
}
.login--box textarea, .login--box input{
    color: #17DFE9 ;
}
 .makeStyles-textField-4, .MuiBox-root.MuiBox-root-11 .makeStyles-textField-4,
.makeStyles-margin-35.makeStyles-textField-37, .makeStyles-margin-20.makeStyles-textField-22{
    width: 90% !important;
}

.login--box--textfield{
    width: 43%;
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/signup/signup.css"],"names":[],"mappings":"AAAA;EACE,kEAAkE;EAClE,+BAA+B;EAC/B,aAAa;AACf;;AAEA;AACA;gBACgB;EACd,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;;EAEE,yBAAyB;AAC3B;AACA;EACE,0CAA0C;AAC5C;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;AACA;IACI,eAAe;AACnB;CACC;;IAEG,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,cAAc;AAClB","sourcesContent":[".login--root {\n  background: linear-gradient(-360deg, #4dcfeb 3.9%, #24667c 96.09%);\n  /* transform: rotate(-90deg); */\n  height: 100vh;\n}\n\n.login--box {\n/*   width: 43%;\n  height: 55%; */\n  background-position: bottom;\n  background-size: cover;\n  background-repeat: no-repeat;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  border-radius: 64px;\n  margin: auto;\n  /*color: #fff;*/\n}\n\n.login--box .MuiFormControl-root {\n  background: #267589;\n  border-radius: 10px;\n}\n.login--box .MuiInputLabel-outlined,\n.login--box .username-label {\n  color: #17dfe9 !important;\n}\n.login--box :focus-visible {\n  outline: -webkit-focus-ring-color auto 0px;\n}\n\n.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n  border-color: #267589 !important;\n  border-radius: 10px;\n}\n.login--box textarea, .login--box input{\n    color: #17DFE9 ;\n}\n .makeStyles-textField-4, .MuiBox-root.MuiBox-root-11 .makeStyles-textField-4,\n.makeStyles-margin-35.makeStyles-textField-37, .makeStyles-margin-20.makeStyles-textField-22{\n    width: 90% !important;\n}\n\n.login--box--textfield{\n    width: 43%;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
