import React, {useState, useEffect, useRef } from "react";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import { ColorModeContext, tokens, themeSettings } from '../../../utilityComponents/theme'
import SendComment from './addComment';
import LoadingSkelaton from './loadingSkelaton';
import { GetCommentAPI } from "../../../functions/api/messages/getComment";
import MessagesFlow from './messagesflow';


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    backgroundColor: 'rgba(196, 196, 196, 0.3)',
    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "62.50%",
    height: "60.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   }

// })



function AddCommentsToCase({ Item, rcTocken}) {
  const [openComments, setOpenComments] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [messagesFlow, setMessagesFlow] = React.useState([]);
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  //const stylingClasses = useStyles()
  const scrollRef = useRef(null);
  const webRef = useRef(null);
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem("user"))
  const chat=()=> {webRef.current.injectJavaScript(
    `window.postMessage({ externalCommand:'login-with-token', token: ${user.userLoginToken} }); true;`,
  )}

  const handleClickOpen = () => {
    setOpenComments(true);
    //getCommentsFunction(Item)

  };

  const getCommentsFunction = (Item) => {
    console.log("getCommentsFunction triggered")
    GetCommentAPI(Item)
    .then(response=>response.text())
    .then(results=> JSON.parse(results))
    .then(comments =>{
      const commentsArray = comments["body"][0]["comments_array"]
      setMessagesFlow(commentsArray)
      console.log("comments back",commentsArray)
    })
    .then(()=>setLoadingData(false)) 
  }


  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  //   }
  // }, [messagesFlow]);

  // useEffect(() => {
  //   window.addEventListener('message', function(e) {
  //     console.log(e.data.eventName); // event name
  //     //unread-changed-by-subscription
  //     //room-opened
  //     //new-message

  //     //EventName=>unread-changed and e.data.data = 1
  //     //means there is a new message waiting 
      
  //     console.log(e.data.data); // event data
  // })

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('message', function(e) {
  //       console.log("removed EventListener"); // event name
  //       //console.log(e.data.data); // event data);
  //   })}
  // }, []);

  const handleSave = () => {
    console.log("SAVE")
  }
  const handleCancel = () => {
    setOpenComments(false);
  };

  return (
    <span>

      <IconButton aria-label="delete" 
      size="small"
        onClick={handleClickOpen}>

        {Item["commentscount"] > 0 ?
          <MarkChatUnreadOutlinedIcon sx={{ color: colors.greenFlow[100] }} fontSize="inherit" /> :
          <ChatBubbleOutlineOutlinedIcon fontSize="inherit" />
        }

      </IconButton>


      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={openComments}
      >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          <Typography sx={themeStyles.typography.h411}>
            Add comments:
          </Typography>
          <Typography sx={themeStyles.typography.h6}>
            {Item["PatientName"] ? Item["PatientName"] : ""}, {Item["PatientAge"] ? Item["PatientAge"] : ""}
          </Typography>


        </BootstrapDialogTitle>


        <DialogContent
          //sx={{alignItems:"center", display:"flex"}}
          dividers={true}
        >

          <iframe
          //onLoad={onLoad}
          id="chat"
          title="chat"
          width='100%'
          //height={height}
          height="500px"
          //scrolling="no"
          //frameBorder="0"
          allowfullscreen
          ref={ref}
          //src="https://app.novu.ai/rocketchat/channel/general/?layout=embedded"
          src="https://chat.novu.ai/channel/general/?layout=embedded"
          ></iframe>


         {/* {loadingData? 
         <LoadingSkelaton />
         :
          MessagesFlowFnt(messagesFlow)        
          } */}


        </DialogContent>


        {/* <DialogActions>
          <Box width={"100%"} display={"flex"} >
            <SendComment Item={Item} getCommentsFunction={getCommentsFunction}/>
          </Box> */}

          {/*           <Button onClick={handleCancel}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Save</Typography>
          </Button> */}
        {/* </DialogActions> */}


      </BootstrapDialog>
    </span>
  );
}

export default AddCommentsToCase