//React components
import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";

//import { useSocket } from "../../../functions/communication/websocket2";
import { useSocket2 } from "../../subComponents/context/socketSimplified";
import { SocketContext } from "../../subComponents/context/SocketContext";

//MUI Components
import { Box, IconButton, Table, TableContainer, Toolbar } from "@mui/material";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { styled, useTheme } from "@mui/material/styles";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

//import { makeStyles } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";



// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

// Custom Components
import Calendar from '../../subComponents/Calender/Calendar'
import FileUploadForm from "../../subComponents/forms/uploadfiles/FileUploadForm";
import { useSelector, useDispatch } from "react-redux";
import TableController from "../../subComponents/table/TableController";
import TableHead_Worklist from "../../subComponents/table/TableHead";
import TableBody_Worklist from "../../subComponents/table/TableBody";
import { columnNames } from './tableColumnNames'
import { addNotification } from "../../../states/slices/notification/notificationSlice"

// Custom functions
//import {patientData} from '../../../functions/api/data/DummyData'
import { GetCasesByDate, GetCasesByDate_onPrem_2, sterlizePatientData } from "../../../functions/api/data/extractdata";
//import Websocket, { Websocket_connect } from "../../../functions/communication/websocket";
//import Session from 'supertokens-web-js/recipe/session';
import { useAuth } from "../../subComponents/auth/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Getnotifications from "../../../functions/api/notifications/getnotifications";
import GetCasesAPI from "../../../functions/api/data/getCases";
import { updateCases, updateUNSterlizedCases } from "../../../states/slices/cases/casesSlice";
import CalenderRange from "../../subComponents/Calender/CalenderRange/CalenderRange";

//
//
// Components STYLINGs START
//
//
//
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    margin: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      //marginLeft: 0,
    }),
  })
);


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

// const useStyles = makeStyles({
//   UploadBtnStyle: {
//     backgroundColor: colors.dark_grey[400],
//     borderColor: 'transparent',
//     color: colors.greenFlow[100],
//     "border-radius": "5px",
//     '&:hover': {
//       backgroundColor: 'transparent',
//       //color: '#3c52b2',
//     },
//   },
//   CustomOverlayStyle: {
//     color: colors.dark_grey[200],
//     marginTop: "300"
//   }
// })



// const CustomOverlay = () => {
//   const classes = useStyles()

//   return (
//     <Grid item xs={12} xl={12} justifyContent="center" display="flex"
//       marginTop="300px">
//       <Typography className={classes.CustomOverlayStyle} sx={themeStyles.typography.h0}>
//         Your schedule is empty for this day. Enjoy!
//       </Typography>
//     </Grid>
//   )
// }

//
//
// Components STYLINGs END
//
//
//

// const Sucessfullynotify = (message) => toast.success(message, {
//   position: "bottom-right",
//   autoClose: 3000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: false,
//   draggable: false,
//   progress: undefined,
//   theme: "dark",
//   style: {
//     width: "300px"
//   }
// });

// const Infonotify = (message) => toast.info(message, {
//   position: "bottom-right",
//   autoClose: 3000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: false,
//   draggable: false,
//   progress: undefined,
//   theme: "dark",
//   style: {
//     width: "300px"
//   }
// });


const Errornotify = (message) => toast.error(message, {
  position: "bottom-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "dark",
  style: {
    width: "300px"
  }
});





const Demo = () => {
  //Styles 
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles()
  const SuspiciousLevels = [0, 1, 2, 3]

  // States 
  //const [selectedDate, setSelectedDate] = useState(new Date())

  const [casesReturned, setCasesReturned] = useState(false)
  const [formUploader, setFormUploader] = useState(false);
  const [openFileUploadForm, setopenFileUploadForm] = useState(false);
  const [patientData_0, setPatientData_0] = useState([])
  const [patientData_1, setPatientData_1] = useState([])
  const [patientData_2, setPatientData_2] = useState([])
  const [patientData_3, setPatientData_3] = useState([])
  const [patientData, setPatientData] = useState({})
  let navigate = useNavigate();
  const { userLoggedIN, onLogout, onLogin } = useAuth();

  //Websocket
  //const socket = useSocket();
  const socket = useContext(SocketContext);

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })
  const [response, setResponse] = useState("");
  const [unSterlizedData, setunSterlizedData] = useState([]);
  const [sterlizedData, setSterlizedData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnNames);
  /*   const SocketENDPOINT = global.config.websocketServerEndpoint;
    const SocketPORT = global.config.websocketServerEndpointport; */

  // REDUX
  const dispatch = useDispatch()
  const firstname = useSelector(state => state.user.firstname)
  const lastname = useSelector(state => state.user.lastname)
  const user = useSelector((state) => state.user)
  const workspace = useSelector((state) => state.Defaultworkspace)
  const notificationRedux = useSelector((state) => state.notifications)
  const { startDate, endDate, IconActive }  = useSelector((state) => state.date)


  //const workspaces = JSON.parse(localStorage.getItem("workspaces"))
  const workspaces = user["userWorkspaces"]
  const Defaultworkspace = useSelector((state) => state.Defaultworkspace)
  const cases = useSelector((state) => state.cases)
  const rcTocken = window.sessionStorage.getItem('Meteor.loginToken')

  const worklistData = useMemo(() => { return handleGetCasesByDate(startDate, endDate) }, [startDate, endDate, Defaultworkspace])
  const Notificationfunction = useMemo(() => { return getNotifications() }, [])


  function getNotifications() {
    Getnotifications(user["userID"])
      .then(response => response.text())
      .then(result => {
        const respond = JSON.parse(Object.assign(result))
        //console.log("notifications", respond)
        if (respond["statusCode"] === 200) {
          //console.log("notifications body", respond["body"])
          dispatch(addNotification(respond["body"]))
          setNotifications(respond["body"])
        }
      }).catch(error => {
        //setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while getting your notifications.", severity: "warning" })
      }
      )
  }

  useEffect(() => {
    sterlizeResults(cases["unSterlizedCases"])
  }, [cases])


  async function handleGetCasesByDate(startDate, endDate) {
    setLoadingData(true)
    //console.log("Running handleGetCasesByDate ")
    //GetCasesByDate_onPrem_2(startDate, endDate,startDate, endDate,user)
    GetCasesAPI(user["userID"], Defaultworkspace["defaultWorkspaceID"], startDate, endDate)
      .then(response => response.text())
      .then(results => {
        //console.log("response sterlizedData 1", results)
        const respond = JSON.parse(Object.assign(results))
        //console.log("response sterlizedData 2", respond)
        if (respond["statusCode"] === 200) {
          dispatch(updateUNSterlizedCases(respond["body"]))
          switch (respond["body"].length) {
            case 0:
              //console.log("response sterlizedData 3", respond)
              setCasesReturned(false)
              setOpenSnackbar({ ...openSnackbar, open: true, Message: "No cases were found.", severity: "info" })
              break;


            default:
              dispatch(updateUNSterlizedCases(respond["body"]))
              setCasesReturned(true)
              sterlizeResults(respond["body"])

              break;
          }
          setLoadingData(false)
        }
        else {
          setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update the Worklist. Please try again later.", severity: "warning" })

        }
      }).catch(errors => {
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update the Worklist. Please try again later.", severity: "warning" })
        //console.log("Loading Error ", errors)
        setLoadingData(false)
      })

  }

  function sterlizeResults(unSterlizedData) {

    const sterlizedData = sterlizePatientData(unSterlizedData)
    //console.log("sterlizeResults", sterlizedData)
    setPatientData(sterlizedData)
    setPatientData_0(sterlizedData["0"])
    setPatientData_1(sterlizedData["1"])
    setPatientData_2(sterlizedData["2"])
    setPatientData_3(sterlizedData["3"])
  }

  function onColumnsVisibilityChange(visibleColumns){
    setVisibleColumns(visibleColumns)
  console.log("visibleColumns",visibleColumns)
  }





  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}
      />
      <Topbar />
      <Sidebar />
      <Main styled={{ backgroundColor: "inherit" }} >
        <Toolbar variant="dense" />
        <Box backgroundColor="inherit">
          {/*     //
        //
        // START ADDING ALL PAGE CONTENTS HERE
        //
        // 
*/}

          {/* <Grid container spacing="2"> */}
          {/* 
      
      Calendar Component
      
    */}
          <Grid container spacing={2} sx={{ justifyContent: "center", display: "flex", marginBottom: "56px" }}>
            <Grid item xs={12} md={6}>
            <Calendar 
            //calendarSelectedDate={calendarSelectedDate} 
            />  
           {/* <CalenderRange /> */}
            </Grid>


            {/* Upload Button Component */}

            {workspace["defaultWorkspaceRights"]["Upload"] ?
              <Grid item xs={12} md={6}
                sx={{ justifyContent: "end", display: "flex" }}
              >
                <FileUploadForm />
              </Grid> : ""}
          </Grid>

          {casesReturned ?
            <Grid container spacing={2} sx={{ justifyContent: "center", display: "flex" }}>
              <Grid item xs={12} xl={12} sx={{ justifyContent: "center", display: "flex" }} >
                <TableController showClassifications={true} columnNames={columnNames} onColumnsVisibilityChange={onColumnsVisibilityChange} 
                  RefreshCases={()=>handleGetCasesByDate(startDate, endDate) } />
              </Grid>

              <Grid item xs={12} xl={12} sx={{ justifyContent: "center", display: "flex" }} >
                <TableContainer component={Table}>
                  <Table aria-label="simple table"
                  //sx={{ minWidth: "1070px", maxWidth: "100%" }}
                  >
                    <TableHead_Worklist showFirstRow={true} visibleColumns={visibleColumns} columnNames={columnNames} />

                    {patientData_3 ?
                      <TableBody_Worklist rcTocken={rcTocken} visibleColumns={visibleColumns} columnNames={columnNames} suspiciouslevel="3" data={patientData_3} id="3" />
                      : ""}
                    {patientData_2 ?
                      <TableBody_Worklist rcTocken={rcTocken} visibleColumns={visibleColumns} columnNames={columnNames} suspiciouslevel="2" data={patientData_2} id="2" />
                      : ""}
                    {patientData_1 ?
                      <TableBody_Worklist rcTocken={rcTocken} visibleColumns={visibleColumns} columnNames={columnNames} suspiciouslevel="1" data={patientData_1} id="1" />
                      : ""}
                    {patientData_0 ?
                      <TableBody_Worklist rcTocken={rcTocken} visibleColumns={visibleColumns} columnNames={columnNames} suspiciouslevel="0" data={patientData_0} id="0" />
                      : ""} 

 
                  </Table>
                </TableContainer>
              </Grid>

            </Grid>


            : //(CustomOverlay()) 

            <><Grid item xs={12} xl={12} justifyContent="center" display="flex"
              //marginTop="300px"
              >
              <Typography
                //className={classes.CustomOverlayStyle} 
                sx={[themeStyles.typography.h0, { color: colors.dark_grey[200], marginTop: "300" }]}>
                Your schedule is empty for this day. Enjoy!
              </Typography>
            </Grid>
            <Grid item xs={12} xl={12} justifyContent="center" display="flex"
              //marginTop="300px"
              >
                <Typography
                  //className={classes.CustomOverlayStyle} 
                  sx={[themeStyles.typography.h4, { color: colors.dark_grey[200], marginTop: "300", display:"flex",alignItems:"center" }]}>
                  Try choosing another date or press <AllInclusiveIcon sx={{ color:colors.text[100], marginLeft:"10px", marginRight:"10px" }} /> to select all cases.

                </Typography>
              </Grid>
              </>

          }

          <ToastContainer
            position="bottom-right"
            autoClose={1500}
            limit={3}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            //pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="dark" />

          {/*     //
        //
        // STOP PAGE CONTENTS HERE
        //
        // 
*/}
        </Box>
      </Main>
    </Box>
  );
};

export default Demo;
