//React components
import { useState, useRef } from "react";
// MUI Components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, IconButton, Typography, Popover } from "@mui/material";
import { Box } from "@mui/system";
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from "react-redux";
import dayjs from 'dayjs';
import CalendarArrowLeft from "../../../utilityComponents/images/CalendarArrowLeft";
import CalendarArrowRight from "../../../utilityComponents/CalendarArrowrRight";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import WeekPicker from "./Weekpicker";
import BasicDateCalendar from "./CalenderPaper";
import { themeSettings, tokens } from "../../../utilityComponents/theme";
import { updateDates, updateIconActive, updateStartDate } from "../../../states/slices/date/dateSlice";

// Function to format the date as "Thursday, March 28, 2024"
function formatLongDate(date) {
  if (date) { return date.toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }); }
};

// Function to format the date as "2024-03-28"
function formatISODate(date) {
  console.log("formatISODate", date)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export default function Calendar() {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const { startDate, endDate, IconActive } = useSelector((state) => state.date);

  const dispatch = useDispatch()
  const [fullDate, setFullDate] = useState(dayjs(new Date()));
  const [textDate, setTextDate] = useState(formatLongDate(new Date()));
  const [shortdate, setShortDate] = useState(dayjs(new Date()));
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCalendar = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setIsOpen(false);
  };


  const handleDateChange = (date) => {
    dispatch(updateIconActive(false))
    // Date Formate
    //Mon May 06 2024 21:49:35 GMT+0200 (Central European Summer Time)
    // console.log(date)
    setFullDate(date)
    setShortDate(dayjs(formatISODate(date)))
    setTextDate(formatLongDate(date))
    setIsOpen(false)

    // Handling Redux date that updates the get API
    // Taking the current Date as start and enddate, till 
    // Daterange calendar is implemented
    const startDateString = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const dates = {
      "startDate": startDateString,
      "endDate": startDateString
    }
    dispatch(updateDates(dates))
  };

  // Function to get the next day based on a given date
  function getNextDay() {
    const nextDate = new Date(fullDate);
    nextDate.setDate(nextDate.getDate() + 1);
    handleDateChange(nextDate)

  }

  // Function to get the previous day based on a given date
  function getPreviousDay() {
    const previousDate = new Date(fullDate);
    previousDate.setDate(previousDate.getDate() - 1);
    handleDateChange(previousDate)
  }

  function handleAllDates() {
    const Startdate = new Date();
    Startdate.setFullYear(1990);
    Startdate.setMonth(0); // January is 0-based index, so January is 0
    Startdate.setDate(1); // Set the date to the 1st
    const startDateString = Startdate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

    const Enddate = new Date();
    Enddate.setFullYear(2990);
    Enddate.setMonth(0); // January is 0-based index, so January is 0
    Enddate.setDate(1); // Set the date to the 1st
    const endDateString = Enddate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });


    const dates = {
      "startDate": startDateString,
      "endDate": endDateString
    }
    dispatch(updateDates(dates))
    dispatch(updateIconActive(true))


  }

  return (

    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={getPreviousDay}>
        <CalendarArrowLeft />
      </IconButton>
      <Button onClick={handleOpenCalendar} sx={{ minWidth: "220px" }}>
        <Typography style={{ color: colors.text[100] }} sx={themeStyles.typography.h5}>
          {textDate ? textDate : "Loading"}
        </Typography>
      </Button>


      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <BasicDateCalendar handleDateChange={handleDateChange} value={shortdate} />
        </Box>
      </Popover>

      <IconButton onClick={getNextDay}>
        <CalendarArrowRight />
      </IconButton>
      <Tooltip title="All">
        <IconButton sx={{ marginLeft: "20px" }} onClick={handleAllDates}>
          <AllInclusiveIcon sx={{ color: IconActive ? colors.greenFlow[100] : colors.text[100] }} />
        </IconButton>
      </Tooltip>

    </Box>
  );
}

