export const columnNames = {
  "number":{
    // Table rows count column
    id:1,
    name: "",
    active: false,
    width:"40px",
    Hideable :false,
    show: true
    

  },
  "emtpy":{
    // Table border
    id:2,
    name: "",
    active: false,
    width:"16px",
    Hideable :false,
    show: true
    
  },
    "PatientName":{
      id:3,
      name: "Name",
      active: false,
      width:"15%",
      Hideable :true,
      show: true
    },
    "patientID":{
      id:8,
      name: "Patient ID",
      active: false,
      width:"",
      Hideable :true,
      show: true
    },
    "DOB":{
      id:10,
      name: "Date of Birth",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "medicalprofile":{
      id:6,
      name: "Medical Profile",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "modality":{
      id:4,
      name: "Modality",
      active: false,
      width:"",
      Hideable :true,
      show: true
    },
    "bodyexaminationpart":{
      id:5,
      name: "Examination",
      active: false,
      width:"",
      Hideable :true,
      show: true
    },
    "AccessionNumber":{
      id:9,
      name: "Accession No.",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "Institution":{
      id:12,
      name: "Institution",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "risk_index":{
      id:7,
      name: "Risk Index",
      active: false,
      width:"",
      Hideable :true,
      show: true
    },
    "main":{
      id:13,
      name: "main",
      active: true,
      width:"10%",
      Hideable :false,
      show: true
    },
    "secondary":{
      id:14,
      name: "secondary",
      active: true,
      width:"10%",
      Hideable :false,
      show: true
    },
    "reviewer":{
      id:15,
      name: "Case Status",
      active: false,
      width:"",
      Hideable :false,
      show: true
    },
    "actions":{
      id:17,
      name: "",
      active: true,
      width:"20%",
      Hideable :false,
      show: true
    },




    // "Acquisition":{
    //   id:11,
    //   name: "Acquisition",
    //   active: false,
    //   width:"",
    //   Hideable :true,
    //   show: false
    // },

    
  };