import React, { Fragment }  from 'react'
import {Text,View, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

const styles = StyleSheet.create({

  PatientTitle: {
      fontSize: 14,
      marginTop: 36,
      paddingBottom: 3,
      fontStyle: "bold",
      fontFamily: "Helvetica-Oblique",
    },
  text: {
    //margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
    PatientDataContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    Data: {
      fontSize: 12,
      fontStyle: "bold",
    },
    label: {
      width: 80,
    },
  });

function ReportMainFindings({ ReportStudy }) {
  console.log("ReportStudy.report.state",ReportStudy.report.state)
  return (
    <Fragment>
      <Text style={styles.PatientTitle}>Main Finding:</Text>
      {/* <Text style={styles.text}>
        {ReportStudy.report.raw}
      </Text> 
      <Html>{ReportStudy.report.state}</Html>*/}
    </Fragment>
  )
}

export default ReportMainFindings