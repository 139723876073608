import React from 'react'

export function getStudyInfo(StudyInstanceUID,organisationid) {
    const values = {
        "StudyInstanceUID":StudyInstanceUID,
        "organisationid": organisationid
    }
    
    console.log("values")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    console.log("FROM API", values)
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(values),
      redirect: 'follow'
    };
    
    return fetch(global.config.api_get_study_info, requestOptions)
}
