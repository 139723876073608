import * as React from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme'
import { Tooltip } from '@mui/material';


import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';



//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    //backgroundColor: 'rgba(196, 196, 196, 0.3)',
    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "62.50%",
    //height:"46.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
  '&.MuiBackdrop-root, &.MuiBackdrop-root-MuiDialog-backdrop': {
    opacity: 0,
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   }

// })


//
//
// Components STYLINGs END
//
//
//



export default function AiConfirmResults(props) {
  const [open, setOpen] = React.useState(false);
  //const stylingClasses = useStyles()

  useEffect(() => {
    console.log(props)

  }, [])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    console.log("SAVE")
    setOpen(false);
  }
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>

      <Tooltip title={"Confirm Results"}>
        <IconButton
          onClick={handleClickOpen}
        //sx={{paddingLeft:"0px",paddingRight:"0px"}}
        >
          <CheckOutlinedIcon />
        </IconButton>
      </Tooltip>



      <BootstrapDialog
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"

        sx={{
          "BackdropProps": {
            opacity: 0,
            background: "transparent"
          }
        }}
        overlayStyle={{ backgroundColor: 'transparent' }}
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        open={open}
      >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>

          <Typography sx={themeStyles.typography.h411}>Confirm Finding</Typography>

        </BootstrapDialogTitle>


        <DialogContent>

          <Typography sx={[themeStyles.typography.h51, { marginBottom: "20px" }]}>
            {props.findingUID.name} will be added to your report?
          </Typography>

          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <TextField fullWidth label="Additional comments" id="fullWidth" />
          </Box>

        </DialogContent>


        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Cancel</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Save</Typography>
          </Button>
        </DialogActions>


      </BootstrapDialog>
    </div>
  );
}