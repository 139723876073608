import React, { useState } from 'react'
import { styled } from '@mui/system';

import { Divider, Grid, TextField, Typography } from '@mui/material'
import { MenuItem, FormControl, InputLabel, Select, Button } from '@mui/material';
import { Field, Form, ErrorMessage } from 'formik'
import { countrieslist } from './countries-list';
import { ModalityList } from './modalitiesAbbreviations';
import { BodyParts } from './BodyParts';


const countryOptions = Object.values(countrieslist)
    .map((country) => country.label)
    .sort((a, b) => a.localeCompare(b));


function General({ errors, touched }) {

    const productInfo = [
        {
            "name": "General.Productname",
            "label": "Product Name"
        },
        {
            "name": "General.Modality",
            "label": "Modality"
        },
        {
            "name": "General.Subspeciality",
            "label": "Subspeciality"
        },
        {
            "name": "General.highlights[0]",
            "label": "Hightlight 1"
        },
        {
            "name": "General.highlights[1]",
            "label": "Hightlight 2"
        },
        {
            "name": "General.highlights[2]",
            "label": "Hightlight 3"
        },
        {
            "name": "General.description",
            "label": "Description"
        },

    ]

    const manufacturerFields = [
        {
            "name": "General.manufacturer.Name",
            "label": "Name"
        },
        {
            "name": "General.manufacturer.Address.Street",
            "label": "Street"
        },
        {
            "name": "General.manufacturer.Address.City",
            "label": "City"
        },
        {
            "name": "General.manufacturer.Address.ZipCode",
            "label": "ZipCode"
        },
        {
            "name": "General.manufacturer.Address.Country",
            "label": "Country"
        },
        {
            "name": "General.manufacturer.Email",
            "label": "Email"
        }

    ]

    const NovuInputField = styled(Field)(({ theme }) => ({
        '&.MuiInputBase-input, &:focus': {
            backgroundColor: 'transparent',
            color: "white"
        },
        '&.MuiInputBase-root, &.MuiOutlinedInput-input': {
            '&:focus': {
                backgroundColor: 'transparent',
                color: "white"
            },
        },
    }));


    return (
        <><Grid item xs={12} padding={"20px"}>

            {productInfo.map((field, index) => (
                field["label"] == "Modality" ?
                    <FormControl fullWidth>
                        <InputLabel id="modality-label" style={{ '&.Mui-focused': { color: 'red' } }}>Modality</InputLabel>
                        <Field
                            as={Select}
                            name="General.Modality"
                            labelId="country-label"
                            label="Modality"
                            style={{ marginBottom: '10px' }}
                            InputLabelProps={{
                                style: {
                                    '&.Mui-focused': {
                                        color: 'red'
                                    }
                                }
                            }}

                        >
                            {ModalityList.map((modality, index) => (
                                <MenuItem key={index} value={modality.shortname}>
                                    {modality.fullname}, {modality.shortname}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                    :
                    field["label"] == "Subspeciality" ?
                        <FormControl fullWidth>
                            <InputLabel id="Subspeciality-label">Subspeciality</InputLabel>
                            <Field
                                as={Select}
                                name="General.Subspeciality"
                                labelId="Subspeciality-label"
                                label="Subspeciality"
                                style={{ marginBottom: '10px' }}
                            >
                                {BodyParts.map((Subspeciality, index) => (
                                    <MenuItem key={index} value={Subspeciality}>
                                        {Subspeciality}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                        :
                        <Field
                            as={TextField}
                            name={field.name}
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            style={{ paddingBottom: '10px' }}
                        />
            ))



            }
        </Grid>
            <Grid item xs={12} padding={"20px"}>
                <Typography >Manufacturer Details: </Typography>
            </Grid>


            <Grid item xs={12} marginLeft={"20px"} padding={"20px"}>

                {manufacturerFields.map((field, index) => (
                    field["label"] == "Country" ?
                        <FormControl fullWidth>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Field
                                as={Select}
                                name="General.manufacturer.Address.Country"
                                labelId="country-label"
                                label="Country"
                                style={{ marginBottom: '10px' }}
                            >
                                {countrieslist.map((country, index) => (
                                    <MenuItem key={index} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                        :

                        <Field
                            as={TextField}
                            name={field.name}
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            style={{ paddingBottom: '10px' }}
                        />


                ))}

                {errors ? console.log("errors", errors) : ""}







            </Grid>
            <Grid item xs={12}>
                {
                    typeof errors === 'object'
                        && Object.keys(errors).length === 0
                        ?
                        null :
                        Object.keys(errors).map((error) => (
                            <Typography>{errors[error]}</Typography>))
                }
            </Grid>
        </>
    )
}

export default General