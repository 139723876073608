import React from 'react'

// Amplify
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../../../amplifyconfiguration.json';
import { withAuthenticator,  Heading, Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Grid, Typography } from '@mui/material';
import { tokens, themeSettings } from "../../../utilityComponents/theme.js";

Amplify.configure(amplifyconfig);

function AmplifyAuth({user}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark");
  return (
    <Grid container spacing={2}
    justifyContent="center">

    <Grid item display="flex"
      xs={12}
      mb={{xl:"5%", xs:"1%"}}
      alignItems="center"
      justifyContent={"center"}
      
    >
      <Typography
        align={"center"}
        color={colors.greenFlow[300]}
        sx={themeStyles.typography.h0}>
        Welcome to novu Ecosystem
      </Typography>
    </Grid>
    </Grid>
  )
}

export default withAuthenticator(AmplifyAuth)