export default function ProfileIcon({width,height}) {
    return (
<svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M338.873 208.26L230.371 208.26L230.371 316.884L338.873 316.884L338.873 208.26Z" fill="#4D4D4D"/>
<path d="M447.326 316.874L338.824 316.874L338.824 425.497L447.326 425.497L447.326 316.874Z" fill="#B3B3B3"/>
<path d="M230.407 99.6472L121.918 99.6472L121.918 208.244L230.407 208.244L230.407 99.6472Z" fill="#808080"/>
<path d="M338.873 642.713L230.371 642.713L230.371 751.309L338.873 751.309L338.873 642.713Z" fill="#B3B3B3"/>
<path d="M447.552 534.1L339.037 534.1L339.037 642.696L447.552 642.696L447.552 534.1Z" fill="#4D4D4D"/>
<path opacity="0.5" d="M923.983 228.245C897.569 115.092 765.499 -75.9639 666.536 31.9014C612.299 90.7691 514.392 347.742 502.065 458.075C495.726 516.59 556.302 526.813 625.33 516.943C789.448 493.678 957.44 370.655 923.983 228.245Z" fill="url(#paint0_linear)"/>
<path opacity="0.5" d="M844.972 552.57C858.398 469.37 740.436 440.25 623.112 439.93C457.197 439.61 501.633 515.13 525.289 582.33C550.544 654.969 598.497 738.489 633.022 782.649C664.671 823.288 690.885 823.928 733.403 779.129C790.306 719.289 834.103 619.129 844.972 552.57Z" fill="url(#paint1_linear)"/>
<path opacity="0.5" d="M923.983 228.245C897.569 115.092 765.499 -75.9639 666.536 31.9014C612.299 90.7691 514.392 347.742 502.065 458.075C495.726 516.59 556.302 526.813 625.33 516.943C789.448 493.678 957.44 370.655 923.983 228.245Z" fill="url(#paint2_linear)"/>
<path opacity="0.5" d="M844.972 552.57C858.398 469.37 740.436 440.25 623.112 439.93C457.197 439.61 501.633 515.13 525.289 582.33C550.544 654.969 598.497 738.489 633.022 782.649C664.671 823.288 690.885 823.928 733.403 779.129C790.306 719.289 834.103 619.129 844.972 552.57Z" fill="url(#paint3_linear)"/>
<path opacity="0.5" d="M338.972 642.693C338.972 625.556 338.981 620.973 338.967 596.54C338.981 596.54 339.166 567.713 338.987 547.674C338.963 544.959 338.963 534.1 338.963 534.1C338.98 534.101 444.761 534.1 447.49 534.1C437.698 550.05 431.206 564.3 420.363 580.251C406.486 600.837 394.191 622.307 376.959 642.693C389.435 642.693 341.684 642.693 338.972 642.693C338.972 650.836 338.972 658.981 338.972 661.696C338.972 669.841 338.963 684.103 338.963 688.844C311.854 732.28 281.336 751.284 281.336 751.284C276.589 751.284 254.887 751.284 249.462 751.285C246.749 751.284 244.036 751.284 243.403 751.285C240.682 751.284 233.186 751.284 230.473 751.284C230.473 748.569 230.473 745.118 230.473 740.425C230.473 734.995 230.473 710.562 230.473 696.988C230.473 682.906 230.473 661.695 230.473 642.692C265.738 642.693 273.876 642.693 338.972 642.693Z" fill="url(#paint4_linear)"/>
<path d="M555.965 534.083L555.965 425.487L501.727 425.487L447.49 425.487L447.49 534.083L555.965 534.083Z" fill="#4D4D4D"/>
<path opacity="0.5" d="M121.869 208.267C121.869 208.267 121.869 192.617 121.869 134.963C121.869 134.963 121.869 123.526 121.869 118.674C121.869 108.562 121.869 99.669 121.869 99.669L143.57 99.669C163.67 113.242 196.909 139.543 231.057 172.122L230.374 208.267L266.295 208.267C298.19 240.846 322.591 267.995 338.867 286.152L338.867 316.865L365.273 316.865C400.537 354.873 429.405 394.907 447.374 425.463L338.879 425.463C338.879 424.526 338.879 401.028 338.879 401.028C338.879 389.323 338.879 373.879 338.879 373.879C338.795 372.666 338.951 343.846 338.867 316.865C338.867 316.865 305.979 316.865 298.19 316.865L262.926 316.865L230.374 316.865C230.374 316.865 230.374 290.543 230.374 276.141L230.374 208.267C230.374 208.267 204.097 208.267 184.259 208.267C170.373 208.267 160.287 208.267 148.995 208.267C139.687 208.267 121.869 208.267 121.869 208.267Z" fill="url(#paint5_linear)"/>
<path opacity="0.5" d="M338.984 642.693C338.984 625.556 338.993 620.973 338.979 596.54C338.993 596.54 339.178 567.713 338.999 547.674C338.975 544.959 338.975 534.1 338.975 534.1C338.992 534.101 444.762 534.1 447.49 534.1C437.699 550.05 431.208 564.3 420.366 580.251C406.49 600.837 394.197 622.307 376.967 642.693C389.441 642.693 341.696 642.693 338.984 642.693C338.984 650.836 338.984 658.981 338.984 661.696C338.984 669.841 338.975 684.103 338.975 688.844C311.869 732.28 281.354 751.284 281.354 751.284C276.608 751.284 254.908 751.284 249.484 751.285C246.771 751.284 244.059 751.284 243.426 751.285C240.705 751.284 233.209 751.284 230.497 751.284C230.497 748.569 230.497 745.118 230.497 740.425C230.497 734.995 230.497 710.562 230.497 696.988C230.497 682.906 230.497 661.696 230.497 642.692C265.758 642.693 273.895 642.693 338.984 642.693Z" fill="url(#paint6_linear)"/>
<path opacity="0.5" d="M121.862 208.267C121.862 208.267 121.862 192.617 121.862 134.963C121.862 134.963 121.862 123.526 121.862 118.674C121.862 108.562 121.862 99.669 121.862 99.669L143.563 99.669C163.664 113.242 196.903 139.543 231.052 172.122L230.369 208.267L266.291 208.267C298.186 240.846 322.588 267.995 338.864 286.152L338.864 316.865L365.27 316.865C400.535 354.873 429.403 394.907 447.373 425.463L338.876 425.463C338.876 424.526 338.876 401.028 338.876 401.028C338.876 389.323 338.876 373.879 338.876 373.879C338.792 372.666 338.948 343.846 338.864 316.865C338.864 316.865 305.975 316.865 298.186 316.865L262.921 316.865L230.369 316.865C230.369 316.865 230.369 290.543 230.369 276.141L230.369 208.267C230.369 208.267 204.091 208.267 184.253 208.267C170.367 208.267 160.281 208.267 148.988 208.267C139.68 208.267 121.862 208.267 121.862 208.267Z" fill="url(#paint7_linear)"/>
<path d="M317.186 940.718C317.186 930.816 314.357 923.567 308.698 918.97C303.216 914.372 295.789 912.074 286.417 912.074C279.343 912.074 273.066 912.958 267.584 914.726C262.102 916.494 257.416 918.528 253.525 920.826L253.525 1020.02C252.641 1020.38 251.315 1020.73 249.546 1021.08C247.778 1021.61 245.833 1021.88 243.711 1021.88C236.107 1021.88 232.305 1018.7 232.305 1012.33L232.305 921.887C232.305 918.174 233.012 915.168 234.427 912.869C236.018 910.394 238.671 908.007 242.384 905.708C246.982 902.879 253.083 900.315 260.687 898.017C268.291 895.718 276.868 894.569 286.417 894.569C302.509 894.569 315.153 898.193 324.348 905.443C333.721 912.693 338.407 924.097 338.407 939.657L338.407 1020.02C337.523 1020.38 336.108 1020.73 334.163 1021.08C332.394 1021.61 330.538 1021.88 328.592 1021.88C320.988 1021.88 317.186 1018.7 317.186 1012.33L317.186 940.718Z" fill="url(#paint8_linear)"/>
<path d="M491.938 959.284C491.938 969.186 490.523 978.115 487.694 986.072C484.864 994.029 480.797 1000.84 475.492 1006.49C470.364 1012.15 464.086 1016.49 456.659 1019.49C449.232 1022.5 440.92 1024 431.725 1024C422.529 1024 414.218 1022.5 406.791 1019.49C399.364 1016.49 392.998 1012.15 387.693 1006.49C382.387 1000.84 378.32 994.029 375.491 986.072C372.661 978.115 371.247 969.186 371.247 959.284C371.247 949.382 372.661 940.453 375.491 932.496C378.497 924.539 382.653 917.732 387.958 912.074C393.263 906.415 399.629 902.083 407.056 899.077C414.483 896.072 422.706 894.569 431.725 894.569C440.744 894.569 448.967 896.072 456.394 899.077C463.821 902.083 470.099 906.415 475.227 912.074C480.532 917.732 484.599 924.539 487.429 932.496C490.435 940.453 491.938 949.382 491.938 959.284ZM431.725 912.074C419.7 912.074 410.239 916.229 403.343 924.539C396.446 932.85 392.998 944.431 392.998 959.284C392.998 974.314 396.358 985.984 403.077 994.294C409.974 1002.43 419.523 1006.49 431.725 1006.49C443.927 1006.49 453.387 1002.34 460.107 994.029C466.827 985.719 470.187 974.137 470.187 959.284C470.187 944.431 466.739 932.85 459.842 924.539C453.122 916.229 443.75 912.074 431.725 912.074Z" fill="url(#paint9_linear)"/>
<path d="M613.17 895.63C616.176 895.63 618.563 896.425 620.332 898.017C622.277 899.608 623.25 901.907 623.25 904.912C623.25 906.858 622.542 910.305 621.128 915.256C619.713 920.03 617.856 925.777 615.557 932.496C613.258 939.039 610.606 946.288 607.6 954.245C604.77 962.202 601.676 970.159 598.316 978.115C595.133 985.895 591.861 993.41 588.501 1000.66C585.318 1007.91 582.312 1014.19 579.483 1019.49C578.598 1020.2 577.007 1020.82 574.708 1021.35C572.586 1021.88 570.287 1022.14 567.811 1022.14C561.268 1022.14 557.024 1020.29 555.079 1016.57C553.487 1013.57 551.454 1009.41 548.978 1004.11C546.502 998.803 543.85 992.791 541.02 986.072C538.191 979.353 535.273 972.192 532.267 964.589C529.261 956.986 526.343 949.471 523.514 942.044C520.684 934.441 518.032 927.192 515.556 920.296C513.08 913.223 511.047 906.946 509.455 901.465C510.693 899.696 512.373 898.282 514.495 897.221C516.617 896.16 518.739 895.63 520.861 895.63C524.044 895.63 526.431 896.425 528.023 898.017C529.791 899.608 531.383 902.26 532.798 905.973L549.243 953.449C550.304 956.455 551.631 960.168 553.222 964.589C554.814 968.832 556.405 973.253 557.997 977.85C559.765 982.271 561.445 986.603 563.037 990.846C564.805 995.09 566.22 998.715 567.281 1001.72L568.342 1001.72C575.415 983.862 581.958 966.269 587.971 948.94C594.16 931.612 599.554 914.549 604.151 897.751C606.45 896.337 609.456 895.63 613.17 895.63Z" fill="url(#paint10_linear)"/>
<path d="M652.724 898.547C653.608 898.193 654.935 897.84 656.703 897.486C658.648 896.956 660.682 896.69 662.804 896.69C670.231 896.69 673.944 899.962 673.944 906.504L673.944 974.667C673.944 986.161 676.951 994.383 682.963 999.334C688.976 1004.11 697.287 1006.49 707.897 1006.49C714.971 1006.49 720.983 1005.79 725.935 1004.37C730.886 1002.78 734.776 1001.19 737.606 999.599L737.606 898.547C738.49 898.193 739.816 897.84 741.585 897.486C743.53 896.956 745.475 896.69 747.42 896.69C755.024 896.69 758.826 899.962 758.826 906.504L758.826 998.803C758.826 1002.52 758.296 1005.52 757.235 1007.82C756.351 1009.94 754.052 1012.06 750.338 1014.19C746.271 1016.49 740.612 1018.7 733.362 1020.82C726.288 1022.94 717.712 1024 707.632 1024C689.948 1024 676.332 1020.11 666.783 1012.33C657.41 1004.55 652.724 992.349 652.724 975.728L652.724 898.547Z" fill="url(#paint11_linear)"/>
</g>
<defs>
<linearGradient id="paint0_linear" x1="714.942" y1="-0.000122062" x2="714.942" y2="520.243" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="673.814" y1="439.929" x2="673.814" y2="812.938" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="714.942" y1="-0.000122062" x2="714.942" y2="520.243" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="673.814" y1="439.929" x2="673.814" y2="812.938" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="296.422" y1="387.184" x2="191.291" y2="784.333" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="49.4433" y1="32.1439" x2="231.135" y2="605.344" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="296.438" y1="387.184" x2="191.298" y2="784.327" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="49.4347" y1="32.1439" x2="231.124" y2="605.346" gradientUnits="userSpaceOnUse">
<stop stop-color="#17DFE9"/>
<stop offset="1" stop-color="#3A9DF2"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="175.481" y1="959.284" x2="811.411" y2="959.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A9DF2"/>
<stop offset="0.619792" stop-color="#17DFE9"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="175.481" y1="959.284" x2="811.411" y2="959.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A9DF2"/>
<stop offset="0.619792" stop-color="#17DFE9"/>
</linearGradient>
<linearGradient id="paint10_linear" x1="175.481" y1="959.284" x2="811.411" y2="959.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A9DF2"/>
<stop offset="0.619792" stop-color="#17DFE9"/>
</linearGradient>
<linearGradient id="paint11_linear" x1="175.481" y1="959.284" x2="811.411" y2="959.284" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A9DF2"/>
<stop offset="0.619792" stop-color="#17DFE9"/>
</linearGradient>
<clipPath id="clip0">
<rect width="1024" height="1018.89" fill="white" transform="translate(0.00585938 1024) rotate(-90)"/>
</clipPath>
</defs>
</svg>

)
}
