import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Stepper, Step, StepLabel, Typography, Grid, TextField } from '@mui/material';
import General from './RegisterationSteps/General';
import * as Yup from 'yup';
import License from './RegisterationSteps/License';


// ... (initialValues and steps remain the same)
const initialValues = {
  General: {
    Productname: '',
    manufacturer: {
      Name: '',
      Address: {
        Street: '',
        City: '',
        ZipCode: '',
        Country: ''
      }
    },
    Logo: '',
    Modality: '',
    Subspeciality: '',
    description: '',
    highlights: ['','','']
  },
  License: {
    Type: 'Research'
  },
  // ... rest of your initial values for other sections
};

const steps = [
  'General',
  'License',
  // Add other steps here
];



const RegisterAI = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*   const handleSubmit = (values, { setStatus }) => {
      // Handle form submission here
      console.log(values);
      // For demonstration purposes, setStatus can be used to reset form status
      setStatus({ success: true });
      // Reset to the first step after submission
      setActiveStep(0);
    }; */

  const handleSubmit = (values,) => {
    // Handle form submission here
    console.log(values);
    // For demonstration purposes, setStatus can be used to reset form status
    //setStatus({ success: true });
    // Reset to the first step after submission
    setActiveStep(0);
  };

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    // Define validation rules for each field
    General: Yup.object().shape({
      Productname: Yup.string().required('Product Name is required'),
      Modality: Yup.string().required('Modality is required'),
      manufacturer: Yup.object().shape({
        Name: Yup.string().required('Manufacturer Name is required'),
        Address: Yup.object().shape({
          Street: Yup.string().required('Street Address is required'),
          City: Yup.string().required('City is required'),
          ZipCode: Yup.string().matches(/^\d{5}$/, 'Invalid Zip Code').required('Zip Code is required'),
          Country: Yup.string().required('Country is required'),
          // You can add specific validation for the country format or values if needed
        }),
        Email: Yup.string().email('Invalid email').required('Email is required'),

        // You can add specific validation for the country format or values if needed
      }),
      // Other fields in the General section with their validation rules
    }),
    // Define validation for other sections if needed
  });


  const validate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
    } catch (errors) {
      return errors.inner.reduce((allErrors, currentError) => {
        return {
          ...allErrors,
          [currentError.path]: currentError.message,
        };
      }, {});
    }
  };



  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ status, values, isValid, isSubmitting, dirty, errors, touched }) => (
        <Form>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} >
              {activeStep === steps.length ? (
                <div>
                  <Typography variant="h5" align="center">
                    Form submitted successfully!
                  </Typography>
                  <Button onClick={() => setActiveStep(0)}>Reset</Button>
                </div>
              ) : (
                <>
                  {/* Render form fields based on the active step */}

                  {activeStep === 0 && (
                    <General errors={errors} touched={touched} />
                  )}

                  {activeStep === 1 && (
                    <License errors={errors} touched={touched} />
                  )}

                  {/* Other steps similarly */}
                  {/* ... */}
                  <Grid item xs={12}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (activeStep === steps.length - 1) {
                          handleSubmit(values);
                        } else {
                          handleNext();
                        }
                      }}
                      type="button"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterAI;
