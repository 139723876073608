import React from 'react';
import PropTypes from 'prop-types';
import ViewportContainer from './ViewportContainer';

const ViewportsLayout = ({ layoutRows, layoutColumns, activeContainerId }) => {
    const isContainerActive = (containerId) => {
        return activeContainerId === containerId;
    };

    const createViewportContainer = (row, column) => {
        const containerId = `viewport-container-${row + 1}-${column + 1}`;
        return (
            <ViewportContainer
                key={containerId}
                containerId={containerId}
                isActive={isContainerActive(containerId)}
                style={getContainerStyle(row, column)}
            />
        );
    };

    const getContainerStyle = (row, column) => {
        return {
            width: `${100 / layoutColumns}%`,
            height: `${100 / layoutRows}%`,
            left: `${column === 0 ? 0 : (100 / layoutColumns) * column}%`,
            top: `${row === 0 ? 0 : (100 / layoutRows) * row}%`
        };
    };

    const createLayoutColumns = (row) => {
        const columnNumbers = [...Array(layoutColumns).keys()];
        return columnNumbers.map(column => createViewportContainer(row, column));
    };

    const createLayoutRows = () => {
        const rowNumbers = [...Array(layoutRows).keys()];
        return rowNumbers.map(row => (
            createLayoutColumns(row)
        ));
    };

    return (
        <div className="viewports-layout">
            {createLayoutRows()}
        </div>
    );
};

ViewportsLayout.propTypes = {
    layoutRows: PropTypes.number.isRequired,
    layoutColumns: PropTypes.number.isRequired,
    activeContainerId: PropTypes.string.isRequired
};

export default ViewportsLayout;
