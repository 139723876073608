export const STUDIES = [
  {  
  type: 'MG',
  studyUid: '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1351.0',
  instances: [
      {
          name: 'R CC 1-1',
          imageLaterality:"CC",
          viewPosition:"R",
          ContainerID: 'viewport-container-1-1',
          //uid:  '2.20210426111839533338.10000007.77286239645550641592990276840979'
          uid: "1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1354.0",
          findings: {
            mamilla: {
                point: {x: 1529, y: 1823},
                radius: 40,
                isEditable: false,
                isVisible: true,
                style: {
                    color: '#E72828',
                    lineWidth: 2
                }
            },
            muscleLine: {
                startPoint: {x: 2043, y: 820},
                endPoint: {x: 2393, y: 1356},
                isEditable: false,
                isVisible: true,
                style: {
                    color: '#E72828',
                    lineWidth: 2,
                    dashedLine: false
                }
            },
            regionLines: [
                {
                    uid: 'region-line-1',
                    startPoint: {x: 1476, y: 1097},
                    middlePoint: {x: 2149, y: 1601},
                    endPoint: {x: 2021, y: 2242},
                    isEditable: false,
                    isVisible: true,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 1,
                        dashedLine: true
                    }
                },
                {
                    uid: 'region-line-2',
                    startPoint: {x: 1528, y: 921},
                    middlePoint: {x: 2294, y: 1564},
                    endPoint: {x: 2230, y: 2279},
                    isEditable: false,
                    isVisible: true,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 2,
                        dashedLine: false
                    }
                }
            ],
            lesions: [
                {
                    uid: 'lesion-1-1',
                    type: 'ellipse-lesion',
                    point: {x: 1857, y: 896},
                    xRadius: 135,
                    yRadius: 75,
                    rotationAngle: 25,
                    isEditable: false,
                    isVisible: true,
                    style: {
                        color: '#0E5715',
                        fillColor: '#0E5715',
                        fillOpacity: 0.2,
                        dashedLine: true
                    }
                },
                {
                    uid: 'lesion-1-2',
                    type: 'polyline-lesion',
                    isEditable: false,
                    isVisible: true,
                    points: [
                        {x: 1624, y: 1026},
                        {x: 1722, y: 969},
                        {x: 1815, y: 1033},
                        {x: 1752, y: 1107},
                        {x: 1707, y: 1087},
                        {x: 1663, y: 1106}
                    ],
                    style: {
                        color: '#C6C413',
                        fillColor: '#C6C413',
                        fillOpacity: 0.2
                    }
                }
            ]
        }
      },
      {
          name: 'L CC 1-2',
          imageLaterality:"CC",
          viewPosition:"L",
          ContainerID: 'viewport-container-1-2',
          uid:  '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1356.0',
          findingName:"Lesion",
          findingPercentage:"75%",
          findings: {
            mamilla: {
              point: { x: 2000, y: 2500 },
              radius: 20,
              isEditable: false,
              isVisible: false,
              style: {
                color: "#E72828",
                lineWidth: 2,
              },
            },
            muscleLine: {
                startPoint: {x: 2043, y: 820},
                endPoint: {x: 2393, y: 1356},
                isEditable: false,
                isVisible: false,
                style: {
                    color: '#E72828',
                    lineWidth: 2,
                    dashedLine: false
                }
            },
            regionLines: [
                {
                    uid: 'region-line-1',
                    startPoint: {x: 1476, y: 1097},
                    middlePoint: {x: 2149, y: 1601},
                    endPoint: {x: 2021, y: 2242},
                    isEditable: false,
                    isVisible: false,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 2,
                        dashedLine: false
                    }
                },
                {
                    uid: 'region-line-2',
                    startPoint: {x: 1528, y: 921},
                    middlePoint: {x: 2294, y: 1564},
                    endPoint: {x: 2230, y: 2279},
                    isEditable: false,
                    isVisible: false,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 2,
                        dashedLine: false
                    }
                }
            ],
            lesions: [
             {
              uid: "lesion-2-1",
              type: "polyline-lesion",
              isEditable: false,
              isVisible: true,
              points: [
                { x: 231, y: 1704 },
                { x: 337, y: 1704 },
                { x: 337, y: 1790 },
                { x: 231, y: 1790 },
              ],
              // style: {
              //   color: "#0E5715",
              //   fillColor: "#0E5715",
              //   fillOpacity: 0.2,
              // },
              style: {
                color: "#C6C413",
                fillColor: "",
                fillOpacity: 0.0,
              }
            }, 
           /*  {
              uid: "lesion-2-2-1",
              type: "polyline-lesion",
              isEditable: false,
              isVisible: true,
              points: [
                { x: 258, y: 1704 },
                { x: 231, y: 1704 },
                { x: 231, y: 1725 },
                { x: 231, y: 1704 },
              ],
              style: {
                color: "#C6C413",
                fillColor: "",
                fillOpacity: 0.0,
              }
            }, */
           /*  {
              uid: "lesion-2-2-2",
              type: "polyline-lesion",
              isEditable: false,
              isVisible: true,
              points: [
                { x: 258, y: 1704 },
                { x: 231, y: 1704 },
                { x: 231, y: 1725 },
                { x: 231, y: 1704 },
              ],
              style: {
                color: "#C6C413",
                fillColor: "",
                fillOpacity: 0.0,
              }
            },
 */
          ]
        }
      },
      {
          name: 'R MLO 2-1',
          imageLaterality:"MLO",
          viewPosition:"R",
          ContainerID: 'viewport-container-2-1',
          uid:  '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1358.0'
      },
      {
          name: 'L MLO 2-2',
          imageLaterality:"MLO",
          viewPosition:"L",
          ContainerID: 'viewport-container-2-2',
          uid:  '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1360.0',
          findingName:"Lesion",
          findingPercentage:"85%",
          findings: {
            mamilla: {
              point: { x: 2000, y: 2500 },
              radius: 40,
              isEditable: false,
              isVisible: false,
              style: {
                color: "#E72828",
                lineWidth: 2,
              },
            },
              muscleLine: {
                  startPoint: {x: 2043, y: 820},
                  endPoint: {x: 2393, y: 1356},
                  isEditable: false,
                  isVisible: false,
                  style: {
                      color: '#E72828',
                      lineWidth: 2,
                      dashedLine: false
                  }
              },
                regionLines: [
                    {
                        uid: 'region-line-1',
                        startPoint: {x: 1476, y: 1097},
                        middlePoint: {x: 2149, y: 1601},
                        endPoint: {x: 2021, y: 2242},
                        isEditable: false,
                        isVisible: false,
                        style: {
                            color: '#0D88B5',
                            lineWidth: 2,
                            dashedLine: false
                        }
                    },
                    {
                        uid: 'region-line-2',
                        startPoint: {x: 1528, y: 921},
                        middlePoint: {x: 2294, y: 1564},
                        endPoint: {x: 2230, y: 2279},
                        isEditable: false,
                        isVisible: false,
                        style: {
                            color: '#0D88B5',
                            lineWidth: 2,
                            dashedLine: false
                        }
                    }
                ],
            lesions: [
                {
                  uid: "lesion-4-1",
                  type: "polyline-lesion",
                  points: [
                    { x: 281, y: 2205 },
                    { x: 385, y: 2205 },
                    { x: 385, y: 2292 },
                    { x: 281, y: 2292 },
                  ],
                  isEditable: false,
                  isVisible: false,
                  // style: {
                  //   color: "#0E5715",
                  //   fillColor: "#0E5715",
                  //   fillOpacity: 0.2,
                  // },
                  style: {
                    color: "#C6C413",
                    fillColor: "#0E5715",
                    fillOpacity: 0.2,
                  }
                },
              ] 
        }
      }
  ]}
]
