import React, {useCallback, useState, useEffect} from 'react'
import {  useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
import SingleFileuploader from './SingleFileuploader';
import { Grid, IconButton, Typography } from '@mui/material';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import { useField } from 'formik';
import { UploadError } from './UploadError';
import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import { useSelector } from 'react-redux';
import SingleFileuploaderAxios from './SingleFileUploadAxios';
import NewUploadFile from './newUploader';

const colors = tokens("dark");
const themeStyles = themeSettings("dark")

let currentId = 0

function getNewId() {
  // we could use a fancier solution instead of a sequential ID :)
  return ++currentId
}

// const useStyles = makeStyles((theme) => ({
//   dropzone: {
//     border: `2px dashed ${theme.palette.primary.main}`,
//     borderRadius: theme.shape.borderRadius,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     background: theme.palette.background.default,
//     height: theme.spacing(20),
//     outline: 'none',
//     width:"100%"
    
//   },
// }));

const DropzoneDIV = styled('div')(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.background.default,
  height: theme.spacing(20),
  outline: 'none',
  width: '100%',
}));

function MultipleFileUploader({name}) {
  
  const [files,setFiles]=useState([])
  //const classes = useStyles();
  const [_, __, helpers] = useField(name);
  
    const user = useSelector((state)=>state.user)
    const Defaultworkspace= useSelector((state)=>state.Defaultworkspace)


  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map(file => ({
      file,
      errors: [],
      id: getNewId()
    }))
    const mappedRej = rejFiles.map(r => ({ ...r, id: getNewId() }))
    setFiles(curr => [...curr, ...mappedAcc, ...mappedRej])
  }, [])
      
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept:{
          'application/dicom ': ['.dcm','.zip','.tar','.rar','.gzip'],
          'application/gzip': ['.gzip'],
          'application/zip': ['.zip'],          
        },
        maxSize: 3000 * 1024*1024 
 
      })

      useEffect(() => {
        helpers.setValue(files);
        // helpers.setTouched(true);
      }, [files]);


      function onUpload(file, url) {
        //console.log("successfully uploaded", file["name"])
        setFiles((curr) =>
          curr.map((fw) => {
            if (fw.file === file) {
              return { ...fw, url };
            }
            return fw;
          })
        );
      }
      
      function onDelete(file) {
        setFiles((curr) => curr.filter((fw) => fw.file !== file));
      }

      
      return (
        <React.Fragment>
        <Grid container spacing={2}>
        
          <Grid item xs={12} xl={12}>
          <DropzoneDIV {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography sx={[themeStyles.typography.h52]} >Drag 'n' drop your DICOM files here,</Typography>
          <IconButton sx={{width: "200px", ":hover":{backgroundColor:"transparent"}}}>or Click to upload</IconButton>
          </DropzoneDIV>
          </Grid>
 
          
          {/* <div>or click to select files</div> */}
        
        
      </Grid>

      {files.length>0
      ?
      files.map((fileWrapper, index) => (
        <Grid item key={fileWrapper.id}>
          {fileWrapper.errors.length ? (

            <UploadError
              file={fileWrapper.file}
              errors={fileWrapper.errors}
              onDelete={onDelete}
            />  
            
          ) : (
            // <SingleFileuploader
            //   user={user}
            //   workspace={Defaultworkspace}
            //   onDelete={onDelete}
            //   onUpload={onUpload}
            //   file={fileWrapper.file}
            // />
            <SingleFileuploaderAxios
              user={user}
              workspace={Defaultworkspace}
              onDelete={onDelete}
              onUpload={onUpload}
              file={fileWrapper.file}
            />

          )}
        </Grid>
      )):""}
        </React.Fragment>
      )
}

export default MultipleFileUploader