import React, { Fragment }  from 'react'
import {Text,View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    PatientTitle: {
      marginTop: 36,
      paddingBottom: 3,
      fontStyle: "bold",
      fontFamily: "Helvetica-Oblique",
    },
  text: {
    //margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
    PatientDataContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    Data: {
      fontSize: 12,
      fontStyle: "bold",
    },
    label: {
      width: 80,
    },
  });

function ReportFinalComments({ Report }) {
  return (
    <Fragment>
    <Text style={styles.PatientTitle}>Additional Comments:</Text>
    <Text style={styles.text}>
      {Report.AdditionalFindings}
      </Text>
  </Fragment>
  )
}

export default ReportFinalComments