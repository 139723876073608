import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userLoggedIN:false, 
  userID:null,
  userLoginToken:null,
  avatarUrl:null,
  userEmail:null,
  userTitle:null, 
  userRole:null,
  organisationid:null,
  userFirstname: null,
  userLastname:null ,
  userFullName:null,
  userUserName:null ,
  userRights: null,
  userWorkspaces: null,
  DefaultWorkspaceID:null

}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update:(state, action)=>{
      console.log("user.action.payload", action)
      state.userLoggedIN=action.payload.userLoggedIN
      state.userID=action.payload.userID
      state.userLoginToken=action.payload.userLoginToken
      state.avatarUrl=action.payload.avatarUrl
      state.userEmail=action.payload.userEmail
      state.userTitle=action.payload.userTitle 
      state.userRole=action.payload.userRole
      state.organisationid=action.payload.organisationid
      state.userFirstname=action.payload.userFirstname
      state.userLastname=action.payload.userLastname
      state.userFullName=action.payload.userFullName
      state.userUserName=action.payload.userUserName
      state.userRights=action.payload.userRights  
      state.userWorkspaces=action.payload.userWorkspaces
      state.DefaultWorkspaceID=action.payload.DefaultWorkspaceID
    },

    updateVal:(state, action) => {
      const { key, val } = action.payload
      console.log("updateVal", key,val)
      state[key] = val
    },
    logIn: (state,action) => {
      state.userLoggedIN = true
    },
    logOut: (state, action) => {
      state.userLoggedIN = false
    }
  },
})

export const userDataSlice = (state)=> state.user
// Action creators are generated for each case reducer function
export const { update,logIn,logOut,updateVal } = userSlice.actions

export default userSlice.reducer