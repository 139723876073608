// REACT COMPONENTS
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import { Button, CssBaseline, Grid, Typography, } from "@mui/material";
import { Box } from "@mui/system";
//import { makeStyles } from "@mui/styles";


//CUSTOM STYLES
import "./landing.css";

import Background from "../../../utilityComponents/images/stripes_backgroung.jpg";
import NOVULogo from "../../../utilityComponents/images/Transparent_Black_BG_1024.png";
import NOVULogoSVG from "../../../utilityComponents/images/novuLOGO";

import Logo from '../../../utilityComponents/images/novu.js';
import { tokens, themeSettings } from "../../../utilityComponents/theme";



export const Landing = () => {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");

  
  let navigate = useNavigate();

  return (
    <Box
      //className="login--root"´
      justifyContent={"center"}
      sx={{
        display: "flex",
        backgroundColor: colors.dark_grey[500]
      }}
    >
      <CssBaseline />
      <Box
        width={"100%"}
        height="20%"
        mt={{xs:"3.5%", xl:"5%"}}
        mb={{xs:"3.5%", xl:"10%"}}
        className="login--box"
        style={{
          backgroundImage: `url(${Background})`
        }}
      >
        <Grid container spacing={2}
          justifyContent="center">

          <Grid item display="flex"
            xs={12}
            mb={{xl:"5%", xs:"1%"}}
            alignItems="center"
            justifyContent={"center"}
            
          >
            <Typography
              align={"center"}
              color={colors.greenFlow[300]}
              sx={themeStyles.typography.h0}>
              Welcome to novu Ecosystem
            </Typography>
          </Grid>

          
          <Grid item display="flex"
            xs={6}
            alignItems="center"
            justifyContent={"right"}
            minWidth="inherit"
          >
            <Button onClick={() => { navigate("/signup") }}
            variant="outlined" 
            //disabled={true}
            sx={{
              ":hover":{
                borderColor:colors.greenFlow[300],
              },
              borderColor:colors.greenFlow[300],
              width:"250px",
            }}
            >
              <Typography
                align={"center"}
                color={colors.greenFlow[300]} sx={[themeStyles.typography.h3]}>
                Sign up
              </Typography>
            </Button>
          </Grid>


          <Grid item display="flex"
            xs={6}
            alignItems="center"
            justifyContent={"left"}
          >
            <Button onClick={() => { navigate("/login") }} 
            variant="outlined" 
            sx={{
              ":hover":{
                borderColor:colors.greenFlow[300],
              },
              borderColor:colors.greenFlow[300],
              width:"250px"}}
              size={"large"}
             >
              <Typography
                align={"center"}
                color={colors.greenFlow[300]} 
                sx={[themeStyles.typography.h3]}>
                Log In
              </Typography>
            </Button>
          </Grid>
        

          {<Grid item display="flex"
            xs={12}
            alignItems="center"
            justifyContent={"center"}
            backgroundColor="inherit"
            sx={{
              backgroundColor: "#252525"
            }}
          >
                   {/* <NOVULogo/> */}
            <Box width="300px" height="300px"
              className="landing--NOVUlogo"
              mt="5%"

              style={{
                //width:"inherit",
                //height:"inherit",
                backgroundImage: `url(${NOVULogo})`
              }}
            />
          </Grid>}

          <Grid item display="flex"
            xs={12}
            alignItems="center"
            justifyContent={"center"}
            backgroundColor="inherit"
            sx={{
              backgroundColor: "#252525"
            }}
          >
                   {/* <NOVULogo/> */}
            <Box width="300px" height="300px"
              className="landing--NOVUlogo"
              mt="5%"

            >
              <Typography
                align={"center"}
                color={colors.greenFlow[300]} 
                sx={[themeStyles.typography.h62]}>
                Version: 2.1.0
              </Typography>
       

            </Box>
          </Grid>

        </Grid>


      </Box>
    </Box>
  );
};
