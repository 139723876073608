import React, {useEffect, useState} from 'react'
import { Grid, LinearProgress } from "@mui/material";
import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import { FileHeader } from './FileHeader';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

const colors = tokens("dark");
const themeStyles = themeSettings("dark")

const LBStyles={
  '& .MuiLinearProgress-bar1Determinate':{
    backgroundColor:colors.text[500],
  },
}

function SingleFileuploader({file,onDelete,onUpload,user,workspace}) {
    const [progress, setProgress] = useState(0);
    const [progressColor,setProgressColor] = useState()
    const colors = tokens("dark");


    useEffect(() => {
        async function upload() {
          const url = await uploadFile(file, setProgress, setProgressColor,user,workspace)
          onUpload(file, url);
        }
    
        upload();
      }, []);


  return (
    <Grid item>
    <FileHeader file={file} onDelete={onDelete} progress={progress} />
      <LinearProgress variant="determinate" value={progress} sx={LBStyles} color={progressColor || "primary"} />
    </Grid>
  )
}

export default SingleFileuploader


function uploadFile(file, onProgress, onProgressColor,user,workspace){
  const url = global.config.api_fileupload
  var retries = 4; 
  
  return new Promise((res,rej)=>{
    const xhr= new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.onload = ()=>{
      console.log("xhr.responseText onload",xhr.responseText)
      console.log("xhr.status onload",xhr.status)
      const resp = JSON.parse(xhr.responseText)
      if (xhr.status !== 200 & retries-- ){
        uploadFile(file, onProgress, onProgressColor) 
        console.log("uploading seccessful")
      }else{
        uploadFile(file, 100, onProgressColor,colors.novuColors[100]) 
      }
      res(resp)
    }
    xhr.onerror = (evt) => {rej(evt)
      if (xhr.status!=200){
        onProgressColor(colors.novuColors[100])
        console.log("error uploading")
      }}
    
    xhr.upload.onprogress = (event) => {
      console.log("event")
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage))
        if (percentage===100){
          onProgressColor("secondary")
        }
      }
    };

    const formdata = new FormData();
    const randomUUID = uuidv4()
    const newFilename= user["userID"]+'_'+workspace["defaultWorkspaceID"]+'_'+randomUUID
    
    console.log("newFilename",newFilename)

    formdata.append('file', file,newFilename);
    formdata.append('filename', newFilename);
    formdata.append('userID',  user["userID"])
    formdata.append('organisationid',  workspace["defaultWorkspaceID"])


    xhr.send(formdata);

  })
}

