// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading{
    display: flex;
    background-color:transparent;
    color: #f5f5f5;
    width: 100%;
    margin-top: 4.375rem;
    
    
}

.icons {
    margin-left:auto;
    margin-right: 8px;
    order: 2;
    justify-content: flex-end;
    justify-content: space-around;
    margin-top: 0px;
}


.icon{
    display: inline-block;
    margin-left: 21px;
}

.text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #F5F5F5;

}`, "",{"version":3,"sources":["webpack://./src/components/subComponents/table/Tcontroller.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,cAAc;IACd,WAAW;IACX,oBAAoB;;;AAGxB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,QAAQ;IACR,yBAAyB;IACzB,6BAA6B;IAC7B,eAAe;AACnB;;;AAGA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,wBAAwB;IACxB,cAAc;;AAElB","sourcesContent":[".heading{\n    display: flex;\n    background-color:transparent;\n    color: #f5f5f5;\n    width: 100%;\n    margin-top: 4.375rem;\n    \n    \n}\n\n.icons {\n    margin-left:auto;\n    margin-right: 8px;\n    order: 2;\n    justify-content: flex-end;\n    justify-content: space-around;\n    margin-top: 0px;\n}\n\n\n.icon{\n    display: inline-block;\n    margin-left: 21px;\n}\n\n.text {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    letter-spacing: -0.015em;\n    color: #F5F5F5;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
