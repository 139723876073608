import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    findings:[]
}

export const aiFindingsSlice = createSlice({
  name: 'aiFindings',
  initialState,
  reducers: {
    updateFindings:(state, action)=>{
        state.findings = action.payload        
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateFindings } = aiFindingsSlice.actions

export default aiFindingsSlice.reducer