import {LinearProgress,Typography} from '@mui/material';
//import {createStyles, makeStyles, styled } from '@mui/styles';
import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 

import React from 'react';
import { FileHeader } from './FileHeader';


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

const LBStyles={
  '& .MuiLinearProgress-bar1Determinate':{
    backgroundColor:'red',
  },
}
/* 
const ErrorLinearProgress = makeStyles((theme) => createStyles({
    bar: {
        backgroundColor: theme.palette.error.main,
    },
}))(LinearProgress); */

export function UploadError({ file, onDelete, errors }) {
  return (
    <React.Fragment>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={100} sx={LBStyles}/>
      {errors.map((error) => (
        <div key={error.code}>
          <Typography color="error">{error.message}</Typography>
        </div>
      ))}
    </React.Fragment>
  );
}
