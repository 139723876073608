import React from 'react'
import 'viewports-core';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { ROI}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';


export function Measurements(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  function handleMeasurementChange(tool){
    global.viewportsCore.setMouseButtonFunction(1, 5);
        global.viewportsCore.measurementsController.setActiveMeasurementType(tool);
  }


  const MenuListImageTools=[
    {
      icon: <CircleOutlinedIcon fontSize="small" />,
      name:"Oval",        
      shortcut:"⌘P",
      functionname:()=>handleMeasurementChange('oval')
  },
    {
        icon: <HorizontalRuleOutlinedIcon fontSize="small" />,
        name:"Distance",        
        shortcut:"",
        functionname: ()=>handleMeasurementChange('ruler')
    },
    {
      icon: <ChangeHistoryOutlinedIcon fontSize="small" />,
      name:"Angle",        
      shortcut:"",
      functionname:()=>handleMeasurementChange('angle')
    },
    {
      icon: <PolylineOutlinedIcon fontSize="small" />,
      name: "Polyline",
      shortcut: "",
      functionname: () => handleMeasurementChange('polyline')
    },
    {
      icon: <RectangleOutlinedIcon fontSize="small" />,
      name: "Area",
      shortcut: "",
      functionname: () => handleMeasurementChange('area')
    },
/*     {
      icon: <PolylineOutlinedIcon fontSize="small" />,
      name: "Polyline",
      shortcut: "⌘P",
      functionname: () => handleMeasurementChange('polyline')
    },
    {
      icon: <PolylineOutlinedIcon fontSize="small" />,
      name: "Polyline",
      shortcut: "⌘P",
      functionname: () => handleMeasurementChange('polyline')
    },
     {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    }  */
]
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <SquareFootOutlinedIcon  fontSize='large'/>
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "220px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
        
      </HoverMenu>
    </div>
  );
}