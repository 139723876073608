import { useSelector } from 'react-redux';
import * as React from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import { Tooltip } from '@mui/material';
import { useEffect } from 'react';
import deleteFindingAPI from '../../../../functions/api/diagnosing/deleteFinding';
import { useDispatch } from 'react-redux';
import { deletefindings } from '../../../../states/slices/cases/casesSlice';


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

 '& .MuiDialog-container':{
  //backgroundColor: 'rgba(196, 196, 196, 0.3)',
  //backgroundColor:"pink"
 },
  '& .MuiDialog-paper':{
    //backgroundColor: colors.dark_grey[400],
    width:"62.50%",
    //height:"46.88%",
    borderRadius:"8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color:"red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


//
//
// Components STYLINGs END
//
//
//


  
export default function AiDeleteResults({finding}) {
  const [open, setOpen] = React.useState(false);
  //const stylingClasses = useStyles()
  const dispatch = useDispatch();
  const CaseFindings = useSelector(state => state.cases.findings)

  
useEffect(() => {
  console.log("AiDeleteResults",CaseFindings)

}, [])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = ()=>{
    console.log("Delete",finding)
    deleteFindingAPI(finding)
    .then(result => {
        viewportsCore.measurementsController.deleteMeasurementById(finding["id"]);
        dispatch(deletefindings({ id: finding["id"] }));
      })

    setOpen(false);
  }
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>

      <Tooltip title={"Delete"}>
        <IconButton 
        onClick={handleClickOpen}
        //sx={{paddingLeft:"0px",paddingRight:"0px"}}
        >
            <ClearOutlinedIcon/>
        </IconButton>
    </Tooltip>



      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          
          <Typography sx={themeStyles.typography.h411}>Delete Finding</Typography>
        
        </BootstrapDialogTitle>


        <DialogContent>
          
          <Typography>
            {finding.structureReporting.name&&finding.user.FullName?
            `Finding: ${finding.structureReporting.name} done by: ${finding.user.FullName} will be deleted? ` :
            "You are about to delete a finding?"}
        </Typography>

         {/*  <Box
            sx={{
                width: 500,
                maxWidth: '100%',
            }}
            >
            <TextField fullWidth label="Additional comments" id="fullWidth" />
            </Box> */}
          
        </DialogContent>


        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Cancel</Typography>
          </Button>
          <Button onClick={handleDelete}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Delete</Typography>
          </Button>
        </DialogActions>


      </BootstrapDialog>
    </div>
  );
}