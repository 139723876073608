// REACT COMPONENTS
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { update, logIn, logOut, updateVal } from "../../../states/slices/user/userSlice";
import { Formik } from "formik";


//MUI
import { Button, CssBaseline, Grid, Typography, Select } from "@mui/material";
import { Box } from "@mui/system";
//import { makeStyles } from "@mui/styles";

//CUSTOM Components
//import { SignUP } from "../../../functions/userState";
import { SignupForm } from "./SignUpForm";
//import {Signup2} from './temp'

//CUSTOM STYLES
import "./signup.css";

import Background from "../../../utilityComponents/images/stripes_backgroung.jpg";

import { tokens, themeSettings } from "../../../utilityComponents/theme";
//import { signUpClicked } from "../../../functions/authentication/signup";
import { signUpClicked_rc } from "../../../functions/authentication/signup_rc";
//import { signUpClicked_st} from "../../../functions/authentication/signup_rc";

import { RegisterNewTeamAPI, RegisterNewUserAPI } from "../../../functions/api/organisational/registerTeam";
import {DeleteUser} from '../../../functions/authentication/deleteuser'

const colors = tokens("dark");
const themeStyles = themeSettings("dark");



export const Signup = () => {
  // const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [signupErrors,setSignupErrors]=useState("")
  const [loading, setLoading] = useState(false);
  //const [password, setPassword] = useState("");
  //const [showPassword, setShowPassword] = useState("");

  const FirstName = useSelector((state) => state.user.FirstName);
  const LastName = useSelector((state) => state.user.LastName);
  const Title = useSelector((state) => state.user.Title);
  const Email = useSelector((state) => state.user.Email);
  const LoggedIn = useSelector((state) => state.user.LoggedIn);
  const dispatch = useDispatch();

  const userData = {
    FirstName: String,
    LastName: String,
    Title: String,
  };

  let navigate = useNavigate();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  function handleSignUP(values) {
    //console.log("handleSignUP", values)
    setLoading(true)
    setSignupErrors("")
    //signUpClicked(values)
    signUpClicked_rc(values)
    .then(response=>response.text())
    .then(response=>{
      const tempresults = JSON.parse(Object.assign(response))
      //console.log("tempresults",tempresults) 
      const results = JSON.parse(tempresults["result"])
      //console.log("results",results)  
      if (!results["success"])
      {
       var errorMessage = ""
       switch (results["errorType"]) {
         case "error-field-unavailable":
           errorMessage=results["details"]["field"]+ " is not available."
           setSignupErrors(errorMessage)
           break;
         
         case "error-input-is-not-a-valid-field":
           errorMessage=results["details"]["field"]+ " is not valid."
           setSignupErrors(errorMessage)
           break;
       
         default:
           window.alert("Unfortunately there was an error. Please try again!")
           break;
       }
       setLoading(false)
       
     }
      else{
        console.log("successfully returned from rc",results)

        const  Data={
          title:results["user"]["customFields"]["title"],
          firstname: results["user"]["customFields"]["firstname"],
          lastname: results["user"]["customFields"]["lastname"],
          email: results["user"]["emails"][0]["address"],
          employeeid: results["user"]["_id"],
          organisationid:results["user"]["customFields"]["organisationID"],
          role: results["user"]["customFields"]["role"],
          rights: '',  
          settings:{
            "DefaultWorkspaceID":results["user"]["customFields"]["organisationID"]
          },
          "WorkspaceRole": "Owner",
          "WorkspaceOwnerID":results["user"]["_id"],
          "WorkspaceRights": {
                            "Access": true,
                            "Appointments": true,
                            "Upload": true,
                            "Review": true,
                            "ViewReport": true,
                            "SignReport": true
                            },
          "WorkspaceStatus":"Joined"
      }

      //console.log("sending data to novuDB",Data)
      RegisterNewUserAPI(Data)
          .then(response => {
            //console.log("Registering new team member", response)
            if (response.status ===200){
              //console.log("success added meta data to novu Bend")    
              // REDIRECT HERE
              window.location.href = "/login"
              setLoading(false)
            }else{
                //console.log("deleting user", Data.employeeid)

                DeleteUser(Data.employeeid)
                .then(response=>{
                  setSignupErrors("Unfortunately there was an error. Please try again!")
                  setLoading(false)
                })
              
            } 
          })
          .catch(error =>{
            setSignupErrors(error)
            setLoading(false)
          });

      }
  })
    .catch(error =>{
      window.alert(error)
    });

  }





  return (
    <Box
      className="login--root"
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <Box
        width="43%"
        sx={{
          height:{xl:"55%", xs:"75%" }
         }}
          minWidth={{xl:"500px", xs:"350px" }}

        className="login--box"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <Grid container spacing={0}
          justifyContent="center">

          <Grid item display="flex"
            xs={12}
            marginTop="5.4%"
            alignItems="center"
            justifyContent={"center"}
            minWidth="inherit"
          >
            <Typography color={colors.text[100]} sx={[themeStyles.typography.h42]}>
              Sign Up
            </Typography>
          </Grid>


          <SignupForm handleSignUP={handleSignUP} loading={loading} />

          <Grid item display="flex"
            xs={12}
            marginTop="5.4%"
            alignItems="center"
            justifyContent={"center"}
            minWidth="inherit"
          >
            {signupErrors!==""?
            <Typography color={colors.text[700]} sx={[themeStyles.typography.h61]}>
            {signupErrors} 
          </Typography>
          :""}
          </Grid>

          <Grid item display="flex"
            xs={12}
            marginTop="5.4%"
            alignItems="center"
            justifyContent={"center"}
            minWidth="inherit"
          >
            <Button onClick={()=>(navigate("/login"))} >
            <Typography color={colors.text[100]} sx={[themeStyles.typography.h61]}>
              Have an account already?
            </Typography>
            </Button>
          </Grid>

        </Grid>


      </Box>
    </Box>
  );
};
