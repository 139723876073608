import React, { Fragment }  from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from './logo.png'

const styles = StyleSheet.create({
  ImageContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom:10
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
    logo: {
        width: 74,
        height: 74,
        marginLeft: 400,
        marginRight: 'auto'
    }
});

function Header() {
  return (
    <Fragment>
{/*       <Text style={styles.header} fixed>
        ~ Created with react-pdf ~
      </Text> */}
      <View style={styles.ImageContainer} fixed>
      <Image style={styles.logo} src={logo} /> 
      </View>
    </Fragment>
  );
}

export default Header;
