export const Viewerconfiguration = {
    features: {
      segmentingAnnotations: true,
      mistMpr3D: true,
      batchImageRequests: true,
      compressPixelsBeforeSave: 'gzip',
      //compressPixelsBeforeSave : 'none'
    },
    requestsConfiguration: {
      maximumMetadataStreams: 5,
      maximumPixelStreams: 15,
      maximumMultiFrameStreams: 4,
      batchSizeMb: 5,
      multiFrameBatchSizeMb: 2
    },
    preloadStudyImages: 'off',
    instancesProgressivePreloadConfig: {
      preloadCount: 10,
      supportedModalities: ['CT', 'MR', 'PT', 'NM', 'US', 'X-RAY'],
      supportedSOPClasses: []
    }

  };