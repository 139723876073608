import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
//import Input from '@mui/joy/Input';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Avatar from '@mui/material/Avatar';
import PersonAdd from '@mui/icons-material/PersonAdd';
import {SearchMembersAPI, getUserAvatar, ListAllTeams} from '../../../../functions/api/organisational/registerTeam'
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme"
import SearchMembers from '../../../../components/subComponents/forms/Team/searchMembers'

import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import BadgeAvatars from '../../avatar/avatar'
import Stack from '@mui/material/Stack';

import RigesterTeam from './RigesterTeam'


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")
//const steps = ['Team information', 'Add members', 'Review'];

const steps = [
  {
    "name": "Team Name",
    "render": teamName()
  },
  {
    "name": "Add members",
    "render": AddTeamMember()
  },
  {
    "name": "Review",
    "render": <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard"
      />
    </Box>
      <Divider />
    </>
  },
]

function teamName() {
  return (
    <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard" />
    </Box><Divider /></>
  )
}

function AddTeamMember() {
  return (
    <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard" />
    </Box><Divider /></>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    backgroundColor: 'rgba(196, 196, 196, 0.3)',
    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "62.50%",
    height: "46.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   }

// })


//
//
// Components STYLINGs END
//
//
//



export default function SearchTeamMember({ handleCancel, open,user }) {
  const fakeApi = () => console.log('Api is called')
  //const [open, setOpen] = useState(true);
  //const stylingClasses = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [members, setMembers] = useState([])
  const [teammembers, setTeamMembers] = useState([])
  const [timer, setTimer] = useState(null)
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const [teams,setTeams]=useState([])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 const listAllTeams=()=>{
  ListAllTeams()
  .then(response => response.text())
    .then(results => {
      const teams =  JSON.parse(Object.assign(results))
      setTeams(teams["teams"])
    })
}

  function getUsers(input){
    SearchMembersAPI(input)
    .then(response => response.text())
    .then(result => {
      const members =  JSON.parse(Object.assign(result))

      if (members["count"]>0){
        setChecked(true)
        members["users"].map((member)=>{
          const Useravatar = global.config.api_get_users_avatar+"?username="+member.username
          Object.assign(member, {avatar:Useravatar});

        })

        setMembers(members["users"])
      }
      
      })
    .catch(error => console.log('error', error));
  }



  const handleReset = () => {
    setActiveStep(0);
  };

  const inputChanged = e => {
    setInputValue(e.target.value)

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      getUsers(e.target.value)
    }, 1000)

    setTimer(newTimer)
  }


  const handleSave = () => {
    console.log("SAVE")
  }
  /*   const handleCancel = () => {
      setOpen(false);
    }; */

  return (
    <div>
      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          <Typography sx={themeStyles.typography.h411}>Add Team Members </Typography>
        </BootstrapDialogTitle>


        <DialogContent>
     

        <Box backgroundColor="inherit"
            sx={{ margin: "24px 24px 24px 24px" }}
          >
            <SearchMembers />

          </Box>


        </DialogContent>


        <DialogActions>
          {/* <Button onClick={handleCancel}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
          </Button> */}
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {color: colors.text[600]}]}>Cancel</Typography>
          </Button>
{/*           <Button onClick={handleSave}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Save</Typography>
          </Button> */}
        </DialogActions>


      </BootstrapDialog>
    </div>
  );
}