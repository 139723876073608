import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { STUDIES } from '../Studies';

const StudiesMenu = ({ activeContainerId, layoutColumns, onLayoutColumnsChanged }) => {
    const studyUidRef = useRef(null);
    const instanceUidRef = useRef(null);

    const openInstanceToContainer = (containerId, instanceUid) => {
        global.viewportsCore.layoutController.openInstanceToViewportContainer(containerId, instanceUid);
    };

    const loadStudy = (containerId, studyUid, instanceUid) => {
        global.viewportsCore.loadStudyWithHIS(studyUid).then((studyData) => {
            const instanceToOpen = instanceUid || studyData.series[0].instances[0].uid;
            global.viewportsCore.layoutController.openInstanceToViewportContainer(containerId, instanceToOpen);
        });
    };

    const loadCustomStudy = () => {
        global.viewportsCore.loadStudyWithHIS(studyUidRef.current.value).then(() => {
            global.viewportsCore.layoutController.openInstanceToViewportContainer(activeContainerId, instanceUidRef.current.value);
        });
    };

    const loadThreeStudies = (studiesToLoad) => {
        if (layoutColumns < 3) {
            onLayoutColumnsChanged({ target: { value: 3 } }, () => loadStudyWithHis(studiesToLoad));
        } else {
            loadStudyWithHis(studiesToLoad);
        }
    };

    const loadStudyWithHis = (studiesToLoad) => {
        global.viewportsCore.loadStudiesWithHIS(studiesToLoad);
    };

    const loadThreeSeriesInSameTime = () => {
        const studiesToLoad = [
            { study: STUDIES[0].studyUid, callback: () => openInstanceToContainer('viewport-container-1-1', STUDIES[0].instances[0].uid) },
            { study: STUDIES[0].studyUid, callback: () => openInstanceToContainer('viewport-container-1-2', STUDIES[0].instances[1].uid) },
            { study: STUDIES[0].studyUid, callback: () => openInstanceToContainer('viewport-container-1-3', STUDIES[0].instances[2].uid) }
        ];
        loadThreeStudies(studiesToLoad);
    };

    const loadThreeStudiesInSameTime = () => {
        const studiesToLoad = [
            { study: STUDIES[0].studyUid, callback: (studyData) => openInstanceToContainer('viewport-container-1-1', studyData.series[0].instances[0].uid) },
            { study: STUDIES[1].studyUid, callback: (studyData) => openInstanceToContainer('viewport-container-1-2', studyData.series[0].instances[0].uid) },
            { study: STUDIES[2].studyUid, callback: (studyData) => openInstanceToContainer('viewport-container-1-3', studyData.series[0].instances[0].uid) }
        ];
        loadThreeStudies(studiesToLoad);
    };

    return (
        <div className="top-menu">
            <div>
                <button onClick={loadThreeSeriesInSameTime}>Load 3 series</button>
                <button onClick={loadThreeStudiesInSameTime}>Load 3 studies</button>
                {STUDIES.map(study => (
                    study.instances.map(instance => (
                        <button key={`studies-button-${instance.uid}`} onClick={() => loadStudy(activeContainerId, study.studyUid, instance.uid)}>
                            {instance.name || study.studyName}
                        </button>
                    ))
                ))}
            </div>
            <div className="custom-study">
                <input type="text" className="input" placeholder="Study UID" ref={studyUidRef} />
                <input type="text" className="input" placeholder="Instance UID" ref={instanceUidRef} />
                <button onClick={loadCustomStudy}>Custom</button>
            </div>
        </div>
    );
};

StudiesMenu.propTypes = {
    activeContainerId: PropTypes.string.isRequired,
    layoutColumns: PropTypes.number.isRequired,
    onLayoutColumnsChanged: PropTypes.func.isRequired
};

export default StudiesMenu;
