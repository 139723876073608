import React from 'react'

export function SendPDFAPI(result, currentStudy, UserData,Measurements) {
//console.log("FROM SendPDFAPI", values)
const values={
  "organisationid":UserData.organisationid,
  "userID":UserData.userID,
  "studyUid":currentStudy.studyUid,
  "Measurements":Measurements,
  "pdf":result
}
// global.config.api_get_comment

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({ values});
//console.log("FROM API", raw);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch("api/api/pdf/send", requestOptions)

}