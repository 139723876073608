import * as React from 'react';
import { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import { Tooltip } from '@mui/material';


import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { updatefindings } from '../../../../states/slices/cases/casesSlice';
import AddFindingAPI from '../../../../functions/api/diagnosing/addFinding';
import DirectionSnackbar from '../../snackbars/snackbar';
import { closeSnackbar, updateSnackbar } from '../../../../states/slices/snackbar/snackbar';



//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

 '& .MuiDialog-container':{
  //backgroundColor: 'rgba(196, 196, 196, 0.3)',
  //backgroundColor:"pink"
 },
  '& .MuiDialog-paper':{
    //backgroundColor: colors.dark_grey[400],
    width:"62.50%",
    //height:"46.88%",
    borderRadius:"8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color:"red"
  },
  '&.MuiBackdrop-root, &.MuiBackdrop-root-MuiDialog-backdrop':{
    opacity:0,
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

 const UploadBtnStyle = {
    //backgroundColor: colors.dark_grey[400],
    color: colors.greenFlow[100],
    
    '&:hover': {
      backgroundColor: 'transparent',
      //color: '#3c52b2',
  },
  }

  // const useStyles = makeStyles({
    
  //   saveBtnStyle:{
  //     color: colors.text[500],
  //     fontStyle:"SemiBold"
  //   },
  //   cancelBtnStyle:{
  //     color: colors.text[600]
  //   }
    
  // })  


//
//
// Components STYLINGs END
//
//
//


  
export default function EditFindings(props) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(props.finding["structureReporting"]?props.finding["structureReporting"]["name"]:"Name");
  //props.finding["structureReporting"]["name"]||""
  const [description, setDescription] = React.useState(props.finding["structureReporting"]?props.finding["structureReporting"]["description"]:"Description");
  //props.findings["structureReporting"]["description"]||""
  //const stylingClasses = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const openSnackbar = useSelector((state)=>state.snackbar)

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = ()=>{
    
    const structureReporting={
        "name":name,
        "description":description
    }
    //console.log("SAVE", name,description,structureReporting)
    
    const newFinding={...props.finding,structureReporting}

    AddFindingsToBackend(newFinding, user, props.finding["AIFinding"],props.finding["confirmed"])

    //Save to State
    dispatch(updatefindings({ id: props.finding["id"], updatedValues: { "structureReporting": structureReporting } }))
    // Because of the async nature better to copy the finding and sync edit it
   
    
    setOpen(false);
  }

  const handleCancel = () => {
    setOpen(false);
  };

  function AddFindingsToBackend(measurementsValues, user, AIFinding,confirmed){
    AddFindingAPI(measurementsValues, user, AIFinding,confirmed)
    .then(response => response.text())
    .then(results => { 
      console.log(results)
      const res = JSON.parse(Object.assign(results))
      if (res.statusCode === 200) {
       dispatch(updateSnackbar({open: true, Message: "Updated your findings successfully.", severity: "success"}))
     } else {
        dispatch(updateSnackbar({open: true, Message: "Failed to save your updates.", severity: "error" }))
    }
      
      })
  }


  return (
    <div>
      <DirectionSnackbar props={openSnackbar} handleClose={() => dispatch(closeSnackbar())}/>

      <Tooltip title={"Edit finding"}>
        <IconButton 
        onClick={handleClickOpen}
        //sx={{paddingLeft:"0px",paddingRight:"0px"}}
        >
            <EditOutlinedIcon/>
        </IconButton>
    </Tooltip>



      <BootstrapDialog
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"

      sx={{
        "BackdropProps":{
          opacity:0,
          background:"transparent"
        }
        }}
        overlaystyle={{backgroundColor: 'transparent'}}
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        open={open}
        >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          
          <Typography sx={themeStyles.typography.h411}>Edit Finding</Typography>
        
        </BootstrapDialogTitle>


        <DialogContent>
          
          <Box
            sx={{
                width: 500,
                maxWidth: '100%',
            }}
            >
            <TextField 
            onChange={(event)=>setName(event.target.value)}
            fullWidth label="Name" id="name" 
            sx={{marginBottom:"20px"}}
            InputLabelProps={{ style: { color: '#fff' }, }}
            value={name}
            />

            <TextField multiline={true} fullWidth 
            onChange={(event)=>setDescription(event.target.value)}
            label="Additional comments" id="fullWidth" 
            InputLabelProps={{ style: { color: '#fff' }, }}
            value={description}
            />
            </Box>
          
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Cancel</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Save</Typography>
          </Button>
        </DialogActions>



      </BootstrapDialog>
    </div>
  );
}