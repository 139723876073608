import React from 'react'

function calculateOvalBoundingBox(centerPosition, xRadius, yRadius) {
    const left = centerPosition.x - xRadius;
    const right = centerPosition.x + xRadius;
    const top = centerPosition.y - yRadius;
    const bottom = centerPosition.y + yRadius;
    console.log(left, right, top, bottom)
    return { left, right, top, bottom };
  }
  
  function extractPixelsInBoundingBox(imagePixels, boundingBox, imageWidth) {
    const { left, right, top, bottom } = boundingBox;
    const pixelsInBoundingBox = [];
    for (let y = Math.floor(top); y <= Math.ceil(bottom); y++) {
      for (let x = Math.floor(left); x <= Math.ceil(right); x++) {
        const index = y * imageWidth + x;
        pixelsInBoundingBox.push(imagePixels[index]);
      }
    }
    return pixelsInBoundingBox;
  }
  
  function convertPixelsToBase64(pixels, width, height) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const imageData = ctx.createImageData(width, height);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
      canvas.toBlob(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }, 'image/png');
    });
  }
  
  function downloadBase64AsPng(base64Data, fileName) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertPixelsToGrayscaleImageData(pixels, width, height) {
    const imageData = new ImageData(width, height);
    for (let i = 0; i < pixels.length; i++) {
      const grayValue = pixels[i]; // Assuming pixels are already normalized grayscale values
      imageData.data[i * 4] = grayValue; // Red channel
      imageData.data[i * 4 + 1] = grayValue; // Green channel
      imageData.data[i * 4 + 2] = grayValue; // Blue channel
      imageData.data[i * 4 + 3] = 255; // Alpha channel (fully opaque)
    }
    return imageData;
  }

function DeepLookFunction(centerPosition, xRadius, yRadius, imagePixels,imageWidth) {

    // Assuming you have the required parameters available
    const ovalBoundingBox = calculateOvalBoundingBox(centerPosition, xRadius, yRadius);
    //const pixelsInBoundingBox = extractPixelsInBoundingBox(imagePixels, ovalBoundingBox, imageWidth);



}

export default DeepLookFunction