import React, { useState, useEffect } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      if (supportsPWA) {
        window.promptInstall();
        console.log("PWA installation initiated");
      }
    };

    document.addEventListener("beforeinstallprompt", handler);
    return () => document.removeEventListener("beforeinstallprompt", handler);
  }, []);

  useEffect(() => {
    const checkInstalled = async () => {
      const installed = await navigator.serviceWorker.getInstalled();
      setIsInstalled(installed.length > 0);
    };

    checkInstalled();
    return () => {};
  }, []);

  const buttonText = isInstalled ? "Uninstall PWA" : "Install as PWA";
  const buttonDisabled = !supportsPWA;

  return (
    <button
      disabled={buttonDisabled}
      onClick={promptInstall}
      style={{ margin: "10px" }}
    >
      {buttonText}
    </button>
  );
};

export default InstallPWA;
