import React, { useState } from "react";
//import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import List from "@mui/material/List";
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import ProfileIcon from "../../../utilityComponents/images/ProfileIcon";
import WorklistIcon from "../../../utilityComponents/images/WorklistIcon";
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
//Custom Components
import TeamDialoge from "../../subComponents/forms/Team/team"
import { useSelector,useDispatch  } from "react-redux";
import { updateDefaultworkspace } from "../../../states/slices/workspaces/workspacesSlice";
import updateDefaultworkspaceAPI from "../../../functions/api/workspace/updateDefaultworkspace";
import { updateVal } from "../../../states/slices/user/userSlice";

const styles = {
  avatarStyles: {
    width: 24,
    height: 24,
  }
}


// const useStyles = makeStyles({
//   Menu_icons: {
//     fill: "#F5F5F5",
//     color: "#F5F5F5",
//   },
//   Menu_Text: {}
// })



export default function WorkspacesMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  //const classes = useStyles()
  const open = Boolean(anchorEl);
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")

  const dispatch = useDispatch();
  const user = useSelector((state)=>state.user);
  const Defaultworkspace=useSelector((state)=>state.Defaultworkspace)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })


////////////////////////////////////////
//
// Functions 
//
/////////////////////////////////////////

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDefaultWorkspaceChange(newDefaultworkspace){
    updateDefaultworkspaceAPI(newDefaultworkspace["defaultWorkspaceID"],user["userID"])
    .then(response => response.text())
    .then(result => {
      console.log("return from updateDefaultworkspaceAPI", result)
      const respond = JSON.parse(Object.assign(result))
      if (respond["statusCode"] === 200) {
        console.log("successfully changed updateDefaultworkspaceAPI", respond)
        dispatch(updateDefaultworkspace(newDefaultworkspace))
        dispatch(updateVal({ key: "DefaultWorkspaceID", val: newDefaultworkspace["defaultWorkspaceID"] }))
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Updated Default Workspace Information", severity: "success" })
      }
      else{
        setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update Workspace Information.", severity: "error" })

      }
    })
    .catch(error =>
      setOpenSnackbar({ ...openSnackbar, open: true, Message: "Failed to update Workspace Information.", severity: "error" })
    );

   
  }

////////////////////////////////////////
//
// Lists structure
//
/////////////////////////////////////////



  return (
    <React.Fragment>
    <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Workspaces">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ mr: "24px" }}
            aria-controls={open ? 'workspaces-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <GroupsOutlinedIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="workspaces-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        color="white"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            color: "white",
            '& .MuiList-root': {
              backgroundColor: colors.dark_grey[400]
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        {user["userWorkspaces"].map((workspace,index)=>{
            const WorkspaceOwnerName = workspace["ownertitle"]+" "+workspace["ownerfirstname"]+" "+workspace["ownerlastname"]
            const DefaultWorkspace={
                defaultWorkspaceID:workspace["organisationid"], 
                defaultWorkspaceName:workspace["name"],
                defaultWorkspaceOwnerName:WorkspaceOwnerName,
                defaultWorkspaceRoles:workspace["role"],
                defaultWorkspaceRights:workspace["rights"],
                defaultWorkspaceStatus:workspace["status"]
                 
              }
            return(
          <MenuItem 
          onClick={()=>handleDefaultWorkspaceChange(DefaultWorkspace)}
              key={index}
          >
          <ListItemIcon sx={{ color: workspace["organisationid"]===Defaultworkspace["defaultWorkspaceID"]?colors.text[500]:"#F5F5F5", fill: workspace["organisationid"]===Defaultworkspace["defaultWorkspaceID"]?colors.text[500]:"#F5F5F5" }}>
           <WorkspacesOutlinedIcon/>
          </ListItemIcon>
          <Typography sx={themeStyles.typography.h71} color={ workspace["organisationid"]===Defaultworkspace["defaultWorkspaceID"]?colors.text[500]:"#F5F5F5"} >
          {workspace["name"]?workspace["name"]:""}
             </Typography>
          
        </MenuItem>
        )})}



 
      </Menu>
    </React.Fragment>
  );
}