import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react';
//import QrCodeWithLogo from 'qrcode-with-logos';

import { Typography } from '@mui/material';
import Logo from '../../../../utilityComponents/images/novu';
import { themeSettings, tokens } from '../../../../utilityComponents/theme';


export default function QRCodeGenerator ({jsonData}) {
    const [apiData, setApiData] = useState(null);
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark");

    useEffect(() => {
        const queryParams = new URLSearchParams({
            id: JSON.stringify(jsonData), // Stringify the JSON data
          });

          //setApiData(`${"https://app.novu.ai/external"}?${queryParams.toString()}`)
          setApiData(`${"https://app.novu.ai/external"}?id=${jsonData}`)
    }, [])
    

  return (
    <span className="qrcode-container">
        {apiData && 
        <><QRCode value={apiData} renderAs="svg" size={200} />
        {/* <Typography sx={[themeStyles.typography.h81]}>
                  Web Link: {apiData}
        </Typography> */}
              </>
        }
    </span>
  )
}
