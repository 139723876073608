export default function GetWorkspaceUsers(WorkspaceOwnerID, WorkspaceID) {
    const user = JSON.parse(localStorage.getItem('user'))
    //console.log(user)
    
    const values = {
        "WorkspaceOwnerID": WorkspaceOwnerID,
        "WorkspaceID": WorkspaceID,
    }

    console.log("GetWorkspaceUsers Request", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.get_users_to_workspace, requestOptions)

}