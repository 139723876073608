import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Image from 'mui-image'
import Slider from "@mui/material/Slider";
import { themeSettings, tokens } from '../../../../../../utilityComponents/theme';
import DeepLookSegmentationPostAPI from '../../../../../../functions/api/AItoolsPartners/DeepLook';
import DeepLookSegmentationImage from './SampleImage';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DirectionSnackbar from "../../../../../subComponents/snackbars/snackbar";

// function ValueLabelComponent(props) {
//   const { children, value } = props;

//   return (
//     <Tooltip enterTouchDelay={0} placement="top" title={value}>
//       {children}
//     </Tooltip>
//   );
// }
// ValueLabelComponent.propTypes = {
//   children: PropTypes.element.isRequired,
//   value: PropTypes.number.isRequired,
// };

const TempAIfindings=  {
  mamilla: {
      point: {x: 1529, y: 1823},
      radius: 40,
      isEditable: false,
      isVisible: true,
      style: {
          color: '#E72828',
          lineWidth: 2
      }
  },
  muscleLine: {
      startPoint: {x: 2043, y: 820},
      endPoint: {x: 2393, y: 1356},
      isEditable: false,
      isVisible: true,
      style: {
          color: '#E72828',
          lineWidth: 2,
          dashedLine: false
      }
  },

  regionLines: [
      {
          uid: 'region-line-1',
          startPoint: {x: 1476, y: 1097},
          middlePoint: {x: 2149, y: 1601},
          endPoint: {x: 2021, y: 2242},
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0D88B5',
              lineWidth: 1,
              dashedLine: true
          }
      },
      {
          uid: 'region-line-2',
          startPoint: {x: 1528, y: 921},
          middlePoint: {x: 2294, y: 1564},
          endPoint: {x: 2230, y: 2279},
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0D88B5',
              lineWidth: 2,
              dashedLine: false
          }
      }
  ],
  lesions: [
      {
          uid: 'lesion-1-1',
          type: 'ellipse-lesion',
          point: {x: 1857, y: 896},
          xRadius: 135,
          yRadius: 75,
          rotationAngle: 25,
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0E5715',
              fillColor: '#0E5715',
              fillOpacity: 0.2,
              dashedLine: true
          }
      },
      {
          uid: 'lesion-1-2',
          type: 'polyline-lesion',
          isEditable: false,
          isVisible: true,
          points: [
              {x: 1624, y: 1026},
              {x: 1722, y: 969},
              {x: 1815, y: 1033},
              {x: 1752, y: 1107},
              {x: 1707, y: 1087},
              {x: 1663, y: 1106}
          ],
          style: {
              color: '#C6C413',
              fillColor: '#C6C413',
              fillOpacity: 0.2
          }
      }
  ]
}

// const values = {
//   "instanceuid": "2.20210425113900508950.10000033.40411363837630478977666154723677",
//   "centerPosition": {
//       "x": 76.65268825437751,
//       "y": 194.66429943553086
//   },
//   "xRadius": 146.66487842425704,
//   "yRadius": 152.47338795661926,
//   "spacing": [0.105473, 0.105473]
// }

export default function DeepLookSlidesDrawer({ anchor, openDrawerState, type, closeDrawer, finding }) {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })
  const [loadingData, setLoadingData] = React.useState(false);
  const [SegmentationResponse, setSegmentationResponse] = useState(null)
  const [marginImage, setMarginImage] = useState("")
  const [defaultMargin, setDefaultMargin] = useState(5)
  const [maxMargin, setMaxMargin] = useState(10);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let values = {
    "instanceuid": finding?.instanceUid,
    "centerPosition": {
        "x": finding?.data.centerPosition.x,
        "y": finding?.data.centerPosition.y
    },
    "xRadius": finding?.data.xRadius,
    "yRadius": finding?.data.yRadius,
    "spacing": finding?.pixelSpacing,
    "margin" : "all"
  }


  useEffect(() => {
    console.log(finding, "finding from DeepLookSlider")
    values = {
      "instanceuid": finding?.instanceUid,
      "centerPosition": {
          "x": finding?.data.centerPosition.x,
          "y": finding?.data.centerPosition.y
      },
      "xRadius": finding?.data.xRadius,
      "yRadius": finding?.data.yRadius,
      "spacing": finding?.pixelSpacing
    }

  }, [finding])
  


  function GetSegmentation() {
    setLoadingData(true)
    
    try {
      DeepLookSegmentationPostAPI(values).then(response => response.json()).then((segmentation) => {

        const Response = JSON.parse(segmentation)["SegmentationResponse"]
        console.log(Response,"Response")
        const SegmentationResponse = JSON.parse(Response)
        console.log(SegmentationResponse)
        setSegmentationResponse(SegmentationResponse)
        setDefaultMargin(SegmentationResponse["default_margin_index"])
        setMaxMargin(SegmentationResponse.margins.length)
        setMarginImage(SegmentationResponse["margins"][SegmentationResponse["default_margin_index"]]["rendered_image_data"])
        setLoadingData(false)
        updateMeasurementsinViewer()
      }).catch(error=>{
        //setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while getting your notifications.", severity: "warning" })
        setLoadingData(false)
        console.log(error)
      }) 
    } catch (error) {
      setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while calling DeepLook Endpoint.", severity: "error" })
      setLoadingData(false)
    }
  }

  function updateMeasurementsinViewer(){
    viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', TempAIfindings);

  }
  const handleChangeMargin = (event, newValue) => {
    //setValue(newValue);
    setDefaultMargin(newValue);
    setMarginImage(SegmentationResponse.margins[newValue].rendered_image_data);
    //    console.log("Slider value changed to:", newValue);
    handleMeasurements(SegmentationResponse.margins[newValue])
  };

  function handleMeasurements(margins){

  }

  const list = (anchor) => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
      onClick={toggleDrawer('bottom', false)}
      onKeyDown={toggleDrawer('bottom', false)}
      backgroundColor={"transparent"}

    >
      <Grid container spacing={2}
        height={"25vh"}
        justifyContent={"center"}
        display={"flex"} alignItems={"center"} >
        <Grid item xs={4} justifyContent={"center"} display={"flex"}>
          <Box width="90%" >
            <Button sx={{ color: colors.novuColors[120], width: "inherit" }}
              onClick={GetSegmentation}>Trigger DeepLook</Button>

            <Typography gutterBottom>Margins</Typography>
            <Slider
              defaultValue={defaultMargin}
              //sx={{}}
              color="secondary"
              value={defaultMargin}
              disabled={SegmentationResponse ? false : true}
              step={1}
              marks
              min={0}
              max={maxMargin - 1}
              onChange={handleChangeMargin}
              //getAriaValueText={valuetext}
              aria-label="Margins"
            />
          </Box>

        </Grid>

        <Grid item xs={4} justifyContent={"center"} display={"flex"} alignItems={"center"} >
          <DeepLookSegmentationImage image_data={marginImage} />
        </Grid>
        <Grid item xs={4} justifyContent={"center"} display={"flex"}>
          <Box width="90%">
            <Typography>
              Segmentation Version: {SegmentationResponse ? SegmentationResponse.segmentation_version : ""}
            </Typography>
            <Typography>
              Pixels per mm: {SegmentationResponse ? SegmentationResponse.pixels_per_mm : ""}
            </Typography>
            <Typography>Margin: {defaultMargin}</Typography>
            <Typography>
              Area mm:{" "}
              {SegmentationResponse?.margins[defaultMargin].outline.area_mm}
            </Typography>
            <Typography>
              Area Pixels:{" "}
              {SegmentationResponse?.margins[defaultMargin].outline.area_pixels}
            </Typography>
            <Typography>
              Spiculation:{" "}
              {SegmentationResponse?.margins[defaultMargin]?.outline.spiculation}
            </Typography>

          </Box>
        </Grid>
      </Grid>

    </Box>
  );

  return (
    <><DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })} />
    <div>

      <React.Fragment key={'bottom'}>

        <SwipeableDrawer
          BackdropProps={{ invisible: true }}
          height={"50%"}
          anchor={anchor}
          open={openDrawerState}
          onClose={() => closeDrawer()}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      


    </div></>
  );
}