import React, { useState, useRef, useEffect } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchBarWithIcon = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const searchInputRef = useRef(null);

  const handleSearchIconClick = () => {
    setExpanded(!expanded);
    setSearchValue('');
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
    // Handle search logic here
  };

  const handleBlur = () => {
    if (searchValue === '' && !expanded) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    if (expanded) {
      searchInputRef.current.focus();
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded]);

  return (


    <Box display="flex" alignItems="center">
          <TextField
              ref={searchInputRef}
              fullWidth
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchValueChange}
              variant="outlined"
              onBlur={handleBlur}
              style={{
                  transition: 'width 0.3s',
                  width: expanded ? '100%' : '0',
                  overflow: expanded ? 'visible' : 'hidden',
                  marginLeft: expanded ? '8px' : '0',
                  fontSize: '13px',
                  fontWeight: '300',
              }}
              InputProps={{
                  style: {
                      fontSize: '13px',
                      fontWeight: '300',
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      paddingLeft: '0',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      height: '40px',
                  },
                  classes: {
                      notchedOutline: expanded ? 'search-field-outline' : '',
                  },
              }} />
              <IconButton onClick={handleSearchIconClick}>
              <SearchIcon />
          </IconButton>
      </Box>
  );
};

export default SearchBarWithIcon;
