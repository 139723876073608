import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  SignatureContainer: {
/*     flexDirection: "row",
    justifyContent: "flex-end", */
    marginTop:"50"
  },
  PatientDataContainer: {
    flexDirection: "row",
    marginLeft:"300",
    //justifyContent: "flex-end",
    //marginTop:"36"
  },
  Data: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 70,
  },
});

const ReportSignature = ({ ReportStudy, user }) => (
  <Fragment>
    <View style={styles.SignatureContainer}>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Report No:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.local_patientID}</Text>
    </View>

    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Author:</Text>
      <Text style={styles.Data}>{user.userFullName}</Text>
    </View>
    </View>

  </Fragment>
);

export default ReportSignature;
