import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Draggable from 'react-draggable';
import Lesion3DRepresentation from './Lesion3DRepresentation';

const LesionDialog = ({ open, onClose, polyPoints }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <Draggable handle="#dialog-title">
                <div>
                    <DialogTitle id="dialog-title">Lesion 3D Representation</DialogTitle>
                    <DialogContent>
                        <Lesion3DRepresentation polyPoints={polyPoints} />
                    </DialogContent>
                </div>
            </Draggable>
        </Dialog>
    );
};

export default LesionDialog;
