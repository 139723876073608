import { Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // Add any additional styles here
  });
  
  export const StyledForm = styled('form')({
    width: '100%',
    marginTop: 'theme.spacing(3)', // You need to replace 'theme.spacing(3)' with the actual value you want to use
    // Add any additional styles here
  });
  
  export const StyledTextField = styled(TextField)({
    backgroundColor: 'black',
    '& .MuiFormHelperText-root': {
      backgroundColor: 'transparent',
    },
  });
  
  export const StyledLabel = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
}));