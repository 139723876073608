// SocketContext.js

import React from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';


export const SocketContext = React.createContext();

export const SocketProvider = ({ children }) => {
    const user = useSelector ((state) => state.user)

    const socket = io({
        auth: {
          userID: user.userID,
          userLoginToken: user.userLoginToken,
          organisationid: user.organisationid
        }
      }); 

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
