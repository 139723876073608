import * as React from 'react';
import {
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import {update} from "../../../states/slices/user/userSlice"
import { userDataSlice } from '../../../states/slices/user/userSlice';

// import Session from 'supertokens-web-js/recipe/session';
// import { useSessionContext } from "supertokens-auth-react/recipe/session";
// import { signOut } from "supertokens-auth-react/recipe/emailpassword";
// import SuperTokens from 'supertokens-web-js';
// import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
// import {doesSessionExist} from 'supertokens-auth-react/recipe/session';
import { updateDefaultworkspace } from '../../../states/slices/workspaces/workspacesSlice';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  //let session = useSessionContext();
  const userRedux = useSelector((state)=>state.user.userLoggedIN)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const HandleLogin = async (response,novu) => {
   const userFullName = response["me"]["customFields"]["title"]+" "+ response["me"]["customFields"]["firstname"]+" "+response["me"]["customFields"]["lastname"]

    const user = {
        userLoggedIN:true, 
        userID:response["userId"],
        userLoginToken:response["authToken"],
        avatarUrl:response["me"]["avatarUrl"],
        userEmail:response["me"]["emails"][0]["address"],
        userTitle:response["me"]["customFields"]["title"], 
        userRole:response["me"]["customFields"]["role"],
        organisationid:response["me"]["customFields"]["organisationID"],
        userFirstname: response["me"]["customFields"]["firstname"],
        userLastname: response["me"]["customFields"]["lastname"],
        userFullName:userFullName,
        userUserName: response["me"]["username"],
        userRights:response["rights"],
        userWorkspaces:novu["body"],
        DefaultWorkspaceID:novu["body"][0]["settings"]["DefaultWorkspaceID"]
    }

    const workspaces=novu["body"]

    //console.log("user", user)
    //console.log("workspaces", workspaces)

    window.sessionStorage.setItem('Meteor.loginToken', response["authToken"]);
    window.sessionStorage.setItem('Meteor.userId', response["userId"]);
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('workspaces', JSON.stringify(workspaces))

    dispatch(update(user))


    {user["userWorkspaces"].map((workspace)=>{
      if(workspace["organisationid"]===user["DefaultWorkspaceID"]){
      const WorkspaceOwnerName = workspace["ownertitle"]+" "+workspace["ownerfirstname"]+" "+workspace["ownerlastname"]
      const DefaultWorkspace={
                defaultWorkspaceID:workspace["organisationid"], 
                defaultWorkspaceName:workspace["name"],
                defaultWorkspaceOwnerName:WorkspaceOwnerName,
                defaultWorkspaceRoles:workspace["role"],
                defaultWorkspaceRights:workspace["rights"],
                defaultWorkspaceStatus:workspace["status"]
                 
              }
      dispatch(updateDefaultworkspace (DefaultWorkspace))
            }
    })
  }


    


    const origin = location.state?.from?.pathname || global.config.homePagePath;
    navigate(origin);   
 }


  const handleLogout = async () => {
    

    //await Session.signOut(); 
    localStorage.clear();
    //await Session.signOut(); 
    window.location.href = "/";

  };

  const value = {
    onLogin: HandleLogin,
    onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const ProtectedRoute = ({ children }) => {
  //const sessionContext = useSessionContext(); 
  const userDataState = useSelector(userDataSlice)
  const userDataStorage = JSON.parse(localStorage.getItem('user')) 

  const location = useLocation();
  //console.log("userData from Protected Route", userDataState, userDataStorage)
  //console.log("user LoggedIn: \n", userDataState.userLoggedIN || userDataStorage.userLoggedIN)
  if (!userDataState.userLoggedIN && !userDataStorage ) {
   // return <Navigate to="/Landing" replace state={{ from: location }} />
   return <Navigate to="/Landing" replace state={{ from: location }}/>
  }
  return children;
};
