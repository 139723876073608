export const columnNames = {
  "number":{
    // Table rows count column
    label:"counter Column",
    name: "",
    active: false,
    width:"40px",
    Hideable :false,
    show: false
  },
  "emtpy":{
    // Table border
    label:"distancing Column",
    name: "",
    active: false,
    width:"16px",
    Hideable :false,
    show: false
    
  },
    "PatientName":{
      name: "Name",
      active: false,
      width:"15%",
      Hideable :true,
      show: true
    },
    "modality":{
      name: "Visit Type",
      active: false,
      width:"8%",
      Hideable :true,
      show: true
    },
    "medicalprofile":{
      name: "Medical Profile",
      active: false,
      width:"10%",
      Hideable :true,
      show: true
    },
    "risk_index":{
      name: "Risk Index",
      active: false,
      width:"9.5%",
      Hideable :true,
      show: true
    },
    "patientID":{
      name: "Patient ID",
      active: false,
      width:"10%",
      Hideable :true,
      show: true
    },
    "Accession":{
      name: "Accession No.",
      active: false,
      width:"10%",
      Hideable :true,
      show: false
    },
    "DOB":{
      name: "Date of Birth",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "Acquisition":{
      name: "Acquisition",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "Instition":{
      name: "Instition",
      active: false,
      width:"",
      Hideable :true,
      show: false
    },
    "main":{
      name: "main",
      active: true,
      width:"10%",
      Hideable :false,
      show: false
    },
    "secondary":{
      name: "secondary",
      active: true,
      width:"10%",
      Hideable :false,
      show: false
    },
    "reviewer":{
      name: "Case Status",
      active: false,
      width:"",
      Hideable :false,
      show: true
    },
    "actions":{
      label:"review Column",
      name: "",
      active: true,
      width:"20%",
      Hideable :false,
      show: true
    },
    
  };