import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  pdf,
  Text,
  View,
} from "@react-pdf/renderer";
//import Html from 'react-pdf-html';

import ReportTitle from "./ReportTitle";
import ReportNo from "./ReportNo";
import PatientInformation from "./PatientInformation";
import Footer from "./Reportfooter";
import Header from "./ReportHeader";
import ReportMainFindings from "./ReportMainFindings";
import ReportFindingsTable from "./ReportFindingsTable";
import ReportFinalComments from "./ReportFinalComments";
import ReportSignature from "./ReportSignature";
import { SendPDFAPI } from "../../../../../functions/api/sendReport/sendPDF";
import { useSelector } from "react-redux";
import logo from "./logo.png";

const styles = StyleSheet.create({
  body: {
    fontFamily: "Helvetica",
    fontSize: 11,
    lineHeight: 1.5,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    paddingLeft: 60,
    paddingRight: 60,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  ImageContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  logo: {
    width: 74,
    height: 74,
    marginLeft: 400,
    marginRight: "auto",
  },
  PatientTitle: {
    fontSize: 14,
    marginTop: 36,
    paddingBottom: 3,
    fontStyle: "bold",
    fontFamily: "Helvetica-Oblique",
  },
});

const Report = {
  userName: "Dr. Markus Müller",
  ReportNo: "201906-28",
  local_patReportientID: "CHEST 81678",
  PatientName: "FARG MOHAMED MOHAMED",
  PatientSex: "M",
  PatientAge: "055Y",
  PatientBirthdate: "19651207",
  StudyInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120621155870700000367",
  SeriesInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120712354943800000482",
  SOPInstanceUID: "1.3.12.2.1107.5.1.7.112500.30000020120712354943800000838",
  SeriesDate: "20201207",
  SeriesTime: "124805.598000",
  BodyExaminationPart: "CHEST",
  Modality: "CT",
  ViewPosition: "",
  ImageLaterality: "",
  AccessionNumber: "",
  InstitutionName: "El Salam Royal Hospital",
  ReferringPhysicianName: "",
  StationName: "CT112500",
  StudyDescription: "Chest Without_Royal(Adult)",
  InstanceOrigin: "REST_API",
  additional: {},
  organisationid: "f1d61c5a-1951-49b3-abbf-a3d59f169418",
  mainfinding:
    "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
  findings: [
    {
      No: 1,
      description: "Lesion",
      Width: "30mm",
      Height: "15mm",
      Area: "25mm2",
      comments:
        "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
    },
    {
      No: 2,
      description: "Lesion2",
      Width: "30mm",
      Height: "15mm",
      Area: "25mm2",
      comments:
        "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
    },
    {
      No: 3,
      description: "Lesion3",
      Width: "30mm",
      Height: "15mm",
      Area: "25mm2",
      comments:
        "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
    },
  ],
  AdditionalFindings:
    "En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho   tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco   y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches,   duelos y quebrantos los sábados, lentejas los viernes, algún palomino de añadidura   los domingos, consumían las tres partes de su hacienda. El resto della concluían sayo   de velarte, calzas de velludo para las fiestas con sus pantuflos de lo mismo",
};

const ReportDocument = ({ ReportStudy, user }) => {
  console.log("ReportStudy", ReportStudy);
  return (
    <Document
      title="MedicalReport"
      author={user.userFullName}
      creator="NOVU AI"
    >
      <Page size="A4" style={styles.body}>
        <Header />
        <ReportTitle title="Medical Report" />
        <ReportNo ReportStudy={ReportStudy} user={user} />
        <PatientInformation ReportStudy={ReportStudy} />
        <Text style={styles.PatientTitle}>Main Finding:</Text>
        <Text style={styles.PatientTitle}>
          {ReportStudy.report.raw}
          {/* {ReportStudy?.report?.raw ? ReportStudy.report.raw : ""} */}
        </Text>
        <ReportFindingsTable ReportStudy={ReportStudy} />
        <ReportSignature ReportStudy={ReportStudy} user={user} />

        {/* <InvoiceThankYouMsg /> */}

        <Footer />
      </Page>
    </Document>
  );
};

export default ReportDocument;

/*    const ReportFile = pdf(ReportDocument(Report)).toBlob();
  SendPDFAPI(ReportFile)
            .then(response => response.text())
            .then(results => { 
                console.log("results",results)
     }) 
  */
