  export const liveData  = [
    {
      "accessionnumber": "Data anonymised", 
      "additional": {}, 
      "bodyexaminationpart": "BREAST", 
      "date": "Fri, 03 Mar 2023 00:00:00 GMT", 
      "initiator_firstname": null, 
      "initiator_lastname": null, 
      "institutionname": "Data anonymised", 
      "local_patientid": "demd122", 
      "modality": "MG", 
      "organisationid": "051df4ac-e3ad-44c9-bad9-659c01b4da4f", 
      "PatientAge": "073Y", 
      "patientbirthdate": "19380101", 
      "PatientName": "demd122", 
      "patientsex": "", 
      "reciever_firstname": null, 
      "reciever_lastname": null, 
      "referringphysicianname": "Data anonymised^^^^", 
      "results": {
        "error": 401
      }, 
      "risk_index": 3.0, 
      "seriesdate": "19330422", 
      "seriestime": "000000", 
      "stationname": "Data anonymised", 
      "status": null, 
      "studydescription": "Screening Mammography", 
      "studyinstanceuid": "1.2.826.0.1.3680043.9.3218.1.1.2695005.4962.1510228559379.4367.0"
    }
  ]
  