import React from 'react'
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
// Custom Styles
import { themeSettings } from "../../../utilityComponents/theme";
import { useState, useEffect } from 'react';


export default function TableHead_Worklist({visibleColumns, showFirstRow}) {
  const themeStyles = themeSettings("dark")
  const [columnCount, setcolumnCount] = useState(visibleColumns.length)

  useEffect(() => {
    CountColumn(visibleColumns)
  }, [visibleColumns])


  
  function CountColumn(visibleColumns){
    //This function counts the columns hidden before the AI results
    // Because the first row cells are collapsed together
    let i = 2
    Object.keys(visibleColumns).map((Item, index) => {
      console.log("Item", Item)
      //console.log("visibleColumns.includes(visibleColumns[Item][name])",visibleColumns.includes(Item))
      if (visibleColumns[Item]["Hideable"]===true && visibleColumns[Item]["show"])
      {          
          i += 1
      }
    })
    console.log("CountColumns", i)
    setcolumnCount(i)
    
  }



  return (

<TableHead align="center">

{showFirstRow? 
    <TableRow style={{borderBottom:"none",padding: "0px"}}>    
       <TableCell align="center" style={{borderBottom:"none", height:"10px", color: "#9f9f9f"}}  colSpan={columnCount}/>
        <TableCell align="center" style={{ borderColor: '#9f9f9f' , height:"10px", color: "#9f9f9f", padding:"0px"}}  colSpan={2}>
          <Typography sx={themeStyles.typography.h51}>AI Findings</Typography>
        </TableCell>
    </TableRow>:""}

    <TableRow>

    {
    Object.keys(visibleColumns).map((Item, index) => {
      //console.log("Table Head",visibleColumns[Item],Item)
      if (visibleColumns[Item]["show"]){
      return(   
      <TableCell align="center" key={index} style={{borderBottom:"none",  height:"40px" , padding: "0px", width:visibleColumns[Item]["width"] , color: visibleColumns[Item]["active"]? "#F5F5F5":'#9f9f9f'}}>
      <Typography sx={themeStyles.typography.h51}>{visibleColumns[Item]["name"]}</Typography> 
      </TableCell>
      )
    }
      
  })}
    </TableRow>

</TableHead>
  )
}

