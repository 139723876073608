//React components
import React, { useState, useEffect, useRef } from "react";

//MUI Components
import { Box, IconButton, Table, TableContainer, Toolbar } from "@mui/material";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { styled, useTheme } from "@mui/material/styles";
//import { makeStyles } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { useSelector } from "react-redux";


//
//
// Components STYLINGs START
//
//
//
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      margin: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${theme.drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duexxampleration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
  
      }),
    })
  );
  
  
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  
  // const useStyles = makeStyles({
  //   UploadBtnStyle: {
  //     backgroundColor: colors.dark_grey[400],
  //     borderColor: 'transparent',
  //     color: colors.greenFlow[100],
  //     "border-radius": "5px",
  //     '&:hover': {
  //       backgroundColor: 'transparent',
  //       //color: '#3c52b2',
  //     },
  //   },
  //   CustomOverlayStyle: {
  //     color: colors.dark_grey[200],
  //     marginTop: "300"
  //   }
  // })
  
  
  
  //
  //
  // Components STYLINGs END
  //
  //
  //

  
const Profile = () => {
     //Styles 
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles()
  const webRef = useRef(null);
  const ref = React.useRef();
  const [height, setHeight] = React.useState("0px");
  // const onLoad = () => {
  //     console.log("height",ref.current.contentWindow.document.body.scrollHeight)
  //       setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  // }

  //const user = JSON.parse(localStorage.getItem("user"))
  //const token = sessionStorage.getItem("Meteor.loginToken")
  const user = useSelector((state)=>state.user)

  const init = () => {
    document.getElementById('profile').contentWindow.postMessage({
      externalCommand: 'login-with-token',
      token: user["userLoginToken"]
    }, '*');
    }

    
  const screensizes = () => {
    const pageHeight = window.screen.height
    const topBarHeight = document.getElementById("GlobalTopbar").clientHeight
    const iframeHeight = pageHeight - 3 * topBarHeight - 24
    setHeight(iframeHeight + "px")
  }

  useEffect(() => {
    screensizes()
  }, [])

    return (

        <Box
        //sx={boxstyles} 
        id={"ChatBox"}
        sx={{ display: "flex" }}
      >
        <CssBaseline />
        <Topbar />
        <Sidebar />
        <Main styled={{ backgroundColor: "inherit" }}
          id={"MainContentChat"}>
          <Toolbar variant="dense" />
          <Box backgroundColor="inherit"
            sx={{ margin: "-24px -24px -24px -24px" }}
          >
            {/*     //
          //
          // START ADDING ALL PAGE CONTENTS HERE
          //
          // 
  */}
            {/* <div onClick={() => {
  document.getElementsByTagName(“iframe”)[0].contentWindow.postMessage(
  { externalCommand: "go", path: "/channel/Evening-Party/?layout=embedded" },
  "http://localhost:3000"
  );}}> Evening Party </div> */}
  
  
  
    <iframe
      //onLoad={onLoad}
      id="profile"
      title="profile"
      width='100%'
      height={height}
      //scrolling="no"
      //frameBorder="0"
      style={{ border: 'none', overflow: 'hidden' }}
      onLoad={init}
      allowfullscreen
      //ref={webRef}
      //src="https://app.novu.ai/auth2/channel/general/?layout=embedded"
      src="https://chat.novu.ai/account/profile"
    ></iframe>
  
  
  
  
  
  
  
  
  
            {/*     //
          //
          // STOP PAGE CONTENTS HERE
          //
          // 
  */}
          </Box>
        </Main >
      </Box >


    )
}

export default Profile;