import React from 'react'

export function DeleteUser(userID) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({'userID':userID})

console.log("values in delete function", raw)
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");


var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(global.config.api_delete_user, requestOptions)

}
