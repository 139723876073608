export default function AddanonymizationAPI(study,user){
    console.log("from AddanonymizationAPI", study,user)
    const values = {
       "StudyInstanceUID": study["StudyInstanceUID"],
       "user":{
        "FullName":user["userFullName"],
        "avatar":user["avatarUrl"]
        },
        "WorkspaceID":user["DefaultWorkspaceID"],
    }

    console.log("AddanonymizationAPI", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.api_anonymization_add, requestOptions)

}
