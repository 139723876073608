import React,{ useState, useEffect } from "react";
import { IconButton,Box, TableContainer,Collapse, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import SubTable from "./subTable";

export default function PatientRow({row}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")
    const [open, setOpen] = React.useState(false);

useEffect(() => {
  console.log("PatientRow",row)
}, [])


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
{/*         <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        {/* <TableCell align="center">{row["PatientMainDicomTags"]["PatientBirthDate"]?row["PatientMainDicomTags"]["PatientBirthDate"]:""}</TableCell>
        <TableCell align="center">{row["PatientMainDicomTags"]["PatientName"]?row["PatientMainDicomTags"]["PatientName"]:""}</TableCell>
        <TableCell align="center">{row["PatientMainDicomTags"]["PatientID"]?row["PatientMainDicomTags"]["PatientID"]:""}</TableCell>
        <TableCell align="center">{row["MainDicomTags"]["StudyDescription"]?row["MainDicomTags"]["StudyDescription"]:""}</TableCell>
        <TableCell align="center">{row["MainDicomTags"]["StudyDate"]?row["MainDicomTags"]["StudyDate"]:""}</TableCell>
        <TableCell align="center">{row["RequestedTags"]["ModalitiesInStudy"]?row["RequestedTags"]["ModalitiesInStudy"]:""}</TableCell>
        <TableCell align="center">{row["MainDicomTags"]["AccessionNumber"]?row["MainDicomTags"]["AccessionNumber"]:""}</TableCell>
        <TableCell align="center">{row["Series"].length}</TableCell> */}


        <TableCell align="center">{row["PatientBirthdate"]?row["PatientBirthDate"]:""}</TableCell>
        <TableCell align="left">{row["PatientName"]?row["PatientName"]:""}</TableCell>
        <TableCell align="center">{row["local_patientID"]?row["local_patientID"]:""}</TableCell>
        <TableCell align="center">{row["StudyDescription"]?row["StudyDescription"]:""}</TableCell>
        <TableCell align="center">{row["seriesdate"][0]?row["seriesdate"][0]:""}</TableCell>
        <TableCell align="center">{row["modality"][0]?row["modality"][0]:""}</TableCell>
        <TableCell align="center">{row["AccessionNumber"]?row["AccessionNumber"]:""}</TableCell>
        <TableCell align="center">{row["seriesinstanceuid"].length}</TableCell>


      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box width={"100%"} sx={{ margin: 1 }}
            //width={"100%"} display={"flex"} 
            >
                              <Box Box width={"80%"} justifyContent={"center"} display={"flex"} >
              <Typography variant="h6" gutterBottom component="div">
                Description:
              </Typography>


              <Typography
              sx={{ width: "100%" }}
              fullWidth
              id="outlined-multiline-static"
              //placeholder="Describe your finding"
              multiline
              rows={4}
              
              //value={row["structureReporting"]["description"]?row["structureReporting"]["description"]:""}
              //defaultValue="Default Value"
              //variant="outlined"
              //onChange={(newValue) => {AddComments2Finding(newValue.target.value)}}
              >
                 {/* {row["MainDicomTags"]["StudyDescription"]?row["MainDicomTags"]["StudyDescription"]:""} */}

                 {row["StudyDescription"]?row["StudyDescription"]:""}

              </Typography>        
              </Box>
               <Box width={"100%"} justifyContent={"flex-end"} display={"flex"} >
                <Box width={"80%"} justifyContent={"flex-end"} display={"flex"} >
                {/* <SubTable  row={row} /> */}
                </Box>
                </Box>



            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
