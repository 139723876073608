import { Button, Grid, Tooltip } from "@mui/material"
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {Typography } from '@mui/material'
import { themeSettings, tokens } from "../../../../utilityComponents/theme";



export function FileHeader({ file, StopUpload,cancelToken , uploadingStatus,progress }) {
  const colors = tokens ("dark");
  const themeStyles = themeSettings("dark")
  return (
    <Grid container display="flex" justify="space-between" alignItems="center" marginTop={"10px"}>
      <Grid item xs="11" display="inherit">
      <Typography sx={[themeStyles.typography.h62]} 
      color={uploadingStatus===false?colors.novuColors[100]:colors.text[100]}
      >{file.name}</Typography> 
      </Grid>
      <Grid item xs="1" display="inherit" justifyContent="end" alignItems={"center"}>
      <Typography sx={[themeStyles.typography.h62]} 
      color={uploadingStatus===false?colors.novuColors[100]:colors.text[100]}
      >{progress}%</Typography> 

          <Tooltip title="Cancel">
          <IconButton onClick={() => StopUpload(file,cancelToken)} size="small">
            <DeleteIcon sx={{color:uploadingStatus===false?colors.novuColors[100]:colors.text[100]}}/>
          </IconButton>
          </Tooltip>

      </Grid>
    </Grid>
  );
}
