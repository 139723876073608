import React from 'react'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import {Backarrow, ReportIcon,Test, Rotate, Fullscreen, Zoom, Unknown4, Unknown5, ROI, Unknown7}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { ContentCopy, ContentCopyOutlined, ContentCut } from '@mui/icons-material';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import TonalityOutlinedIcon from '@mui/icons-material/TonalityOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined';

export function ImageToolsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  const MenuListImageTools=[
    {
        icon: <Zoom fontSize="small" />,
        name:"Zoom",        
        shortcut:"Z",
        functionname: zoom
    },
    {
      icon: <TonalityOutlinedIcon fontSize="small" />,
      name:"Windowing/Leveling",        
      shortcut:"1/6 ",
      functionname:windowing
  },
    {
        icon: <Rotate90DegreesCcwOutlinedIcon fontSize="small" />,
        name:"Rotate",        
        shortcut:"R",
        functionname: rotate
    },
    {
        icon: <PanToolAltOutlinedIcon fontSize="small" />,
        name:"Pan",        
        shortcut:"P",
        functionname:pan
    },
    {
        icon: <FlipOutlinedIcon fontSize="small" />,
        name:"Vertical Flip",        
        shortcut:"F",
        functionname:flip
    },

]
 
function zoom() {
  global.viewportsCore.setMouseButtonFunction(1, 2);
}
function windowing() {
  global.viewportsCore.setMouseButtonFunction(1, 1);
}
function rotate() {
  viewportsCore.getActiveViewport().applyRightRotation();
}
function pan() {
  global.viewportsCore.setMouseButtonFunction(1, 5);
}
function flip() {
  viewportsCore.getActiveViewport().applyHorizontalFlip();
}

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <DeblurOutlinedIcon fontSize='large'/>
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "220px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
        
      </HoverMenu>
    </div>
  );
}