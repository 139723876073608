import { createSlice} from '@reduxjs/toolkit'

const initialState = {
    defaultWorkspaceID:null, 
    defaultWorkspaceName:null,
    defaultWorkspaceOwnerName:null,
    defaultWorkspaceRoles:null,
    defaultWorkspaceRights:null,
    defaultWorkspaceStatus:null
     
  }
  


export const defaultWorkspaceSlice= createSlice({
    name:"Defaultworkspace",
    initialState,
  reducers: {
    updateDefaultworkspace:(state, action)=>{
      console.log("action.payload", action)
      state.defaultWorkspaceID=action.payload.defaultWorkspaceID
      state.defaultWorkspaceName=action.payload.defaultWorkspaceName
      state.defaultWorkspaceOwnerName=action.payload.defaultWorkspaceOwnerName
      state.defaultWorkspaceRoles=action.payload.defaultWorkspaceRoles
      state.defaultWorkspaceRights=action.payload.defaultWorkspaceRights
      state.defaultWorkspaceStatus=action.payload.defaultWorkspaceStatus
      
    },
}
})


export const { updateDefaultworkspace } = defaultWorkspaceSlice.actions;
const DefaultworkspaceReducer =  defaultWorkspaceSlice.reducer;
export default DefaultworkspaceReducer;
