// REACT COMPONENTS
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import { Button, CssBaseline, Grid, useMediaQuery,Typography, } from "@mui/material";
import { Box } from "@mui/system";
//import { makeStyles } from "@mui/styles";


//CUSTOM STYLES
import "./landing.css";

import Background from "../../../utilityComponents/images/stripes_backgroung.jpg";
import NOVULogo from "../../../utilityComponents/images/Transparent_Black_BG_1024.png";
import NOVULogoSVG from "../../../utilityComponents/images/novuLOGO.js";

import Logo from '../../../utilityComponents/images/novu.js';
import { tokens, themeSettings } from "../../../utilityComponents/theme.js";

// Amplify
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../../../amplifyconfiguration.json';
import { withAuthenticator, ThemeProvider, Theme,Heading, Authenticator, View, Text, Image, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import AmplifyAuth from "./AmplifyAuth.jsx";
//import { Auth } from 'aws-amplify';
//import { Hub } from 'aws-amplify/utils';
//import App from "../../../App";

Amplify.configure(amplifyconfig);



const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
      label: "Email"
    },
  },
  signUp: {
    'custom:title': {
      label: 'Title:',
      placeholder: 'Enter your Title:',
      isRequired: false,
      order: 1,
    },
    'custom:firstname': {
      label: 'First Name:',
      placeholder: 'Enter your First Name:',
      isRequired: true,
      order: 2,
    },
    'custom:lastname': {
      label: 'Last Name:',
      placeholder: 'Enter your Last Name:',
      isRequired: true,
      order: 3,
    },
    // username: {
    //   label: 'Email:',
    //   placeholder: 'Enter your Email:',
    //   isRequired: true,
    //   order: 4,
    // },
    'custom:username': {
      label: 'Username:',
      placeholder: 'Enter your Username:',
      isRequired: true,
      order: 3,
    },
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 5,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 6,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTotp: {
    QR: {
      totpIssuer: 'NOVU Ecosystem',
      totpUsername: 'NOVU',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

export const Landingonline = () => {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up('xm'));
  

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            width={"150px"}
            height={"150px"}
            alt="NOVU logo"
            src={NOVULogo}
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  
    // SignIn: {
    //   Header() {
    //     const { tokens } = useTheme();
  
    //     return (
    //       <Heading
    //         //backgroundColor={colors.dark_grey[500]}
    //         //border={}
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Sign in to your account
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     const { toForgotPassword } = useAuthenticator();
  
    //     return (
    //       <View textAlign="center">
    //         <Button
    //           fontWeight="normal"
    //           onClick={toForgotPassword}
    //           size="small"
    //           variation="link"
    //         >
    //           Reset Password
    //         </Button>
    //       </View>
    //     );
    //   },
    // },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    // ConfirmSignUp: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     return <Text>Footer Information</Text>;
    //   },
    // },
    // SetupTotp: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     return <Text>Footer Information</Text>;
    //   },
    // },
    // ConfirmSignIn: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     return <Text>Footer Information</Text>;
    //   },
    // },
    // ForgotPassword: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     return <Text>Footer Information</Text>;
    //   },
    // },
    // ConfirmResetPassword: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
    //   Footer() {
    //     return <Text>Footer Information</Text>;
    //   },
    // },
  };
  
  
  let navigate = useNavigate();

  const registerUser = async () => {
    // Your logic to register the user after successful login
    console.log("registerUser")
  };

  function handleAuthStateChange(state) {
    alert(state)
    if (state === 'signedIn') { 
      alert('here');
      console.log("USer SignedIn")
/*       return ( // go To Entry point of app
        <ApolloProvider store={store} client={client}>
        <AppWithNavigationState/>
        </ApolloProvider>
      ); */
    }
  }

// Auth.currentAuthenticatedUser({
//     bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
// }).then(user => console.log(user))
// .catch(err => console.log(err));


  return (
    <Box
      //className="login--root"´
      justifyContent={"center"}
      sx={{
        display: "flex",
        backgroundColor: colors.dark_grey[500]
      }}
    >
      <CssBaseline />
      <Box
        width={"100%"}
        height="20%"
        //mt={{xs:"3.5%", xl:"5%"}}
        mb={{xs:"3.5%", xl:"10%"}}
        className="login--box"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <Grid container spacing={2}
          justifyContent="center">

           <Grid item display="flex"
            xs={12}
            //mb={{xl:"5%", xs:"1%"}}
            alignItems="center"
            justifyContent={"center"}
            
          >
            <Typography
            
              align={"center"}
              color={colors.greenFlow[300]}
              sx={themeStyles.typography.h1}>
              Welcome to novu Ecosystem
            </Typography>
          </Grid> 

          
          <Grid item display="flex"
            xs={12}
            alignItems="center"
            justifyContent={"center"}
            minWidth="inherit">




<Authenticator 
hideSignUp={true}
onAuthUIStateChange={(authState, authData) => {
  console.log('User authState:', authState)
        if (authState === 'signedIn' && authData) {
          console.log('User object:', authData); // Print the user object
        }
      }}
onStateChange={(authState, authData) => {
  console.log('User authState:', authState)
        if (authState === 'signedIn' && authData) {
          console.log('User object:', authData); // Print the user object
        }
      }}
formFields={formFields} 
components={components} 
onAuthStateChange={(authState) => {
  console.log("onAuthStateChange")
  console.log("authState", authState)

  // Handle the user's authentication state change here, e.g. redirect to a different page or perform additional actions.
  // You can access the user's credentials using Auth.currentCredentials().
  // You can also access the user's Cognito ID using Auth.currentAuthenticatedUser().id.
  // You can also access the user's custom attributes using Auth.currentAuthenticatedUser().attributes.
  // You can also access the user's sign-in status using Auth.currentAuthenticatedUser().signInUserSession.isValid().
  // You can also access the user's sign-in status using Auth.currentAuthenticatedUser().signInUserSession.accessToken.jwtToken.
  // You can also access the user's sign-in status using Auth.currentAuthenticatedUser().signInUserSession.accessToken.payload.email.
  // You can also access the user's sign-in status using Auth.currentAuthenticatedUser().signInUserSession.accessToken.payload.email_verified.
  if (authState === 'signedIn') {
    registerUser()
      .then(() => {
        //navigate('/demo'); // Redirect the user to /demo after successful login
        console.log("USer SignedIn")
      })
      .catch((error) => {
        console.error('Error registering user:', error);
      });
  }
}}
>
      {({ signOut, authStatus }) => 
      {
        console.log("authStatus",authStatus)
        //navigate("/demo")
      return <button onClick={signOut}>Sign out</button>
    }
      }
    </Authenticator> 
 

          </Grid>


        </Grid>


      </Box>
    </Box>
  );
};
