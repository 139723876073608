import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import EastIcon from '@mui/icons-material/East';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';

const iconColor = "white"
export const ManipulationActions =[
    {
      icon: <VerticalAlignBottomIcon sx={{color:iconColor}} fontSize="small" />,
      name: "MPR Coronal",
      shortcut: "",
      functionname: ()=> createMprMist("CORONAL")
    },
    {
      icon: <VerticalAlignBottomOutlinedIcon sx={{color:iconColor}}  fontSize="small" />,
      name: "MPR Axial",
      shortcut: "",
      functionname:()=>  createMprMist("AXIAL")
    },
    {
      icon: <EastIcon sx={{color:iconColor}}  fontSize="small" />,
      name: "MPR Sagital",
      shortcut: "",
      functionname: ()=> createMprMist("SAGITTAL")
    },
    {
      icon: <ViewInArIcon  sx={{color:iconColor}}  fontSize="small" />,
      name: "MPR 3D",
      shortcut: "",
      functionname: ()=> createMprMist3d()
    },
    {
      icon: <DeviceHubOutlinedIcon sx={{color:iconColor}}  fontSize="small" />,
      name: "Oblique",
      shortcut: "",
      functionname: ()=> createObliqueMistMpr()
    },
   

]

function createMprMist (type) {
    console.log("createMprMist",type)
    const container = getActiveContainer()
    global.viewportsCore.createMPRViewport(container.containerId, type);
}

function createMprMist3d () {
    console.log("createMprMist3d")
    const container = getActiveContainer()
    global.viewportsCore.createMPRViewport3D(container.containerId);
}

function createObliqueMistMpr () {
    console.log("createObliqueMistMpr")
    const container = getActiveContainer()
    global.viewportsCore.generateObliqueMPR(container.containerId);
}


  function getActiveContainer(){
    const container= viewportsCore.layoutController.getActiveContainer();
    console.log("active COntainer", container.containerId)
    return container
  }