import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme"
import { Tooltip } from '@mui/material';



export default function BadgeAvatars({src,alt, status, size}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")


    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          backgroundColor: status==="online"?colors.avatar[100]:colors.avatar[200],
          color: status==="online"?colors.avatar[100]:colors.avatar[200],
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            //border: `1px solid ${status==="online"?colors.avatar[100]:colors.avatar[200]}`,
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }));


  return (


        <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      > <Tooltip title={alt}>
        <Avatar alt={alt} src={src} 
        sx={{
          width:"24px",
          height:"24px",
          borderRadius:"18px"
        }}
        //size={size}
        />
        </Tooltip>
      </StyledBadge>
    
 
  );
}