import React, {useState} from 'react'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { ROI}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import DeepLookSlidesDrawer from '../../../pages/viewer2.0/components/sliders/deeplookSlider';
import { DraggableResizableDialog } from '../../../subComponents/DraggableDialoge.jsx';
import DeeplookContent from '../../../pages/viewer2.0/components/sliders/deeplookSlider/DeeplookContent';



export function AItools(props) {
    const {finding} = props
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogeState,setOpenDialogeState] = useState(false)
  const [type,setType] = useState('DeepLook')
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  function closDialoge(){
    setOpenDialogeState(false)
    }

  const MenuListImageTools=[
    {
        icon: <BlurCircularIcon fontSize="small" finding={finding}/>,
        name:"DeepLook",        
        shortcut:"",
        functionname:()=>setOpenDialogeState(true)
    },
//     {
//       icon: <CircleOutlinedIcon fontSize="small" />,
//       name:"Circle",        
//       shortcut:"⌘C",
//       functionname:props.customFunction
//   },
//     {
//         icon: <PolylineOutlinedIcon fontSize="small" />,
//         name:"Polyline",        
//         shortcut:"⌘P",
//         functionname:props.rotate
//     },

]
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <SmartButtonIcon fontSize="large" />
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "250px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
        
      </HoverMenu>
      {/* <DeepLookSlidesDrawer anchor='right' finding={finding} openDrawerState={openDrawerState} type={type} closeDrawer={closeDrawer} /> */}

      <DraggableResizableDialog size={"sm"}
      disableEscapeKeyDown={true}
      open={openDialogeState} title={"DL Precise™"} onClose={closDialoge}>
        <DeeplookContent finding={finding} type={type} size={"sm"}/>
      </DraggableResizableDialog>

    </div>
  );
}

