export default function AddReportAPI(report,cases){
    console.log("from AddReportAPI", report)
    const values = {
       
       "StudyInstanceUID": cases["study"],
       "report":
       {
       "body":report,
       },        
    }

    console.log("AddReportAPI", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.api_report_update, requestOptions)

}
