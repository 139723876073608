import {patientData} from './DummyData'
import { liveData } from './LiveData';

export const GetAllCases = async (startDate,endDate) => {
  let sterlizedData = ""  
  switch (global.config.deploymentType) {
      case "onPrem":
        sterlizedData = GetAllCases_onPrem(startDate,endDate)
        break;
        case "onCloud":
          sterlizedData = GetAllCases_onCloud()
        break;
      default:
        throw "Error getting data"
        break;
    }
        return sterlizedData
}

function GetAllCases_onPrem(){
  const unSterlizedData = patientData
  /*  //console.log("Running get data from om Prem function")
   const client_handler_server = "http://" + global.config.client_handler_Endpoint + ":" + global.config.client_handler_Endpoint_port

   var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/json");
   myHeaders.append("Authorization", token);
   var requestOptions = {
     method: 'POST',
     headers: myHeaders,
     //body: raw,
     redirect: 'follow'
   };

   fetch(client_handler_server + "/all", requestOptions)
     .then(response => response.text())
     .then(result => {
       //console.log(result)
       const data = JSON.parse(Object.assign(result))
       const data2 = data[0]
       //console.log(data[0])

       if (data.length === 0) {
         //console.log("no data")
         setOverlay(true)
         setPageHeight("95%")
       }

       else {
         ////console.log(data[0]["Density_nr"])
         setOverlay(false)
         setPageHeight("auto")
         setPatients(data)
         setIsPending(false)

         ////console.log("my data is",data)
       }
       return data
     })
     .catch(error => //console.log('error', error));
*/
    return sterlizePatientData(unSterlizedData)
}


function GetAllCases_onCloud(){

}

export const GetCasesByDate = async (startDate,endDate) => {
  let sterlizedData = ""  
  //console.log(startDate,endDate)
  switch (global.config.deploymentType) {
      case "onPrem":
        sterlizedData = GetCasesByDate_onPrem(startDate,endDate)
        break;
        case "onCloud":
          sterlizedData = GetCasesByDate_onCloud()
        break;
      default:
        throw "Error getting data"
        break;
    }

        return sterlizedData

}

const GetCasesByDate_onCloud = async(startDate,endDate) =>{    
/* 
    if (newDate === "") { getTodayDate() }
    setIsPending(true)

    // //console.log(token)
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        date: newDate,
        user_id: localStorage.getItem("user_id")
      }
    }

    //console.log("date passed to Getcases", newDate)
    await API.get(apiName, casesPath + "/live", requestInfo)
      .then(response => {
        //console.log("app.js", response)
        //console.log("app.js length", response.length)

        const data = response;

        if (data.length === 0) {
          //console.log("no data")
          setOverlay(true)
          setPageHeight("95%")
        }

        else {
          ////console.log(data[0]["Density_nr"])
          setOverlay(false)
          setPageHeight("auto")
          setPatients(data)
          setIsPending(false)

          //console.log("my data is", data)
        }



        return data
      })
      .catch(error => {
        //console.log("Error fetching data getdata from lambda")
      }) */
  }


  export function GetCasesByDate_onPrem_2 (startDate,endDate, user) {

    const raw={
      "userId":user.userID,
      "organisationId":user.organisationid,
      "startDate":startDate,
      "endDate":endDate
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.userLoginToken);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: 'follow'
    };

   return fetch(global.config.api_get_cases_by_date, requestOptions)
  }


  const GetCasesByDate_onPrem = async (startDate,endDate) => {
    const unSterlizedData = patientData
    //const unSterlizedData = liveData
    /* let unSterlizedData = []
    const token = "123456"
    //console.log("Running get data from om Prem function")
    const client_handler_server = "http://" + global.config.client_handler_Endpoint + ":" + global.config.client_handler_Endpoint_port

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      //body: raw,
      redirect: 'follow'
    };

    fetch(client_handler_server + "/alll", requestOptions)
      .then(response => response.text())
      .then(result => {
        //console.log(result)
        unSterlizedData = JSON.parse(Object.assign(result))
        //console.log("unSterlizedData",unSterlizedData)

        if (unSterlizedData.length === 0) {
          //console.log("no data")
        }
        else {
          ////console.log(data[0]["Density_nr"])
          //const sterlizedPatientData = sterlizePatientData(unSterlizedData)
          ////console.log("sterlizedPatientData",sterlizedPatientData)
          return (sterlizePatientData(unSterlizedData))
          ////console.log("my data is",data)
        }
        //return data
      })
      .catch(error => //console.log('error', error));  */
       
      return (sterlizePatientData(unSterlizedData))    

  }

  export function sterlizePatientData(data){
    // This function splits the results into 4 groups based on their 
    // Suspicious Levels
    //console.log("sterlizePatientData",data)
    let patientData_suspiciousLevel_0=[]
    let patientData_suspiciousLevel_1=[]
    let patientData_suspiciousLevel_2=[]
    let patientData_suspiciousLevel_3=[]
    
    //data = data["body"]

   if(data.length>0){data.map((Item,index)=>{
      //console.log("Item",Item)
      //console.log("Item condition", Item["results"]== undefined )

      if(Item["results"] == undefined || Item["results"]["error"]===401){
        //console.log("Item results null",Item)
        patientData_suspiciousLevel_0.push(Item)
      }
      else{
        //console.log("Item results not null",Item,Item["results"])
      //let results = Item["results"]["classify"]["main"]

      if (Item["results"]["Findings"]!==null){
        // let suspiciousLevel = 0
        // results["Findings"].map((result,order)=>{

        //   if (results[result]["suspiciousLevel"]> suspiciousLevel){
            
        //     suspiciousLevel = results[result]["suspiciousLevel"]

        //   }else{}
        // })

        switch (Item["results"]["MaxValues"]["suspiciousLevel"]) {
          case 1:
            patientData_suspiciousLevel_1.push(Item)
            break;
          case 2:
            patientData_suspiciousLevel_2.push(Item)
            break;
          case 3:
            patientData_suspiciousLevel_3.push(Item)
              break;
        
          default:
            patientData_suspiciousLevel_0.push(Item)
            break;
        }

      }else{
        patientData_suspiciousLevel_0.push(Item)
      } 
    }
    })}
    
    const patientDataSteilized = {
      "0":patientData_suspiciousLevel_0,
      "1":patientData_suspiciousLevel_1,
      "2":patientData_suspiciousLevel_2,
      "3":patientData_suspiciousLevel_3,
    }
    //console.log("sterlizedData", patientDataSteilized)
    return patientDataSteilized
  }

export const extractAIFindings = async (airesults)=> {
    ////console.log("input for extractAIFindings", airesults)
    let results = []
      airesults.instances.map((instance,index)=>{
        
        if ('findings' in instance ){
        
          if (instance.findings.lesions.length>0)
          {
            instance.findings.lesions.map((finding)=>{

              finding = {
                "name":instance.findingName || "Finding",
                "percentage": instance.findingPercentage || "",
                "uid": finding.uid,
                "ContainerID":instance.ContainerID,
                "isVisible":finding.isVisible,
                "isEditable":finding.isEditable
              }
              results.push(finding)
            })
          }
          
        } 
        return results
      })
    } 
