//import React from 'react'
import * as React from 'react';
import { useState,useEffect,useRef,useMemo  } from 'react';
import _ from 'lodash';
import screenfull from 'screenfull';
import { useNavigate, useLocation } from "react-router-dom";
import { AppBar, Box, Button, Grid, Menu, MenuItem, Toolbar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import ViewerToolBar from '../../global/Viewer/ToolBar/ViewerToolBar';
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";

import './css/Index.css'

// Import the circular menu
import { CircleMenu, CircleMenuItem,TooltipPlacement,} from "react-circular-menu";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";

// MedDream Custom components
//import './css/Demo.css';
import 'viewports-core';
import LoadInstanceSpeedDial from './loadStudySpeedDial'
import StudiesMenu from './StudiesMenu/StudiesMenu';
import LeftMenu from './LeftMenu/LeftMenu';
import ViewportsLayout from './ViewportsLayout/ViewportsLayout';
import { findings } from './findings';

import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

import {ViewerMenuContext} from '../../subComponents/ViewerContextMenu';
import { usePopupState,bindContextMenu, bindMenu} from 'material-ui-popup-state/hooks'
import { DummyDicom } from '../../../functions/api/data/DicomData';
import { extractAIFindings } from '../../../functions/api/data/extractdata';
import ViewerSidebar from '../../global/Viewer/Sidebar/ViewerSidebar';
//REDUX
import {useSelector, useDispatch} from 'react-redux'
import {updateFindings} from "../../../states/slices/aiFindings/aifindingsSlice"
import { getStudyInfo } from '../../../functions/api/data/viewerAPIs/getStudyInfo';
import { SterlizeDicomResults_updated_series } from '../../../functions/api/data/viewerAPIs/sterlizeDicomResults';
//import { FindInPageDimensions } from '@styled-icons/material/FindInPage';
import { deletefindings,addfindings, updateSterlizedCases, updateSterlizedCasesComments, updatefindings, resetfindings, bulkUpdateFindings, resetReport, updateReportState, updateActiveStudiesInViewer } from '../../../states/slices/cases/casesSlice';
import AddFindingAPI from '../../../functions/api/diagnosing/addFinding';
import getStudyInfoExternal from '../../../functions/api/anonymization/getStudyInfoExternal';
import { useResponsiveQueries } from '../../../utilityComponents/device';


const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);


const activeContainerIdArray = [
  {
    ContainerID: 'viewport-container-1-1'
  },
  {
    ContainerID: 'viewport-container-1-2'
  },
  {
    ContainerID: 'viewport-container-2-1'
  },
  {
    ContainerID: 'viewport-container-2-2'
  }
]

/* const findingsMeddream = {
  mamilla: {
    point: {x: 1529, y: 1823},
    radius: 40,
    isEditable: true,
    isVisible: true,
    style: {
      color: '#E72828',
      lineWidth: 2
    }
  }
};
 */

/* const studiesToLoad = [
  {
      study: studies.studyUid,
      //callback: () => global.viewportsCore.layoutController.openInstanceToContainer('viewport-container-1-1', studies.instances[0].uid)
      callback: (studyData) => {
        console.log(studyData)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[0].ContainerID,
          studies.instances[0].uid
          ).then(() => {
//           global.viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1',findingsMeddream)
          });

      }
  },
  {
      study: studies.studyUid,
      callback: (studyData) => {console.log(studyData)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[1].ContainerID,
          studies.instances[1].uid)}
  },
  {
      study: studies.studyUid,
      callback: (studyData) => {console.log(studyData)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[2].ContainerID,
          studies.instances[2].uid)}
  },
  {
      study: studies.studyUid,
      callback: (studyData) => {console.log(studyData)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[3].ContainerID,
          studies.instances[3].uid)}
  },
]; */


function ViewerExternal({props, route, navigate}){
  const theme = useTheme();
  const colors = tokens("dark");
  const dispatch = useDispatch();
  const {isMobile} = useResponsiveQueries();
  const themeStyles = themeSettings("dark")
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })

  const [aiFindings, setAiFindings] = useState([])
  const [manualfindings, setManualfindings] = useState([])
  const [measurementsSet, setMeasurementsSet] = useState({})
  const [measurements, setMeasurements] = useState({})

  //REDUX
  const aiFindingsState = useSelector(state => state.aiFindings)
  const Case = useSelector(state => state.cases)
  const CaseFindings = useSelector(state => state.cases.findings)
  const user = useSelector(state => state.user)
  
  //NAVIGATION
  const location = useLocation();


  const [timer, setTimer] = useState(null)
  const [layoutRows, setLayoutRows] = useState(1)
  const [layoutColumns, setLayoutColumns] = useState(1)
  const [activeContainerId, setActiveContainerId] = useState('viewport-container-1-1')
  const [selectedContainerId, setSelectedContainerId] = useState('')
  const [contextMenuState, setContextMenuState] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hangingProtocoll, setHangingProtocoll] = useState("ALL")
  const [nextHangingProtocoll, setNextHangingProtocoll] = useState("CC")
  const [loadingData, setLoadingData] = useState(true);
  const [currentStudy, setCurrentStudy] = useState();
  const [customizedMobileDevice, setCustomizedMobileDevice]=useState(false)

  

  const [viewCounter, setViewCounter] = useState(1)
  const [availablePorts, setAvailablePorts] = useState([])
  const [externalviewer, setExternalviewer] = useState(false)


//Initialization of Viewport


/////////////////////////////////////////////////
//
//
//
//      Initialization of Viewport and loading studies & series
//
//
//
//////////////////////////////////////////////////

useEffect(() => {
  console.log("External Viewer", externalviewer)
}, [externalviewer])

const Initialization  = useMemo(() => ViewerPageInitializationFunction(),[])

const isMobileDevice = () => {
  //return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  return window.matchMedia("(pointer: coarse)").matches;
}
// Check if the user is on an iPad
const isIPad =() => {
return navigator.userAgent.indexOf('iPad') !== -1;
}

function ViewerPageInitializationFunction(){
  console.log("Running ViewerPageInitializationFunction")
  //setLoadingData(true)
  setOpenSnackbar({ ...openSnackbar, open: true, 
    Message: "Started loading your study.", severity: "info" })
    
  try{
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const paramObject = Object.fromEntries(params.entries());
    setExternalviewer(paramObject["id"].length>0)
    console.log("data from external", paramObject, paramObject["id"],paramObject["id"].length>0)
  getStudyInfoExternal(paramObject["id"])
  .then(response => response.text())
  .then(results => { 
    console.log("results", results)
    const res = JSON.parse(Object.assign(results))
    setOpenSnackbar({ ...openSnackbar, open: true, 
      Message: "Loaded your study successfully.", severity: "info" })


    console.log("Get Study Info", res) 
 
    const sterlizedStudy = SterlizeDicomResults_updated_series(res)
    
    setCurrentStudy(sterlizedStudy)
    dispatch(updateSterlizedCases(sterlizedStudy))
    //dispatch(resetReport())
    dispatch(updateReportState(sterlizedStudy.report.body))
    // Manage Findings
    dispatch(resetfindings())
    dispatch(bulkUpdateFindings(sterlizedStudy.findings))
    sterlizeFindingsFunction()


    
    //localStorage.setItem('Study', JSON.stringify(sterlizedStudy))
    console.log("sterlizedStudy study ",sterlizedStudy)
      
  
  viewportInitialization()
    .then((res) => {  if (res ===200){    

       global.viewportsCore.loadStudiesWithHIS(sterlizedStudy.instances).then((studyData)=>
        setOpenSnackbar({ ...openSnackbar, open: true, 
        Message: "Loaded your study successfully2."+studyData, severity: "info" })
        ).catch(error => {
        // Handle the error here
        setOpenSnackbar({ ...openSnackbar, open: true, 
          Message: "Error opening your study."+error, severity: "error" })
    
        console.error('Error fetching data:', error);
      })
       
    
      }})
  }) 
  .then(()=>
    {
      setLoadingData(false)
      //sterlizeFindingsFunction()
  }) 
}catch(error){
  setLoadingData(false)
  alert("Error Loading Data", error)
  console.log(error,"error")
}

}
 
async function viewportInitialization(Type){
  //setOpenSnackbar({ ...openSnackbar, open: true, Message: "Initializing your environment.", severity: "info" })

  const configuration = {
    features: {
      segmentingAnnotations: true,
      mistMpr3D: true,
      batchImageRequests: true,
      //compressPixelsBeforeSave : 'none'
    },
    requestsConfiguration: {
      maximumMetadataStreams: 5,
      maximumPixelStreams: 15,
      maximumMultiFrameStreams: 4,
      batchSizeMb: 5,
      multiFrameBatchSizeMb: 2
  }
};

global.viewportsCore.create(configuration);
console.log("isMobile", isMobileDevice(),isIPad())
    
if(isMobileDevice() || isIPad()){
  setLayoutRows(1)
  setLayoutColumns(1)
  setCustomizedMobileDevice(true)

  viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-1-2', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-1-3', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-2-1', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-2-2', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-2-3', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-3-1', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-3-2', false);
  // viewportsCore.layoutController.addLayoutContainer('viewport-container-3-3', false);

}else{
  setLayoutRows(2)
  setLayoutColumns(2)
  viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);
  viewportsCore.layoutController.addLayoutContainer('viewport-container-1-2', false);
  //viewportsCore.layoutController.addLayoutContainer('viewport-container-1-3', false);
  viewportsCore.layoutController.addLayoutContainer('viewport-container-2-1', false);
  viewportsCore.layoutController.addLayoutContainer('viewport-container-2-2', false);
  //viewportsCore.layoutController.addLayoutContainer('viewport-container-2-3', false);
  //viewportsCore.layoutController.addLayoutContainer('viewport-container-3-1', false);
  //viewportsCore.layoutController.addLayoutContainer('viewport-container-3-2', false);
  //viewportsCore.layoutController.addLayoutContainer('viewport-container-3-3', false);
}


global.viewportsCore.infoLabelsController.enabled = false; //To enable or disable labels.
viewportsCore.disableScrollLock();

//viewportsCore.registerEvent('viewport-loader', (object) => console.log('viewport-loader', object));
//viewportsCore.registerEvent('progress-loader', (object) => console.log('progress-loader', object));

viewportsCore.registerEvent('mg-lesion-selected', (data) => mgLesionSelected(data));
viewportsCore.registerEvent('active-container-changed', (containerId) => activeContainerChanged(containerId));
viewportsCore.registerEvent('viewport-series-changed', ({containerId, previousSeriesUid, currentSeriesUid, currentStudyUid}) => SeriesChanged(containerId, currentSeriesUid,currentStudyUid));
viewportsCore.registerEvent('measurement-created', (data) => measurementCreated(data))
viewportsCore.registerEvent('measurement-updated', (data) => measurementsChanged(data));
viewportsCore.registerEvent('measurement-deleted', (data) => measurementDeleted(data));


return 200
}

// async function viewportInitialization(Type){

//     const configuration = {
//       features: {
// 		    segmentingAnnotations: true,
//         mistMpr3D: true,
//         batchImageRequests: true,
//         //compressPixelsBeforeSave : 'none'
//       },
//       requestsConfiguration: {
//         maximumMetadataStreams: 5,
//         maximumPixelStreams: 15,
//         maximumMultiFrameStreams: 4,
//         batchSizeMb: 5,
//         multiFrameBatchSizeMb: 2
//     }
//   };

//   global.viewportsCore.create(configuration);

//   if (Type ==="CT" || Type ==="MRI"){
//     console.log("Type: ", Type)
//     viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);
//   }else{
//     console.log("Type:", Type)
//     setLayoutRows(2)
//     setLayoutColumns(2)

//     viewportsCore.layoutController.addLayoutContainer('viewport-container-1-1', true);
//     viewportsCore.layoutController.addLayoutContainer('viewport-container-1-2', false);
//     viewportsCore.layoutController.addLayoutContainer('viewport-container-2-1', false);
//     viewportsCore.layoutController.addLayoutContainer('viewport-container-2-2', false);
//       }

 
 
//   global.viewportsCore.setBaseUrl('.');  // This line is not required. Used only when it needs to configure different context path for requests.
//   global.viewportsCore.infoLabelsController.enabled = false; //To enable or disable labels.
//   viewportsCore.disableScrollLock();
//   global.viewportsCore.registerEvent(global.viewportsCore.getConstants().CORE_EVENTS.ACTIVE_CONTAINER_CHANGED, (containerId) => selectViewportContainer(containerId));
//   // capturing rotation changes in image
//   global.viewportsCore.registerEvent(global.viewportsCore.getConstants().CORE_EVENTS.IMAGE_ROTATION_CHANGED, (degrees, containerId) => console.log(degrees, containerId));
//    // capturing position changes in image
//   global.viewportsCore.registerEvent(global.viewportsCore.getConstants().CORE_EVENTS.IMAGE_POSITION_CHANGED, (imagePosition, containerId) => console.log(imagePosition, containerId));
//   // capturing zoom  changes in image
//   global.viewportsCore.registerEvent(global.viewportsCore.getConstants().CORE_EVENTS.ZOOM_CHANGED, (scale, point, containerId) => console.log(scale, point, containerId));

//   return 200
//   }

function loadStudy_updated (study) {
    
  //global.viewportsCore.loadStudyWithHIS(study.studyUid, 'study')
  
  const STUDIES = [
    {
        studyName: 'CT',
        studyUid: '1.3.12.2.1107.5.1.7.112500.30000020120621155870700000367',
        instances: [
            {
                name: 'MR 01',
                uid:  '1.3.12.2.1107.5.1.7.112500.30000020120712354943800000262'
            },
            {
                name: 'MR 02',
                uid:  '1.3.12.2.1107.5.1.7.112500.30000020120712354943800000164'
            },
            {
                name: 'MR 03',
                uid:  '1.3.12.2.1107.5.1.7.112500.30000020120712354943800000683'
            }
        ]
    },
  ]


  const studiesToLoad = [
    {
        study: STUDIES[0].studyUid,
        callback: () =>  global.viewportsCore.layoutController.openInstanceToViewportContainer('viewport-container-1-1', STUDIES[0].instances[0].uid)
    },
    {
        study: STUDIES[0].studyUid,
        callback: () =>  global.viewportsCore.layoutController.openInstanceToViewportContainer('viewport-container-1-2', STUDIES[0].instances[1].uid)
    },
    {
        study: STUDIES[0].studyUid,
        callback: () =>  global.viewportsCore.layoutController.openInstanceToViewportContainer('viewport-container-1-3', STUDIES[0].instances[2].uid)
    },
];


  global.viewportsCore.loadStudiesWithHIS(study)
 
}

function openStudyToViewport_updated (study) {
    
  return Promise.resolve(
    study.instances.map((instance,index)=>{
      if (!viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"])){
          console.log("container Unavailable?\n",viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"]))
        viewportsCore.layoutController.addLayoutContainer(activeContainerIdArray[index]["ContainerID"]);
      }
    //  console.log("instance",instance)
      global.viewportsCore.layoutController.openInstanceToViewportContainer(
        instance["ContainerID"],
         instance.uid);
    })
    
  )
}



function loadStudy (studies) {
    
    global.viewportsCore.loadStudyWithHIS(studies.studyUid, 'study')
    .then(() => { 
      openStudyToViewport(studies)
      .then(() => {
          console.log("Case Loaded")

          if(studies["Type"]==="MG")
          {
          setHangingProtocoll("ALL")
          setNextHangingProtocoll("CC")}
            //showAIResults(studies)
        })
    });
}

 function openStudyToViewport (studies) {
    
    return Promise.resolve(
      studies.instances.map((instance,index)=>{
        if (!viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"])){
            console.log("container Unavailable?\n",viewportsCore.layoutController.isLayoutContainerAvailable(instance["ContainerID"]))
          viewportsCore.layoutController.addLayoutContainer(activeContainerIdArray[index]["ContainerID"]);
        }
      //  console.log("instance",instance)
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
          instance["ContainerID"],
           instance.uid);
      })
      
    )
}

function SeriesChanged(containerId, currentSeriesUid,currentStudyUid){
  //console.log("viewport-series-changed",containerId, currentSeriesUid,currentStudyUid)
  dispatch(updateActiveStudiesInViewer({ActiveContainer:containerId, SeriesInstanceUID:currentSeriesUid}))

}

function showAIResults(studies,instanceUid,viewportId){

  const viewPortObj = viewportsCore.controller.getViewport(viewportId)
  console.log("viewPortObj",viewPortObj)
  const containerId = viewPortObj["containerId"]

  let obj = studies.instances.find(o => o.uid === instanceUid);

 
   if ('findings' in obj){
     global.viewportsCore.mgLesionsController.createMGLesion(
        containerId,
       obj.findings
     ); 
   }
}

 
/////////////////////////////////////////////////
//
//
//
//      Handling Functionalities of Viewport
//
//
//
//////////////////////////////////////////////////


const VIEWPORT_FUNCTIONS = {
  NONE: 0,
  WL: 1,
  ZOOM: 2,
  PAN: 3,
  SCROLL: 4,
  MEASURE: 5,
  ROTATE: 6,
  CROSSHAIR: 8,
  SEGMENTATION_TOOLS: 13
};

function handlelandmark(landmarkData){
  console.log("Function handleLandMark",landmarkData)
}

function activeContainerChanged(containerId){
  console.log("activeContainerChanged", containerId)
  setSelectedContainerId(containerId)
}
// Listen to Keyboard inputs and apply changes

useEffect(() => {
  window.addEventListener("keydown", detectKeyDown);
  window.addEventListener("keyup", upHandler);
  // Remove event listeners on cleanup
  return () => {
    window.removeEventListener("keydown", detectKeyDown);
    window.removeEventListener("keyup", upHandler);
  };
}, []);

function upHandler(){
handleMouseButtonFunction(0)
}

function detectKeyDown(e){
  switch (e.key) {
    case "h":
      //changeHangingProtocol2(STUDIES)
      //MoveToNextHangingProtocoll(hangingProtocoll)
      break;
      case "c":
        changeHangingProtocol(currentStudy,"CC")
      break;
      case "m":
        changeHangingProtocol(currentStudy,"MLO")
      break;
      case "a":
        changeHangingProtocol(currentStudy,"ALL")
      break;

    case "Escape":
      handleMouseButtonFunction(0)
    break;
    case "l":
      handleMouseButtonFunction(1)
    break;
    case "z":
      handleMouseButtonFunction(2)
    break;
    case "p":
      handleMouseButtonFunction(3)
    break;
    case "s":
      handleMouseButtonFunction(4)
    break;
    
    case "r":
      handleMouseButtonFunction(6)
    break;

/*       case "c":
      handleMouseButtonFunction(8)
    break; */

    case "q":
      resetAllViewports()
    break;
    default:
      handleMouseButtonFunction(0)
      break;
  }

}

function handleMouseButtonFunction (value) {
  global.viewportsCore.setMouseButtonFunction(1, value);
  //global.viewportsCore.measurementsController.setActiveMeasurementType('none');

}

function resetAllViewports () {
global.viewportsCore.resetAllViewportsRenders();
}




/////////////////////////////////////////////////
//
//
//
//      Handling Measurements and AI Findings
//
//
//
//////////////////////////////////////////////////

//const sterlizeAIFindings=useMemo(() => sterlizeAIFindingsFunction(), [])
//const [myFindingsArray, setMyFindingsArray] = useState([]);
const previousAddedObjectsRef = useRef([]);
const previousUpdatedObjectsRef = useRef([]);


function sterlizeFindingsFunction(){
  // Here check if there are any AI findings returned by the API call for the current study and
  // run a loop to insert them one by one in the redux state and 
  console.log("CaseFindings",CaseFindings)

}

const  measurementCreated = (data) =>{
   addFinding(data)
}

const measurementsChanged = data => {
  updateFinding(data)
  }

function measurementDeleted(data){
    console.log("measurementUpdatedById",data)
  }

//const measurementsList = useMemo(() => { UpdateMeasurement(measurements)}, [measurements])


function addFinding(measurement){
  const updatedMeasurementsValues=  updateMeasurementsValues(measurement)
  const isObjectExists = previousAddedObjectsRef.current.some(obj => obj.id === updatedMeasurementsValues.id);
  console.log("existingObjectIndex 1",isObjectExists)
  
  if (!isObjectExists) {
    console.log("CaseFindings",CaseFindings)
    const existingObjectIndex = CaseFindings.findIndex(obj =>obj.id === updatedMeasurementsValues["id"]);
    console.log("existingObjectIndex2 ",existingObjectIndex)

    if(existingObjectIndex===-1){
      console.log("existingObjectIndex 3",existingObjectIndex)
    const FinalMeasurementsValues = addMeasurementsUserData(updatedMeasurementsValues,user,0,1)
    console.log("updatedMeasurementsValues from addFinding",updatedMeasurementsValues)
    dispatch(addfindings(FinalMeasurementsValues))
    AddFindingsToBackend(FinalMeasurementsValues, user, 0,1)
  //  previousAddedObjectsRef.current = [...previousAddedObjectsRef.current, updatedMeasurementsValues];
  }
    previousAddedObjectsRef.current = [...previousAddedObjectsRef.current, updatedMeasurementsValues]
  }


}

function updateFinding(data){
  const updatedMeasurementsValues=  updateMeasurementsValues(data)
  const areObjectsEqual = _.isEqual(updatedMeasurementsValues["data"], previousUpdatedObjectsRef.current["data"]);
  
  if(!areObjectsEqual){
    console.log("updateFinding different",updatedMeasurementsValues)
    
    const existingObjectIndex = CaseFindings.findIndex(obj => obj.id === updatedMeasurementsValues["id"]);
    if (existingObjectIndex !== -1) {

      const NewUpdatedMeasurements = {...CaseFindings[existingObjectIndex],data: updatedMeasurementsValues["data"] }
      console.log("updateFinding different2",existingObjectIndex,NewUpdatedMeasurements)
      AddFindingsToBackend(NewUpdatedMeasurements, user, 0,1)
      dispatch(updatefindings({ id: updatedMeasurementsValues["id"], updatedValues: { data: updatedMeasurementsValues["data"] } }))
      previousUpdatedObjectsRef.current = updatedMeasurementsValues;
    }

  }
}


function AddFindingsToBackend(measurementsValues, user, AIFinding,confirmed){
  AddFindingAPI(measurementsValues, user, AIFinding,confirmed)
  .then(response => response.text())
  .then(results => { 
    console.log(results)
    const res = JSON.parse(Object.assign(results))
    if (res.statusCode === 200) {
      // The API got a 200 response
      setOpenSnackbar({ ...openSnackbar, open: true, 
        Message: "Updated your measurements successfully.", severity: "success" })
    } else {
      // The API got a different response code
      setOpenSnackbar({ ...openSnackbar, open: true, 
        Message: "Failed to update your new measurements.", severity: "error" })
    }
    
    })
}


function addMeasurementsUserData(measurements,user,AIFInding,confirmed){
  try{
    let newMeasurements = measurements
  Object.assign(newMeasurements, {
    "user":{
      "FullName":user["userFullName"],
      "avatar":user["avatarUrl"]
  },
  "organisationid":user["DefaultWorkspaceID"],
  "AIFinding":AIFInding,
  "confirmed":confirmed,
  "timestamp":new Date(),
  "show":true,
  "structureReporting":{
    "name":"",
    "description":""
}
})

return newMeasurements
}
catch(error){
  console.log(error)
  return measurements
}
}


function updateMeasurementsValues(measurements,user,AIFInding,confirmed){
  console.log("updateMeasurementsValues",measurements)
  let newMeasurements = measurements
  let diagonals={}
  switch (newMeasurements.type) {
    case "polyline":
      diagonals = getMaxWidthAndHeight(newMeasurements.data.points)

      if(typeof  newMeasurements["data"]["width"] !== 'undefined' ){
        const updatedMeasurements = {...measurements,data:{...measurements.data, 
          "width": diagonals["width"],
          "height": diagonals["height"],
          "area": diagonals["width"]*diagonals["height"]
        }}

        return updatedMeasurements
      }
      else{
        // First Call. Create the variables
        Object.assign(newMeasurements.data, {"width": diagonals["width"],"height": diagonals["height"],"area": diagonals["width"]*diagonals["height"] })
        return newMeasurements
      }


      break;
      case "area":
        diagonals = getMaxWidthAndHeight(newMeasurements.data.points)

        if(typeof  newMeasurements["data"]["width"] !== 'undefined' ){
          const updatedMeasurements = {...measurements,data:{...measurements.data, 
            "width": diagonals["width"],
            "height": diagonals["height"],
            "area": newMeasurements["data"]["area"]
          }}

          return updatedMeasurements
        }
        else{
          // First Call. Create the variables
          Object.assign(newMeasurements.data, {"width": diagonals["width"],"height": diagonals["height"],"area": newMeasurements["data"]["area"] })
          return newMeasurements
        }

        break;
      case "oval":
        console.log("updateMeasurementsValues Oval 1",newMeasurements)
        const ovalValues = newMeasurements.data.ovalValues.split(" ")
        //console.log(ovalValues)
        const width = ovalValues[16]+" "+ovalValues[17]
        const height = ovalValues[19]+" "+ovalValues[17]
        const area = ovalValues[13]+" "+ovalValues[14]

        console.log("updateMeasurementsValues Oval 11 ",newMeasurements["data"]["width"])
        // Due to the nature of multiple calling possibility of the function 
        // Here is a check if the width and etc variables should be created or updated

        // Second Call. Update the variables
        if(typeof  newMeasurements["data"]["width"] !== 'undefined' ){
          console.log("updateMeasurementsValues Oval 2 ",newMeasurements)

          const updatedMeasurements = {...measurements,data:{...measurements.data, 
            "width": width,
            "height": height,
            "area": area
          }}

          console.log("updateMeasurementsValues Oval 22",updatedMeasurements)

          return updatedMeasurements
        }
        else{
          // First Call. Create the variables
          console.log("updateMeasurementsValues Oval 3",newMeasurements)
          Object.assign(newMeasurements.data, {"width": width,"height": height,"area": area })
          return newMeasurements
        }
        
        
      break;
  
    default:
      break;
}

}





// function UpdateMeasurement(measurements){
//   if(Object.keys(measurements).length>0){  
//     const foundObject = Object.values(measurementsSet).find((object) => object.id === measurements.id);
  
//     const updatedMeasurementsValues=  updateMeasurementsValues(measurements)
    
  
//     if (foundObject) {
//       //Update Measurements
//       console.log('Object found:', updatedMeasurementsValues);
  
//       //updateMeasurementsObject(updatedMeasurementsValues)
//       setMeasurementsSet((prevObjectList) => ({
//         ...prevObjectList,
//         [updatedMeasurementsValues.id]: { ...prevObjectList[updatedMeasurementsValues.id], data: updatedMeasurementsValues.data }
//       }));
//       AddFindingAPI(updatedMeasurementsValues, user, 0)
//       setMeasurements(null)
//       //dispatch(updatefindings({id:updatedMeasurementsValues.id,data:updatedMeasurementsValues})) 
//     } else {
//       // Create a new Measurement
//       console.log('Object not found',updatedMeasurementsValues);
//       AddFindingAPI(updatedMeasurementsValues, user, 0)
//       //dispatch(Addfindings(updatedMeasurementsValues))
//       //setMeasurements(measurements)
//       setMeasurementsSet((prevMeasurements) => ({...prevMeasurements,  [updatedMeasurementsValues["id"]]: updatedMeasurementsValues }));
//       setMeasurements(null)
//     }}
// }

// useEffect(() => {
//   const ConfirmedFindings = localStorage.getItem("confirmedFindings") || ""
//   if (ConfirmedFindings != ""){
//     dispatch(updatefindings(ConfirmedFindings));
// }
// // window.addEventListener("storage", onStorageUpdate);
// //   return () => {
// //     window.removeEventListener("storage", onStorageUpdate);
// //   };
// }, []);

// const onStorageUpdate = (e) => {
//   const { key, newValue } = e;
//   if (key === "confirmedFindings") {
//     //setName(newValue);
//     dispatch(updatefindings(findings))
//   }
// };

// useEffect(() => {
// console.log("setMeasurementsSet useEffect",measurementsSet)

// const findings=[]
// Object.keys(measurementsSet).map((key)=>{
//   console.log(measurementsSet[key])
//   findings.push(measurementsSet[key])
// })
// console.log("findings",findings)
// localStorage.setItem("confirmedFindings",findings);
// dispatch(updatefindings(findings))


// }, [measurementsSet])











function getMaxWidthAndHeight(points) {
  //console.log("getMaxWidthAndHeight",points)
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  for (let i = 0; i < points.length; i++) {
    //console.log("x,y,z",points[i])
    const {x, y,z} = points[i];
    //console.log("x,y,z",points[i][0],points[i][1],points[i][2])
    minX = Math.min(minX, points[i][0]);
    minY = Math.min(minY, points[i][1]);
    maxX = Math.max(maxX, points[i][0]);
    maxY = Math.max(maxY, points[i][1]);
  }

  const maxWidth = Math.round(maxX - minX);
  const maxHeight = Math.round(maxY - minY);

  //console.log("width:", maxWidth," height:", maxHeight)


  return {width: maxWidth, height: maxHeight};
}

// function addcomments(comment,id){
//   console.log("Added comments to case from INdex", comment, id)

//   setManualfindings(manualfindings=>manualfindings.map(finding =>{
//     if (finding.id === id) {
//       Object.assign(finding.data, {"comment": comment})
//       return finding
//       //return { ...finding, data: data.data };
//     } else {
//       return finding;
//     }
//   }))


// }

// function caseComments(comments){
//   console.log("caseComments",comments)
//   if(currentStudy){
//     //dispatch(updateSterlizedCasesComments({"generalComments": comments}))
//     //Object.assign(currentStudy, {"generalComments": comments})
// }
// }


/*
function createExampleMGLesion (containerID) {
    const findings = {
        mamilla: {
            point: {x: 1529, y: 1823},
            radius: 40,
            isEditable: false,
            isVisible: false,
            style: {
                color: '#E72828',
                lineWidth: 2
            }
        },
        muscleLine: {
            startPoint: {x: 2043, y: 820},
            endPoint: {x: 2393, y: 1356},
            isEditable: false,
            isVisible: false,
            style: {
                color: '#E72828',
                lineWidth: 2,
                dashedLine: false
            }
        },
        regionLines: [
            {
                uid: 'region-line-1',
                startPoint: {x: 1476, y: 1097},
                middlePoint: {x: 2149, y: 1601},
                endPoint: {x: 2021, y: 2242},
                isEditable: false,
                isVisible: false,
                style: {
                    color: '#0D88B5',
                    lineWidth: 2,
                    dashedLine: false
                }
            },
            {
                uid: 'region-line-2',
                startPoint: {x: 1528, y: 921},
                middlePoint: {x: 2294, y: 1564},
                endPoint: {x: 2230, y: 2279},
                isEditable: false,
                isVisible: false,
                style: {
                    color: '#0D88B5',
                    lineWidth: 2,
                    dashedLine: false
                }
            }
        ],
        lesions: [
            {
                uid: 'lesion-1',
                type: 'ellipse-lesion',
                point: {x: 1857, y: 896},
                xRadius: 135,
                yRadius: 75,
                rotationAngle: 25,
                isEditable: false,
                isVisible: false,
                style: {
                    color: '#0E5715',
                    fillColor: '#0E5715',
                    fillOpacity: 0.2,
                    dashedLine: true
                }
            },
            {
                uid: 'lesion-2',
                type: 'polyline-lesion',
                isEditable: false,
                isVisible: false,
                points: [
                    {x: 1624, y: 1026},
                    {x: 1722, y: 969},
                    {x: 1815, y: 1033},
                    {x: 1752, y: 1107},
                    {x: 1707, y: 1087},
                    {x: 1663, y: 1106}
                ],
                style: {
                    color: '#C6C413',
                    fillColor: '#C6C413',
                    fillOpacity: 0.2
                }
            }
        ]
    };
    global.viewportsCore.mgLesionsController.createMGLesion(containerID, findings)
}
 */

/* function loadCustomStudy(STUDIES) {
  const study = '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1351.0'
    return Promise.resolve(global.viewportsCore.loadStudiesWithHIS(study,'study'))
    }

function openCustomStudyToViewport(STUDIES){
  return Promise.resolve(
    STUDIES.instances.map((instance, index)=>{
    global.viewportsCore.layoutController.openInstanceToViewportContainer(instance.ContainerID, instance.uid)
  })
  )

} */

  /*  
    
  function cacheStudy (studyUid, storageId) {
    global.viewportsCore.cacheStudy(studyUid, storageId);
  }
  
 function loadStudy (containerId, studyUid, instanceUid) {
    global.viewportsCore.loadStudyWithHIS(studyUid).then(async (studyData) => {
        const instanceToOpen = instanceUid || studyData.series[0].instances[0].uid;
  
        await global.viewportsCore.layoutController.openInstanceToViewportContainer(containerId, instanceToOpen).then(() => {
          createMGLesion(containerId);
          console.log('global.viewportsCore.loadStudyWithHIS(study)',global.viewportsCore.loadStudyWithHIS(studyUid))
  
        });
        console.log(studyData)
    })
    //.then((studyData)=>{console.log("loaded case", studyData)})
  }
   */


  function mgLesionSelected(data){
    //handleMouseButtonFunction(0)
    console.log("mgLesionSelected",data)
   
    /* let obj = studies.instances.findings.lesions.uid.find(o => o.uid === data["uid"]);
    console.log(obj) */
  }


  function editAIResults(){
  
    console.log("Change Mammila visibility \n")
    global.viewportsCore.mgLesionsController.changeMamillaIsVisibleStatus(
      'viewport-container-2-2', true);
  }

 

/////////////////////////////////////////////////
//
//
//
//      Handling  Hanging Protocol 
//
//
//
//////////////////////////////////////////////////

function changeHangingProtocol(studies,view){
  console.log("changeHangingProtocol triggered", studies, view)

  let newSTUDIES = {
    studyName: currentStudy["Type"],
    studyUid: currentStudy.studyUid,
    instances: []
        }
  console.log("changeHangingProtocol newSTUDIES", newSTUDIES)
  
  currentStudy.instances.map((instance,index)=>{
   
   if(instance.viewPosition===view){
    console.log(instance)
    newSTUDIES.instances.push(instance)}
  })


  console.log("newSTUDIES",newSTUDIES)

   switch (view) {
    case "CC":
    setLayoutRows(1)
    setHangingProtocoll("CC")  
    
    //openStudyToViewport(newSTUDIES)
    //setNextHangingProtocoll("MLO")

    break;

    case "MLO":
      setLayoutRows(1)
      setHangingProtocoll("MLO")  
      
      //openStudyToViewport(newSTUDIES)
      //setNextHangingProtocoll("ALL")

    break;

    case "ALL":
      setLayoutRows(2)
      setHangingProtocoll("ALL")  
      //openStudyToViewport(STUDIES)
      //setNextHangingProtocoll("CC")
    break;

    default:
    break;
  } 

}  

function changeHangingProtocol2(studies){

if (studies.type==="MG"){
  const availableHangingProtocols = ["CC","MLO","ALL"]
  const currentHangingProtocolIndex = availableHangingProtocols.indexOf(hangingProtocoll)
  console.log("currentHangingProtocolIndex",currentHangingProtocolIndex,hangingProtocoll)
  //setNextHangingProtocoll("MLO")
  if (currentHangingProtocolIndex === availableHangingProtocols.length+1){
      setHangingProtocoll(availableHangingProtocols[0])
    }
    else{
      setHangingProtocoll(availableHangingProtocols[currentHangingProtocolIndex+1])
    }
}
}

useEffect(() => {
  if(currentStudy){
  console.log("hangingProtocoll changed", hangingProtocoll)
  //console.log("NextHangingProtocol changed", nextHangingProtocoll)
  loadCustomView(currentStudy,hangingProtocoll)}
}, [hangingProtocoll])


async function loadCustomView(studies,view){

  let newSTUDIES = {
    studyName: studies["Type"],
    studyUid: studies.studyUid,
    instances: []
        }

  studies.instances.map((instance,index)=>{
   
   if(instance.viewPosition===view){
    newSTUDIES.instances.push(instance)}
  })


  console.log("loadCustomView", view)
  switch (view) {
  case "CC":
    setLayoutRows(1)
    console.log("CC VIEW")
    //setStudy2Load(newSTUDIES)
    openStudyToViewport(newSTUDIES)
    /* global.viewportsCore.layoutController.openInstanceToViewportContainer(
      activeContainerIdArray[0].ContainerID,
      studies.instances[0].uid)
    global.viewportsCore.layoutController.openInstanceToViewportContainer(
        activeContainerIdArray[1].ContainerID,
        studies.instances[1].uid)
        console.log("Avaibale ports \n",global.viewportsCore.layoutController.getLayoutContainers())
     */
    setNextHangingProtocoll("MLO")

    break;
    case "MLO":
      //setLayoutRows(1)
      console.log("MLO VIEW")
   /*  global.viewportsCore.layoutController.openInstanceToViewportContainer(
        activeContainerIdArray[0].ContainerID,
        studies.instances[2].uid)
    global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[1].ContainerID,
          studies.instances[3].uid)
          console.log("Avaibale ports \n",global.viewportsCore.layoutController.getLayoutContainers())
           */
          openStudyToViewport(newSTUDIES)
          setNextHangingProtocoll("ALL")
      break;

    case "ALL":
    setLayoutRows(2)

    openStudyToViewport(studies)
     /*  global.viewportsCore.layoutController.openInstanceToViewportContainer(
        activeContainerIdArray[0].ContainerID,
        studies.instances[0].uid)
      global.viewportsCore.layoutController.openInstanceToViewportContainer(
          activeContainerIdArray[1].ContainerID,
          studies.instances[1].uid)
      global.viewportsCore.layoutController.openInstanceToViewportContainer(
        activeContainerIdArray[2].ContainerID,
        studies.instances[2].uid)
          global.viewportsCore.layoutController.openInstanceToViewportContainer(
            activeContainerIdArray[3].ContainerID,
            studies.instances[3].uid)
             */
            setNextHangingProtocoll("CC")
    break;

    default:
      console.log("No changes ")
      break;
}
}


function selectViewportContainer (containerId) {
    setActiveContainerId(containerId);
}

function onLayoutColumnsChanged ( type) {
  //console.log("onLayoutColumnsChanged triggered",layoutColumns,type)
  //setLayoutColumns(number)  
   switch (type) {
    case "add":
    //  console.log("onLayoutColumnsChanged add",layoutRows)
      setLayoutColumns(layoutColumns+1)
      break;
  
    default:
    //  console.log("onLayoutColumnsChanged add",layoutRows)
      setLayoutColumns(layoutColumns-1)
      break;
  }
}

function onLayoutRowsChanged ( type) {
  console.log("onLayoutRowsChanged triggered",layoutRows,type)
   switch (type) {
    case "add":
      //console.log("layoutRows add",layoutRows)
      setLayoutRows(layoutRows+1)
      break;
  
    default:
      //console.log("layoutRows default",layoutRows)
      setLayoutRows(layoutRows-1)
      break;
  } 

  //setLayoutRows(number)
}







/* function createMGLesion(side) {
 
 
/*   const findings = {
    mamilla: {
      point: { x: 1000, y: 1500 },
      radius: 40,
      isEditable: true,
      isVisible: true,
      style: {
        color: "#E72828",
        lineWidth: 2,
      },
    },
    muscleLine: {
      startPoint: { x: 1000, y: 820 },
      endPoint: { x: 1593, y: 1356 },
      isEditable: false,
      isVisible: true,
      style: {
        color: "#E72828",
        lineWidth: 2,
        dashedLine: false,
      },
    },
    regionLines: [
      {
        uid: "region-line-1",
        startPoint: { x: 1476, y: 1097 },
        middlePoint: { x: 2149, y: 1601 },
        endPoint: { x: 2021, y: 2242 },
        isEditable: false,
        isVisible: true,
        style: {
          color: "#0D88B5",
          lineWidth: 2,
          dashedLine: false,
        },
      },
      {
        uid: "region-line-2",
        startPoint: { x: 1528, y: 921 },
        middlePoint: { x: 2294, y: 1564 },
        endPoint: { x: 2230, y: 2279 },
        isEditable: false,
        isVisible: true,
        style: {
          color: "#0D88B5",
          lineWidth: 2,
          dashedLine: false,
        },
      },
    ],
    lesions: [
      {
        uid: "lesion-1",
        type: "ellipse-lesion",
        point: { x: 1857, y: 896 },
        xRadius: 135,
        yRadius: 75,
        rotationAngle: 25,
        isEditable: false,
        isVisible: true,
        style: {
          color: "#C6C413",
          fillColor: "#0E5715",
          fillOpacity: 0.2,
          dashedLine: true,
        },
      },
      {
        uid: "lesion-2",
        type: "polyline-lesion",
        isEditable: false,
        isVisible: true,
        points: [
          { x: 1624, y: 1026 },
          { x: 1722, y: 969 },
          { x: 1815, y: 1033 },
          { x: 1752, y: 1107 },
          { x: 1707, y: 1087 },
          { x: 1663, y: 1106 },
        ],
        // style: {
        //   color: "#0E5715",
        //   fillColor: "#0E5715",
        //   fillOpacity: 0.2,
        // },
        style: {
          color: "#C6C413",
          fillColor: "#0E5715",
          fillOpacity: 0.2,
        }
      },
    ],
  }; 
  const addEllipse = {
    uid: "lesion-2",
    type: "polyline-lesion",
    isEditable: true,
    isVisible: true,
    points: [
      { x: 2624, y: 1026 },
      { x: 1722, y: 969 },
      { x: 1815, y: 1033 },
      { x: 1752, y: 1107 },
      { x: 1707, y: 1087 },
      { x: 1663, y: 1106 },
    ],
    style: {
      color: "#C6C413",
      fillColor: "#C6C413",
      fillOpacity: 0.2,
    },
  }
  setViewPort1(findings)
  setViewPort2(findings)
  setViewPort3(findings)
  setViewPort4(findings)
  // console.log('this is new viewport on load', viewport)
  
    viewportsCore.mgLesionsController.createMGLesion(
      side,
      findings
    );
}
 */

/* function startFullSCreen(){
  if (screenfull.isEnabled) {
		screenfull.request();
	} else {
		// Ignore or do something else
	}
} */



/* function RightClickContextMenu(){
  return(
    <>
    <ViewerMenuContext />
    </>
  )

} */

/* const MenuPopupState = (popupState) => {
  
  return (
    <Menu 

    {...bindMenu(popupState)}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
  >
    <MenuItem onClick={popupState.close}>Cake</MenuItem>
    <MenuItem onClick={popupState.close}>Death</MenuItem>
  </Menu>
  );
} */




  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <Topbar /> */}
      {!loadingData?<ViewerSidebar currentStudy={currentStudy} airesults={currentStudy}/>:""} 
      
     {/*  <ViewerToolBar/> */}
      <Main sx={{marginTop:externalviewer?"0":"",paddingTop:externalviewer?"0":""}}>
      
      <ViewerToolBar
      customizedMobileDevice={customizedMobileDevice}
      sx={{marginTop:externalviewer?"0":"",paddingTop:externalviewer?"0":""}}
      //sterlizeAIFindingsFunction={sterlizeFindingsFunction}
      loadingData={loadingData} 
      //caseComments={caseComments}
      //addcomments={addcomments}
      externalviewer={externalviewer}
      manualfindings={manualfindings}
      currentStudy={currentStudy}
      onLayoutColumnsChanged={(number)=>{onLayoutColumnsChanged(number)}}
      onLayoutRowsChanged={(number)=>{onLayoutRowsChanged(number)}}
      function1={()=>{changeHangingProtocol(currentStudy,"CC")}}
      function2={()=>{changeHangingProtocol(currentStudy,"MLO")}}
      function3={()=>{changeHangingProtocol(currentStudy,"ALL")}}
      />


{/*     //START ADDING ALL PAGE CONTENTS HERE
 
*/}
  <LoadInstanceSpeedDial currentStudy={currentStudy}/> 

    <div 
    //className="viewportDiv" 
    style={{
      //height:"745px",
      margin:"-10px -24px 0px -24px",
    //  height: "87vh"
    }} 
      id="viewer" 
      className="viewer-menu-container"
      {...bindContextMenu(popupState)}>

        <ViewportsLayout
            layoutRows={layoutRows}
            layoutColumns={layoutColumns}
            activeContainerId={activeContainerId}
            patientInformation="Andrea Müller"
            showpatientInformation={false}
            
        />
        <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}/>
    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingData}
        >
                <CircularProgress color="inherit" />
            </Backdrop>
     <Menu
      {...bindMenu(popupState)}
      sx={{
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        backgroundImage:"none",
        boxShadow:"none",
        borderRadius:"50%",       
      }
    }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          
          <ViewerMenuContext/>
   {/*  <MenuItem onClick={popupState.close}>Cake</MenuItem>
      <MenuItem onClick={popupState.close}>Death</MenuItem>  */}
    </Menu> 



    </div>
                    
            
        

{/*     //
        //
        // END PAGE CONTENTS HERE
        //
        // 
*/}
   

      
      </Main>
    </Box>
  );
};

export default ViewerExternal;

