export const SEGMENTATION_TYPES = {
    BOUNDING_BOX: 'bounding-box',
    FREE_DRAW: 'free-draw',
    SMART_PAINT: 'smart-paint'
};

export const SEGMENTATION_TOOL_TYPES = {
    NONE: 'none',
    BOUNDING_BOX: 'bounding-box',
    BOUNDING_BOX_2D: 'bounding-box-2d',
    BOUNDING_BOX_PENCIL: 'bounding-box-pencil',
    FREE_DRAW: 'free-draw',
    FREE_DRAW_PENCIL: 'free-draw-pencil',
    ERASE_CONTOUR: 'erase-contour',
    SMART_PAINT_BRUSH: 'smart-paint-brush',
    SMART_PAINT_BRUSH_WITH_REF: 'smart-paint-brush-with-ref',
    SMART_PAINT_FILL_BRUSH: 'smart-paint-fill-brush',
    SMART_PAINT_BUCKET: 'smart-paint-bucket',
    SMART_PAINT_SMOOTH_BRUSH: 'smart-paint-smooth-brush'
};

export const SUBTYPES = {
    BBOX_3D: '3D',
    BBOX_2D: '2D'
};
