export default function updateWorkspaceNameAPI(user,form){
    //console.log("from AddUserToWorkspace", newUser)
    const values = {
        "employeeid": user["userID"],
        "organisationid": user["organisationid"],
        "name":form.name
    }

    //console.log("AddUserToWorkspace", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.update_workspace_name, requestOptions)

}