// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./utilityComponents/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 400 500 600 700 800; /* Specify the font weights */
  font-stretch: 75% 100%; /* Specify the font stretch */
  font-named-instance: 'Regular', 'Italic'; /* Specify the named instances */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

html,
.body,
#root,
.app,


:root{
  background-color: #252525;
  background: #252525;

    --form-color: cyan

}

.content {
/*    height: 100%;  */
   width: 100%; 
   background-color: #252525;
   font-family: "Open Sans", sans-serif;
   display: "flex";
}

.app {
  display: flex;
  position: relative;
  background-color: #252525;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,sKAAsK;;AAEtK;EACE,wBAAwB;EACxB,kBAAkB;EAClB,oCAAoC,EAAE,6BAA6B;EACnE,sBAAsB,EAAE,6BAA6B;EACrD,wCAAwC,EAAE,gCAAgC;EAC1E,+DAAsG;AACxG;;AAEA;;;;;;;EAOE,yBAAyB;EACzB,mBAAmB;;IAEjB;;AAEJ;;AAEA;AACA,sBAAsB;GACnB,WAAW;GACX,yBAAyB;GACzB,oCAAoC;GACpC,eAAe;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */\n\n@font-face {\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-weight: 300 400 500 600 700 800; /* Specify the font weights */\n  font-stretch: 75% 100%; /* Specify the font stretch */\n  font-named-instance: 'Regular', 'Italic'; /* Specify the named instances */\n  src: url('./utilityComponents/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');\n}\n\nhtml,\n.body,\n#root,\n.app,\n\n\n:root{\n  background-color: #252525;\n  background: #252525;\n\n    --form-color: cyan\n\n}\n\n.content {\n/*    height: 100%;  */\n   width: 100%; \n   background-color: #252525;\n   font-family: \"Open Sans\", sans-serif;\n   display: \"flex\";\n}\n\n.app {\n  display: flex;\n  position: relative;\n  background-color: #252525;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #e0e0e0;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #888;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
