export const pieData = [
    {
      "id": "CT",
      "label": "CT",
      "value": 140,
      "color": "hsl(7, 70%, 50%)"
    },
    {
      "id": "X-Ray",
      "label": "X-Ray",
      "value": 196,
      "color": "hsl(47, 70%, 50%)"
    },
    {
      "id": "MRI",
      "label": "MRI",
      "value": 549,
      "color": "hsl(297, 70%, 50%)"
    },
    {
      "id": "Mammography",
      "label": "Mammography",
      "value": 509,
      "color": "hsl(300, 70%, 50%)"
    },
    {
      "id": "Ultrasound",
      "label": "Ultrasound",
      "value": 311,
      "color": "hsl(193, 70%, 50%)"
    }
  ]