import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SlidesDrawer from './components/SlidesDrawer';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme"



const actions = [

//  { icon: <PhotoOutlinedIcon />, name: 'Instance' },
  { icon: <CollectionsOutlinedIcon />, name: 'Series' },
  { icon: <FilterNoneOutlinedIcon />, name: 'Study'},
];





export default function LoadInstanceSpeedDial({currentStudy}) {
    const [openDrawerState,setOpenDrawerState] = React.useState(false)
    const [openSpeedDial,setOpenSpeedDial] = React.useState(false)
    const [type,setType] = React.useState('')
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")


    function openDrawer(type){
        console.log("open Drawer function", type)
        setType(type)
        setOpenDrawerState(true)
    }
    
    function closeDrawer(){
    setOpenDrawerState(false)
    }


  return (
    // <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <React.Fragment key={'bottom'}>
          <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ 
                //'& .MuiFab-primary': {backgroundColor: colors.novuColors[120], color: 'transparent' },
                    position: 'absolute',
                    bottom: 16, 
                    right: 16,
                    //borderRadius:0 
                }}
              icon={<AddPhotoAlternateIcon />}
              direction = {'left'}
          >
              {actions.map((action) => (
                  <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => openDrawer(action.name)}

                  />
              ))}
          </SpeedDial>

          <SlidesDrawer currentStudy={currentStudy} openDrawerState={openDrawerState} type={type} closeDrawer={closeDrawer} />
      </React.Fragment>
    // </Box>
  );
}