import React, { useState, useEffect, useCallback } from 'react';
import './css/Demo.css';
import 'viewports-core';
import StudiesMenu from './StudiesMenu/StudiesMenu';
import LeftMenu from './LeftMenu/LeftMenu';
import ViewportsLayout from './ViewportsLayout/ViewportsLayout';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';



const MedDreamExampleE6 = () => {
    const [layoutRows, setLayoutRows] = useState(2);
    const [layoutColumns, setLayoutColumns] = useState(2);
    const [activeContainerId, setActiveContainerId] = useState('viewport-container-1-1');

    useEffect(() => {
        const configuration = {
            features: {
                mistMpr3D: true,
                batchImageRequests: true,
                compressPixelsBeforeSave: 'gzip',
                alternativeMeasurementMouseActions: false,
                segmentingAnnotations: true
            },
            requestsConfiguration: {
                maximumMetadataStreams: 5,
                maximumPixelStreams: 15,
                maximumMultiFrameStreams: 4,
                batchSizeMb: 5,
                multiFrameBatchSizeMb: 2
            }
        };
        global.viewportsCore.create(configuration);
        global.viewportsCore.setBaseUrl('.');  // This line is not required. Used only when it needs to configure different context path for requests.
        const activeContainerChangedHandler = (containerId) => setActiveContainerId(containerId);
        global.viewportsCore.registerEvent(global.viewportsCore.getConstants().CORE_EVENTS.ACTIVE_CONTAINER_CHANGED, activeContainerChangedHandler);

        return () => {
            global.viewportsCore.unregisterEvent(global.viewportsCore.getConstants().CORE_EVENTS.ACTIVE_CONTAINER_CHANGED, activeContainerChangedHandler);
        };
    }, []);

    const selectViewportContainer = useCallback((containerId) => {
        setActiveContainerId(containerId);
    }, []);

    const onLayoutColumnsChanged = useCallback((event) => {
        setLayoutColumns(parseInt(event.target.value));
    }, []);

    const onLayoutRowsChanged = useCallback((event) => {
        setLayoutRows(parseInt(event.target.value));
    }, []);

    return (
        <div className="content">
            <StudiesMenu
                onLayoutColumnsChanged={onLayoutColumnsChanged}
                activeContainerId={activeContainerId}
                layoutRows={layoutRows}
                layoutColumns={layoutColumns}
            />
            <div className="viewer-menu-container">
                <LeftMenu
                    onLayoutColumnsChanged={onLayoutColumnsChanged}
                    onLayoutRowsChanged={onLayoutRowsChanged}
                    activeContainerId={activeContainerId}
                    layoutRows={layoutRows}
                    layoutColumns={layoutColumns}
                />
                <div id="viewer">
                    <ViewportsLayout
                        layoutRows={layoutRows}
                        layoutColumns={layoutColumns}
                        activeContainerId={activeContainerId}
                    />
                </div>
            </div>
        </div>
    );
};

export default MedDreamExampleE6;
