export default function UpdateNotificationsAPIs(notificationsIDs) {
    
    const values = {
        "notifications":notificationsIDs,
        "status":"read"        
    }

    console.log("UpdateNotifications Request", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.update_users_notifications, requestOptions)

}