import { Box } from '@mui/material'
import React from 'react'

function AdminIframe() {
  return (
    <Box 
    width="inherit"
    height="800px"
    justifyContent={"center"} display={"flex"}>

    <iframe src="https://chat.novu.ai/account/profile" width={"100%"} 
    height={"100%"}
    justifyContent={"center"} 
    display={"flex"}/>
     
     
  </Box>

    
  )
}

export default AdminIframe