import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
//import Input from '@mui/joy/Input';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Avatar from '@mui/material/Avatar';
import PersonAdd from '@mui/icons-material/PersonAdd';
import {SearchMembersAPI, getUserAvatar, ListAllTeams} from '../../../../functions/api/organisational/registerTeam'
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme"
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import BadgeAvatars from '../../../subComponents/avatar/avatar'
import Stack from '@mui/material/Stack';

function teamName() {
    return (
      <><Box sx={{ width: '100%' }}>
        <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Team Name"
          variant="standard" />
      </Box><Divider /></>
    )
  }
  
  function AddTeamMember() {
    return (
      <><Box sx={{ width: '100%' }}>
        <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Team Name"
          variant="standard" />
      </Box><Divider /></>
    )
  }

export default function SearchMembers() {
const colors = tokens("dark");
const themeStyles = themeSettings("dark")
const [inputValue, setInputValue] = useState('')
const [users, setUsers] = useState([])
const [teammembers, setTeamMembers] = useState([])
const [timer, setTimer] = useState(null)
const [activeStep, setActiveStep] = useState(0);
const [checked, setChecked] = useState(false);
const [teams,setTeams]=useState([])


const inputChanged = e => {
    setInputValue(e.target.value)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      getUsers(e.target.value)
    }, 1500)
  
    setTimer(newTimer)
  }
    function getUsers(input){
      SearchMembersAPI(input)
      .then(response => response.text())
      .then(result => {
        const members =  JSON.parse(Object.assign(result))
  
        if (members["count"]>0){

          setChecked(true)
          members["users"].map((member)=>{
            const Useravatar = global.config.api_get_users_avatar+"?username="+member.username
            Object.assign(member, {avatar:Useravatar})
            Object.assign(member, {addToTeam:false}); 
          })

          setUsers(members["users"])
        }
        
        })
      .catch(error => console.log('error', error));
    }

  return (
    <>
              <Box display="flex" justifyContent="center" width="100%" margin="10px 0px 10px 0px ">
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >

              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Add Team Members"
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => (inputChanged(e))}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>


          <Box 
          width="100%"
          justifyContent="center" display="flex" flexDirection={"column"} >
          
          {users.map((user, index) => {
            //const Useravatar = global.config.api_get_users_avatar+"?username="+user.username
     
            return(
            
            <Grow
            in={checked}
            {...(checked ? { timeout: 500*(index+1) } : {})}
          >
           <Box
              width={"100%"}
              key={index}
          
              //component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%",
               borderRadius:'10px',
               //backgroundColor: colors.dark_grey[100],
                height:"50px",
              marginTop:"20px" }}
              justifyContent="space-between" display="flex"
            >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                 <BadgeAvatars size="sm" alth={user.name} src={user.avatar} status={user.status} />
              
                    <Typography
                      fontWeight="lg"
                      level="body3"
                      textColor="text.primary"
                    >
                      Name: {user.name}
                    </Typography>
                 
                </Box>

                <IconButton 
                color="primary" 
                sx={{ p: '10px' }} aria-label="directions"
                onClick={()=>{
                   
                    setTeamMembers([user, ...teammembers])
                }}
                >
                  <PersonAdd sx={{ fill: "white" }} />
                </IconButton>
              
            </Box>
            </Grow>
            )
            })}
            </Box>
    </>

  )
}

