import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

export default function OpacitySlider (props) {
    const {opacityDefaults} = global.viewportsCore.segmentingAnnotationsController.getToolSettings();
    const [currentOpacity, setCurrentOpacity] = useState(opacityDefaults.min);

    function setValue (value) {
        setCurrentOpacity(value);
        global.viewportsCore.segmentingAnnotationsController.updateAnnotationOpacity(props.annotation, value);
    }

    useEffect(() => {
        if (!props.annotation) {
            return;
        }
        const currentValue = Number.parseFloat(props.annotation.getCurrentOpacity());
        setCurrentOpacity(currentValue);
    }, [props.annotation]);


    if (!props.annotation) {
        return null;
    }

    return (
        <div
            className="slider opacity-slider"
        >
            Opacity:
            <input
                type="range"
                min={opacityDefaults.min}
                max={opacityDefaults.max}
                value={currentOpacity}
                step={opacityDefaults.step}
                onChange={(value) => setValue(value.currentTarget.value)}
            />
            {currentOpacity}
        </div>
    );
}

OpacitySlider.propTypes = {
    annotation: PropTypes.object
};
