export const findings = {
    mamilla: {
      point: { x: 2529, y: 2823 },
      radius: 40,
     // isEditable: true,
      //isVisible: true,
      style: {
        color: "#E72828",
        lineWidth: 2,
      },
    }
  };

   /*
   ,
     muscleLine: {
      startPoint: { x: 4043, y: 820 },
      endPoint: { x: 4393, y: 1356 },
      isEditable: true,
      isVisible: true,
      style: {
        color: "#E72828",
        lineWidth: 2,
        dashedLine: false,
      },
    },
    regionLines: [
      {
        uid: "region-line-1",
        startPoint: { x: 1476, y: 1097 },
        middlePoint: { x: 2149, y: 1601 },
        endPoint: { x: 2021, y: 2242 },
        isEditable: true,
        isVisible: true,
        style: {
          color: "#0D88B5",
          lineWidth: 2,
          dashedLine: false,
        },
      },
      {
        uid: "region-line-2",
        startPoint: { x: 1528, y: 921 },
        middlePoint: { x: 2294, y: 1564 },
        endPoint: { x: 2230, y: 2279 },
        isEditable: true,
        isVisible: true,
        style: {
          color: "#0D88B5",
          lineWidth: 2,
          dashedLine: false,
        },
      },
    ],
    lesions: [
      {
        uid: "lesion-1",
        type: "ellipse-lesion",
        point: { x: 1857, y: 896 },
        xRadius: 135,
        yRadius: 75,
        rotationAngle: 25,
        isEditable: true,
        isVisible: false,
        style: {
          color: "#C6C413",
          fillColor: "#0E5715",
          fillOpacity: 0.2,
          dashedLine: true,
        },
      },
      {
        uid: "lesion-2",
        type: "polyline-lesion",
        isEditable: true,
        isVisible: false,
        points: [
          { x: 1624, y: 1026 },
          { x: 1722, y: 969 },
          { x: 1815, y: 1033 },
          { x: 1752, y: 1107 },
          { x: 1707, y: 1087 },
          { x: 1663, y: 1106 },
        ],
        // style: {
        //   color: "#0E5715",
        //   fillColor: "#0E5715",
        //   fillOpacity: 0.2,
        // },
        style: {
          color: "#C6C413",
          fillColor: "#0E5715",
          fillOpacity: 0.2,
        }
      },
    ], */