import * as React from 'react';
import { createContext, useState, useEffect,useContext,useMemo } from "react";
// React components
import { Routes,Route, 
  NavLink,
  Navigate,
  useNavigate,
  useLocation,} from "react-router-dom";
import { store} from './states/redux/store';
import * as reactRouterDom from "react-router-dom";

// Import CSS components
import {ColorModeContext, useMode} from './utilityComponents/theme'
import {CssBaseline, ThemeProvider} from "@mui/material"

// Importing Global components
//import Topbar from './components/global/topbar/Topbar'
//import Sidebar from './components/global/sidebar/Sidebar'


// Importing pages

// import Dashboard from './components/pages/dashboard'
//import Worklist from './components/pages/worklist'
import Patients from './components/pages/patients'
import Appointments from './components/pages/appointments'
import Viewer from './components/pages/viewer/index'
import Appstore from './components/pages/appstore'
import Profile from './components/pages/profile'
import {Settings} from "./components/pages/settings";
// import Calender from './components/pages/profile'
// import Clinician from './components/pages/profile'
// import Hdepartment from './components/pages/profile'
import Demo from "./components/pages/demo";
import Chat from "./components/pages/chat";
import Teams from "./components/pages/teams";
import { Login } from "./components/pages/Login/Login";
import {Signup} from "./components/pages/signup/Signup"
import {Landing} from "./components/pages/landing/landing"
import Performance from "./components/pages/KPIs-Rad"
import ViewerExternal from './components/pages/viewerExternal';
import RegisterAIProduct from './components/pages/registerai';
import Medicalassistant from './components/pages/medicalassistant';
import { KPIsAI } from './components/pages/KPIs-AI';
//import { LoginOnline } from './components/pages/LoginOnline/Loginonline';
import {Landingonline} from './components/pages/landingonline/landingonline';

// Additional Component
import { SocketContext, SocketProvider } from './components/subComponents/context/SocketContext';
import {logout_rc} from '../src/functions/authentication/logout'
import { AuthProvider, ProtectedRoute } from './components/subComponents/auth/auth';
import { useDispatch, useSelector } from 'react-redux';
import { updateDates } from './states/slices/date/dateSlice';
import { HandleGetCasesByDate } from './components/global/functions/handleGetCasesByDate';
import { GetNotifications } from './components/global/functions/getNotification';
import { io } from 'socket.io-client';
import MedDreamExample from './components/pages/meddreamexample/Demo';
import MedDreamExampleE6 from './components/pages/meddreamexamplee6/Demo';
import { ViewerUpdated } from './components/pages/viewer2.0';
import { ViewerArchived } from './components/pages/viewerArchived';
import GetAndStoreAvatars from './functions/avatars/GetAndStore';
import Cases from './components/pages/cases';



export async function Globallogout () {
  await logout_rc(); 
  localStorage.clear();
  window.sessionStorage.clear();
  window.location.href = "/";
}


function App() {
  const [theme, colorMode] = useMode();
  const selectedDate= useSelector((state)=>state.date)
  const socket = useContext(SocketContext);
  const user = useSelector((state)=>state.user)
  const Defaultworkspace = useSelector((state) => state.Defaultworkspace);
  const dispatch = useDispatch();
  //useMemo(() => GetAndStoreAvatars(), [])


useEffect(() => {
  console.log("SOCKET", socket)
  console.log("user.userLoggedIN", user.userLoggedIN)


if(user.userLoggedIN ){  
  const socket = io({
    auth: {
      userID: user.userID,
      userLoginToken: user.userLoginToken,
      organisationid: user.organisationid
    }
  }); 


  socket.on("connect", ()=>{
    console.log("CONNECTED TO SOCKET")
    //Sucessfullynotify("Successfully connected to live server.")
    //socket.emit('join-team', user.organisationid)
    
      socket.emit('join-team', Defaultworkspace["defaultWorkspaceID"])
    
    
  })

  socket.on('FromAPI', function(){
        //HandleGetCasesByDate(selectedDate, Defaultworkspace, user)
         //Sucessfullynotify('New cases has been added.')
       })

  socket.on('notifications', function(data){
        //HandleGetCasesByDate(selectedDate)
        console.log("got nottification",data)

        GetNotifications ()
        //setNewNotifications(true)
        //Sucessfullynotify('New Notifications.')
      })
      socket.on('update_task', function(data){
        //HandleGetCasesByDate(selectedDate)
        console.log("got nottification on update_task",data)
        //HandleGetCasesByDate(selectedDate, Defaultworkspace, user)
        //Sucessfullynotify('New Notifications on update_task.')
      })

  socket.on('update_case_status', function(data){
        //handleGetCasesByDate(selectedDate)
        //HandleGetCasesByDate(selectedDate, Defaultworkspace, user)
        console.log("WS: update_case_status",data)
        //getNotifications()
        //Sucessfullynotify('New Notifications.')
      })
      
  socket.on('message-from-server', (message)=>{
    //Sucessfullynotify(message)
  })

  socket.on('workspace', (message)=>{
    //Sucessfullynotify(message)
  })

  socket.on("connect_error", (err) => {
    //Errornotify("Live Server: ",err.message)
    console.log(err.message); // prints the message associated with the error
  });}


}, [user,socket]);

/*  useEffect(() => {
  window.addEventListener('message', function(e) {
    console.log(e.data.eventName); // event name
    console.log(e.data.data); // event data
});

document.querySelector('iframe').contentWindow.postMessage({
  externalCommand: 'login-with-token',
  token : sessionStorage.getItem("Meteor.loginToken")
}, '*')

 
   return () => {
    window.removeEventListener('message')
   }
 }, []) */
 

  return (
     <AuthProvider>
      {/* <SuperTokensWrapper> */}
    <ColorModeContext.Provider value={colorMode}>
       <ThemeProvider theme={theme}>
      {/*  <CssBaseline /> */}
        
      <div className="app">
          <main className='content'>
          {/* <Topbar/>
          <Sidebar/> */}
            
            {/* <SessionAuth> */}
              <Routes>

                {/* public routes*/}
                <Route path="/*" element={<Landing/>} />
                <Route path="/signup" element={<Signup/>} />  
                <Route path="/login" element={<Login/>} />
                <Route path="/landingonline" element={<Landingonline/>} />
                <Route path="/external" element={<ViewerExternal />} /> 
                <Route path="/example" element={<MedDreamExample />} /> 
                <Route path="/example6" element={<MedDreamExampleE6 />} /> 
                <Route path="/Viewerarchived" element={<ViewerArchived />} /> 

                

                {/* private routes*/}

                <Route path="demo" element={<ProtectedRoute> <SocketProvider  store={store}><Demo/></SocketProvider ></ProtectedRoute>}/>
                <Route path="cases" element={<ProtectedRoute> <SocketProvider  store={store}><Cases/></SocketProvider ></ProtectedRoute>}/>
                <Route path="chat" element={<ProtectedRoute> <SocketProvider  store={store}><Chat/></SocketProvider ></ProtectedRoute>}/>
                <Route path="teams" element={<ProtectedRoute> <SocketProvider  store={store}><Teams/></SocketProvider ></ProtectedRoute>}/>
                {/* <Route path="worklist" element={<ProtectedRoute> <SocketProvider  store={store}><Worklist/></SocketProvider ></ProtectedRoute>}/> */}
                <Route path="appointments" element={<ProtectedRoute> <SocketProvider  store={store}><Appointments/></SocketProvider ></ProtectedRoute>}/>
                <Route path="patients" element={<ProtectedRoute> <SocketProvider  store={store}><Patients/></SocketProvider ></ProtectedRoute>}/>
                <Route path="Settings" element={<ProtectedRoute> <SocketProvider  store={store}><Settings/></SocketProvider ></ProtectedRoute>}/>
                <Route path="Performance" element={<ProtectedRoute> <SocketProvider  store={store}><Performance/></SocketProvider ></ProtectedRoute>}/>
                <Route path="profile" element={<ProtectedRoute> <SocketProvider  store={store}><Profile/></SocketProvider ></ProtectedRoute>}/>
                <Route path="appstore" element={<ProtectedRoute> <SocketProvider  store={store}><Appstore/></SocketProvider ></ProtectedRoute>}/>
                <Route path="registerai" element={<ProtectedRoute> <SocketProvider  store={store}><RegisterAIProduct/></SocketProvider ></ProtectedRoute>}/>
                <Route path="medicalassistant" element={<ProtectedRoute> <SocketProvider  store={store}><Medicalassistant/></SocketProvider ></ProtectedRoute>}/>
                <Route path="kpis-ai" element={<ProtectedRoute> <SocketProvider  store={store}><KPIsAI/></SocketProvider ></ProtectedRoute>}/>
                <Route path="viewer" element={<ProtectedRoute> <SocketProvider  store={store}><Viewer/></SocketProvider ></ProtectedRoute>}/>
                <Route path="viewerNew" element={<ProtectedRoute> <SocketProvider  store={store}><ViewerUpdated/></SocketProvider ></ProtectedRoute>}/> 

              </Routes>
              {/* </SessionAuth> */}
              {/* </SocketProvider> */}
              
          </main>
      </div>
       </ThemeProvider> 
    </ColorModeContext.Provider>
    {/* </SuperTokensWrapper> */}
    </AuthProvider>
  );
}

export default App;
