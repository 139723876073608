import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Checkbox,Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const eye = <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C20.27 12.39 16 15.5 11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5ZM2.18 8C3.83 11.36 7.24 13.5 11 13.5C14.76 13.5 18.17 11.36 19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8Z" fill="#F5F5F5" />
</svg>

const ColumnsVisibility = ({ columnNames, onColumnsVisibilityChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (columnId) => () => {
    const updatedColumnNames = { ...columnNames };
    updatedColumnNames[columnId].show = !updatedColumnNames[columnId].show;
    onColumnsVisibilityChange(updatedColumnNames);
    setAnchorEl(null);
  };

  return (
    <>
    <Tooltip title="Columns">
      <IconButton onClick={handleMenuClick}>
        {eye}
      </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {Object.keys(columnNames).map((columnId) => (
          columnNames[columnId].Hideable && (
            <MenuItem key={columnId} onClick={handleMenuItemClick(columnId)}>
              <Checkbox checked={columnNames[columnId].show} />
              {columnNames[columnId].name}
            </MenuItem>
          )
        ))}
      </Menu>
    </>
  );
};

export default ColumnsVisibility;
