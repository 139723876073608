import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ViewerInteractions from './ViewerInteractions';
import Mpr from './Mpr';
import Measurements from './Measurements';
import SegmentationModal from '../Segmentation/SegmentationModal';
import '../css/Segmentation.css';

const LeftMenu = ({ activeContainerId, layoutColumns, layoutRows, onLayoutColumnsChanged, onLayoutRowsChanged }) => {
    const petSeriesUidRef = useRef(null);
    const landmarkUidRef = useRef(null);
    const [showSegmentationModal, setShowSegmentationModal] = useState(false);

    const showIntensity = () => {
        global.viewportsCore.enableIntensity();
    };

    const hideIntensity = () => {
        global.viewportsCore.disableIntensity();
    };

    const showAngles = () => {
        global.viewportsCore.measurementsController.toggleAngleBetweenIntersectingRulers(true);
    };

    const hideAngles = () => {
        global.viewportsCore.measurementsController.toggleAngleBetweenIntersectingRulers(false);
    };

    const createLandmark = () => {
        global.viewportsCore.landmarksController.enable((landmarkData) => console.log(landmarkData));
        global.viewportsCore.landmarksController.createLandmark();
    };

    const removeLandmark = () => {
        global.viewportsCore.landmarksController.removeLandmark(landmarkUidRef.current.value);
    };

    const fusion = () => {
        const petSeriesUid = petSeriesUidRef.current.value || global.viewportsCore.getActiveViewport().getSeries().uid;
        global.viewportsCore.getActiveViewport().enableFusion(petSeriesUid);
    };

    const resetAllViewports = () => {
        global.viewportsCore.resetAllViewportsRenders();
    };

    const enableReferenceLines = () => {
        global.viewportsCore.enableReferenceLines();
    };

    const disableReferenceLines = () => {
        global.viewportsCore.disableReferenceLines();
    };

    const cacheStudy = () => {
        global.viewportsCore.cacheStudy(global.viewportsCore.getActiveViewportStudy().uid, 'Orthanc');
    };

    const enableSync = () => {
        global.viewportsCore.enableSync((func, param) => {
            doFunctionFromEvents(func, param);
        });
        global.viewportsCore.getViewportsList().forEach(viewport => {
            createInputInViewport(viewport);
        });
    };

    const disableSync = () => {
        global.viewportsCore.disableSync((func, param) => {
            doFunctionFromEvents(func, param);
        });
        global.viewportsCore.getViewportsList().forEach(viewport => {
            document.getElementById(`syncCheckbox-${viewport.containerId}`).remove();
        });
    };

    const doFunctionFromEvents = (func, param) => {
        switch (func) {
            case 'image-position-changed':
                global.viewportsCore.applyPanForLockedViews(param.containerId, param.imagePosition.x, param.imagePosition.y);
                break;
            case 'zoom-changed':
                global.viewportsCore.applyZoomForLockedViews(param.containerId, param.scale, param.point);
                break;
            default:
                break;
        }
    };

    const showSegmentationModalFunc = () => {
        global.viewportsCore.generateMpr(activeContainerId, ['viewport-container-1-1', 'viewport-container-1-2', 'viewport-container-2-1']);
        global.viewportsCore.preloadSegmentingAnnotationsController().then(() => setShowSegmentationModal(true));
        global.viewportsCore.setMouseButtonFunction(0, 13);
    };

    const hideSegmentationModal = () => {
        setShowSegmentationModal(false);
    };

    const createInputInViewport = (viewport) => {
        let input = document.createElement('input');
        input.className = 'sync-checkbox';
        input.type = 'checkbox';
        input.id = `syncCheckbox-${viewport.containerId}`;
        input.checked = global.viewportsCore.getLockedViewportContainers().includes(viewport.containerId)
        input.onclick = () => {
            if (input.checked === true) {
                global.viewportsCore.addToSync([viewport.containerId]);
            } else {
                global.viewportsCore.removeFromSync([viewport.containerId]);
            }
        };
        document.getElementById(viewport.containerId).appendChild(input);
    };

    return (
        <div className="mouse-function">
            <span>Layout columns: {layoutColumns}</span>
            <input
                type="range"
                min="1"
                max="5"
                value={layoutColumns}
                onChange={onLayoutColumnsChanged}
            />
            <span>Layout rows: {layoutRows}</span>
            <input
                type="range"
                min="1"
                max="3"
                value={layoutRows}
                onChange={onLayoutRowsChanged}
            />
            <br/>
            <ViewerInteractions/>
            <button onClick={showIntensity}>Show Intensity</button>
            <button onClick={hideIntensity}>Hide Intensity</button>
            <button onClick={showAngles}>Show Angles</button>
            <button onClick={hideAngles}>Hide Angles</button>
            <br/>
            <br/>
            <Measurements/>
            <br/>
            <button onClick={createLandmark}>Landmark</button>
            <input type="text" className="input" placeholder="Landmark Ref. UID" ref={landmarkUidRef}/>
            <button onClick={removeLandmark}>Remove Landmark</button>
            <br/>
            <Mpr activeContainerId={activeContainerId}/>
            <br/>
            <input type="text" className="input" placeholder="Series UID" ref={petSeriesUidRef}/>
            <button onClick={fusion}>Fusion</button>
            <br/>
            <button onClick={resetAllViewports}>Reset All</button>
            <button onClick={enableReferenceLines}>Enable References Lines</button>
            <button onClick={disableReferenceLines}>Disable References Lines</button>
            <button onClick={cacheStudy}>Cache Study</button>
            <br/>
            <button onClick={enableSync}>Enable sync</button>
            <button onClick={disableSync}>Disable sync</button>
            <br/>
            <button onClick={showSegmentationModalFunc}>Segmentation</button>
            <SegmentationModal show={showSegmentationModal} onClose={hideSegmentationModal}/>
        </div>
    );
};

LeftMenu.propTypes = {
    onLayoutColumnsChanged: PropTypes.func.isRequired,
    onLayoutRowsChanged: PropTypes.func.isRequired,
    activeContainerId: PropTypes.string.isRequired,
    layoutColumns: PropTypes.number.isRequired,
    layoutRows: PropTypes.number.isRequired
};

export default LeftMenu;
