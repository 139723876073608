import React from 'react'
import { useEffect, Suspense } from "react";
import Backdrop from '@mui/material/Backdrop';
import { Box, Toolbar } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
//import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { HealthCheckAll } from "../../../functions/api/data/settings";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import RegisterAI from './RegisterAI';

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));


//const resource = fetchData();

export default function RegisterAIProduct() {
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null)


  function healthCheck() {
    setOpen(true)
    HealthCheckAll().then(res => {
      setData(res)
      setOpen(false)
    })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main>
        <Toolbar variant="dense" />
        <Box >

          {/*     //
        //
        // START ADDING ALL PAGE CONTENTS HERE
        //
        // 
*/}

       
          <Box>
            <RegisterAI/> 
          </Box>
          

       


          {/*     //
        //
        // END PAGE CONTENTS HERE
        //
        // 
*/}

        </Box>
      </Main>
    </Box>
  );
};


