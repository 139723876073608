import {useMediaQuery} from "react-responsive";

export const useResponsiveQueries = () => {
  const isMobile = useMediaQuery({query: "(max-width: 480px)"});
  const isTablet = useMediaQuery({query: "(max-width: 820px)"});
  const isPortrait = useMediaQuery({query: "(orientation: portrait)"});
  const isRetina = useMediaQuery({query: "(min-resolution: 2dppx)"});
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  return {
    isTablet,
    isMobile,
    isPortrait,
    isRetina,
    isBigScreen,
  };
};