export default function DeepLookSegmentationPostAPI(input,margin) {
    //url = global.config.api_anonymization_add
    console.log("from DeepLookPostSegmentation",input)
    const values = {
        "margin":margin,
        "instanceuid": input.instanceuid,
        "centerPosition": {
            "x": input.centerPosition.x,
            "y": input.centerPosition.y
        },
        "xRadius": input.xRadius,
        "yRadius": input.xRadius,
        "spacing": [input.spacing[0], input.spacing[1]]
    }

    console.log("DeepLookPostSegmentation", values)
    const url = "https://app.novu.ai/api/deeplook/segmentation/get"
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(url, requestOptions)
}
