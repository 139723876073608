export default function deleteUserFromWorkspace(newUser){
    console.log("from deleteUserFromWorkspace", newUser)

    const values = {
        "employeeid": newUser["employeeid"],
        "WorkspaceID": newUser["organisationid"],
    }

    console.log("from deleteUserFromWorkspace", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.delete_users_from_workspace, requestOptions)

}