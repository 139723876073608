import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";


export default function AICard({ aiproduct }) {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const theme = useTheme();

  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        src={aiproduct["General"]["Logo"]}
        alt="Live from space album cover"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between"}}>
            <Box>
              <Typography component="div" variant="h5">
                {aiproduct["General"]["Productname"]}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                By: {aiproduct["General"]["manufacturer"]["Name"]}  {aiproduct["AIDetails"]["Version"]?  `| Version: ${aiproduct["AIDetails"]["Version"]}`:""}  {aiproduct["Certification"]["CE"]["granted"]? "| CE":""} {aiproduct["Certification"]["FDA"]["granted"]? " FDA":""}
              </Typography>
            </Box>

            <Box>
            <Typography component="div" variant="h5">
                Subscribe
              </Typography>

{/*               <IconButton aria-label="play/pause">
                <PlayArrowIcon sx={{ height: 38, width: 38 }} />
              </IconButton> */}
            </Box>
          </Box>
          {/*           <Typography component="div" variant="h5">
            {aiproduct["General"]["description"]}
          </Typography> */}

        </CardContent>

        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <Typography component="div" sx={[themeStyles.typography.h71]}>
            {aiproduct["General"]["description"]}
          </Typography>
          {/*           <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton>
          <IconButton aria-label="play/pause">
            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          <IconButton aria-label="next">
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton> */}
        </Box>

      </Box>
      {/* <CardMedia
        component="img"
        sx={{ width: 151 }}
        image="/static/images/cards/live-from-space.jpg"
        alt="Live from space album cover"
      /> */}
    </Card>
  );
}