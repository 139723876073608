import React from 'react'
import { useDispatch } from 'react-redux'
import { addfindings } from '../../../../states/slices/cases/casesSlice'

export const TempAIfindings=  {
  mamilla: {
      point: {x: 1529, y: 1823},
      radius: 40,
      isEditable: false,
      isVisible: true,
      style: {
          color: '#E72828',
          lineWidth: 2
      }
  },
  muscleLine: {
      startPoint: {x: 2043, y: 820},
      endPoint: {x: 2393, y: 1356},
      isEditable: false,
      isVisible: true,
      style: {
          color: '#E72828',
          lineWidth: 2,
          dashedLine: false
      }
  },
  regionLines: [
      {
          uid: 'region-line-1',
          startPoint: {x: 1476, y: 1097},
          middlePoint: {x: 2149, y: 1601},
          endPoint: {x: 2021, y: 2242},
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0D88B5',
              lineWidth: 1,
              dashedLine: true
          }
      },
      {
          uid: 'region-line-2',
          startPoint: {x: 1528, y: 921},
          middlePoint: {x: 2294, y: 1564},
          endPoint: {x: 2230, y: 2279},
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0D88B5',
              lineWidth: 2,
              dashedLine: false
          }
      }
  ],
  lesions: [
      {
          uid: 'lesion-1-1',
          type: 'ellipse-lesion',
          point: {x: 1857, y: 896},
          xRadius: 135,
          yRadius: 75,
          rotationAngle: 25,
          isEditable: false,
          isVisible: true,
          style: {
              color: '#0E5715',
              fillColor: '#0E5715',
              fillOpacity: 0.2,
              dashedLine: true
          }
      },
      {
          uid: 'lesion-1-2',
          type: 'polyline-lesion',
          isEditable: false,
          isVisible: true,
          points: [
              {x: 1624, y: 1026},
              {x: 1722, y: 969},
              {x: 1815, y: 1033},
              {x: 1752, y: 1107},
              {x: 1707, y: 1087},
              {x: 1663, y: 1106}
          ],
          style: {
              color: '#C6C413',
              fillColor: '#C6C413',
              fillOpacity: 0.2
          }
      }
  ]
}

// const activeContainerIdArray = [
//   'viewport-container-1-1'
//   ,
//   'viewport-container-1-2'
//   ,
//   'viewport-container-2-1'
//   ,
//   'viewport-container-2-2'
//   ,
//   // 'viewport-container-3-1'
//   // ,
//   // 'viewport-container-1-3'
//   // ,
//   // 'viewport-container-2-3'
//   // ,
//   // 'viewport-container-3-3'
//   // ,
//   // 'viewport-container-3-2'
  
// ]

// export function sterlizeDicomResults(study) {
//         const containerID='viewport-container-1-1'

//         console.log("unsterlized Study", study)
//         if (study.length === 0) {
//           console.log("no data")
//         }
//         else {
//           const sterlizedStudy = {
//             "Type": study[0]["Modality"],
//             "studyUid": study[0]["StudyInstanceUID"],
//             "BodyExaminationPart":study[0]["BodyExaminationPart"],
//             "PatientName":study[0]["PatientName"],
//             "PatientAge":study[0]["PatientAge"],
//             "PatientSex":study[0]["PatientSex"],
//             "local_patientID":study[0]["local_patientID"],
//             "ReferringPhysicianName":study[0]["ReferringPhysicianName"],
//             "SeriesDate":study[0]["SeriesDate"],
//             "SeriesTime":study[0]["SeriesTime"],
//             "instances": []
//           }

//           //study[0]["SeriesInstanceUID"]
//           study.map((Singleinstance,index)=>{
            
//             const instance = {
//               name: Singleinstance["StudyDescription"],
//               imageLaterality:Singleinstance["ImageLaterality"],
//               viewPosition:Singleinstance["ViewPosition"],
//               //ContainerID: activeContainerIdArray[index]["ContainerID"],
//               uid: Singleinstance["SOPInstanceUID"],
//               //findings: Tempfindings                
//           }
//           sterlizedStudy.instances.push(instance)

//         })

//         console.log("sterlizedStudy",sterlizedStudy)

//        const  AssignHangingProtol = AssignContainers(sterlizedStudy)

//          return AssignHangingProtol
//         }

// }

function generateContainerIds(layoutRows,layoutColumns) {
  const activeContainerIdArray = [];
  for (let i = 1; i <= layoutRows; i++) {
      for (let j = 1; j <= layoutColumns; j++) {
          activeContainerIdArray.push(`viewport-container-${i}-${j}`);
      }
  }
  return activeContainerIdArray;
}

export function SterlizeDicomResults_updated_series(study,layoutRows,layoutColumns) {
  const containerID='viewport-container-1-1'
  const activeContainerIdArray = generateContainerIds(layoutRows,layoutColumns);
  //console.log("unsterlized Study", study)
 
  if (study.length === 0) {
    console.log("no data")
    window.alert("Error: No Cases to load.");
  }
  else {

    // Extract Findings
    let ListofFindings=[]

    // study.map((series,index)=>{
    //   if(series.finding[0]){
    //     series.finding.map((Singlefinding,index)=>{
    //       ListofFindings.push(Singlefinding)
    //       //dispatch(addfindings(Singlefinding))
    //     })
    //   }
    // })

    // Extract Study Info
    let sterlizedStudy={
      "study": study[0].StudyInstanceUID,
      "report": study[0]?.report||{'body':'<p>Start writing your <strong>main findings</strong> here.</p>'},
      "modality": study[0]?.modality[0] ??"",
      "PatientName":study[0]?.PatientName[0] ?? "",
      "PatientAge":study[0]?.PatientAge[0] ??"",
      "PatientSex":study[0]?.PatientSex[0] ??"",
      "PatientBirthdate":study[0]?.PatientBirthdate[0] ??"",
      "local_patientID":study[0]?.local_patientid[0] ??"",
      "InstitutionName":study[0]?.InstitutionName[0] ??"",
      "ReferringPhysicianName":study[0]?.ReferringPhysicianName[0] ??"",
      "StationName":study[0]?.StationName[0] ??"",
      "StudyDescription":study[0]?.StudyDescription[0] ??"",
      "instances":[]
    }
  


    // Extract Series Info
    study.map((series,index)=>{
      const instance= Math.round(series["instancescount"] / 2)
     // console.log("instance length",instance)
     let seriesUID = {
      "study": series?.StudyInstanceUID ?? "",
      "SeriesDate": series?.SeriesDate ?? "",
      "SeriesTime": series?.SeriesTime ?? "",
      "SeriesInstanceUID": series?.SeriesInstanceUID ?? "",
      "BodyExaminationPart": series?.BodyExaminationPart?.[0] ?? "",
      "ImageLaterality": series?.ImageLaterality?.[0] ?? "",
      "ViewPosition": series?.ViewPosition?.[0] ?? "",
      "modality": series?.modality?.[0] ?? "",
      "StudyDescription": series?.StudyDescription?.[0] ?? "",
      "instanceUID": series?.instances?.[0] ?? "",
      "DefaultActiveContainer": activeContainerIdArray[index],
      //callback: () => global.viewportsCore.layoutController.openInstanceToViewportContainer(activeContainerIdArray[index], series?.instances?.[0] ?? "")
    };

    // Only assign the callback if activeContainerIdArray[index] has a value
    if (activeContainerIdArray[index]) {
      Object.assign(seriesUID, {
          callback: () => global.viewportsCore.layoutController.openInstanceToViewportContainer(activeContainerIdArray[index], series?.instances?.[0] ?? "")
      });
  }

      //console.log("seriesUID",instance,activeContainerIdArray[index],series["instances"][0])
      sterlizedStudy.instances.push(seriesUID)
      

      if(series.finding[0]){
        series.finding.map((Singlefinding,subindex)=>{
          Object.assign(Singlefinding,{"ActiveContainer":activeContainerIdArray[index]})
          Object.assign(Singlefinding,{"show":false})
          ListofFindings.push(Singlefinding)
          //viewportsCore.measurementsController.createNewMeasurements(activeContainerIdArray[index], [Singlefinding]);
          //viewportsCore.measurementsController.hideMeasurementById(measurementId);
        })
      }
     
    })

    Object.assign(sterlizedStudy,{"findings":ListofFindings})
    
  console.log("sterlizedStudySeries",sterlizedStudy)





  return sterlizedStudy
  }

}



// function AssignContainers(sterlizedStudy){
//   if(sterlizedStudy["Type"]==="MG"){
//     sterlizedStudy.instances.map((SingleInstance, index)=>{
//       Object.assign(SingleInstance, {"ContainerID":activeContainerIdArray[index]["ContainerID"]})
//       return SingleInstance
//     })
//   }else{
//     sterlizedStudy.instances.map((SingleInstance, index)=>{
//       Object.assign(SingleInstance, {"ContainerID":activeContainerIdArray[0]["ContainerID"]})
//       return SingleInstance
//     })
//   }

//   console.log("AssignHangingProtol",sterlizedStudy)
//   return sterlizedStudy
// }