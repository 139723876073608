import { createSlice } from '@reduxjs/toolkit'
// import dayjs from 'dayjs';

// const today = dayjs(new Date());
// const startDateString = today.toLocaletodayString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
// const endtDateString = today.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

const initialState = {
  startDate: "01-01-1900",
  endDate: "31-12-2990",
  IconActive:false
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    updateStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    updateEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    updateDates:(state,action)=>{
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    updateIconActive:(state,action)=>{
      state.IconActive = action.payload;
      
    },
  },
})

export const {updateStartDate, updateEndDate,updateDates,updateIconActive}= dateSlice.actions
const dateReducer =  dateSlice.reducer;
export default dateReducer;
