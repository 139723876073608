import React from 'react'
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
// Custom Styles
import { themeSettings } from "../../../utilityComponents/theme";
import { useState, useEffect } from 'react';


export default function TableHead_Cases() {
  const themeStyles = themeSettings("dark")
  const [columnCount, setcolumnCount] = useState(2)
  const columnNames = [
    // {
    //   // Table border
    //   name: "",
    //   active: false,
    //   width: "5px",


    // },
    // {
    //   // Table border
    //   name: "",
    //   active: false,
    //   width: "5px",


    // },
    {
      // Table border
      name: "Patient Name",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Visit Type",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Check-in-Status",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Case Recieved",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Scan Status",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "1st Reviewer",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "2nd Reviewer",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Supervisor",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Sign Report",
      active: false,
      width: "",

    },
    {
      // Table border
      name: "Report",
      active: false,
      width: "",
    },
    {
      // Table border
      name: "",
      active: false,
      width: "15%",
    },

  ];

  return (

    <TableHead align="center">
      <TableRow>
        {
          columnNames.map((Item, index) =>  (

                <TableCell align="center" key={index} 
                style={{ borderBottom: "none", 
                height: "40px", 
                padding: "0px", 
                width: Item["width"], 
                color: Item["active"] ? "#F5F5F5" : '#9f9f9f' }}>
                  <Typography sx={themeStyles.typography.h52}>{Item["name"]}
                  </Typography>
                </TableCell>

              )
            )}
      </TableRow>

    </TableHead>
  )
}

