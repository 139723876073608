import React from 'react'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { Horizontal, Vertical, Rotate, Fullscreen, Zoom, Unknown4, Unknown5, ROI, Unknown7 } from "../../../../utilityComponents/images/viewerIcons";
/* import { MenuListImageTools } from './MenuLists'; */
import { usePopupState, bindHover, bindMenu, } from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'/* 
import { ContentCopy, ContentCopyOutlined, ContentCut } from '@mui/icons-material';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import TonalityOutlinedIcon from '@mui/icons-material/TonalityOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined'; */
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import BorderVerticalOutlinedIcon from '@mui/icons-material/BorderVerticalOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

// Custom Imports
import 'viewports-core';




export function HangingProtocol({ function1, function2, function3, onLayoutColumnsChanged, onLayoutRowsChanged }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  /*  function AddColumn(){
     console.log(viewportsCore.layoutController.getLayoutContainers())
     viewportsCore.layoutController.addLayoutContainer("viewport-container-3-1", false);
   }
 
   function RemoveColumn(){
     viewportsCore.layoutController.getLayoutContainers();
     viewportsCore.layoutController.removeLayoutContainer(containerId);
   }
 
   function AddRow(){
     viewportsCore.layoutController.getLayoutContainers();
   }
 
   function RemoveRow(){
     viewportsCore.layoutController.getLayoutContainers();
     viewportsCore.layoutController.removeLayoutContainer(containerId);
   }
  */


  const MenuListImageTools = [
    {
      icon: <PlaylistAddOutlinedIcon fontSize="small" />,
      name: "Row",
      shortcut: <><IconButton onClick={() => onLayoutRowsChanged("add")} >
        <AddOutlinedIcon fontSize='small' />
      </IconButton>
        <IconButton onClick={() => onLayoutRowsChanged()}>
          <RemoveOutlinedIcon fontSize='small' />
        </IconButton>
        </>,
      //functionname:props.zoom
      functionname: () => console.log()
    },
    /*     {
          icon: <PlaylistRemoveOutlinedIcon fontSize="small" />,
          name: "Remove Row",
          shortcut: "",
          //functionname: zoom
          functionname:  ()=> onLayoutRowsChanged()
        }, */
    {
      icon: <BorderVerticalOutlinedIcon fontSize="small" />,
      name: "Add Column",
      shortcut: <><IconButton onClick={() => onLayoutColumnsChanged("add")} >
        <AddOutlinedIcon fontSize='small' />
      </IconButton>
        <IconButton onClick={() => onLayoutColumnsChanged()}>
          <RemoveOutlinedIcon fontSize='small' />
        </IconButton></>,
    //functionname: zoom
    functionname: () => console.log()
    },
   /*  {
      icon: <BorderVerticalOutlinedIcon fontSize="small" />,
      name: "Remove Column",
      shortcut: "C",
      //functionname: zoom
      functionname: ()=> onLayoutColumnsChanged()
    }, */
   
]


const HGprotocols = [

  {
    icon: <Horizontal fontSize="small" />,
    name: "CC View",
    shortcut: "C",
    //functionname: zoom
    functionname: function1
  },
  {
    icon: <Vertical fontSize="small" />,
    name: "MLO View",
    shortcut: "M",
    functionname: function2
  },
  {
    icon: <GridViewOutlinedIcon fontSize="small" />,
    name: "All views",
    shortcut: "A",
    functionname: function3
  },

]




function handleMouseButtonFunction(value) {
  global.viewportsCore.setMouseButtonFunction(1, value);
}
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


return (
  <div>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      {...bindHover(popupState)}
    >
      <DashboardOutlinedIcon fontSize='large' />
    </IconButton>
    <HoverMenu
      {...bindMenu(popupState)}
      MenuListProps={{ 'aria-labelledby': 'basic-button', }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >

      {MenuListImageTools.map((Item, index) => (
        <MenuItem onClick={Item.functionname} sx={{ width: "220px" }}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
      ))}

      <Divider />

      {HGprotocols.map((Item, index) => (
        <MenuItem onClick={Item.functionname} sx={{ width: "220px" }}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
      ))}




    </HoverMenu>
  </div>
);
}