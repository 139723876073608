import { createSlice} from '@reduxjs/toolkit'


export const snackbarSlice= createSlice({
    name:"snackbar",
    initialState: {
        open: false,
        Message: "TEST ME",
        severity: "success"
      },
     reducers:{
        updateSnackbar:(state,action)=>{
            console.log("updateSnackbar payload", action.payload)
            const { open, Message,severity } = action.payload;

            state.open = action.payload.open
            state.Message = action.payload.Message
            state.severity = action.payload.severity
        },
        closeSnackbar:(state,action)=>{
            console.log("closeSnackbar payload", action.payload)
            state.open = false
           
        },
     }
})


export const {updateSnackbar,closeSnackbar} = snackbarSlice.actions;
const snackbarReducer =  snackbarSlice.reducer;
export default snackbarReducer;
