import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
//import Input from '@mui/joy/Input';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Avatar from '@mui/material/Avatar';
import PersonAdd from '@mui/icons-material/PersonAdd';
import {SearchMembersAPI, getUserAvatar, ListAllTeams} from '../../../../functions/api/organisational/registerTeam'
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme"
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import BadgeAvatars from '../../../subComponents/avatar/avatar'
import Stack from '@mui/material/Stack';



//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")
//const steps = ['Team information', 'Add members', 'Review'];

const steps = [
  {
    "name": "Team Name",
    "render": teamName()
  },
  {
    "name": "Add members",
    "render": AddTeamMember()
  },
  {
    "name": "Review",
    "render": <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard"
      />
    </Box>
      <Divider />
    </>
  },
]

function teamName() {
  return (
    <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard" />
    </Box><Divider /></>
  )
}

function AddTeamMember() {
  return (
    <><Box sx={{ width: '100%' }}>
      <TextField
        required
        id="standard-required"
        label="Required"
        defaultValue="Team Name"
        variant="standard" />
    </Box><Divider /></>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    backgroundColor: 'rgba(196, 196, 196, 0.3)',
    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "62.50%",
    height: "46.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   }

// })


//
//
// Components STYLINGs END
//
//
//



export default function TeamDialoge({ handleCancel, open }) {
  const fakeApi = () => console.log('Api is called')
  //const [open, setOpen] = useState(true);
  //const stylingClasses = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [members, setMembers] = useState([])
  const [teammembers, setTeamMembers] = useState([])
  const [timer, setTimer] = useState(null)
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const [teams,setTeams]=useState([])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 const listAllTeams=()=>{
  ListAllTeams()
  .then(response => response.text())
    .then(results => {
      const teams =  JSON.parse(Object.assign(results))
      setTeams(teams["teams"])
    })
}
const inputChanged = e => {
  setInputValue(e.target.value)

  clearTimeout(timer)

  const newTimer = setTimeout(() => {
    getUsers(e.target.value)
  }, 1000)

  setTimer(newTimer)
}
  function getUsers(input){
    SearchMembersAPI(input)
    .then(response => response.text())
    .then(result => {
      const members =  JSON.parse(Object.assign(result))

      if (members["count"]>0){
        setChecked(true)
        members["users"].map((member)=>{
          const Useravatar = global.config.api_get_users_avatar+"?username="+member.username
          Object.assign(member, {avatar:Useravatar});
          //member["avatar"]= 

        })
 /*        members["users"].map((user)=>(
          
          getUserAvatar(user["_id"])
          .then(response => response.text())
          .then(result => {console.log("userAvatar", result)})
          )) */
        setMembers(members["users"])
      }
      
      })
    .catch(error => console.log('error', error));
  }

  /*   const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }; */

  const handleReset = () => {
    setActiveStep(0);
  };




  const handleSave = () => {
    console.log("SAVE")
  }
  /*   const handleCancel = () => {
      setOpen(false);
    }; */

  return (
    <div>
      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          <Typography sx={themeStyles.typography.h411}>Build your team</Typography>
        </BootstrapDialogTitle>


        <DialogContent>
          <Box display="flex" justifyContent="center" width="100%" margin="10px 0px 10px 0px ">
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
              {/*      <IconButton sx={{ p: '10px' }} aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Add Team Members"
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => (inputChanged(e))}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              {/*         <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
              <DirectionsIcon />
            </IconButton> */}
            </Paper>
          </Box>

          <Box height='300px' justifyContent="center" display="flex" flexDirection={"row"} >
          {members.map((user, index) => {
            const Useravatar = global.config.api_get_users_avatar+"?username="+user.username

            return(
            
            <Grow
            in={checked}
            //style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 500*(index+1) } : {})}
          >
           <Box
              key={index}
              background="red"
              //component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "90%",
               borderRadius:'10px', backgroundColor: colors.dark_grey[100], height:"100px",
              marginTop:"20px" }}
              justifyContent="space-between" display="flex"
            >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <BadgeAvatars size="sm" alth={user.name} src={user.avatar} status={user.status} />
                  {/* <Avatar size="sm" alth={"SE"} src={Useravatar}/> */}
                  <div>
                    <Typography
                      fontWeight="lg"
                      level="body3"
                      textColor="text.primary"
                    >
                      Name: {user.name}
                    </Typography>
                    {/* <Typography level="body3">Username: {user.username}</Typography> */}
                  </div>
                </Box>

                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
                onClick={()=>(setTeamMembers([user, ...teammembers]))}
                >
                  <PersonAdd sx={{ fill: "white" }} />
                </IconButton>
              
            </Box>
            </Grow>
            )
            })}
            </Box>

            {members.length>0?<Divider/>:""}

            <Box height="100px" backgroundColor="red" >
               <Stack direction="row" spacing={2}>

    
            {teammembers.map((teammember, index)=>{
              const Useravatar = global.config.api_get_users_avatar+"?username="+teammember.username
              console.log("teammember", teammembers, teammember)
              return
              (
              <BadgeAvatars size="sm" alth={teammember.name} src={Useravatar} status={teammember.status} />
            )})}
            </Stack>
            </Box>
            






          {/*         <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label.name}</StepLabel>
           
            </Step>
          );
        })}
      </Stepper>
        
        {activeStep === 0?steps[0]["render"]:activeStep===1?steps[1]["render"]:steps[2]["render"]}
      
      {activeStep ===  steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>

        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Cancel</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Save</Typography>
          </Button>
        </DialogActions>

      </BootstrapDialog>
    </div>
  );
}