module.exports = global.config = {
    // orthanc: "http://localhost/orthanc/",
    // orthanc:  "https://yqf7asw2fm.eu-west-1.awsapprunner.com/" 
    homePagePath: "/demo",
    deploymentType: process.env.deploymentType || "onPrem",
    //websocketServerEndpointport: process.env.port || 8000,
    //websocketServerEndpoint : process.env.serverEndpoint || "localhost",
    // client_handler_Endpoint: process.env.client_handler_Endpoint || "localhost",
    // client_handler_Endpoint_port : process.env.client_handler_Endpoint_port || "5003",
    // client_handler_Endpoint_nginx : process.env.client_handler_Endpoint_nginx || "/client",
    auth_Endpoint : process.env.auth_Endpoint || "http://localhost",
    auth_Endpoint_port : process.env.auth_Endpoint_port || "5004",
    api_fileupload : process.env.api_fileupload || "api/api/fileupload",
    api_registerAi : process.env.api_registerAi || "api/ai/register",
    api_healthCheck_all: process.env.api_healthCheck || "api/healthcheck/all",
    api_healthCheck_backend: process.env.api_healthCheck || "api/healthcheck",
    //api_get_cases_by_date: process.env.api_fileupload || "api/db/getcases_by_date",
    api_register_Organisation: process.env.api_register_Organisation || "api/api/registerorganisation",
    api_get_Organisation: process.env.api_get_Organisation || "api/api/getorganisation",
    api_register_Team: process.env.api_register_Team || "api/api/registerteam",
    api_get_team: process.env.api_get_team || "api/api/getteammembers",
    api_get_team_member: "api/api/api_get_team_member",
    api_update_team_member_defaultworkspace: "api/api/api_update_team_member/defaultworkspace",
    api_get_case_status: "api/api/api_get_case_status",
    api_get_study_info: "api/api/getstudyinfo",
    api_send_comment: "api/api/comments/add",
    api_get_comment: "api/api/comments/get",
    api_send_pdf: "api/api/pdf/send",
    api_get_cases_by_date:"api/api/cases/get",
    api_register_new_user: process.env.api_register_new_user || "api/api/registerNewUser",
    api_delete_user: "api2/api/v1/users.delete",
    api_create_user:"api2/api/v1/users.create",
    api_login_user:"auth2/api/v1/login",
    api_logout_user:"auth2/api/v1/logout",
    api_search_users:"auth2/api/v1/users.list",
    api_get_users_avatar:"auth2/api/v1/users.getAvatar",
    api_rc_base:"auth2/",
    update_workspace_name:"api/api/workspace/setting/name",
    get_users_to_workspace:"api/api/Workspace/users/get",
    add_users_to_workspace:"api/api/Workspace/users/add",
    update_users_Status_workspace:"api/api/Workspace/users/update/status",
    update_user_in_workspace:"api/api/Workspace/users/update",
    delete_users_from_workspace:"api/api/Workspace/users/delete",
    get_users_notifications:"api/api/Workspace/users/get/notifications",
    update_users_notifications:"api/api/Workspace/users/update/notifications",
    api_findings_add:"api/api/findings/add",
    api_findings_delete:"api/api/findings/delete",
    api_report_update:"api/api/reports/add",
    // Anonymization
    api_anonymization_add:"api/api/studies/anonymize/add",
    api_anonymization_delete:"api/api/studies/anonymize/delete",
    api_anonymization_load:"api/api/studies/anonymize/load",

};
