import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Getnotifications from '../../../functions/api/notifications/getnotifications'
import { addNotification } from '../../../states/slices/notification/notificationSlice'
import UpdateNotificationsAPIs from '../../../functions/api/notifications/updateNotificationsAPI'

export default function UpdateNotifications(notificationsIDs){


    UpdateNotificationsAPIs(notificationsIDs)
    .then(response => response.text())
    .then(result => {
      const respond = JSON.parse(Object.assign(result))
      console.log("notifications", respond)
      if (respond["statusCode"]===200){
        console.log("updated notifications to read")
    }
  }).catch(error =>
    {
      //setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while getting your notifications.", severity: "warning" })
  }
  )
}
