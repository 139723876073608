import React, { useState, useEffect, useRef, useMemo } from "react";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { tokens, themeSettings } from "../../../../utilityComponents/theme";
import { Divider, Grid, Link, Tooltip } from "@mui/material";
import QRCodeGenerator from "./qrcodeGenerator";
import AddanonymizationAPI from "../../../../functions/api/anonymization/AddAnonymization";
import CircularIndeterminate from "../../LoadingSpinner/CircularIndeterminate";
import LoadingButton from '@mui/lab/LoadingButton';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { useSelector } from "react-redux";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DELETEanonymizationAPI from "../../../../functions/api/anonymization/deleteanonymization";
import DirectionSnackbar from "../../snackbars/snackbar";
//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    //backgroundColor: colors.dark_grey[700],

    '& .MuiDialog-container': {
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        //backgroundColor:"pink"
    },
    '& .MuiDialog-paper': {
        //backgroundColor: colors.dark_grey[400],
        width: "62.50%",
        height: "60.88%",
        borderRadius: "8px"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        color: "red"
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
    //backgroundColor: colors.dark_grey[400],
    color: colors.greenFlow[100],

    '&:hover': {
        backgroundColor: 'transparent',
        //color: '#3c52b2',
    },
}

// const useStyles = makeStyles({

//     saveBtnStyle: {
//         color: colors.text[500],
//         fontStyle: "SemiBold"
//     },
//     cancelBtnStyle: {
//         color: colors.text[600]
//     }

// })



function Anonymization({ Item, rcTocken }) {
    const [open, setOpen] = React.useState(false);
    const [apiData, setApiData] = useState(null);
    const [loadingData, setLoadingData] = React.useState(false);
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark");
    //const stylingClasses = useStyles()
    const ref = useRef();
    const user = useSelector((state) => state.user)
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        Message: "TEST ME",
        severity: "success"
    })
    const LoadAnonymizeData = useMemo(() => loadDataFnt(), [])

    function loadDataFnt() {
        if (Item["anonymization"] !== null) {
            console.log("Reading anonymized Data", Item);
            setApiData(Item["anonymization"][0]);
        } else {
            //console.log("The first element is null.");
        }
    }

    const handleCopyClick = async (textToCopy) => {
        //const textToCopy = "Hello, world!"; // Replace with the text you want to copy
        //console.log(textToCopy)
        try {
            await navigator.clipboard.writeText(textToCopy);
            setOpenSnackbar({
                ...openSnackbar, open: true,
                Message: "Web address copied to clipboard.", severity: "success"
            })
        } catch (err) {
            console.error("Failed to copy text: ", err);
            //alert("Copying to clipboard failed!");
            setOpenSnackbar({
                ...openSnackbar, open: true,
                Message: "Copying to clipboard failed!", severity: "error"
            })
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        //getCommentsFunction(Item)

    };

    function handleDelete() {

        setLoadingData(true)
        //console.log("anonymizefnt triggered")
        DELETEanonymizationAPI(Item, apiData["ID"])
            .then(response => response.text())
            .then(results => JSON.parse(results))
            .then(anonymizationResults => {
                //console.log("anonymizationResults", anonymizationResults)
                setApiData(null)
                setOpenSnackbar({
                    ...openSnackbar, open: true,
                    Message: "Anonymization and QRCODE deleted.", severity: "success"
                })
            })
            .then(() => setLoadingData(false))
            .catch((error) => {
                setLoadingData(false)
                setOpenSnackbar({
                    ...openSnackbar, open: true,
                    Message: "Failed to delete QRCODE. Please try again.", severity: "error"
                })
            })

    }
    function anonymizefnt() {
        //console.log(Item)
        setLoadingData(true)
        //console.log("anonymizefnt triggered")
        AddanonymizationAPI(Item, user)
        console.log("Item", Item)
            .then(response => response.text())
            .then(results => JSON.parse(results))
            .then(anonymizationResults => {
                //console.log("anonymizationResults", anonymizationResults)
                setOpenSnackbar({
                    ...openSnackbar, open: true,
                    Message: "Anonymization and QRCODE generated.", severity: "success"
                })
                setApiData(anonymizationResults["anonymization"])
            })
            .then(() => setLoadingData(false))
            .catch((error) => {
                setLoadingData(false)
                setOpenSnackbar({
                    ...openSnackbar, open: true,
                    Message: "Failed to generate QRCODE.", severity: "error"
                })
                //                window.alert(error)
            })
    }



    const handleSave = () => {
        //console.log("SAVE")
    }
    const handleCancel = () => {
        setOpen(false);
    };

    return (

        <span>

            <Tooltip title="External Consultation" >
                <IconButton aria-label="delete"
                    size="small"
                    onClick={handleClickOpen}>


                    {Item["anonymization"] ?
                        <PersonPinOutlinedIcon sx={{ color: colors.greenFlow[100] }} fontSize="inherit" /> :
                        <PersonPinOutlinedIcon fontSize="inherit" />
                    }



                </IconButton>
            </Tooltip>


            <BootstrapDialog
                //overlayStyle={{backgroundColor: 'red'}}
                onClose={handleCancel}
                aria-labelledby="customized-dialog-title"
                open={open}
            >


                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
                    <Typography sx={themeStyles.typography.h411}>
                        Anonymize and generate QRCode for:
                    </Typography>
                    <Typography sx={themeStyles.typography.h6}>
                        {Item["PatientName"] ? Item["PatientName"] : ""}, {Item["PatientAge"] ? Item["PatientAge"] : ""}
                    </Typography>


                </BootstrapDialogTitle>


                <DialogContent
                    sx={{ alignItems: "center", display: "flex" }}
                    dividers={true}
                >
                    <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })} />

                    <Box width={"100%"} height={"100%"} display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexDirection={"column"}
                    >

                        <LoadingButton
                            size="small"
                            onClick={anonymizefnt}
                            endIcon={<QrCode2Icon sx={{ color: colors.greenFlow[100] }} />}
                            loading={loadingData}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>
                                <Typography sx={[themeStyles.typography.h61, UploadBtnStyle]}>
                                    {loadingData ? "Anonymizing your study..." : "Anonymize and generate QRCode"}
                                </Typography>
                            </span>
                        </LoadingButton>


                        <Box width={"100%"} display={"flex"} height={"70%"}
                            //backgroundColor={"white"}
                            justifyContent={"space-around"} alignItems={"center"}>

                            {loadingData ? <CircularIndeterminate sx={{ color: "inherit" }} />
                                :
                                apiData && <>
                                    <Grid container spacing={2} justifyContent={"center"} display={"flex"}>
                                        <Grid item xs={12} md={6} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                            <QRCodeGenerator jsonData={apiData["ID"]} />
                                        </Grid>
                                        <Grid item xs={12} md={6} sx={{ justifyContent: "space-around", display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center", flexDirection: "column" }}>
                                                <Typography sx={[themeStyles.typography.h81]}>
                                                    Created By: {apiData["user"]["FullName"]}
                                                </Typography>

                                                <Typography sx={[themeStyles.typography.h81]}>
                                                    Valid Untill: {apiData["validity"]}
                                                </Typography>
                                            </Box>
                                            {/* <Button onClick={handleDelete}>
                                                <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Delete Link</Typography>
                                            </Button> */}
                                            <Button onClick={handleDelete}>
                                                <Typography sx={[themeStyles.typography.h6, { color: colors.text[600] }]}>Delete Link</Typography>
                                            </Button>






                                        </Grid>
                                        <Grid item xs={12} md={12} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                            <Typography sx={[themeStyles.typography.h81]} color="white" >
                                                Web Address: {apiData["webaddress"]}
                                                <IconButton
                                                    onClick={() => handleCopyClick(apiData["webaddress"])}
                                                >
                                                    <ContentCopyOutlinedIcon fontSize="small" />
                                                </IconButton>

                                            </Typography>

                                        </Grid>

                                    </Grid>


                                </>


                            }

                        </Box>


                        <Typography sx={themeStyles.typography.h71} id="note">
                            The current study will be anonymized for external consultation. The validity for the provided link is 24h.
                        </Typography>
                    </Box>

                    {/* <Divider />

                    <Box width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"end"}>
                        
                    </Box> */}





                </DialogContent>


                <DialogActions>
                    <Button onClick={handleCancel}>
                        <Typography sx={[themeStyles.typography.h6, { color: colors.text[600] }]}>Cancel</Typography>
                    </Button>

                    {/* <Button onClick={handleCancel}>
                        <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
                    </Button> */}
                    {/*                     <Button onClick={handleSave}>
                        <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Save</Typography>
                    </Button> */}
                </DialogActions>


            </BootstrapDialog>
        </span>
    );
}

export default Anonymization