export const STUDIES = [
    {
        studyName: 'MR',
        studyUid: '1.2.826.0.1.3680043.8.1055.1.20161012115812848.450575715.8637545',
        instances: [
            {
                name: 'MR 01',
                uid:  '1.2.826.0.1.3680043.8.1055.1.20161012115812871.587801656.5542600'
            },
            {
                name: 'MR 02',
                uid:  '1.2.826.0.1.3680043.8.1055.1.20161012115856640.846142357.4611469'
            },
            {
                name: 'MR 03',
                uid:  '1.2.826.0.1.3680043.8.1055.1.20161012120203263.230128495.4970509'
            }
        ]
    },
    {
        studyName: 'XA',
        studyUid: '1.2.826.0.1.3680043.8.1055.1.20200514151934839.648013404.6215014',
        instances: [
            {
                uid: '1.2.826.0.1.3680043.8.1055.1.20200514151934838.193046589.9731916'
            }
        ]
    },
    {
        studyName: 'CR',
        studyUid: '1.2.826.0.1.3680043.8.1055.1.20200514152440924.84843799.73760067',
        instances: [
            {
                uid: '1.2.826.0.1.3680043.8.1055.1.20200514152440923.77646889.35021132'
            }
        ]
    },
    {
        studyName: 'CT',
        studyUid: '1.2.840.113619.2.55.3.4271045733.996.1449464144.595',
        instances: [
            {
                uid: '1.2.840.113619.2.55.3.4271045733.996.1449464144.602.1'
            }
        ]
    },
];
