export function getMaxWidthAndHeight(points) {
    //console.log("getMaxWidthAndHeight",points)
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < points.length; i++) {
      //console.log("x,y,z",points[i])
      const { x, y, z } = points[i];
      //console.log("x,y,z",points[i][0],points[i][1],points[i][2])
      minX = Math.min(minX, points[i][0]);
      minY = Math.min(minY, points[i][1]);
      maxX = Math.max(maxX, points[i][0]);
      maxY = Math.max(maxY, points[i][1]);
    }

    const maxWidth = Math.round(maxX - minX);
    const maxHeight = Math.round(maxY - minY);

    //console.log("width:", maxWidth," height:", maxHeight)


    return { width: maxWidth, height: maxHeight };
  }