import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material'
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";


const MenuProps = {
    PaperProps: {
        style: {
            width: '10%',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);'
        },
    },
};

function CustomSelectorRole({ options,changeMember, member }) {
    const [anchorEl, setAnchorEl] = useState(null);
    //const [rightValue,setRightValue]=useState(false)

    const open = Boolean(anchorEl);
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")


/*     useEffect(() => {
      console.log("new RightValue",value, member)
    }, []) */
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (newValue)=>{
        
        let newSet = {
            "name":"role",
            "value":newValue
        }
        //setRightValue(false)
        changeMember("role",newSet, member["employeeid"])
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Box height="36px"
                //backgroundColor={ colors.dark_grey[500]}
                margin={"6px 6px 6px 6px"} width={'inherit'}
                justifyContent={"center"} display={"flex"} alignItems={"center"}
                onClick={handleClick}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    },
                    borderRadius: '2px'
                }}
            >
                <Typography
                    align="center"
                    sx={themeStyles.typography.h71}
                    color={colors.text[100]}>
                    {member["role"]}
                </Typography>
            </Box>

            <Menu

                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuProps={MenuProps}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    width: '150px'
                }}

            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                    >
                        <Box height="36px"
                            width="150px"
                            borderRadius={"2px"} alignItems={"center"} display="flex" justifyContent={"center"}
                            backgroundColor={colors.dark_grey[500]}
                            border='1px solid #4D4D4D'
                            onClick={()=>handleChange(option)}
                        >
                            <Typography
                                align="center"
                                sx={themeStyles.typography.h8}
                                color={colors.text[100]}
                            >
                                {option}
                            </Typography>

                        </Box>

                    </MenuItem>
                ))}

            </Menu>
        </>
    );
}

export default CustomSelectorRole