//import { configureStore, ThunkAction, Action ,getDefaultMiddleware } from "@reduxjs/toolkit";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
//import { getDefaultMiddleware } from '@reduxjs/toolkit';
import userReducer from '../slices/user/userSlice'
import notificationsReducer from '../slices/notification/notificationSlice'
import aiFindingsReducer from '../slices/aiFindings/aifindingsSlice'
import DefaultworkspaceReducer from "../slices/workspaces/workspacesSlice";
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from "@reduxjs/toolkit";
import {thunk} from 'redux-thunk';
import casesReducer from "../slices/cases/casesSlice";
import dateReducer from "../slices/date/dateSlice";
import loadingDataReducer from "../slices/loading/loadingSlice";
//import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import snackbarReducer from "../slices/snackbar/snackbar";

const persistConfig={
  key:"root",
 // version:1,
  storage,
}
const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
  whitelist: ['updatefindings'],
};
//const middlewares = [createStateSyncMiddleware(config)];

const reducer = combineReducers({
    user:userReducer,
    cases:casesReducer,
    snackbar:snackbarReducer,
    notifications:notificationsReducer,
    Defaultworkspace:DefaultworkspaceReducer,
    date:dateReducer,
    loadingData:loadingDataReducer,
    aifindings: aiFindingsReducer,  
})

const persistedReducer = persistReducer(persistConfig, reducer)

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })

 export const store=  configureStore({
  reducer: persistedReducer,
//  middleware: [thunk]
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customizedMiddleware),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false, // Optional: Disable serializability check if needed (use with caution)
  }).concat(thunk), 
  
  //middleware: [createStateSyncMiddleware(config)]
  // middleware: [
  //   createStateSyncMiddleware({
  //     blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
  //   }),
  // ],
  });

  //initMessageListener(store);
  export const persistor = persistStore(store)
