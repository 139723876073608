import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Dialog, DialogContent } from '@mui/material';

function ThreeDObject({ open, handleCloseDialog }) {
  const canvasRef = useRef(null);
  const scene = useRef(new THREE.Scene());
  const camera = useRef(new THREE.PerspectiveCamera(75, 1, 0.1, 1000));
  const renderer = useRef();

  useEffect(() => {
    if (canvasRef.current) {
      // Initialize renderer after canvas has been rendered
      renderer.current = new THREE.WebGLRenderer({ canvas: canvasRef.current });
      renderer.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);

      // Set camera aspect ratio and position
      camera.current.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      camera.current.position.z = 5;
      camera.current.updateProjectionMatrix();

      // Create a grid helper for reference
      // (Optional, for visualization)
      const gridHelper = new THREE.GridHelper(5, 5);
      scene.current.add(gridHelper);

      // Generate dummy polypoints for a basic irregular shape with different z values for each layer
      const layers = 5; // Adjust as needed
      const pointsPerLayer = 5; // Adjust as needed
      const polypoints = [];
      for (let layer = 0; layer < layers; layer++) {
        for (let i = 0; i < pointsPerLayer; i++) {
          const x = Math.random() * 2 - 1; // Random x coordinate between -1 and 1
          const y = Math.random() * 2 - 1; // Random y coordinate between -1 and 1
          const z = layer * 2 - 4; // Adjust the factor (-4) to spread layers along the z-axis
          polypoints.push({ x, y, z });
        }
      }

      // Create a buffer geometry
      const pointsGeometry = new THREE.BufferGeometry();
      const positions = new Float32Array(polypoints.length * 3); // Each point has x, y, z coordinates
      for (let i = 0; i < polypoints.length; i++) {
        positions[i * 3] = polypoints[i].x;
        positions[i * 3 + 1] = polypoints[i].y;
        positions[i * 3 + 2] = polypoints[i].z;
      }
      pointsGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

      // Create points material
      const pointsMaterial = new THREE.PointsMaterial({ color: 0x00ff00, size: 0.1 });

      // Create points mesh
      const pointsMesh = new THREE.Points(pointsGeometry, pointsMaterial);
      scene.current.add(pointsMesh);

      const animate = () => {
        renderer.current.render(scene.current, camera.current);
        requestAnimationFrame(animate);
      };

      animate();

      // Cleanup on unmount
      return () => {
        window.cancelAnimationFrame(animate);
      };
    }
  }, [open]); // Dependency on open prop for re-rendering

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogContent>
        <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      </DialogContent>
    </Dialog>
  );
}

export default ThreeDObject;
