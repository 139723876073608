import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import {Backarrow, ReportIcon,Test, Rotate, Fullscreen, Zoom, Unknown4, Unknown5, ROI, Unknown7}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
  import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { ContentCopy, ContentCopyOutlined, ContentCut } from '@mui/icons-material';

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  const MenuListImageTools=[
    {
        icon: <ContentCut fontSize="small" />,
        name:"Cut",        
        shortcut:"⌘X",
        functionname:props.customFunction
    },
    {
        icon: <ContentCopyOutlined fontSize="small" />,
        name:"COPY",        
        shortcut:"⌘Y",
        functionname:props.customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    }
]
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <Fullscreen />
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "150px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
        
      </HoverMenu>
    </div>
  );
}