//React components
import React, { useState, useEffect, useRef } from "react";

//MUI Components
import { Box, Divider, IconButton, Table, TableContainer, Toolbar } from "@mui/material";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from "@mui/material/styles";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';


// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

// import data and charts
import { pieData } from './demodata/pieData'
import { PieChart } from './charts/pieChart'
import BarChartKPI from "./charts/BarChart";
import FourCards from "../../subComponents/4cards";
import BasicLineChart from "../../subComponents/charts/line";
import { StyledLabel } from "../../subComponents/forms/formcomponenets";

//
//
// Components STYLINGs START
//
//
//
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    margin: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,

    }),
  })
);


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     height: '200px',
//     padding: theme.spacing(2),
//     textAlign: 'center', // Center text within the Paper component
//   },
//   label: {
//     textAlign: 'center', // Align text to the left
//   },
//   UploadBtnStyle: {
//     backgroundColor: colors.dark_grey[400],
//     borderColor: 'transparent',
//     color: colors.greenFlow[100],
//     "border-radius": "5px",
//     '&:hover': {
//       backgroundColor: 'transparent',
//       //color: '#3c52b2',
//     },
//   },
//   CustomOverlayStyle: {
//     color: colors.dark_grey[200],
//     marginTop: "300"
//   }
// }));

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   height: '200px',
//   padding: theme.spacing(2),
//   textAlign: 'center',
// }));

// const StyledLabel = styled(Typography)(({ theme }) => ({
//   textAlign: 'center',
// }));

// const StyledUploadBtn = styled('button')(({ theme, colors }) => ({
//   backgroundColor: colors.dark_grey[400],
//   borderColor: 'transparent',
//   color: colors.greenFlow[100],
//   borderRadius: '5px',
//   '&:hover': {
//     backgroundColor: 'transparent',
//     //color: '#3c52b2',
//   },
// }));

// const StyledCustomOverlay = styled('div')(({ theme, colors }) => ({
//   color: colors.dark_grey[200],
//   marginTop: "300px",
// }));



//
//
// Components STYLINGs END
//
//
//


export const KPIsAI = () => {
  //Styles 
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles()
  const webRef = useRef(null);
  const ref = React.useRef();
  const user = JSON.parse(localStorage.getItem("user"))
  const token = sessionStorage.getItem("Meteor.loginToken")
  const cardscolors = ['#FFC107', '#03A9F4', '#4CAF50', '#FF5722'];
  //const classes = useStyles();
  
  
  // Example figures
  // Model Performance Metrics Section
  const modelPerformanceLabels = ['Accuracy', 'Precision', 'Recall', 'F1 Score'];
  const modelPerformanceValues = ["90%", "85%", "80%", "82.5%"]; // Example values

  // Utilization and Adoption Rates Section
  const utilizationLabels = ['Active Users', 'Adoption Rate', 'Patient Volume', 'Subscription Retention'];
  const utilizationValues = [50, "70%", 500, '78%']; // Example values

  // Model Downtime and Reliability Section
  const downtimeLabels = ['Downtime Duration', 'MTBF', 'MTTR', 'Availability Percentage'];
  const downtimeValues = ['10 hours per month', 200, 1, '98%']; // Example values

  return (

    <Box
      //sx={boxstyles} 
      id={"ChatBox"}
      sx={{ display: "flex" }}
    >
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main styled={{ backgroundColor: "inherit" }}
        id={"MainContentChat"}>
        <Toolbar variant="dense" />
        <Box backgroundColor="inherit"
          id="box after main"
        //sx={{ margin: "-24px -24px -24px 24px" }}
        >
          {/*     //
          //
          // START ADDING ALL PAGE CONTENTS HERE
          //
          // 
  */}

          <Box backgroundColor="inherit"
            //justifyContent={"space-between"} display="flex" alignItems={"center"}
            sx={{ margin: "5.5% 24px 4% 24px" }}
          >
            <Typography
              align="center"
              //onClick={()=>setChecked(true)}
              color={colors.text[500]}
              sx={themeStyles.typography.h21}
              >
              AI-Business Intelligence & QA Dashboard
            </Typography>
          </Box>

          <Grid container spacing={3} display={"flex"}
            //margin={"20px 0px 0px 20px"}
            alignContent={"center"} alignItems={"center"}
            
            >

            {/* Model Performance Metrics Section */}
            <Typography variant="h4" 
            align={"center"}

            >Model Performance Metrics</Typography>
            <FourCards
              labels={modelPerformanceLabels}
              values={modelPerformanceValues}
              colors={['#FF9800', '#FFC107', '#FFEB3B', '#FF9800']}
            />

            {/* Utilization and Adoption Rates Section */}
            <Typography variant="h4" gutterBottom>Utilization and Adoption Rates</Typography>
            <FourCards
              labels={utilizationLabels}
              values={utilizationValues}
              colors={['#2196F3', '#03A9F4', '#00BCD4', '#2196F3']}
            />

            {/* Model Downtime and Reliability Section */}
            <Typography variant="h4" gutterBottom>Model Downtime and Reliability</Typography>
            <FourCards
              labels={downtimeLabels}
              values={downtimeValues}
              colors={['#4CAF50', '#8BC34A', '#CDDC39', '#4CAF50']}
            />

            {/* <Grid item xs="12" xl="6" justifyContent={"center"}
            >
              <Typography variant="h5" color="secondary" className={classes.label}>Detailed summary for Cases</Typography>

              <Box height="500px">

                <PieChart data={pieData} />
              </Box>
            </Grid>
            <Grid item xs="12" xl="6">
              <Typography variant="h5" color="secondary" className={classes.label}>Average Time per Casesc Break-down</Typography>

              <Box height="500px">
                <BarChartKPI />
              </Box>
            </Grid> */}

            <Grid item xs="12" xl="12" marginTop={"30px"}>
              <StyledLabel variant="h4" color="secondary" 
              //className={classes.label}
              >Accumulated number of Cases [K]</StyledLabel>

              <Box height="500px">
                <BasicLineChart />
              </Box>
            </Grid>

            
          </Grid>


        




          {/*     //
          //
          // STOP PAGE CONTENTS HERE
          //
          // 
  */}
        </Box>
      </Main >
    </Box >


  )
};