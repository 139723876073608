import React, { useEffect } from "react";
//import { makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { TextField, IconButton,Button,Tooltip, Typography } from '@mui/material'
import { SendCommentAPI } from "../../../functions/api/messages/sendComment";
import SendIcon from '@mui/icons-material/Send';
import { GetCommentAPI } from "../../../functions/api/messages/getComment";



// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(),
//       width: "100%"
//     },
//     "& .MuiOutlinedInput-root": {
//       //position: "relative"
//     },
//     "& .MuiIconButton-root": {
//       position: "absolute",
//       top: 0,
//       right: 0
//     }
//   }
// }));

export default function SendComment({Item, getCommentsFunction}) {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [sendingData, setSendingData] = React.useState(false);


   

  const handleChange = (event) => {
    console.log(event.target.value)
    setValue(event.target.value);
  };

  const sendComment=()=>{
    
    if (value.length>0){
      setSendingData(true)
      console.log("value",value)
      SendCommentAPI(value, Item)
      .then((response)=>{
        if (response.status===200){
          setValue('');
          setSendingData(false)
          getCommentsFunction(Item)
        }
      })
      
    }
  }
  

/*   const getCommentsFunction = () => {
    GetCommentAPI(Item)
    .then(response=>response.text())
    .then(results=> JSON.parse(results))
    .then(comments =>{
      const commentsArray = comments["body"][0]["comments_array"]
      console.log("comments back",commentsArray)
    })
  } */


  return (
 
        <><TextField
      sx={{ width: "100%" }}
      fullWidth
      id="outlined-multiline-static"
      placeholder="Add your message"
      multiline
      rows={4}
      value={value}
      //defaultValue="Default Value"
      variant="outlined"
      onChange={(newValue) => {setValue(newValue.target.value)}}
      />
      <LoadingButton sx={{ height: "auto",
       borderColor: colors.greenFlow[100],
      marginLeft:"10px",
      ":hover":{
        borderColor: colors.greenFlow[100]
      }
    }} 
      loading={sendingData}
      endIcon={<SendIcon sx={{color:colors.greenFlow[100]}}/>}
      onClick={()=>{sendComment()}}
       variant="outlined" 
       disabled={value.length===0}
      >
        <Typography
          //align="right"
          sx={themeStyles.typography.h60}
          color={colors.greenFlow[100]}>
          Send
        </Typography>
        {/* <FlashOnIcon /> */}
      </LoadingButton></>

  );
}