import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loadingData:false
}

export const loadingDataSlice = createSlice({
  name: 'loadingData',
  initialState,
  reducers: {
    updateloadingData:(state, action)=>{
      console.log("loadingData.action.payload", action)
      state.loadingData=action.payload.loadingData
    },
  },
})

export const updateloadingData = (state)=> state.loadingData
// Action creators are generated for each case reducer function
const loadingDataReducer =  loadingDataSlice.reducer;
export default loadingDataReducer;
