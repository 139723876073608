import React from 'react';
import PropTypes from 'prop-types';

export default class StudiesMenu extends React.Component {
    loadExampleStudy () {
        const study = '1.2.826.0.1.3680043.9.3218.1.1.3822746.1025.1511356299887.1351.0';
        global.viewportsCore.loadStudyWithHIS(study, 'study').then((studyData) => {
            this.openExampleStudyToViewport(studyData).then(() => {
                this.createExampleMGLesion();
            })
        });
    }

    openExampleStudyToViewport (studyData) {
        const instanceUid = studyData.series[0].instances[0].uid;
        return global.viewportsCore.layoutController.openInstanceToViewportContainer('viewport-container-1-1', instanceUid);
    }

    createExampleMGLesion () {
        const findings = {
            mamilla: {
                point: {x: 1529, y: 1823},
                radius: 40,
                isEditable: true,
                isVisible: true,
                style: {
                    color: '#E72828',
                    lineWidth: 2
                }
            },
            muscleLine: {
                startPoint: {x: 2043, y: 820},
                endPoint: {x: 2393, y: 1356},
                isEditable: true,
                isVisible: true,
                style: {
                    color: '#E72828',
                    lineWidth: 2,
                    dashedLine: false
                }
            },
            regionLines: [
                {
                    uid: 'region-line-1',
                    startPoint: {x: 1476, y: 1097},
                    middlePoint: {x: 2149, y: 1601},
                    endPoint: {x: 2021, y: 2242},
                    isEditable: true,
                    isVisible: true,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 2,
                        dashedLine: false
                    }
                },
                {
                    uid: 'region-line-2',
                    startPoint: {x: 1528, y: 921},
                    middlePoint: {x: 2294, y: 1564},
                    endPoint: {x: 2230, y: 2279},
                    isEditable: true,
                    isVisible: true,
                    style: {
                        color: '#0D88B5',
                        lineWidth: 2,
                        dashedLine: false
                    }
                }
            ],
            lesions: [
                {
                    uid: 'lesion-1',
                    type: 'ellipse-lesion',
                    point: {x: 1857, y: 896},
                    xRadius: 135,
                    yRadius: 75,
                    rotationAngle: 25,
                    isEditable: true,
                    isVisible: true,
                    style: {
                        color: '#0E5715',
                        fillColor: '#0E5715',
                        fillOpacity: 0.2,
                        dashedLine: true
                    }
                },
                {
                    uid: 'lesion-2',
                    type: 'polyline-lesion',
                    isEditable: true,
                    isVisible: true,
                    points: [
                        {x: 1624, y: 1026},
                        {x: 1722, y: 969},
                        {x: 1815, y: 1033},
                        {x: 1752, y: 1107},
                        {x: 1707, y: 1087},
                        {x: 1663, y: 1106}
                    ],
                    style: {
                        color: '#C6C413',
                        fillColor: '#C6C413',
                        fillOpacity: 0.2
                    }
                }
            ]
        };
        global.viewportsCore.mgLesionsController.createMGLesion('viewport-container-1-1', findings)
    }

    getStudiesData () {
        return [
            {
                studyName: 'MG',
                studyUid: '2.20210425113900508950.10000004.53964076754256015566140462938777',
                instances: [
                    {
                        name: 'R CC 1-1',
                        containerId: 'viewport-container-1-1',
                        uid: "2.20210425113900508950.10000041.17727602303783820631143295068324"
                    },
                    {
                        name: 'L CC 1-2',
                        containerId: 'viewport-container-1-2',
                        uid:  '2.20210425113900508950.10000055.12809542510759087660631196894629',
                        findings: {
                            //Added because required
                            mamilla: {
                                point: {x: 1529, y: 1823},
                                radius: 40,
                                isEditable: true,
                                isVisible: true,
                                style: {
                                    color: '#E72828',
                                    lineWidth: 2
                                }
                            },
                            muscleLine: {
                                startPoint: {x: 2043, y: 820},
                                endPoint: {x: 2393, y: 1356},
                                isEditable: true,
                                isVisible: true,
                                style: {
                                    color: '#E72828',
                                    lineWidth: 2,
                                    dashedLine: false
                                }
                            },
                            regionLines: [],
                            //-------

                            lesions: [
                                {
                                    uid: 'lesion-2',
                                    type: 'polyline-lesion',
                                    isEditable: false,
                                    isVisible: true,
                                    points: [
                                        {x: 231, y: 1704},
                                        {x: 337, y: 1704},
                                        {x: 337, y: 1790},
                                        {x: 231, y: 1790},
                                    ],
                                    style: {
                                        color: '#C6C413',
                                        fillColor: '#0E5715',
                                        fillOpacity: 0.2,
                                    }
                                },
                            ]
                        }
                    },
                    {
                        name: 'R MLO 2-1',
                        containerId: 'viewport-container-2-1',
                        uid:  '1.2.826.0.1.3680043.8.1055.1.2019120217818560.284166762.95463222'
                    },
                    {
                        name: 'L MLO 2-2',
                        containerId: 'viewport-container-2-2',
                        uid:  '2.20210425113900508950.10000049.83065129183026905877970512963162',
                        findings: {
                            mamilla: {
                                point: {x: 2000, y: 2500},
                                radius: 40,
                                isEditable: false,
                                isVisible: false,
                                style: {
                                    color: "#E72828",
                                    lineWidth: 2,
                                },
                            },
                            //Added because required
                            muscleLine: {
                                startPoint: {x: 2043, y: 820},
                                endPoint: {x: 2393, y: 1356},
                                isEditable: true,
                                isVisible: true,
                                style: {
                                    color: '#E72828',
                                    lineWidth: 2,
                                    dashedLine: false
                                }
                            },
                            regionLines: [],
                            //-------

                            lesions: [
                                {
                                    uid: 'lesion3',
                                    type: 'polyline-lesion',
                                    points: [
                                        {x: 281, y: 2205},
                                        {x: 385, y: 2205},
                                        {x: 385, y: 2292},
                                        {x: 281, y: 2292},
                                    ],
                                    isEditable: false,
                                    isVisible: true,
                                    style: {
                                        color: '#C6C413',
                                        fillColor: '#0E5715',
                                        fillOpacity: 0.2,
                                    }
                                },
                            ]
                        }
                    }
                ]
            }
        ];
    }

    loadCustomStudies () {
        const studiesData = this.getStudiesData();
        const studiesToLoad = [
            {
                study: studiesData[0].studyUid,
                callback: () => {
                    const instance = studiesData[0].instances[0];
                    this.openInstanceAndCreateMGLesion(instance);
                }
            },
            {
                study: studiesData[0].studyUid,
                callback: () => {
                    const instance = studiesData[0].instances[1];
                    this.openInstanceAndCreateMGLesion(instance);
                }
            },
            {
                study: studiesData[0].studyUid,
                callback: () => {
                    const instance = studiesData[0].instances[2];
                    this.openInstanceAndCreateMGLesion(instance);
                }
            },
            {
                study: studiesData[0].studyUid,
                callback: () => {
                    const instance = studiesData[0].instances[3];
                    this.openInstanceAndCreateMGLesion(instance);
                }
            }
        ];
        global.viewportsCore.loadStudiesWithHIS(studiesToLoad);
    }

    loadCustomStudiesFixed () {
        const studiesData = this.getStudiesData();
        const studiesToLoad = studiesData.map((studyData) => ({
            study: studyData.studyUid,
            callback: () => {
                studiesData[0].instances.forEach((instance) => this.openInstanceAndCreateMGLesion(instance));
            }
        }));
        console.log(studiesToLoad);
        global.viewportsCore.loadStudiesWithHIS(studiesToLoad);
    }

    openInstanceAndCreateMGLesion (instance) {
        global.viewportsCore.layoutController.openInstanceToViewportContainer(instance.containerId, instance.uid).then(() => {
            if (instance.findings) {
                global.viewportsCore.mgLesionsController.createMGLesion(instance.containerId, instance.findings);
            }
        });
    }

    render () {
        return (
            <div className="top-menu">
                <button onClick={() => this.loadExampleStudy()}>Load MG example study</button>
                <button onClick={() => this.loadCustomStudies()}>Load custom studies</button>
                <button onClick={() => this.loadCustomStudiesFixed()}>Load custom studies fixed</button>
            </div>
        )
    }
}

StudiesMenu.propTypes = {
    activeContainerId: PropTypes.string.isRequired,
    layoutColumns: PropTypes.number.isRequired,
    onLayoutColumnsChanged: PropTypes.func.isRequired
}
