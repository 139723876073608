import React, { useState,useEffect, useMemo } from 'react'
//import { convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw , ContentState} from 'draft-js';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from 'react-redux';
import { updateReportState,updateReportRaw } from '../../../../states/slices/cases/casesSlice';

export default function TextEditor({currentStudy}) {
    const dispatch = useDispatch()  
    //let editorState = EditorState.createEmpty()
    const [reportBody, setReportBody] = useState()
    //const [reportHTML, setReportHTML] = useState('<p>Write your main <strong>Findings</strong> here</p>')
    let typingTimer;
    const delay = 3000; // Adjust this value to set the delay in milliseconds (1 second in this example)

    let reportBodyState = useSelector((state)=>state.cases.report.state)
    const GetReportBody = useMemo(() => ConvertStateToBody(), [])
    
    
    function ConvertStateToBody(){

      console.log("Initial reportBodyState ",reportBodyState)
      
      const contentBlock = htmlToDraft(reportBodyState);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setReportBody(EditorState.createWithContent(contentState));
      }
    }
    
    const handleInput = (editorState) => {
      // Clear the previous timer
      clearTimeout(typingTimer);
    
      // Start a new timer to update the state after the specified delay
      typingTimer = setTimeout(() => {
        // Code to update the state goes here
        dispatch(updateReportState(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
        dispatch(updateReportRaw(editorState.getCurrentContent().getPlainText()))
       // console.log('Updating state with: ', editorState.getCurrentContent().getPlainText());

      }, delay);
    };

      

    const onEditorStateChange = (editorState)=>{
        setReportBody(editorState)
        console.log("editorState", editorState)
        //console.log("editorState draftToHtml", editorState.getCurrentContent().getPlainText())
        //console.log("editorState draftToHtml2", draftToHtml(convertToRaw(editorState.getCurrentContent())))
        handleInput(editorState)
    }



  return (
    <Editor
      editorState={reportBody}
      //contentState={contentState}
      // toolbarClassName="toolbarClassName"
      // wrapperClassName="wrapperClassName"
      // editorClassName="editorClassName"
      //onContentStateChange={onContentStateChange}
      onEditorStateChange={onEditorStateChange} 
      toolbar={{
        options: ['inline', 'fontSize', 'colorPicker','list','link', 'textAlign', 'history'],
        inline: { inDropdown: false,options: ['bold', 'italic', 'underline'] },
        textAlign: { inDropdown: false },
        fontSize: { inDropdown: false },
        colorPicker: { inDropdown: true },
        list: { inDropdown: false },
        link: { inDropdown: true },
        history: { inDropdown: true },
    }}
      />

  )
}

 