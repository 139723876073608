export default function Logo() {
    return (
<svg width="62" height="26" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.92793 5.70492C9.92793 4.48087 9.597 3.5847 8.93514 3.01639C8.29396 2.44809 7.42527 2.16393 6.32906 2.16393C5.50173 2.16393 4.76748 2.27322 4.1263 2.4918C3.48512 2.71038 2.93701 2.96175 2.48198 3.2459L2.48198 15.5082C2.37857 15.5519 2.22344 15.5956 2.01661 15.6393C1.80978 15.7049 1.58227 15.7377 1.33407 15.7377C0.44469 15.7377 5.86405e-10 15.3443 5.56333e-10 14.5574L1.29059e-10 3.37705C1.11517e-10 2.91803 0.0827325 2.54645 0.248198 2.2623C0.434347 1.95628 0.744595 1.6612 1.17894 1.37705C1.71671 1.02732 2.43027 0.710383 3.31965 0.42623C4.20903 0.142077 5.21216 1.99358e-10 6.32906 2.42077e-10C8.21123 3.14067e-10 9.69008 0.448088 10.7656 1.34426C11.8618 2.24044 12.4099 3.65027 12.4099 5.57377L12.4099 15.5082C12.3065 15.5519 12.141 15.5956 11.9135 15.6393C11.7067 15.7049 11.4895 15.7377 11.262 15.7377C10.3726 15.7377 9.92793 15.3443 9.92793 14.5574L9.92793 5.70492Z" fill="url(#paint0_linear_621_2872)"/>
<path d="M30.3672 8C30.3672 9.22404 30.2017 10.3279 29.8708 11.3115C29.5399 12.2951 29.0642 13.1366 28.4437 13.8361C27.8439 14.5355 27.1096 15.071 26.2409 15.4426C25.3722 15.8142 24.4001 16 23.3246 16C22.2491 16 21.2769 15.8142 20.4083 15.4426C19.5396 15.071 18.795 14.5355 18.1745 13.8361C17.554 13.1366 17.0783 12.2951 16.7473 11.3115C16.4164 10.3279 16.2509 9.22404 16.2509 8C16.2509 6.77596 16.4164 5.67213 16.7473 4.68853C17.0989 3.70492 17.585 2.86339 18.2055 2.16393C18.826 1.46448 19.5706 0.928962 20.4393 0.557377C21.308 0.185792 22.2697 8.51785e-10 23.3246 8.92131e-10C24.3794 9.32477e-10 25.3412 0.185792 26.2099 0.557377C27.0786 0.928962 27.8128 1.46448 28.4127 2.16393C29.0331 2.86339 29.5089 3.70492 29.8398 4.68853C30.1914 5.67213 30.3672 6.77596 30.3672 8ZM23.3246 2.16393C21.9181 2.16393 20.8116 2.6776 20.0049 3.70492C19.1983 4.73224 18.795 6.16394 18.795 8C18.795 9.85792 19.1879 11.3005 19.9739 12.3279C20.7806 13.3333 21.8974 13.8361 23.3246 13.8361C24.7517 13.8361 25.8583 13.3224 26.6442 12.2951C27.4302 11.2678 27.8232 9.83607 27.8232 8C27.8232 6.16394 27.4199 4.73224 26.6132 3.70492C25.8273 2.6776 24.731 2.16393 23.3246 2.16393Z" fill="url(#paint1_linear_621_2872)"/>
<path d="M44.5468 0.131148C44.8984 0.131148 45.1776 0.229509 45.3844 0.42623C45.6119 0.622951 45.7257 0.907104 45.7257 1.27869C45.7257 1.51913 45.643 1.94536 45.4775 2.55738C45.312 3.14754 45.0949 3.85792 44.826 4.68853C44.5571 5.49727 44.2468 6.39344 43.8952 7.37705C43.5643 8.36066 43.2023 9.34426 42.8094 10.3279C42.4371 11.2896 42.0544 12.2186 41.6614 13.1148C41.2892 14.0109 40.9375 14.7869 40.6066 15.4426C40.5032 15.5301 40.317 15.6066 40.0482 15.6721C39.8 15.7377 39.5311 15.7705 39.2415 15.7705C38.4762 15.7705 37.9798 15.541 37.7523 15.082C37.5662 14.7104 37.3283 14.1967 37.0388 13.541C36.7492 12.8852 36.4389 12.1421 36.108 11.3115C35.7771 10.4809 35.4358 9.59563 35.0842 8.65574C34.7326 7.71585 34.3913 6.78689 34.0604 5.86885C33.7294 4.92896 33.4192 4.03279 33.1296 3.18033C32.8401 2.30601 32.6022 1.53006 32.4161 0.852459C32.5608 0.63388 32.7573 0.459016 33.0055 0.327869C33.2537 0.196721 33.5019 0.131148 33.7501 0.131148C34.1224 0.131148 34.4016 0.229509 34.5878 0.42623C34.7946 0.622951 34.9808 0.95082 35.1462 1.40984L37.0698 7.27869C37.1939 7.65027 37.349 8.10929 37.5352 8.65574C37.7213 9.18033 37.9075 9.72678 38.0936 10.2951C38.3004 10.8415 38.4969 11.3771 38.6831 11.9016C38.8899 12.4262 39.0554 12.8743 39.1795 13.2459L39.3036 13.2459C40.1309 11.0383 40.8962 8.86339 41.5994 6.72131C42.3233 4.57923 42.9542 2.46994 43.4919 0.393442C43.7608 0.218579 44.1124 0.131148 44.5468 0.131148Z" fill="url(#paint2_linear_621_2872)"/>
<path d="M49.1731 0.491804C49.2765 0.448088 49.4316 0.404372 49.6385 0.360656C49.866 0.295083 50.1038 0.262296 50.352 0.262296C51.2207 0.262296 51.6551 0.666667 51.6551 1.47541L51.6551 9.90164C51.6551 11.3224 52.0067 12.3388 52.7099 12.9508C53.4131 13.541 54.3853 13.8361 55.6262 13.8361C56.4536 13.8361 57.1568 13.7486 57.7359 13.5738C58.3151 13.3771 58.7701 13.1803 59.101 12.9836L59.101 0.491804C59.2044 0.448088 59.3596 0.404372 59.5664 0.360656C59.7939 0.295083 60.0214 0.262296 60.2489 0.262296C61.1383 0.262296 61.583 0.666667 61.583 1.47541L61.583 12.8852C61.583 13.3443 61.521 13.7158 61.3969 14C61.2934 14.2623 61.0246 14.5246 60.5902 14.7869C60.1145 15.071 59.4526 15.3443 58.6046 15.6066C57.7773 15.8689 56.7742 16 55.5952 16C53.5269 16 51.9343 15.5191 50.8174 14.5574C49.7212 13.5956 49.1731 12.0874 49.1731 10.0328L49.1731 0.491804Z" fill="url(#paint3_linear_621_2872)"/>
<defs>
<linearGradient id="paint0_linear_621_2872" x1="-6.64621" y1="8" x2="67.7334" y2="8" gradientUnits="userSpaceOnUse">
<stop stopColor="#3A9DF2"/>
<stop offset="0.619792" stopColor="#17DFE9"/>
</linearGradient>
<linearGradient id="paint1_linear_621_2872" x1="-6.64621" y1="8" x2="67.7334" y2="8" gradientUnits="userSpaceOnUse">
<stop stopColor="#3A9DF2"/>
<stop offset="0.619792" stopColor="#17DFE9"/>
</linearGradient>
<linearGradient id="paint2_linear_621_2872" x1="-6.64621" y1="8" x2="67.7334" y2="8" gradientUnits="userSpaceOnUse">
<stop stopColor="#3A9DF2"/>
<stop offset="0.619792" stopColor="#17DFE9"/>
</linearGradient>
<linearGradient id="paint3_linear_621_2872" x1="-6.64621" y1="8" x2="67.7334" y2="8" gradientUnits="userSpaceOnUse">
<stop stopColor="#3A9DF2"/>
<stop offset="0.619792" stopColor="#17DFE9"/>
</linearGradient>
</defs>
</svg>
)
}
