import React from 'react'

export function RegisterNewOrganisationAPI(values) {
console.log("FROM API", values)


var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({ values});
console.log("FROM API", raw)

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(global.config.api_register_Organisation, requestOptions)

}

export function GetOrganisationAPI(values) {
  console.log("FROM API", values)
  
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({ values});
  console.log("FROM API", raw)
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  return fetch(global.config.api_get_Organisation, requestOptions)
  
  }
  

