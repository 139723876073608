//React Components
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../../subComponents/context/SocketContext";

// MUI Components
import { Box, IconButton, useTheme } from "@mui/material";
//import { makeStyles } from '@mui/styles';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DirectionSnackbar from "../../subComponents/snackbars/snackbar";

// Custom components
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import  Logo from '../../../utilityComponents/images/novu.js';
import LetterAvatars from './LetterAvatars'
import Avatar from '@mui/material/Avatar';
import AccountMenu from './subMenu'
// Custom functions
//import {signOUT} from '../../../functions/userState';
//import { signOut  } from "supertokens-web-js/recipe/emailpassword";
//import Session from "supertokens-web-js/recipe/session";

import { Globallogout } from "../../../App";

import {useSelector, useDispatch} from 'react-redux';
import { userDataSlice } from "../../../states/slices/user/userSlice";
import { update, logIn,logOut } from "../../../states/slices/user/userSlice";
import { useAuth } from "../../subComponents/auth/auth";
import StackTeamMembersAvatar from "../../subComponents/name2avatar/stackAvatar";
import NotificationsMenu from "./notifications";
import WorkspacesMenu from "./workspaces";
import { HandleGetCasesByDate } from "../functions/handleGetCasesByDate";
import UpdateNotifications from "../functions/updateNotifications";
import { GetNotifications } from "../functions/getNotification";
import InstallPWA from "../../subComponents/PWA/installPWA";
;


const colors = tokens("dark");

// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//   },
//   title: {
//     flexGrow: 1,
//   },
//   logout:
//   {
//     color:colors.text[500]
//   }

// });

const Avatarstyles = {
  textcolor:{
    color: colors.dark_grey[500]
  },
  avatarStyles:{
    width: 24, 
    height: 24,
    backgroundColor:colors.greenFlow[100]
  }
}

const Topbar = ({customizedMobileDevice}) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { onLogout} = useAuth();
  let navigate = useNavigate();
  //const stylingClasses = useStyles()
  const colors = tokens("dark");
  const [newNotiffications,setNewNotifications]=useState(true)
  const colorMode = useContext(ColorModeContext);
  const iconColor= colors.text[500]
  const AppBarBgColor = colors.dark_grey[600]
  const themeStyles = themeSettings("dark")
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    Message: "TEST ME",
    severity: "success"
  })

  const socket = useContext(SocketContext);
  //const FirstName = useSelector(state=>state.user.FirstName)
  //const user = ()=>{ JSON.parse(localStorage.getItem("user"))}
  const user = useSelector((state)=>state.user)
  const Defaultworkspace = useSelector((state) => state.Defaultworkspace);
  const notificationRedux = useSelector((state)=>state.notifications)
  const workspace=useSelector((state)=>state.Defaultworkspace)
  const selectedDate= useSelector((state)=>state.date)
  const workspaces = user["userWorkspaces"]

  let  FullName = user.userFirstname +' ' + user.userLastname


  
async function handleSignout(){
  //await Session.signOut(); 
  Globallogout();

  }

  

  /////////////////////////////////////
  //
  // Handling Socket connection
  //
  /////////////////////////////////////

  // useEffect(() => {

  //   socket.on("connect", ()=>{
  //     //Sucessfullynotify("Successfully connected to live server.")
  //     //socket.emit('join-team', user.organisationid)
  //     workspaces.map((team)=>(
  //       socket.emit('join-team', team.organisationid)
  //     ))
      
  //   })

  //   socket.on('FromAPI', function(){
  //          HandleGetCasesByDate(selectedDate)
  //          //Sucessfullynotify('New cases has been added.')
  //        })

  //   socket.on('notifications', function(data){
  //         //HandleGetCasesByDate(selectedDate)
  //         console.log("got nottification",data)

  //         GetNotifications()
  //         setNewNotifications(true)
  //         //Sucessfullynotify('New Notifications.')
  //       })
  //       socket.on('update_task', function(data){
  //         //HandleGetCasesByDate(selectedDate)
  //         console.log("got nottification on update_task",data)
  //         HandleGetCasesByDate(selectedDate, Defaultworkspace, user)
  //         //Sucessfullynotify('New Notifications on update_task.')
  //       })

  //   socket.on('update_case_status', function(data){
  //         //handleGetCasesByDate(selectedDate)
  //         console.log("WS: update_case_status",data)
  //         //getNotifications()
  //         //Sucessfullynotify('New Notifications.')
  //       })
        
  //   socket.on('message-from-server', (message)=>{
  //     //Sucessfullynotify(message)
  //   })

  //   socket.on('workspace', (message)=>{
  //     //Sucessfullynotify(message)
  //   })

  //   socket.on("connect_error", (err) => {
  //     //Errornotify("Live Server: ",err.message)
  //     console.log(err.message); // prints the message associated with the error
  //   });


  // }, []);


function readNotifications(notificationsIDs){
  UpdateNotifications(notificationsIDs)
  setNewNotifications(false)
}


  return (
    <AppBar position="fixed" elevation={0} 
    sx={{
       zIndex: (theme) => theme.zIndex.drawer + 1,
       }}
        id={"GlobalTopbar"}
       >
      <DirectionSnackbar props={openSnackbar} handleClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}/>

        <Toolbar  variant="dense" disableGutters>
          {/* LOGO */}
        <Box 
          display="flex"
          justifyContent= "left"
          flex-direction= "column" 
          width={"50%"}
          marginLeft="1.67%"
          >
        <IconButton onClick={() => {navigate(global.config.homePagePath)}}>
        <Logo />
        </IconButton>
      </Box>

       {/* Right Side ICONS */}

        <Box 
        display="flex" 
        justifyContent= "end"
        alignContent="center"
        width={"50%"}
        color={iconColor}
        marginRight="1.67%">

{/* 
          <IconButton sx={{color:iconColor}}>
            <NotificationsOutlinedIcon />
          </IconButton>
 */}
          < NotificationsMenu newNotiffications={newNotiffications} readNotifications={readNotifications} />
          <WorkspacesMenu />

           <IconButton sx={{
            color:iconColor,

            "&:hover":{
              backgroundColor:"transparent",
              cursor:"default"
            },
          }}> 

          <Typography sx={themeStyles.typography.h51}>
          {/* {userData.userTitle }  {userData.userFirstname } {userData.userLastname } */}
          {user?user.userTitle:"" }  {user?user.userFirstname:"" } {user?user.userLastname:"" }
          </Typography>
          </IconButton>

          <AccountMenu user={user} FullName={FullName} />
{/*           <InstallPWA />
 */}         
{customizedMobileDevice?"": <IconButton onClick={()=>handleSignout()} sx={{color:colors.text[500]}}>
          <Typography sx={themeStyles.typography.h51}>
            Logout
          </Typography>
          </IconButton>}
        </Box>

        </Toolbar>
      </AppBar>
  );
};

export default Topbar;