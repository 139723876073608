import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { Box, Button, CssBaseline, Divider, Grid, IconButton, Menu, MenuItem, SvgIcon, Tooltip, useTheme } from "@mui/material";
import FindingsTable from '../../../subComponents/forms/report/findingstable/findingsTable';
import TextEditor from '../../../subComponents/forms/report/TextEditor';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportPDFForm from '../../../subComponents/forms/report/pdf/ReportForm'
import {ReportInformation} from '../../../subComponents/forms/report/pdf/ReportInformation'
import { useDispatch, useSelector } from 'react-redux';
import AddReportAPI from '../../../../functions/api/diagnosing/report';
import { closeSnackbar, updateSnackbar } from '../../../../states/slices/snackbar/snackbar';
import DirectionSnackbar from '../../../subComponents/snackbars/snackbar';


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

  '& .MuiDialog-container': {
    //backgroundColor: 'rgba(196, 196, 196, 0.3)',
    backgroundColor: 'transparent',

    //backgroundColor:"pink"
  },
  '& .MuiDialog-paper': {
    //backgroundColor: colors.dark_grey[400],
    width: "72.50%",
    height: "66.88%",
    borderRadius: "8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color: "red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UploadBtnStyle = {
  //backgroundColor: colors.dark_grey[400],
  color: colors.greenFlow[100],

  '&:hover': {
    backgroundColor: 'transparent',
    //color: '#3c52b2',
  },
}

// const useStyles = makeStyles({

//   saveBtnStyle: {
//     color: colors.text[500],
//     fontStyle: "SemiBold"
//   },
//   cancelBtnStyle: {
//     color: colors.text[600]
//   }

// })


//
//
// Components STYLINGs END
//
//
//



export default function ReportCase({ open, handleCancel}) {
  const [openPDF, setOpenPDF] = useState(false);
  //const stylingClasses = useStyles()
  const dispatch = useDispatch()
  const ReportStudy = useSelector((state)=>state.cases)
  const currentStudy = useSelector((state)=>state.cases.SterlizedCases)
  const findings = useSelector((state)=>state.cases.findings)
  const openSnackbar = useSelector((state)=>state.snackbar)
  
  //const currentStudy =  JSON.parse(localStorage.getItem("Study")) 
  const user = useSelector((state)=>state.user)
  const Measurements = JSON.parse(localStorage.getItem("measurements")) 
  let reportBody = useSelector((state)=>state.cases.report.state)

  const PatientDetails=[
    {
      "name":"Patient Name",
      "value": currentStudy["PatientName"]?currentStudy["PatientName"]:""
      
    },
    {
      "name":"Patient Age",
      "value": currentStudy["PatientAge"]?currentStudy["PatientAge"]:""
      
    },
    {
      "name":"Patient Sex",
      "value": currentStudy["PatientSex"]?currentStudy["PatientSex"]:""
      
    }
  ]


  const handleSave = () => {
    console.log("SAVE")

    AddReportAPI(reportBody,currentStudy)
    .then(response => response.text())
    .then(results => { 
    console.log(results)
    const res = JSON.parse(Object.assign(results))
    if (res.statusCode === 200) {
      // The API got a 200 response
      dispatch(updateSnackbar({open: true, Message: "Updated your report successfully.", severity: "success"}))
    } else {
      // The API got a different response code
      console.log("Error")
      dispatch(updateSnackbar({open: true, Message: "Failed to save your report.", severity: "error" }))
    }
    
    })


  }

  const handleView = () => {
    console.log("View")
    setOpenPDF(true)
  }


  return (
    
      <div>
      <DirectionSnackbar props={openSnackbar} handleClose={() => dispatch(closeSnackbar())}/>

      <ReportPDFForm 
      ReportStudy={ReportStudy}
      open={openPDF} 
      currentStudy={currentStudy}
      user={user}
      Measurements={Measurements}
      Report = {ReportInformation}
      handleCancel={()=>setOpenPDF(false)} />

      <BootstrapDialog
        hideBackdrop={"true"}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"85%"}
      >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          <Box width={"94%"} justifyContent={"space-between"} display={"flex"}>
            <Typography sx={themeStyles.typography.h411}>Medical Report </Typography>

            <Tooltip title={"Open new window"}>
              <OpenInNewOutlinedIcon onClick={() => console.log("open report externally")} />
            </Tooltip>

          </Box>
        </BootstrapDialogTitle>


        <DialogContent>
       
        {Object.keys(currentStudy).length>0?
        <><Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color={colors.novuColors[140]}
              >
                <Typography sx={themeStyles.typography.h5}>Patient Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {PatientDetails.map((item, index) => (
                    <li>
                      <Typography style={{ marginTop: "-5px", marginLeft: "-5px" }}
                        sx={themeStyles.typography.h61}>
                        {item["name"]}: {item["value"]}
                      </Typography>
                    </li>
                  ))}



                </ul>
              </AccordionDetails>
            </Accordion><Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Findings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                 <FindingsTable findings={findings} />
                </AccordionDetails>
              </Accordion><Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>General comments</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:"white"}}>
                  <TextEditor currentStudy={currentStudy}  />
                </AccordionDetails>
              </Accordion></>



           :""}
        </DialogContent>


        {/* <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Close</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Save</Typography>
          </Button>
          <Button onClick={handleView}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>View</Typography>
          </Button>
        </DialogActions> */}

        <DialogActions>
          <Button onClick={handleCancel}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Close</Typography>
          </Button>
          <Button onClick={handleSave}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Save</Typography>
          </Button>
          <Button onClick={handleView}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >View</Typography>
          </Button>
        </DialogActions>



      </BootstrapDialog>
    </div>
  );
}