import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Navigate } from "react-router-dom";

// Design 
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { medicalProfileSymbol } from "../../../utilityComponents/images/medicalprofile"

// Custom Styles
import { IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
//import {makeStyles, withStyles} from '@mui/styles'
import { styled } from '@mui/system';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { borderRadius } from '@mui/system';
import { unknown,low,medium, high, comment, inReview, notStarted, pending,awaitingSignature, RequestSupport } from './icons';
import { MedicalProfile } from '../../../utilityComponents/images/viewerIcons';
import TeamMembersAvatar from "../../pages/settings/TeamMembers";
import StackTeamMembersAvatar from "../name2avatar/stackAvatar";
import StartNewCase from "../forms/startcase";
import { AssignCase2User } from '../../../functions/api/diagnosing/diagnosing';
import AddCommentsToCase from '../messages/addCommentsDialoge';
import { getStudyInfo } from '../../../functions/api/data/viewerAPIs/getStudyInfo';
import BadgeAvatars from '../avatar/avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useSelector } from 'react-redux';
import Anonymization from '../forms/anonymization/anonymization';

export default function TableBody_Worklist({visibleColumns,data,suspiciouslevel,rcTocken}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")
    const user = useSelector((state)=>state.user)
    const workspace=useSelector((state)=>state.Defaultworkspace)
    //const BorderColor= "rgb(227, 109, 106)"
    const [borderStyle, setBorderStyle] = useState("")
    const [borderColor, setBorderColor] = useState("")
    const [openDialoge, setOpenDialoge] = useState(false)
    const [reviewCase, setReviewCase] = useState({})
    const webRef = useRef(null);
    let navigate = useNavigate();


   // const SpanStyle= {display: "flex", marginLeft:"10px","align-items": "center"}
useEffect(() => {
    //console.log("FROM TABLE",data)
}, [data])

useEffect(() => {
    SetborderStyle(suspiciouslevel)
}, [suspiciouslevel])

const chat=()=> {webRef.current.injectJavaScript(
    `window.postMessage({ externalCommand:'login-with-token', token: ${window.sessionStorage.getItem('Meteor.loginToken')} }); true;`,
  )}

{/* <div onClick={() => {
document.getElementsByTagName(“iframe”)[0].contentWindow.postMessage(
{ externalCommand: "go", path: "/channel/Evening-Party/?layout=embedded" },
"http://localhost:3000"
);}}> Evening Party </div> */}


function SetborderStyle(suspiciouslevel){
    ////console.log("suspiciouslevel",suspiciouslevel)
    let ColorStyle=""
    switch (suspiciouslevel) {
        case "1":
            ColorStyle = "#3A9DF2"     
        break;
        case "2":
            ColorStyle = "#F7AB34"
        break;
        case "3":
            ColorStyle = "#E36D6A"
        break;
        default:
            ColorStyle = "#9F9F9F"
        break;
      }
        ////console.log(`4px solid ${ColorStyle}`)
        setBorderStyle(`4px solid ${ColorStyle}`)
        setBorderColor(ColorStyle)
}

function sterlizeRiskIndex(index){
    let sterlizedIndex = ""
    switch(index){
        case 1:
            sterlizedIndex = <div>Low<div style={{"margin-top": "-5px","margin-bottom": "-10px"}}>{unknown}{low}</div></div>
        break;
        case 2:
            sterlizedIndex = <div margin="0px">Middle<div style={{"margin-top": "-5px","margin-bottom": "-10px"}}>{unknown}{low}{medium}</div></div>
        break;
        case 3:
            sterlizedIndex = <div>High<div style={{"margin-top": "-5px","margin-bottom": "-15px"}}>{unknown}{low}{medium}{high}</div></div>
        break;
        default:
            sterlizedIndex = <div>Unknown<div style={{"margin-top": "-5px","margin-bottom": "-10px"}}>{unknown}</div></div>
    }
        
    
    return(sterlizedIndex)
}

/* const testTeamMember=[{
    "firstname":"Sherief",
    "lastname":"Emam"
}] */

function sterlizeStatues(Item){
    const SpanStyle= {display: "flex", marginLeft:"10px", alignItems: "center"}
    let sterlizedStatues = ""
    ////console.log("Case Item", Item["status"])
/*     const initiatorName =[{
        "firstname": Item["initiator_firstname"],
        "lastname":Item["initiator_lastname"]
    }]  */
    const initiatorName= Item["initiator_firstname"]+' '+Item["initiator_lastname"]
    const recieverName = Item["reciever_firstname"]+' '+Item["reciever_lastname"]

    const initiatorAvatar =global.config.api_get_users_avatar + "?userId=" + Item["initiator_id"]
    const recieverAvatar =global.config.api_get_users_avatar + "?userId=" + Item["reciever_id"]



    switch(Item["status"]){
        case "":
            sterlizedStatues = <span style={SpanStyle}>{notStarted}Not Started</span>
        break;
        case "In review":
            
              sterlizedStatues = <span style={SpanStyle}>
                {/* {inReview} */}
                {/* <TeamMembersAvatar teamMembers={initiatorName} size="small"/> */}
                <AvatarGroup max={4} sx={{
                    '& .MuiAvatar-root':{
                        width: "24px",
                        height: "24px",
                        borderRadius: "18px",
                        fontSize:"10px"}
                    }}>
                
                {Item["initiator"]!==null?
                Item["initiator"].map((userID)=>{
                const initiatorAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} />
                )}):""                
                }

                </AvatarGroup>
                
                <Typography 
                sx={themeStyles.typography.h7}
                ml="10px"
                >
                  In Review                        
                </Typography> 
                 </span>
        break;
        case "Reported":

            sterlizedStatues = <span style={SpanStyle}>
                {/* {inReview} */}
                {/* <TeamMembersAvatar teamMembers={initiatorName} size="small"/> */}
                <AvatarGroup max={4}>
                
                {Item["initiator"]!==null?
                Item["initiator"].map((userID)=>{
                const initiatorAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} />
                )}):""                
                }

                </AvatarGroup>
                <Typography 
                sx={themeStyles.typography.h7}
                ml="10px"
                >
                  Reported                        
                </Typography> 
                 </span>
        break;

        case "Awaiting signature":
            sterlizedStatues = <span style={SpanStyle}>
                <AvatarGroup max={4}>

                {Item["reciever"]!==null?
                Item["reciever"].map((userID)=>{
                const recieverAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={recieverName} src={recieverAvatar} />
                )}):""                
                }


                {Item["initiator"]!==null?
                Item["initiator"].map((userID)=>{
                const initiatorAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} />
                )}):""                
                }

               



               {/*  {recieverName.length>0?<BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} />:""}
                <BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} /> */}
                </AvatarGroup>
                <Typography 
                sx={themeStyles.typography.h7}
                ml="10px"
                >
                  Awaiting Signature                        
                </Typography>

                </span>
        break;
        case "Request support":


            sterlizedStatues = <span style={SpanStyle}>
            {/* <StackTeamMembersAvatar teamMembers={RecieverName} size="small"/> */}
            <AvatarGroup max={4}>
                {Item["reciever"]!==null?
                Item["reciever"].map((userID)=>{
                const recieverAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={recieverName} src={recieverAvatar} />
                )}):""                
                }

                
                {Item["initiator"]!==null?
                Item["initiator"].map((userID)=>{
                const initiatorAvatar =global.config.api_get_users_avatar + "?userId=" + userID
                    return(
                <BadgeAvatars size="sm" alth={initiatorName} src={initiatorAvatar} />
                )}):""                
                }


                </AvatarGroup>
                <Typography 
                sx={themeStyles.typography.h7}
                ml="10px"
                >
                  Request Support                       
                </Typography>
             </span>




        break;
        default:
            sterlizedStatues = <span style={SpanStyle}>{notStarted}Not started</span>
    }
        
    
    return(sterlizedStatues)
}


function sterlizeResults(input){
    const SpanStyle= {display: "flex", marginLeft:"10px","align-items": "center"}
    let sterlizedClassifyResults = []
    

    Object.keys(input).map((Item,index) => {
        
         switch (Item) {
            case "classify":
                sterlizedClassifyResults= sterlizeClassifyResults(input[Item])
                break;
            case "segment":
                sterlizeSegmentResults(Item)
            break;
            case "predict":
                sterlizePredictResults(Item)
            break;
            default:
                break;
        } 
    })
    return(sterlizedClassifyResults)
}

function sterlizeClassifyResults2(Mainresults,borderStyle){
    const SpanStyle= {display: "flex", marginLeft:"10px","align-items": "center"}
    //console.log("borderStyle",borderStyle)
    // Split the string by spaces
    const parts = borderStyle.split(" ");
    // Get the last part which is the color value
    const colorValue = parts[parts.length - 1];

    return(
        Object.keys(Mainresults).map((Item,index)=>(

        <>
        
        
        <span style={SpanStyle}>
        <Chip 
        //variant="outlined" 
        sx={{background:"transparent"}}
        //color="info" 
        //icon={<Typography>{ Mainresults[Item]["probability"]}%</Typography>} 
        label={<Typography color={colorValue} >{Mainresults[Item]["Name"]}</Typography>} 
        avatar={<Avatar
        sx={{backgroundColor:colorValue?colorValue:""}}
        >{ Mainresults[Item]["probability"]}%</Avatar>}/>
        
{/*               {Mainresults[Item]["Name"]}, {Mainresults[Item]["probability"]}
 */}            
            </span></>
        ))
    )
}


function sterlizeClassifyResults(Mainresults){
    const SpanStyle= {display: "flex", marginLeft:"10px","align-items": "center"}
    
    return(
        Object.keys(Mainresults).map((Item,index)=>(
        <span  style={SpanStyle}>
            {Mainresults[Item]["class"]}, {Mainresults[Item]["probability"]}
            </span>
        ))
    )
}

function transformToDateISO(inputDate) {
    if (inputDate && inputDate.length === 8) {
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);
      const isoDate = `${year}-${month}-${day}`;
      return isoDate;
    } else {
      // Invalid input, return an empty string or handle the error as needed
      return "";
    }
  }

function sterlizeSegmentResults(){
    ////console.log("segment")
}

function sterlizePredictResults(){
    ////console.log("predict")
}

const reviewHandleButton = (Item) => {
    setReviewCase(Item)
    setOpenDialoge(true)

  }
const closeReviewDialog = () => {
        setOpenDialoge(false);
  };

  const startCase = (Item) => {
    localStorage.setItem("studyUID", Item["StudyInstanceUID"]);
    setOpenDialoge(false);
    AssignCase2User(Item["StudyInstanceUID"], user["userID"],"","In review")
    .then(response =>{
        // const win = window.open("/viewernew", {state:{"StudyInstanceUID":Item["StudyInstanceUID"], "organisationid":user["organisationid"]}});
        // win.focus();
        window.location.href = "/viewernew?StudyInstanceUID=" + Item["StudyInstanceUID"] + "&organisationid=" + user["organisationid"];

    })
  }

// function patientID(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
// }

// const NumberCell = withStyles((theme) => ({
//     //Border Cell contains the length of the rows and the borderline
//     root: {
//         "&.MuiTableCell-body":
//         {
//             padding: "0px 0px 0px 0px",
//             align:"center",
//             borderBottom:"none",
//             borderTop:"none",
//             "borderRight":borderStyle,
//         }
//   }
// }))(TableCell);

// const BorderlessCell = withStyles((theme) => ({
//     //Border Cell contains the length of the rows and the borderline
//     root: {
//         "&.MuiTableCell-body":
//         {
//             border:"none",
//         }
//     }
//   }
// ))(TableCell);


// const LastTableCell = withStyles((theme) => ({
//     // First Cell has special padding formate  
//     root: {
//         "&.MuiTableCell-body":
//         {
//             padding: "5px 0px 5px 0px",
//             align:"right",
//             borderTop:"1px solid rgba(81, 81, 81, 1)"
//         }
//   }
// }))(TableCell);

// const BodyTableCell = withStyles((theme) => ({
//     // Gerneal Table Cell
//     root: {
//         "&.MuiTableCell-body":
//         {
//             padding: "5px 0px 5px 0px",
//             align:"center",
//             borderTop:"1px solid rgba(81, 81, 81, 1)",
//             borderRight:"1px solid rgba(81, 81, 81, 1)"
//         },
//         "&:hover": {
//             // backgroundColor: "red"
//     }
//   }
// }))(TableCell);


// NumberCell Component

const NumberCell = styled(TableCell)(({ theme }) => ({
    padding: "0px",
    textAlign: "center",
    borderBottom: "none",
    borderTop: "none",
    borderRight: borderStyle, // Assuming borderStyle is defined somewhere
}));

// BorderlessCell Component
const BorderlessCell = styled(TableCell)(({ theme }) => ({
    border: "none",
}));

// LastTableCell Component
const LastTableCell = styled(TableCell)(({ theme }) => ({
    padding: "5px 0px 5px 0px",
    textAlign: "right",
    borderTop: "1px solid rgba(81, 81, 81, 1)",
}));

// BodyTableCell Component
const BodyTableCell = styled(TableCell)(({ theme }) => ({
    padding: "5px 0px 5px 0px",
    textAlign: "center",
    borderTop: "1px solid rgba(81, 81, 81, 1)",
    borderRight: "1px solid rgba(81, 81, 81, 1)",
    '&:hover': {
        // backgroundColor: "red"
    },
}));


const borderNumberStyle={
        "width": "26.24px",
        "height": "26.24px",
        //"border": "1px solid #E36D6A",
        "border": `1px solid ${borderColor}`,
        "borderRadius": "50%",
        "color": borderColor,
        textAlign:"center",
        //marginRight:"-10px"
      }

  return (
    <>
    <TableBody>
        <StartNewCase reviewCase={reviewCase} openDialoge={openDialoge} closeReviewDialog={closeReviewDialog} startCase={startCase}/>
        
        {
        data.map((Item,index)=>{
        ////console.log("TAble DATA", Item)

        return (
        <TableRow sx={{padding:"0px", margin:"0px"}} key={index}> 

                {/* Formating Cells */}
           {index===0 && visibleColumns["number"]["show"]? 
           <>
            {/* TABLE CELL FOR ROW NUMBER and BORDER */}
            
            <NumberCell  rowSpan={data.length} >
            <Typography 
                align="left"
                sx={[themeStyles.typography.h52 , borderNumberStyle]}
                color={colors.dark_grey[300]}>
                    {data.length}
                        
                </Typography> 
            </NumberCell>

            {/* TABLE CELL For distancing*/}
           <BorderlessCell rowSpan={data.length}>
            </BorderlessCell>
            </>
            :""}

                 {/* TABLE BODY with actual Data*/}
            
            {visibleColumns["PatientName"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                {Item["PatientName"] ? `${Item["PatientName"].toUpperCase()}, ${Item["PatientAge"]}` : ""}                </Typography>
            </BodyTableCell>
            :""}
            
            {visibleColumns["patientID"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                marginLeft={"5px"}
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {Item["local_patientID"]?Item["local_patientID"]:""}
                </Typography>
            </BodyTableCell>
            :""}


            {visibleColumns["DOB"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                marginLeft={"5px"}
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {Item["PatientBirthdate"]?transformToDateISO(Item["PatientBirthdate"]):""}
                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["medicalprofile"]["show"]?
                <BodyTableCell>
                
                <Typography onClick={()=>{
                    //console.log(Item["StudyInstanceUID"])
                }}
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.text[100]}>
                    <IconButton>
                    <MedicalProfile fontSize="medium" sx={{fill:"white"}} />
                    </IconButton>
                </Typography>
                
            </BodyTableCell>
            :""}

            {visibleColumns["modality"]["show"]?

            <BodyTableCell>
                <Typography 
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {/* {Item["modality"]? `${Item["modality"]}`:""} */}
                    
                    {
                    Item["modality"]?
                    Item["modality"].map((modality)=>(
                        `${modality} `
                    ))
                    :""
                   }
                    

                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["bodyexaminationpart"]["show"]?

            <BodyTableCell>
                <Typography 
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                   {/*  {Item["bodyexaminationpart"]? `${Item["bodyexaminationpart"]}`:""} */}
                   {
                    Item["bodyexaminationpart"]?
                    Item["bodyexaminationpart"].map((bodyexaminationpart)=>(
                        `${bodyexaminationpart} `
                    ))
                    :""
                   }
                </Typography>
            </BodyTableCell>
            :""}

            
           





            {visibleColumns["AccessionNumber"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                marginLeft={"5px"}
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {Item["AccessionNumber"] ? Item["AccessionNumber"] : ""}              
                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["Institution"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                marginLeft={"5px"}
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
            {Item["InstitutionName"] && Item["InstitutionName"] !== "None" ? Item["InstitutionName"] : ""}                
            </Typography>
            </BodyTableCell>
            :""}


            {visibleColumns["risk_index"]["show"]?
                <BodyTableCell>
                <Typography 
                align="left"
                marginLeft={"5px"}
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {sterlizeRiskIndex(Item["risk_index"])}
                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["main"]["show"]?
                <BodyTableCell>
            
                    <Typography 
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.text[100]}>
                
                {Item["results"]==undefined || Item["results"]["error"]?"":
                    sterlizeClassifyResults2(Item["results"]["Findings"], borderStyle)}

                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["secondary"]["show"]?
                <BodyTableCell>
                <Typography 
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.text[100]}>
{/*                     {Item["results"]==undefined || Item["results"]["error"]?"":
                    Item["results"]["classify"]["secondary"] ? 
                    sterlizeClassifyResults(Item["results"]["classify"]["secondary"]) :
                ""} */}
                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["reviewer"]["show"]?
                <BodyTableCell>
                <Typography 
                align="center"
                sx={themeStyles.typography.h71}
                color={colors.dark_grey[300]}>
                    {sterlizeStatues(Item)}
                </Typography>
            </BodyTableCell>
            :""}

            {visibleColumns["actions"]["show"]? <LastTableCell justifyContent={"end"} align="right" alignItems={"center"}>

                    <AddCommentsToCase rcTocken={rcTocken} Item={Item}/>
                    
                    <Anonymization  rcTocken={rcTocken} Item={Item}/>

                    {workspace["defaultWorkspaceRights"]["Review"]?
                    <Tooltip title="Open Case">    
                    <Button  sx={{fontFamily:"inherit",
                     fontSize:"inherit",
                     color:"inherit",
                     //width:"200px",
                     ":hover":{
                        backgroundColor:"transparent",
                     }
                     }}
                     justifyContent={"end"}>
                    
                        <Typography 
                    align="right"
                    onClick={()=>{reviewHandleButton(Item)}}
                    sx={themeStyles.typography.h60}
                    color={colors.greenFlow[300]}>
                        Review
                     </Typography>
                </Button>
                </Tooltip>:""}

            </LastTableCell>:""}

              

        </TableRow>)}
        
        )}
    

        
            {data.length>0?<TableRow sx={{border:"none"}} key={"VSuspiciousDivider"}>
                <BorderlessCell height="50px"  sx={{border:"none"}}></BorderlessCell>
                <BorderlessCell sx={{border:"none"}}></BorderlessCell>
                <BorderlessCell></BorderlessCell>
                <BorderlessCell></BorderlessCell>
                <BorderlessCell></BorderlessCell>
                <BorderlessCell></BorderlessCell>
                <BorderlessCell></BorderlessCell>
                <BorderlessCell></BorderlessCell>
            </TableRow>:""}
         
      </TableBody>
      </>
  )
}
