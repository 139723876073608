export const ModalityList = [
    { "shortname": "BI", "fullname": "Biomagnetic imaging" },
    { "shortname": "CD", "fullname": "Color flow Doppler" },
    { "shortname": "CR", "fullname": "Computed Radiography" },
    { "shortname": "CT", "fullname": "Computed Tomography" },
    { "shortname": "DD", "fullname": "Duplex Doppler" },
    { "shortname": "DG", "fullname": "Diaphanography" },
    { "shortname": "DX", "fullname": "Digital Radiography" },
    { "shortname": "EPS", "fullname": "Cardiac Electrophysiology" },
    { "shortname": "ER", "fullname": "Endoscopy" },
    { "shortname": "ES", "fullname": "Endoscopy" },
    { "shortname": "GM", "fullname": "General Microscopy" },
    { "shortname": "HC", "fullname": "Hard Copy" },
    { "shortname": "HD", "fullname": "Hemodynamic Waveform" },
    { "shortname": "IO", "fullname": "Intra-oral Radiography" },
    { "shortname": "IVUS", "fullname": "Intravascular Ultrasound" },
    { "shortname": "LS", "fullname": "Laser surface scan" },
    { "shortname": "MG", "fullname": "Mammography" },
    { "shortname": "MR", "fullname": "Magnetic Resonance" },
    { "shortname": "NM", "fullname": "Nuclear Medicine" },
    { "shortname": "OP", "fullname": "Ophthalmic Photography" },
    { "shortname": "OT", "fullname": "Other" },
    { "shortname": "PR", "fullname": "Presentation State" },
    { "shortname": "PT", "fullname": "Positron emission tomography (PET)" },
    { "shortname": "PX", "fullname": "Panoramic X-Ray" },
    { "shortname": "RG", "fullname": "Radiographic imaging (conventional film/screen)" },
    { "shortname": "RF", "fullname": "Radio Fluoroscopy" },
    { "shortname": "RTDOSE", "fullname": "Radiotherapy Dose" },
    { "shortname": "RTIMAGE", "fullname": "Radiotherapy Image" },
    { "shortname": "RTPLAN", "fullname": "Radiotherapy Plan" },
    { "shortname": "RTRECORD", "fullname": "RT Treatment Record" },
    { "shortname": "RTSTRUCT", "fullname": "Radiotherapy Structure Set" },
    { "shortname": "SM", "fullname": "Slide Microscopy" },
    { "shortname": "SMR", "fullname": "Stereometric Relationship" },
    { "shortname": "SR", "fullname": "SR Document" },
    { "shortname": "ST", "fullname": "Single-photon emission computed tomography (SPECT)" },
    { "shortname": "TG", "fullname": "Thermography" },
    { "shortname": "US", "fullname": "Ultrasound" },
    { "shortname": "XA", "fullname": "X-Ray Angiography" },
    { "shortname": "XC", "fullname": "External-camera Photography" }
];
