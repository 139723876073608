import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import TitlebarBelowImageList from './instancesList';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Image from 'mui-image'

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

export default function SlidesDrawer({ openDrawerState, type, closeDrawer, currentStudy }) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: true,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    function OpeninViewer(UID){
        console.log("Active Container", viewportsCore.layoutController.getActiveContainer(),UID)
        const ActiveContainer= viewportsCore.layoutController.getActiveContainer()
        global.viewportsCore.layoutController.openInstanceToViewportContainer(
            ActiveContainer["containerId"],UID)

        closeDrawer()
    }

    const list = (anchor) => (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer('bottom', false)}
            onKeyDown={toggleDrawer('bottom', false)}
            backgroundColor={"transparent"}

        >
            {currentStudy ?
                <Grid container spacing={2} 
                height={"25vh"} 
                justifyContent={"center"} 
                display={"flex"} alignItems={"center"} >

                    {type==="Study"?

                    currentStudy["instances"].map((instance, index) => {
                      const ImageSource='https://app.novu.ai/api/pacs/image?uid='+instance.instanceUID

                      return(
                     <Grid item xs={4} md={2} >
                     <Card 
                     //sx={{ minWidth: 80 }} 
                      width={70}
                     onClick={()=>{OpeninViewer(instance.uid)}}
                     >
                       <Image src={ImageSource} sx={{width:64, height:64}} width={64} height={64}/> 
                         <CardContent display="flex" justifyContent={"center"} >
                         

                             <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                 {instance.name}
                             </Typography>
                             <Typography variant="h71" component="div">
                             {instance.studydescription} 
                             </Typography>
                             {/* <Typography sx={{ mb: 1.5 }} >
                             View Position: {instance.viewPosition}
                             </Typography> */}
{/*                                    <Typography variant="body2">
                             UID: {instance.uid}
                             </Typography> */}
                         </CardContent>
{/*                                 <CardActions>
                             <Button size="small">Learn More</Button>
                         </CardActions> */}
                     </Card>

                 </Grid>
                 )})

                 : type==="Series"?

                 currentStudy["instances"].map((instance, index) => (
                 <Grid item xs={4} md={2}>
                 <Card sx={{ minWidth: 80 }}
                 onClick={()=>{OpeninViewer(instance.uid)}}
                 >
                     <CardContent>
                         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                             {instance.name}
                         </Typography>
                         <Typography variant="h71" component="div">
                         Image Laterality series: {instance.imageLaterality} 
                         </Typography>
                         <Typography sx={{ mb: 1.5 }} >
                         View Position: {instance.viewPosition}
                         </Typography>
{/*                                    <Typography variant="body2">
                         UID: {instance.uid}
                         </Typography> */}
                     </CardContent>
{/*                                 <CardActions>
                         <Button size="small">Learn More</Button>
                     </CardActions> */}
                 </Card>

             </Grid>
             ))

             :

                    currentStudy["instances"].map((instance, index) => (


                        <Grid item xs={4} md={2}>
                            <Card sx={{ minWidth: 80 }}
                            onClick={()=>{OpeninViewer(instance.uid)}}
                            >
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {instance.name}
                                    </Typography>
                                    <Typography variant="h71" component="div">
                                    Image Laterality instances: {instance.imageLaterality} 
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} >
                                    View Position: {instance.viewPosition}
                                    </Typography>
 {/*                                    <Typography variant="body2">
                                    UID: {instance.uid}
                                    </Typography> */}
                                </CardContent>
{/*                                 <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                            </Card>

                        </Grid>

                    ))
                    
                  }
                </Grid>
                
                : ""}


            {/*  <Grid xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid xs={8}>
          <Item>xs=8</Item>
        </Grid> */}


            {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}

            {/* <TitlebarBelowImageList currentStudy={currentStudy} /> */}

        </Box>
    );

    return (
        <div>

            <React.Fragment key={'bottom'}>

                <SwipeableDrawer
                    height={"50%"}
                    anchor={'bottom'}
                    open={openDrawerState}
                    onClose={() => closeDrawer()}
                    onOpen={toggleDrawer('bottom', true)}
                >
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}