import React from 'react'

export function registerNewAi(values) {
    console.log("FROM API", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({ values});
    console.log("FROM API", raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("http://127.0.0.1:5000/ai/register", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));


    return (
    <div>registerNewAi</div>
  )
}

export default registerNewAi