
export const unknown = <svg width="22" height="4" viewBox="0 0 22 4" fill="none" style={{ marginRight: "4px" }} display="inline-block" xmlns="http://www.w3.org/2000/svg">
<rect width="22" height="4" fill="#C4C4C4" />
          </svg>

export const low = <svg width="22" height="4" viewBox="0 0 22 4" fill="none" style={{ marginRight: "4px" }} display="inline-block" xmlns="http://www.w3.org/2000/svg">
<rect width="22" height="4" fill="#FFC7C6" />
              </svg>

export const medium = <svg width="22" height="4" viewBox="0 0 22 4" display="inline-block" style={{ marginRight: "4px" }} fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="22" height="4" fill="#FDA2A0" />
          </svg>

export const high = <svg width="22" height="4" viewBox="0 0 22 4" display="inline-block" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="22" height="4" fill="#E36D6A" />
            </svg>

export const comment = <svg width="14" height="14" viewBox="0 0 14 14" 
fill="#F5F5F5" xmlns="http://www.w3.org/2000/svg">
<path d="M12.332 0.332031H1.66536C0.932031 0.332031 0.332031 0.932031 0.332031 1.66536V13.6654L2.9987 10.9987H12.332C13.0654 10.9987 13.6654 10.3987 13.6654 9.66536V1.66536C13.6654 0.932031 13.0654 0.332031 12.332 0.332031ZM12.332 9.66536H2.46536L1.66536 10.4654V1.66536H12.332V9.66536Z" />
              </svg>

export const inReview = <svg width="20" height="20" viewBox="0 0 20 20" 
fill="#2AD7C1" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
<path d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34783 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17316C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.5 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.5 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.5 16.36 14.83ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM10 9C9.60218 9 9.22065 8.84196 8.93934 8.56066C8.65804 8.27935 8.5 7.89782 8.5 7.5C8.5 7.10217 8.65804 6.72064 8.93934 6.43934C9.22065 6.15803 9.60218 6 10 6C10.3978 6 10.7794 6.15803 11.0607 6.43934C11.342 6.72064 11.5 7.10217 11.5 7.5C11.5 7.89782 11.342 8.27935 11.0607 8.56066C10.7794 8.84196 10.3978 9 10 9Z"  />
              </svg>
export const notStarted = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" 
fill="#4DCFEB" style={{ marginRight: "10px" }}><path d="M8 16h2V8H8Zm4 0 6-4-6-4Zm0 6q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>

export const pending =<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"
fill="#C4C4C4" style={{ marginRight: "10px" }}><path d="M7 13.5q.625 0 1.062-.438Q8.5 12.625 8.5 12t-.438-1.062Q7.625 10.5 7 10.5t-1.062.438Q5.5 11.375 5.5 12t.438 1.062Q6.375 13.5 7 13.5Zm5 0q.625 0 1.062-.438.438-.437.438-1.062t-.438-1.062Q12.625 10.5 12 10.5t-1.062.438Q10.5 11.375 10.5 12t.438 1.062q.437.438 1.062.438Zm5 0q.625 0 1.062-.438.438-.437.438-1.062t-.438-1.062Q17.625 10.5 17 10.5t-1.062.438Q15.5 11.375 15.5 12t.438 1.062q.437.438 1.062.438ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>

export const awaitingSignature =<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" 
fill="#FFFFFF" style={{ marginRight: "10px" }}><path d="M9 17q.425 0 .713-.288Q10 16.425 10 16t-.287-.713Q9.425 15 9 15t-.712.287Q8 15.575 8 16t.288.712Q8.575 17 9 17Zm3 0q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm3 0q.425 0 .713-.288Q16 16.425 16 16t-.287-.713Q15.425 15 15 15t-.712.287Q14 15.575 14 16t.288.712Q14.575 17 15 17Zm-4.05-3 5.625-5.65-1.4-1.425-4.25 4.25L8.8 9.05l-1.4 1.4ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>

export const RequestSupport = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" 
fill="#F8A49F" style={{ marginRight: "10px" }}><path d="M11.95 18q.525 0 .888-.363.362-.362.362-.887t-.362-.887q-.363-.363-.888-.363t-.888.363q-.362.362-.362.887t.362.887q.363.363.888.363Zm-.9-3.85h1.85q0-.825.188-1.3.187-.475 1.062-1.3.65-.65 1.025-1.238.375-.587.375-1.412 0-1.4-1.025-2.15T12.1 6q-1.425 0-2.312.75-.888.75-1.238 1.8l1.65.65q.125-.45.563-.975Q11.2 7.7 12.1 7.7q.8 0 1.2.437.4.438.4.963 0 .5-.3.937-.3.438-.75.813-1.1.975-1.35 1.475-.25.5-.25 1.825ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>