import React from 'react'

export function signInClicked_rc(email, password) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
        "email": email, 
        "password": password
        })

        //console.log("login Info",raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          //return fetch("auth2/api/v1/user/login", requestOptions)
          return fetch(global.config.api_login_user, requestOptions)
}
