// REACT Components
import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from '@mui/system';
import { ColorModeContext, tokens } from "../../../utilityComponents/theme";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import DrawerIconLeft from "../../../utilityComponents/images/DrawerIconLeft";
import DrawerIconRight from "../../../utilityComponents/images/DrawerIconRight";
import WorklistIcon from "../../../utilityComponents/images/WorklistIcon";
import PatientsIcon from "../../../utilityComponents/images/PatientsIcon";
import AppointementsIcon from "../../../utilityComponents/images/AppointementsIcon";
import ProfileIcon from "../../../utilityComponents/images/ProfileIcon";
import StorefrontIcon from '@mui/icons-material/Storefront';
import Tooltip from '@mui/material/Tooltip';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import TryIcon from '@mui/icons-material/Try';

//import {MenuItem_1,MenuItem_2,MenuItem_3} from "./SidebarItems";


//const drawerWidth = "18.75%";
const drawerWidth = "220px";
const colors = tokens("dark");
const NavBarBgColor = colors.dark_grey[400];



////////////////////////////////////////
//
// Menu Items
//
/////////////////////////////////////////


const MenuItem_1 = [
 
    {
      text: "Worklist Live",
      icon: <WorklistIcon />,
      path: "/demo",
    },
    // {
    //   text: "Appointments",
    //   icon: <AppointementsIcon />,
    //   path: "/appointments",
    // },
    {
      text: "Patients",
      icon: <PatientsIcon />,
      path: "/patients",
    },
  ];

  const MenuItem_reception = [
 
    {
      text: "Cases",
      icon: <WorklistIcon />,
      path: "/cases",
    },
    // {
    //   text: "Appointments",
    //   icon: <AppointementsIcon />,
    //   path: "/appointments",
    // },
    {
      text: "Patients",
      icon: <PatientsIcon />,
      path: "/patients",
    },
  ];
  
  const MenuItem_2 = [
    {
      text: "Profile",
      icon: <ProfileIcon />,
      path: "/profile",
    },
    {
      text: "Teams",
      icon: <GroupsOutlinedIcon />,
      path: "/teams",
    },
    {
      text: "Performance",
      icon: <SpeedOutlinedIcon />,
      path: "/Performance",
    }
  ];
  
 
  const MenuItem_3 = [
    {
      text: "Gen AI",
      icon: <TryIcon />,
      iconAlternative: <MessageOutlinedIcon />,
      path: "/medicalassistant",
    },
    {
      text: "Chat",
      icon: <ModeCommentOutlinedIcon />,
      iconAlternative: <MessageOutlinedIcon />,
      path: "/chat",
    },
    {
      text: "Settings",
      icon: <SettingsOutlinedIcon />,
      path: "/settings",
    },
  ];

  const MenuItem_4 = [
    {
      text: "Register AI",
      icon: <AppRegistrationIcon />,
      path: "/registerai",
    },
    {
      text: "App Store",
      icon: <StorefrontIcon />,
      path: "/appstore",
    },
    {
      text: "AI KPIs",
      icon: <SpeedOutlinedIcon />,
      path: "/kpis-ai",
    },
    // {
    //   text: "Viewer",
    //   icon: <AppointementsIcon />,
    //   path: "/viewer",
    // },
    // {
    //   text: "ViewerNew",
    //   icon: <ProfileIcon />,
    //   path: "/viewerNew",
    // },
    // {
    //   text: "Demo",
    //   icon: <WorklistIcon />,
    //   path: "/worklist",
    // },
  ];


////////////////////////////////////////
//
// Drawer Styles when opened and Closed
//
/////////////////////////////////////////

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  
});

const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //width:"2.89%",
   [theme.breakpoints.up("xl")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  }, 
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    
    
  }),
}));


////////////////////////////////////////
//
//  Styles
//
/////////////////////////////////////////

const RootDrawer = styled('div')(({ theme }) => ({
  '& .MuiDrawer-paper, & .MuiListItem-root': {
    padding: "0px",
    boxSizing: 'border-box',
    backgroundColor: NavBarBgColor,
    overflow: "hidden",
    fill: "white",
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.015em",
    color: "#F5F5F5",
  },
  "& .MuiListItemIcon-root": {
    fill: "inherit"
  },
  "& .Mui-selected , &.MuiListItemText-primary": {
    padding: "0px",
    color: "#4DCFEB",
    fill: "#4DCFEB",
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.015em",
  },
}));

// const useStyles = makeStyles({

//     root: {
//         '& .MuiDrawer-paper, & .MuiListItem-root': {
//             //width: 320,
//             padding:"0px",
//             boxSizing: 'border-box',
//             backgroundColor: NavBarBgColor,
//             //color: 'white',
//             overflow:"hidden",
//             fill:"white",
//             fontFamily: 'Open Sans',
//             fontStyle: "normal",
//             fontWeight: "300",
//             fontSize: "18px",
//             lineHeight: "24px",
//             letterSpacing: "-0.015em",
//             color: "#F5F5F5",

//         },
//         "&.MuiListItemIcon-root":{
//             fill:"inherit"
//         },

//         "& .Mui-selected , &.MuiListItemText-primary":{
//             padding:"0px",
//             color:"#4DCFEB",
//             fill:"#4DCFEB",

//             fontFamily: 'Open Sans',
//             fontStyle: "normal",
//             fontWeight: "700",
//             fontSize: "18px",
//             lineHeight: "24px",
//             letterSpacing: "-0.015em",
//         },        
//       },
//     selected: {},
//     typographyStyle:{
//         fontSize:"inherit"    
//     } 
//   });



export default function Sidebar({newMessage}) {
  const theme = useTheme();
  //const styles = useStyles();
  //const classes = useStyles()
  let navigate = useNavigate();
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const colors = tokens("dark");
  const colorMode = useContext(ColorModeContext);
  const NavBarBgColor = colors.dark_grey[600];



////////////////////////////////////////
//
// Lists structure
//
/////////////////////////////////////////

  function ListStructure(MenuItem) {
    return (
      <List>
        {MenuItem.map((Item) => 
        {
          //console.log("Sidebar",Item.path,location.pathname, (Item.path===location.pathname))
        return(
          <ListItem
            key={Item.text}
            onClick={() => {navigate(Item.path)}}
            selected={Item.path === location.pathname}
            color={Item.path === location.pathname?"#4DCFEB":"#F5F5F5"}
            fill={Item.path === location.pathname?"#4DCFEB":"#F5F5F5"}
            sx={{
              padding: "0px"
            }}
          >
            <ListItemButton >

            <Tooltip title={Item.text} placement="bottom-end">
              <ListItemIcon 
              sx={{
                padding: "0px",
              color: Item.path === location.pathname ? "#4DCFEB" : "#F5F5F5", 
              fill: Item.path === location.pathname ? "#4DCFEB" : "#F5F5F5"}}
              // style={{
              //   color: Item.path === location.pathname ? "#4DCFEB" : "", 
              //   fill: Item.path === location.pathname ? "#4DCFEB" : ""// Set color based on selection
              // }}
             >
                {Item.text=="Chat" && newMessage=="1" && selected =="false"
                ? Item.iconAlternativeicon 
                : Item.icon}
                
                </ListItemIcon>
                </Tooltip>
              <ListItemText  primary={
              <Typography variant="" 
              sx={{fontSize:"inherit"}}
              //className={classes.typographyStyle}
              >
                {Item.text}</Typography>}
             />
            </ListItemButton>
          </ListItem>
        )}
        
        )}
      </List>
    );
  }

////////////////////////////////////////
//
// Drawer Component
//
/////////////////////////////////////////

  return (
    
      
      <Drawer variant="permanent" open={open} 
      id={"GlobalSidebar"}
      //className={classes.root}
      className={RootDrawer}
      >
        {/* Placeholder under the Appbar */}
        <DrawerHeader />

        {/* Open/Close Drawer */}

        <Box sx={{ overflow: "auto" }} id={"GlobalSidebarBox"}>
          {/* Flip the state of the Drawer */}
          <Box
            display="flex"
            justifyContent="end"
            onClick={() => {setOpen(!open)}}
          >
            {open ? (
              <IconButton><DrawerIconLeft /></IconButton>
            ) : (
              <IconButton><DrawerIconRight /></IconButton>
            )}
          </Box>

          {/* List of Elements in the Drawer */}
          {/* Do not change here. Add to the lists (
            pages_group1/2/3) declared above */}

          {ListStructure(MenuItem_1)}
          <Divider />
          {ListStructure(MenuItem_reception)}
          <Divider />
          {ListStructure(MenuItem_2)}
          <Divider />
          {ListStructure(MenuItem_3)}
          <Divider />
          {ListStructure(MenuItem_4)}
          <Divider />
        </Box>
      </Drawer>

    
  );
}
