import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts';

export default function BasicLineChart() {
    const chartSetting = {
        yAxis: [
          {
            label: 'Total Number of Cases',
          },
        ],
        //width: 500,
        height: 500,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
          },
        },
        margin:{ top: 10, bottom: 30, left: 40, right: 10 }
      };
  return (
    <LineChart
    
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 3.5, 5, 8.5, 10.5, 13],
        },
      ]}

      {...chartSetting}
    />
  );
}