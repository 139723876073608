import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';

import Container from "@mui/material/Container";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select , Paper} from "@mui/material";
import {ColorModeContext, tokens, themeSettings} from "../../../utilityComponents/theme";
import {registerNewAi} from "../../../functions/api/ai/registerNewAi";
import { StyledForm, StyledPaper, StyledTextField } from "../../subComponents/forms/formcomponenets";


let RegisterAiSchema = yup.object().shape({
  productName: yup.string().required().nullable(false),
  imageLocation: yup.string().required().nullable(false),
  path: yup.string().required().nullable(false),
  level: yup.string().required().nullable(false),
  type: yup.string().required().nullable(false),
  modality: yup.string().required().nullable(false),
  bodyexaminationpart: yup.string().required().nullable(false),
  outcome_name: yup.string().required().nullable(false),
  outcome_values: yup.string().required().nullable(false),
  negative_case: yup.string().required().nullable(false),
  manufacturer: yup.string().required().nullable(false),

});;

// const useStyles = makeStyles(theme => ({
//   paper: {
//     //marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center"
//   },

//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(3)
//   },
//   submit: {
//     //margin: theme.spacing(3, 0, 2)
//   },
//   textField: {
//     backgroundColor:"black",
//     "& .MuiFormHelperText-root":{
//       backgroundColor: "transparent"
//     },
//     ":&.hover":{
      
//     }
//   }

// }));

// const StyledPaper = styled(Paper)({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   // Add any additional styles here
// });

// const StyledForm = styled('form')({
//   width: '100%',
//   marginTop: 'theme.spacing(3)', // You need to replace 'theme.spacing(3)' with the actual value you want to use
//   // Add any additional styles here
// });

// const StyledTextField = styled(TextField)({
//   backgroundColor: 'black',
//   '& .MuiFormHelperText-root': {
//     backgroundColor: 'transparent',
//   },
// });

export const RegisterAiForm = () => {
  //const classes = useStyles();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const fieldBackgroundColor= colors.greenFlow[200]
  const [bodyexaminationpart, setBodyexaminationpart] = useState('');


  const InputFieldStyle = {
    borderRadius:"10px",
  backgroundColor:fieldBackgroundColor
  }

  //      "name": "ai_breast",
  //     "image_location": "ecr",
  //     "path": "/ai",
  //     "level": "information",
  //     "type": "classify",
  //     "modality": "MG",
  //     "bodyexaminationpart": "BREAST",
  //     "outcome_name": "Lesion",
  //     "outcome_values": "{'positive':'Lesion','negative':'NO Lesion'}",
  //     "negative_case": "NO Lesion",
  //     "manufacturer": "NOVU"

  const optionsModailty=[
    {"value":"MG", "label":"MG"},
    {"value":"X-RAY", "label":"X-RAY"},
    {"value":"CT-HEAD", "label":"CT-HEAD"},
  ]

  const optionsExaminationPart=[
    {"value":"HEAD", "label":"HEAD"},
    {"value":"BREAST", "label":"BREAST"},
    {"value":"CHEST", "label":"CHEST"},
  ]

  const optionstype=[
    {"value":"Classify", "label":"Classify"},
    {"value":"Segment", "label":"Segment"},
    {"value":"Predict", "label":"Predict"},
  ]

  const optionslevel=[
    {"value":"Main", "label":"Main"},
    {"value":"Secondary", "label":"Secondary"},
  ]

  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper 
      //</Container>className={classes.paper}
      >
        <Formik
          initialValues={{
            productName:'123',
            imageLocation:'ecr',
            path:'123',
            level:'123',
            type:'123',
            modality:'123',
            bodyexaminationpart:'123',
            outcome_name:'123',
            outcome_values:'123',
            negative_case:'123',
            manufacturer:'123',
           
          }}
          validationSchema={RegisterAiSchema}
          onSubmit={values => {
            registerNewAi(values);
            //console.log("FORMIK", values)
          }}
        >
          {({ errors, isValid, handleChange, touched }) => (
            <StyledForm 
            //className={classes.form}
            >
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    //className = {classes.textField}
                    error={errors.productName && touched.productName}
                    autoComplete="productName"
                    name="productName"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="productName"
                    placeholder="Product Short Name"
                    //autoFocus
                    sx={InputFieldStyle}
                                      
                    /* helperText={
                      errors.firstname && touched.firstname
                        ? errors.firstname
                        : null
                    } */
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    //className = {classes.textField}
                    error={errors.imageLocation && touched.imageLocation}
                    autoComplete="imageLocation"
                    name="imageLocation"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="imageLocation"
                    placeholder="Image Localtion"
                    //autoFocus
                    sx={InputFieldStyle}
                                      
                    /* helperText={
                      errors.firstname && touched.firstname
                        ? errors.firstname
                        : null
                    } */
                  />
                </Grid>


                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.path && touched.path}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="path"
                    placeholder="Triggering path (e.g. /ai/results)"
                    name="path"
                    autoComplete="path"
                    sx={InputFieldStyle}
                  />
                </Grid>

                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">AI Level</InputLabel>
                  <Field name="level" component={CustomizedSelectForFormik}>
                    
                     {optionslevel.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>


                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">AI Type</InputLabel>
                  <Field name="type" component={CustomizedSelectForFormik}>
                    
                     {optionstype.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>

                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Modality</InputLabel>
                  <Field name="modality" component={CustomizedSelectForFormik}>
                    
                     {optionsModailty.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>
                

                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Body Examination Part</InputLabel>
                  <Field name="bodyexaminationpart" component={CustomizedSelectForFormik}>
                    
                     {optionsExaminationPart.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.outcome_name && touched.outcome_name}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="outcome_name"
                    placeholder="outcome_name"
                    name="outcome_name"
                    autoComplete="outcome_name"
                    sx={InputFieldStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.outcome_values && touched.outcome_values}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="outcome_values"
                    placeholder="outcome_values"
                    name="outcome_values"
                    autoComplete="outcome_values"
                    sx={InputFieldStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.negative_case && touched.negative_case}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="negative_case"
                    placeholder="negative_case"
                    name="negative_case"
                    autoComplete="negative_case"
                    sx={InputFieldStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.manufacturer && touched.manufacturer}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="manufacturer"
                    placeholder="manufacturer"
                    name="manufacturer"
                    autoComplete="manufacturer"
                    sx={InputFieldStyle}
                  />
                </Grid>

               
                <Grid item xs={12}>

                  <Button 
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid}

                  sx={{
                    backgroundColor:colors.greenFlow[100],
                    ":hover":{
                      backgroundColor:colors.greenFlow[100],
                    }
                  }}
                  //onClick={handleSignIN}
                  size="large" 
              //variant="contained"
              >
                <Typography
                  sx={themeStyles.typography.h5}
                  color={"white"}
                >
                  Sign Up
                </Typography>
              </Button>

                   
                  </Grid>
              </Grid>
              
            </StyledForm>
          )}
        </Formik>
      </StyledPaper>
    </Container>
  );
};
