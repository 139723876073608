import React from 'react'

export function GetUserInformation(user_id) {

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"user_id": user_id});
//console.log("FROM API", raw)

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(global.config.api_get_team_member, requestOptions)

}
