export const BodyParts = [
    'ABDOMEN',
    'ANKLE',
    'ARM',
    'BREAST',
    'CHEST',
    'CLAVICLE',
    'COCCYX',
    'CSPINE',
    'ELBOW',
    'EXTREMITY',
    'FOOT',
    'HAND',
    'HEAD',
    'HEART',
    'HIP',
    'JAW',
    'KNEE',
    'LEG',
    'LSPINE',
    'NECK',
    'PELVIS',
    'SHOULDER',
    'SKULL',
    'SSPINE',
    'TSPINE',
    ''
];