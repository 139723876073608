import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider,Typography } from '@mui/material';


// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.secondary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.secondary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));


  const colors = tokens("dark");

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#ccc"

          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: colors.greenAccent[100]
            }
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            //backgroundColor: "#fff",
            backgroundColor: "pink",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.7,
              //backgroundColor: "#fff"
              //backgroundColor: "#ccc"
              backgroundColor: "#17DFE9",
              color:"pink"
            }
          }
        }
      }
    }
  });


export default function AutoSaveComponent({ autoSaveChangesFunction,autoSave }) {
    const themeStyles = themeSettings("dark")
    return (

        <ThemeProvider theme={theme}>
        <FormGroup>
             <FormControlLabel
        control={
        <Android12Switch 
        checked={autoSave} 
        //sx={{width:'48px', height:"24px"}}
        size='medium'
        onChange={autoSaveChangesFunction}
        />
    }
        label={
            <Typography
                    align="center"
                    sx={themeStyles.typography.h71}
                    color={colors.text[200]}>
                    Autosave
                </Typography>
        }
        labelPlacement="start"
      />


      </FormGroup>
      </ThemeProvider>


    );
}