// REACT COMPONENTS
import React, { useEffect, useState, useContext,useMemo } from "react";

//REDUX
import {useSelector, useDispatch} from 'react-redux'
import { update, logIn,logOut } from "../../../states/slices/user/userSlice";
import dayjs from 'dayjs';

//MUI
import {
  Button,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { Box } from "@mui/system";
//import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//CUSTOM Components
//import {signIN, singOUT} from '../../../functions/userState'

//CUSTOM STYLES
import "./Login.css";

import Background from "../../../utilityComponents/images/stripes_backgroung.jpg";

import {
  ColorModeContext,
  tokens,
  themeSettings,
} from "../../../utilityComponents/theme";
import { useNavigate } from "react-router-dom";
//import { signInClicked } from "../../../functions/authentication/siginin";
import { signInClicked_rc } from "../../../functions/authentication/siginin_rc";

import { useAuth } from "../../subComponents/auth/auth";
//import { sendEmailClicked } from "../../../functions/authentication/forgetpassword";
import { GetUserInformation } from "../../../functions/api/organisational/getUserInformation";
//import UpdateInitialDates from "../../../functions/datesHandler/updateDate";
import { updateDates } from "../../../states/slices/date/dateSlice";




const colors = tokens("dark");
const themeStyles = themeSettings("dark");

function UpdateInitialDates() {
  const dispatch = useDispatch()

  const today = dayjs()
  //const startDateString = today.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const startDateString = today.format('YYYY-MM-DD'); // Format the date as desired

  const dates = {
      "startDate": startDateString,
      "endDate": startDateString
    }
    dispatch(updateDates(dates))

}


export const Login = () => {
  //const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [loginError, setLoginError] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate()
  const firstname = useSelector(state=>state.user.FirstName)
  const LoggedIn = useSelector(state=>state.user.LoggedIn)
  const dispatch = useDispatch();
  useMemo(() => UpdateInitialDates(),)

  const { onLogin, onLogout } = useAuth();

  const BoxMinWidth = "500px"

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      //dispatch(logIn)
      handleSignIN()
    }
  };

async function handleSignIN(e){

  setLoading(true)
    await signInClicked_rc(userName,password)
    .then(response=>response.text())
    .then(responseParser =>{

      var results = JSON.parse(responseParser)
      //console.log("response",results["status"])

      if(results["status"]==="success") {
        //console.log("Successful login",results)
        GetUserInformation(results["data"]["userId"]) 
        .then(response=>response.text())
        .then(responseParser =>{
          const NovuResults = JSON.parse(Object.assign(responseParser))
          //console.log("Successful login NOVUDB",NovuResults)
          onLogin(results["data"],NovuResults)
          
          ////console.log("Login","sucess")
        })
        .catch(error =>{ 
      
          //console.log('error', error)
        });
      }
      else 
       {        
        setLoginError(results["message"])
      }
      setLoading(false)
    })
    .catch(error =>{ 
      setLoading(false)
      console.log('error', error)});

  // await signInClicked(userName,password)
  // .then(response => {
  //   console.log(response)

  //   if (response.response.status === "OK" && response.logged === true) {
  //     login_RC(userName,password)
  //     //FetchUserInformation(response.userID)

  //   }
  //   else {console.log("ERROR")
  //     setLoginError("Error Logging IN")
  //     onLogout()
  //   }
  // })
  

       //const data = JSON.parse(Object.assign(result))
        //console.log("signInClicked_rc",data)
        //onLogin(data)
        //localStorage.setItem("organisationID","86a238a6-04f4-471e-8d29-23832de46a81")     



  }

  async function FetchUserInformation(user_id){
    await GetUserInformation(user_id)
    .then(response => response.text())
      .then(result => {
        const data = JSON.parse(Object.assign(result))
        console.log("userLoginData",data)
        onLogin(data)
        //localStorage.setItem("organisationID","86a238a6-04f4-471e-8d29-23832de46a81")     

        })
        .catch(error =>{ console.log(error)}); 

  }

  async function handleForgetPassword(){
    setLoginError("")
    // await sendEmailClicked(userName)
    // .then (response => {
    //   if (response === true) {
    //     setLoginError("If your Email exists, we will send you a password reset link.")
    //   }
    // })
  }



  return (
    <Box 
      className="login--root" 
      alignItems="center" 
      sx={{        
      display: "flex"
      }}>
      <CssBaseline />
      <Box
            width="43%"
            //height="55%"
           sx={{
            height:{xl:"55%", xs:"75%" }
           }}
            minWidth={{xl:"500px", xs:"350px" }}
            //minWidth={BoxMinWidth}
            className="login--box"
            style={{ backgroundImage: `url(${Background})` }}
          >

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        minWidth="inherit"
      >
        <Grid item xs={2} minWidth="inherit">
          {/* <LoginForm /> */}

          
            <Grid item display="flex" 
            justifyContent="start" 

            marginTop="9.4%"
            alignItems={"center"}
            >
              <IconButton onClick={()=>navigate("/landing")}>
              <ArrowBackIcon mr="30px"/>
              </IconButton>
              <Typography ml="60px" color={colors.text[100]} sx={[themeStyles.typography.h42]}>
                Please Log in with your credentials
              </Typography>
            </Grid>

            <Box id="Credentials BOX"
            //marginLeft="13%" 
            //justifyContent={"center"} 
            //display="grid" 
            margin="20px"  
            //width="380px"
            >
              
              <Grid item 
              //display="flex" 
              //justifyContent="left" 
              marginBottom="3%">
                <Typography sx={[themeStyles.typography.h4111]}>
                  Credentials
                </Typography>
              </Grid>


              {/* 
              
              USERNAME FIELS 
              
              
              */}
              
              <Grid item 
              //width="80%" 
              xs={11}>
              <FormControl
                //className={clsx( classes.textField)}
                sx={{ 
                  width: "inherit", 
                  display:"flex",
                  marginTop: 2 }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Email
                </InputLabel>
                <OutlinedInput
                sx={{
                  borderRadius:"inherit",
                  ":focus":{
                    borderRadius:"10px"
                  }
                }}
                  fullWidth
                  required={true}
                  id="outlined-adornment-username"
                  type={"text"}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value.toLowerCase())}

                  //onChange={(e) => setPassword(e.target.value)}
                  //onKeyPress={handleKeypress}
                 // labelWidth={70}
                />
              </FormControl>
              </Grid>

              {/*
              / 
              
              Password FIELD 
              
              
              */}

              <Grid item 
              //width="80%" 
              xs={11}>
              <FormControl
                //className={clsx( classes.textField)}
                sx={{ 
                  width: "inherit", 
                  display:"flex",
                  marginTop: 2 }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                sx={{
                  borderRadius:"inherit",
                  ":focus":{
                    borderRadius:"10px"
                  }
                }}
                  fullWidth
                  required={true}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeypress}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title={
                        showPassword ? "Show password":"Hide password"}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                 // labelWidth={70}
                />
              </FormControl>
              </Grid>
             
              {/* 
              
              Reset password 
              
              
              */}

              <Grid item >
              <Button size="large" variant="text" 
              disabled = {"true"}
              onClick={handleForgetPassword}>
                <Typography
                  sx={themeStyles.typography.h9}
                  color={colors.text[500]}
                >
                  Forget password
                </Typography>
              </Button>
              </Grid>
              
              
              
              
             {/* 
              
              LogIn Button 
              
              
              */}

              <Grid container alignItems={"center"}>
              <Grid item xs={6}>
              <LoadingButton 
              sx={{
                marginTop:"20px",
                backgroundColor:colors.greenFlow[100],
                ":hover":{
                  backgroundColor:colors.greenFlow[100],
                }
              }}
              loading={loading}
              onClick={handleSignIN}
              size="large" 
              //variant="contained"
              >
                <Typography
                  sx={themeStyles.typography.h5}
                  color={"white"}
                >
                  Confirm
                </Typography>
              </LoadingButton>
              </Grid>


              {/* 
              
              Errors 
              
              
              */}
              { loginError?
                <Grid item xs={3} display="contents"  alignContent={"top"} justifyContent="center">
                <Typography marginTop={"20px"} sx={themeStyles.typography.h61} color={colors.novuColors[100]}> 
                {loginError}
                 </Typography>
                </Grid>:""}
                </Grid>
            </Box>
          
        </Grid>
      </Grid>
      </Box>
    </Box>
  );
};
