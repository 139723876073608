import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const Lesion3DRepresentation = ({ polyPoints }) => {
    const containerRef = useRef();

    useEffect(() => {
        // Initialize Three.js scene
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(400, 400); // Adjust size as needed
        containerRef.current.appendChild(renderer.domElement);

        // Add controls
        const controls = new OrbitControls(camera, renderer.domElement);

        // Create lesion mesh
        const lesionGeometry = new THREE.BufferGeometry();
        const vertices = [];
        polyPoints.forEach(({ x, y, z }) => {
            vertices.push(x, y, z);
        });
        lesionGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        const lesionMaterial = new THREE.PointsMaterial({ color: 0xff0000, size: 1 });
        const lesionMesh = new THREE.Points(lesionGeometry, lesionMaterial);
        scene.add(lesionMesh);

        // Set camera position
        camera.position.z = 5;

        // Animate the scene
        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };
        animate();

        return () => {
            // Clean up Three.js objects
            renderer.dispose();
            controls.dispose();
        };
    }, [polyPoints]);

    return <div ref={containerRef} />;
};

export default Lesion3DRepresentation;
