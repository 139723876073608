import { SvgIcon } from "@mui/material";

export function medicalProfileSymbol(props){
/*     <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    > */
    return (
    <SvgIcon {...props}>
      <path
        d="M9.00016 0L7.00016 2V4H4.00017C2.85017 4 2.12017 5 2.00017 6L1.00017 16C0.880165 17 1.54017 18 3.00017 18H19.0002C20.4602 18 21.1202 17 21.0002 16L20.0002 6C19.8802 5 19.0602 4 18.0002 4H15.0002V2L13.0002 0H9.00016ZM9.00016 2H13.0002V4H9.00016V2ZM10.0002 7H12.0002V10H15.0002V12H12.0002V15H10.0002V12H7.00016V10H10.0002V7Z"/>
      </SvgIcon>
    )
    /* </svg> */
  };

