export default function AddFindingAPI(finding,user,AIFInding, confirmed){
     console.log("from AddFindingAPI", finding)
     const values = {
        "id":finding["id"],
        "seriesUid": finding["seriesUid"],
        "studyUid": finding["studyUid"],
        "finding":
        {
        "id":finding["id"],
        "seriesUid": finding["seriesUid"],
        "studyUid": finding["studyUid"],
        "instanceUid": finding["instanceUid"],
        "data": finding["data"],
        "colors":finding["colors"],
        "timestamp":new Date(),
        "type":finding["type"],
        "pixelSpacing":finding["pixelSpacing"],
        "user":{
            "FullName":user["userFullName"],
            "avatar":user["avatarUrl"]
        },
        "organisationid":user["DefaultWorkspaceID"],
        "AIFinding":AIFInding,
        "confirmed":confirmed,
        "structureReporting":{
            "name":finding["structureReporting"]["name"],
            "description":finding["structureReporting"]["description"]
        }
        },        
     }
 
     console.log("AddFindingAPI", values)
     var myHeaders = new Headers();
     myHeaders.append("Content-Type", "application/json");
 
     var raw = JSON.stringify({ "values": values });
     //console.log("FROM API", raw)
 
     var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
     };
 
     return fetch(global.config.api_findings_add, requestOptions)
 
 }
