export default function deleteFindingAPI(finding){
    // console.log("from AddUserToWorkspace", finding)
     const values = {
        "id":finding["id"]
     }
 
     console.log("AddFindingAPI", values)
     var myHeaders = new Headers();
     myHeaders.append("Content-Type", "application/json");
 
     var raw = JSON.stringify({ "values": values });
     //console.log("FROM API", raw)
 
     var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
     };
 
     return fetch(global.config.api_findings_delete, requestOptions)
 
 }
