import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme"

import { useSelector, useDispatch } from 'react-redux'
import { updateFindings } from "../../../../states/slices/aiFindings/aifindingsSlice"
import Image from 'mui-image'
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

// ICONS
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Backarrow, ReportIcon, Test, Rotate, Fullscreen, Zoom, Unknown4, Unknown5, ROI, Unknown7 } from "../../../../utilityComponents/images/viewerIcons";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AiConfirmResults from '../../../subComponents/forms/AiResults/confirmForm';
import AiDeleteResults from '../../../subComponents/forms/AiResults/deleteFinding';
import { updateActiveStudiesInViewer, updateFindingsViewer } from '../../../../states/slices/cases/casesSlice';

const Accordion = styled((props) => (
  <MuiAccordion
    //disableGutters 
    elevation={0}
    //square 
    {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  /*  '&:not(:last-child)': {
     borderBottom: 0,
   },
   '&:before': {
     display: 'none',
   }, */
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));





export default function StudiesSeries({ DragandDropSeries,currentStudy, airesults, maxwidth, actions, SideBaropened, openSidebar }) {

  const [expanded, setExpanded] = useState('panel1');
  const [openAIResults, setOpenAIResults] = useState(true)
  const [aiFindings, setAiFindings] = useState([])
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const ResultWidth = maxwidth - 20

  //REDUX
  const aiFindingsState = useSelector((state) => state.aifindings.findings)
  const dispatch = useDispatch();

  const ResultsStyle = {
    marginTop: "20px",
    marginBottom: "20px",
  }

  useEffect(() => {
    //console.log("SideBaropened",SideBaropened)
    if (!SideBaropened) {
      setOpenAIResults(false)
    } else { setOpenAIResults(false) }
  }, [SideBaropened])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /*      useEffect(() => {
        console.log("aiFindingsState",aiFindingsState)
        extractAIFindings(aiFindingsState)
      }, [aiFindingsState])  */


  useEffect(() => {
    if (airesults) {
      extractAIFindings(airesults)
    }
  }, [airesults])


  /*      useEffect(() => {
        console.log("aiFindings changed",aiFindings)
        setAiFindings(aiFindings)
      }, [aiFindings])   */


  function extractAIFindings(airesults) {
    //console.log("airesults", airesults)

    let results = []
    airesults.instances.map((instance, index) => {

      if ('findings' in instance) {

        if (instance.findings.lesions.length > 0) {
          instance.findings.lesions.map((finding) => {

            finding = {
              "name": instance.findingName,
              "percentage": instance.findingPercentage,
              "uid": finding.uid,
              "ContainerID": instance.ContainerID,
              "isVisible": finding.isVisible,
              "isEditable": finding.isEditable
            }
            results.push(finding)
          })
        }
      }
      setAiFindings(results)
    })
  }


  function handleAIAccordion(openAIResults, SideBaropened) {

    if (SideBaropened) { setOpenAIResults(!openAIResults) }
    else { setOpenAIResults(false) }

    // Close Sub accordions if the main AI results Accordioni is closed
    if (!openAIResults) {
      setExpanded(false)
    }

  }

  /* const AccordinButtons = [
      {
        title:"Confirm",
        icon: <CheckOutlinedIcon/>,
        action: confirmResults
      },
      {
        title:"Edit",
        icon: <EditOutlinedIcon/>,
        action: editResults
      },
      {
        title:"Show",
        icon: <VisibilityOutlinedIcon/>,
        action: showResults
      },
  
      {
        title:"Delete",
        icon: <ClearOutlinedIcon/>,
        action: deleteResults
      },
    ]
  
    function IconsMenu(AccordinButtons,uid) {
      return (
        <>
        <Divider/>
        <Grid container spacing={2} sx={{marginTop:"0px",marginBottom:"10px",
          width:"220px"}} 
        //justifyContent={"space-between"}
        >
          {AccordinButtons.map((Item) => (
            <Grid item sx={{paddingLeft:"0px",}}>
              <Tooltip title={Item.title}>
                <IconButton 
                //onClick={Item.action}
                //sx={{paddingLeft:"0px",paddingRight:"0px"}}
                >
                  {Item.icon}
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        </>
      );
    }
   */
  function confirmResults(uid) {
    console.log("confirmResults", uid)
    AiConfirmResults()

  }

  function deleteResults(uid) {
    console.log("deleteResults")
  }

  function editResults(uid) {
    console.log("editResults")

    let obj = aiFindings.find(o => o.uid === uid);
    let EditableState = !obj.isEditable

    global.viewportsCore.mgLesionsController.changeLesionIsEditableStatus(obj.ContainerID, obj.uid, EditableState);

    setAiFindings(
      aiFindings.map(finding =>
        finding.uid === uid
          ? { ...finding, isEditable: EditableState }
          : finding
      ))

    console.log("Change isEditable to: ", uid, EditableState)
  }

  function showResults(uid) {
    let obj = aiFindings.find(o => o.uid === uid);
    let VisibleState = !obj.isVisible

    global.viewportsCore.mgLesionsController.changeLesionIsVisibleStatus(obj.ContainerID, obj.uid, VisibleState);

    setAiFindings(
      aiFindings.map(finding =>
        finding.uid === uid
          ? { ...finding, isVisible: VisibleState }
          : finding
      ))

    console.log("Change to: ", uid, VisibleState)
  }

  function hideResults(uid) {
    console.log("hideResults")
  }

  function handleMeasurementChange(tool) {
    global.viewportsCore.setMouseButtonFunction(1, 5);
    global.viewportsCore.measurementsController.setActiveMeasurementType(tool);
  }

  function handleDragStart(event, uid,series){
    event.dataTransfer.setData("uid", uid);
    event.dataTransfer.setData("series", series);
    //DragandDropSeries(event,series)

  }
  function OpeninViewer(UID, series) {
    //console.log("Active Container", viewportsCore.layoutController.getActiveContainer(), UID, series)
    // search for the findings for that series
    // Change the container to the active container
    console.log("OpeninViewerseries", series, UID)
    const ActiveContainer = viewportsCore.layoutController.getActiveContainer()
    global.viewportsCore.layoutController.openInstanceToViewportContainer(ActiveContainer["containerId"], UID)


    dispatch(updateActiveStudiesInViewer({ActiveContainer:ActiveContainer["containerId"],SeriesInstanceUID:series["SeriesInstanceUID"]}))
    
    

    //closeDrawer()
  }

  return (


    <Accordion expanded={openAIResults} square
      sx={{
        "&.expanded": {
          color: "red"
        }
      }}
      onChange={() => (handleAIAccordion(openAIResults, SideBaropened))}
    >
      <AccordionSummary sx={{ height: SideBaropened ? "50px" : "200px", "overflow-x": "hidden" }}
        expandIcon={SideBaropened ? <ExpandMoreIcon /> : ""}>
        {SideBaropened ?
          <Typography color={colors.text[100]}
            sx={themeStyles.typography.h52}>Study & Series</Typography>
          :
          <Button onClick={() => { openSidebar() }}>
            <Typography color={colors.novuColors[120]}
              sx={[themeStyles.typography.h5, { transform: 'rotate(-90deg)' }]}>Study & Series</Typography>
          </Button>
        }

      </AccordionSummary>



      {currentStudy ?
        <AccordionDetails>

          <ImageList sx={{ 
            width: '100%',
            maxHeight:450
            // height: 450 
            }} 
             cols={2} 
          //rowHeight={164}
          >
            {

              currentStudy["instances"].map((instance, index) => {
                const ImageSource = 'https://app.novu.ai/api/pacs/image?uid=' + instance.instanceUID

                return (
                  <ImageListItem key={index}>
                    {/* <IconButton 
                    onDragStart={(e) => handleDragStart(e, instance.instanceUID,currentStudy["instances"][index])}
                    onClick={()=>{OpeninViewer(instance.instanceUID,currentStudy["instances"][index])}}
                    sx={{
                      ":hover":{
                        background:"transparent"
                      }
                    }}
                    > */}
                    <Image 
                    onDragStart={(e) => handleDragStart(e, instance.instanceUID,currentStudy["instances"][index])}
                    onClick={()=>{OpeninViewer(instance.instanceUID,currentStudy["instances"][index])}}
                    src={ImageSource} sx={{ width: 90, height: 90 }} width={64} height={64}
                    />
                    {/* </IconButton> */}

                    <ImageListItemBar
                      sx={{width:90}}
                      title={
                      <Typography variant="h71" display="block" width={60} >
                      {instance.studydescription} 
                      </Typography>
                      }
                      subtitle={
                      <>
                      
                          {instance.imagelaterality?
                          <span> 
                            `Image Laterality: ${instance.imagelaterality}` 
                          </span>
                          :""}

                          {instance.viewposition?
                          <span> 
                            `View Position: ${instance.viewposition}` 
                          </span>
                          :""}

                      

                      </>
                      }
                      position="below"
                    />

                  </ImageListItem>

                )
              })



            }


          </ImageList>
        </AccordionDetails>


        : ""}



    </Accordion>


  );
}