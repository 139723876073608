import * as React from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';

import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme'


//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

 '& .MuiDialog-container':{
  backgroundColor: 'rgba(196, 196, 196, 0.3)',
  //backgroundColor:"pink"
 },
  '& .MuiDialog-paper':{
    //backgroundColor: colors.dark_grey[400],
    width:"62.50%",
    //height:"46.88%",
    borderRadius:"8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color:"red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


  // const useStyles = makeStyles({
    
  //   saveBtnStyle:{
  //     color: colors.text[500],
  //     fontStyle:"SemiBold"
  //   },
  //   cancelBtnStyle:{
  //     color: colors.text[600]
  //   }
    
  // })  


//
//
// Components STYLINGs END
//
//
//


  
export default function StartNewCase({openDialoge,closeReviewDialog, startCase,reviewCase}) {
  const [open, setOpen] = React.useState(true);
  //const stylingClasses = useStyles()


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = ()=>{
    console.log("SAVE")
  }


  return (
 
     

      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={closeReviewDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialoge}
        >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={closeReviewDialog}>
          
          <Typography sx={themeStyles.typography.h411}>Review a new case </Typography>
        
        </BootstrapDialogTitle>


        <DialogContent>
          <Typography gutterBottom display="block">
            You will be assigned a {reviewCase["bodyexaminationpart"]} case for Patient: {reviewCase["PatientName"]}. 
          </Typography>

          
        </DialogContent>


        <DialogActions>
{/*           <Button   onClick={closeReviewDialog}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
          </Button>
          <Button  onClick={()=>{startCase(reviewCase)}}>
          <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Confirm</Typography>
          </Button> */}

          <Button onClick={closeReviewDialog}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[600],
            }]}
              //className={stylingClasses.cancelBtnStyle}
            >Cancel</Typography>
          </Button>
          <Button onClick={()=>{startCase(reviewCase)}}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Confirm</Typography>
          </Button>


        </DialogActions>


      </BootstrapDialog>

  );
}