import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    ViewContainer:{
        marginTop: '36',
    },
  PatientDataContainer: {
    flexDirection: "row",
    marginLeft:"300",
    //color:"red"
    //justifyContent: "flex-end",
    //textAlign: 'right',
  },
  Data: {
    fontSize: 12,
    fontStyle: "bold",
    //textAlign: 'justify',
  },
  label: {
    width: 80,
  },
});
const Time= new Date().toLocaleDateString();
const Newdate = new Date().toDateString();

var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var dateTime = date+' '+time;
 
console.log(dateTime)

console.log("Newdate",Newdate)

const ReportNo = ({ ReportStudy,user }) => (
  <Fragment>
    <View style={styles.ViewContainer}>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Report No:</Text>
      <Text style={styles.Data}>{ReportStudy.SterlizedCases.local_patientID}</Text>
    </View>

    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Author:</Text>
      <Text style={styles.Data}>{user.userFullName}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Date: </Text>
      <Text style={styles.Data}>{Newdate}</Text>
    </View>
    <View style={styles.PatientDataContainer}>
      <Text style={styles.label}>Time: </Text>
      <Text style={styles.Data}>{time}</Text>
    </View>
    </View>
  </Fragment>
);

export default ReportNo;
