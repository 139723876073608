// import React from 'react'
 

// export default function DeepLookSegmentationImage({image_data, size}) {
//   const getSizeInPixels = () => {
//     switch (size) {
//       case 'xs':
//         return 30;
//       case 'sm':
//         return 40;
//       case 'md':
//         return 50;
//       case 'xl':
//         return 100;
//       default:
//         return 50; // Default to medium size
//     }
//   };

//   const imageSize = getSizeInPixels();
//     return (
//         <div>
//           {/* <h1>Image</h1> */}
//          {image_data && <img  src={`data:image/png;base64,${image_data}`} alt="Base64"
//          style={{ width: `${imageSize*10}px`, height: `${imageSize*10}px` }} />}
//         </div>
//       );
// }
import React, { useRef, useEffect } from 'react';

export default function DeepLookSegmentationImage({ image_data, size, window, level }) {
  console.log("DeepLook IMage", window,level)
  const canvasRef = useRef(null);

  const getSizeInPixels = () => {
    switch (size) {
      case 'xs':
        return 30;
      case 'sm':
        return 40;
      case 'md':
        return 50;
      case 'xl':
        return 100;
      default:
        return 50; // Default to medium size
    }
  };

  const applyWindowLevel = (imageData, window, level) => {
    const data = imageData.data;
    const min = level - window / 2;
    const max = level + window / 2;

    for (let i = 0; i < data.length; i += 4) {
      for (let j = 0; j < 3; j++) {
        let pixel = data[i + j];
        pixel = ((pixel - min) / (max - min)) * 255;
        pixel = Math.max(0, Math.min(255, pixel));
        data[i + j] = pixel;
      }
    }
    return imageData;
  };

  useEffect(() => {
    if (image_data && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const img = new Image();
      img.src = `data:image/png;base64,${image_data}`;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        if (window !== undefined && level !== undefined) {
          let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          imageData = applyWindowLevel(imageData, window, level);
          context.putImageData(imageData, 0, 0);
        }
      };
    }
  }, [image_data, window, level]);

  const imageSize = getSizeInPixels();

  return (
    <div>
      {image_data && (
        <canvas
          ref={canvasRef}
          style={{ width: `${imageSize * 10}px`, height: `${imageSize * 10}px` }}
        />
      )}
    </div>
  );
}
