export default function GetCasesAPI(userID, WorkspaceID,startDate,endDate) {


    const values = {
        "userID": userID,
        "WorkspaceID": WorkspaceID,
        "startDate":startDate,
        "endDate":endDate
    }

    //console.log("GetCasesAPI Request", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.api_get_cases_by_date, requestOptions)

}