import React from 'react'
import 'viewports-core';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { ROI}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import EastIcon from '@mui/icons-material/East';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';


export function Manipulation(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })


  function MPR(view){
    const container = getActiveContainer()
    console.log("MPR",view)
    viewportsCore.changeViewportOrientation(container.containerId, view);
    //viewportsCore.generateObliqueMPR(container.containerId);

  }
function createMprMist (type) {
    console.log("createMprMist",type)
    const container = getActiveContainer()
    global.viewportsCore.createMPRViewport(container.containerId, type);
}

function createMprMist3d () {
    console.log("createMprMist3d")
    const container = getActiveContainer()
    global.viewportsCore.createMPRViewport3D(container.containerId);
}

function createObliqueMistMpr () {
    console.log("createObliqueMistMpr")
    const container = getActiveContainer()
    global.viewportsCore.generateObliqueMPR(container.containerId);
}


  function getActiveContainer(){
    const container= viewportsCore.layoutController.getActiveContainer();
    console.log("active COntainer", container.containerId)
    return container
  }


  const ObliqueMPR =[
    {
      icon: <VerticalAlignBottomIcon fontSize="small" />,
      name: "MPR Coronal",
      shortcut: "",
      functionname: ()=> createMprMist("CORONAL")
    },
    {
      icon: <VerticalAlignBottomOutlinedIcon fontSize="small" />,
      name: "MPR Axial",
      shortcut: "",
      functionname:()=>  createMprMist("AXIAL")
    },
    {
      icon: <EastIcon fontSize="small" />,
      name: "MPR Sagital",
      shortcut: "",
      functionname: ()=> createMprMist("SAGITTAL")
    },
    {
      icon: <ViewInArIcon fontSize="small" />,
      name: "MPR 3D",
      shortcut: "",
      functionname: ()=> createMprMist3d()
    },
    {
      icon: <DeviceHubOutlinedIcon fontSize="small" />,
      name: "Oblique",
      shortcut: "",
      functionname: ()=> createObliqueMistMpr()
    },
   

]
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <ViewInArOutlinedIcon  fontSize='large'/>
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {/* {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "220px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
<Divider/> */}
{ObliqueMPR.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "220px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }

        
        
      </HoverMenu>
    </div>
  );
}