import React from 'react'
import { useEffect, useState, useRef } from 'react';

// Design 
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { medicalProfileSymbol } from "../../../utilityComponents/images/medicalprofile";

// Custom Styles
import { IconButton, TableBody, TableCell, TableRow, Tooltip, Typography, Box } from '@mui/material'
//import { makeStyles, withStyles } from '@mui/styles'
import { styled } from '@mui/system';

import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { borderRadius } from '@mui/system';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
//import { unknown,low,medium, high, comment, inReview, notStarted, pending,awaitingSignature, RequestSupport } from './icons';
//import { MedicalProfile } from '../../../utilityComponents/images/viewerIcons';
//import TeamMembersAvatar from "../settings/TeamMembers";
//import StackTeamMembersAvatar from "../name2avatar/stackAvatar";
//import StartNewCase from "../forms/startcase";
//import { AssignCase2User } from '../../../functions/api/diagnosing/diagnosing';
//import AddCommentsToCase from '../messages/addCommentsDialoge';
//import { getStudyInfo } from '../../../functions/api/data/viewerAPIs/getStudyInfo';
import Grow from '@mui/material/Grow';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import BadgeAvatars from '../../subComponents/avatar/avatar'
import Selector from './Select'
import CustomSelector from './customSelector';
import CustomSelectorRole from './customSelectorRole';

const roles = [
  'Receptionist',
  'Clinician',
  'Radiologist',
  'Head of Department',
  'Admin',
  'Owner'
];
const rights = [
  {'name': 'Allow',
  'value':true
},
{'name': 'Deny',
'value':false
},
  
];

const status = [
  'Pending',
  'Joined',
  'Deactivate'
];

export default function TableBody_Team({checked, data,deletUser,saveUser, changeMember}) {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const user = JSON.parse(localStorage.getItem("user"))
  //const BorderColor= "rgb(227, 109, 106)"
  const [borderStyle, setBorderStyle] = useState("")
  const [borderColor, setBorderColor] = useState("")


  const rcTocken = 12334
 


  function SetborderStyle(suspiciouslevel) {
    //console.log("suspiciouslevel",suspiciouslevel)
    let ColorStyle = ""
    switch (suspiciouslevel) {
      case "1":
        ColorStyle = "#3A9DF2"
        break;
      case "2":
        ColorStyle = "#F7AB34"
        break;
      case "3":
        ColorStyle = "#E36D6A"
        break;
      default:
        ColorStyle = "#9F9F9F"
        break;
    }
    //console.log(`4px solid ${ColorStyle}`)
    setBorderStyle(`4px solid ${ColorStyle}`)
    setBorderColor(ColorStyle)
  }


  // const NumberCell = withStyles((active) => ({
  //   //Border Cell contains the length of the rows and the borderline
  //   root: {
  //     "&.MuiTableCell-body":
  //     {
  //       padding: "0px 0px 0px 0px",
  //       align: "center",
  //       borderBottom: "none",
  //       borderTop: "none",
  //       width: "10px",
  //       //"borderRight": `4px solid ${active?"red":"green"}`,
  //     }
  //   }
  // }))(TableCell);

  // const BorderlessCell = withStyles((theme) => ({
  //   //Border Cell contains the length of the rows and the borderline
  //   root: {
  //     "&.MuiTableCell-body":
  //     {
  //       border: "none",
  //     }
  //   }
  // }
  // ))(TableCell);


  // const LastTableCell = withStyles((theme) => ({
  //   // First Cell has special padding formate  
  //   root: {
  //     "&.MuiTableCell-body":
  //     {
  //       padding: "5px 0px 5px 0px",
  //       align: "right",
  //       borderTop: "1px solid rgba(81, 81, 81, 1)"
  //     }
  //   }
  // }))(TableCell);

  // const BodyTableCell = withStyles((theme) => ({
  //   // Gerneal Table Cell
  //   root: {
  //     "&.MuiTableCell-body":
  //     {
  //       padding: "5px 0px 5px 0px",
  //       align: "center",
  //       // justifyContent:"center",
  //       // display:"flex",
  //       borderRadius: "5px",
  //       borderTop: "1px solid rgba(81, 81, 81, 1)",
  //       borderRight: "1px solid rgba(81, 81, 81, 1)"
  //     },
  //     "&:hover": {
  //       // backgroundColor: "red"
  //     }
  //   }
  // }))(TableCell);


// NumberCell Component
const NumberCell = styled(TableCell)(({ theme }) => ({
  padding: "0px",
  textAlign: "center",
  borderBottom: "none",
  borderTop: "none",
  borderRight: borderStyle, // Assuming borderStyle is defined somewhere
}));

// BorderlessCell Component
const BorderlessCell = styled(TableCell)(({ theme }) => ({
  border: "none",
}));

// LastTableCell Component
const LastTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px 0px 5px 0px",
  textAlign: "right",
  borderTop: "1px solid rgba(81, 81, 81, 1)",
}));

// BodyTableCell Component
const BodyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px 0px 5px 0px",
  textAlign: "center",
  borderTop: "1px solid rgba(81, 81, 81, 1)",
  borderRight: "1px solid rgba(81, 81, 81, 1)",
  '&:hover': {
      // backgroundColor: "red"
  },
}));


  return (
    <>
    <Grow
            in={checked}
            //style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 750 } : {})}
          >
      <TableBody>

        {
          data.map((member, index) => {
            return (
              <Grow
            in={checked}
            //style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 750*(index+1) } : {})}
          >
              <TableRow sx={{ padding: "0px", margin: "0px" }} key={index} id={index}>

                <BodyTableCell>
                  <Box sx={{
                    //width:"10px",
                    //height:"98%",
                    //"borderLeft": `4px solid ${member.active ? colors.greenFlow[100]: colors.novuColors[200]}`
                    "borderLeft": `4px solid ${member["rights"]["Access"] ? colors.greenFlow[100]: colors.novuColors[200]}`

                  }}>
                    <Typography
                      align="center"
                      sx={themeStyles.typography.h60}
                      color={colors.dark_grey[300]}>
                      {member["memberName"]? member["memberName"] : ""}
                    </Typography>

                  </Box>

                </BodyTableCell>





                <BodyTableCell>
                  <Typography
                    align="center"
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                    <BadgeAvatars size="sm" alth={member["memberName"]} src={member["Useravatar"]} status={user.status} />

                  </Typography>

                </BodyTableCell>


  {/* Status */}

                <BodyTableCell>
                <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                      {member.status}
                  </Typography>
                </BodyTableCell>



                  {/* Roles */}
                <BodyTableCell>
                <CustomSelectorRole options={roles}  member={member}  changeMember={changeMember}/>

 {/*                  <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                    <Selector options={roles} value={member["role"]} placeholder="Roles" multiple={false} />
                  </Typography> */}
                </BodyTableCell>

                  {/* Access */}
                  <BodyTableCell>
                  <CustomSelector options={rights}  rightName="Access" member={member} value={member["rights"]["Access"]}  changeMember={changeMember}/>

{/*                   <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                    <Selector options={roles} value={member["role"]} placeholder="Roles" multiple={false} />
                  </Typography> */}
                </BodyTableCell>

                                  {/* Appointments */}
                  <BodyTableCell>
                  <CustomSelector options={rights}  rightName="Appointments" member={member} value={member["rights"]["Appointments"]}  changeMember={changeMember}/>

{/*                   <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                     <Selector options={roles} value={member["role"]} placeholder="Roles" multiple={false} />
                 </Typography> */}
                </BodyTableCell>


{/* upload */}
                <BodyTableCell>
                <CustomSelector options={rights}  rightName="Upload" member={member} value={member["rights"]["Upload"]}  changeMember={changeMember}/>

{/*                   <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                     <Selector options={rights} placeholder="Upload" value={member["rights"]["Upload"]}  multiple={false} />
                   </Typography> */}
                </BodyTableCell>


{/* Review */}
                <BodyTableCell>
                <CustomSelector options={rights}  rightName="Review" member={member} value={member["rights"]["Review"]}  changeMember={changeMember}/>

{/*                   <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                     <Selector options={rights} placeholder="Review cases" value={member["rights"]["Review"]}  multiple={false} />
                   </Typography> */}
                </BodyTableCell>

{/* SignReport */}
                <BodyTableCell>
                <CustomSelector options={rights}  rightName="SignReport" member={member} value={member["rights"]["SignReport"]}  changeMember={changeMember}/>
{/*                   <Typography
                    align="center"
                    marginLeft={"5px"}
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                    <Selector options={rights} placeholder="Sign Reports" value={member["rights"]["SignReport"]}  multiple={false} />
                   </Typography> */}
                </BodyTableCell>


{/* View Reports */}
                <BodyTableCell>
                 <CustomSelector options={rights}  rightName="ViewReport" member={member} value={member["rights"]["ViewReport"]}  changeMember={changeMember}/>
{/*                   <Typography
                    align="center"
                    sx={themeStyles.typography.h71}
                    color={colors.dark_grey[300]}>
                    <Selector options={rights} placeholder="View Reports" value={member["rights"]["ViewReport"]}  multiple={true} />
                  </Typography> */}
                </BodyTableCell>



                <LastTableCell justifyContent={"end"} align="right" alignItems={"center"}>

                  {/*                     <AddCommentsToCase rcTocken={rcTocken} Item={Item}/>
 */}
                  <Tooltip title="Message">
                  <IconButton aria-label="Message"
                    size="small"
                  //onClick={handleClickOpen}
                  disabled="true"
                  >
                    <ChatBubbleOutlineOutlinedIcon fontSize="inherit" />
                  </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                  <IconButton aria-label="delete" size="small"
                  onClick= {()=>deletUser(member)}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                  </Tooltip>


                  <Tooltip title="Save"> 
                  <IconButton aria-label="save" size="medium"
                  onClick={()=>saveUser(member)}
                  >
                  <SaveOutlinedIcon fontSize="inherit" />
                </IconButton>
                </Tooltip>
                  
                  {/* <Tooltip title="Save">
                    <Button sx={{
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      color: "inherit",
                      //width:"200px",
                      ":hover": {
                        backgroundColor: "transparent",
                      }
                    }}
                      justifyContent={"end"}>

                      <Typography
                        align="right"
                        onClick={() => { console.log("review", member) }}
                        sx={themeStyles.typography.h60}
                        color={colors.greenFlow[300]}>
                        Save
                      </Typography>
                    </Button>
                  </Tooltip> */}

                </LastTableCell>



              </TableRow>
              </Grow>)
          })
        }


      </TableBody>
      </Grow>
    </>
    
  )
}
