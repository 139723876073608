import React, { useEffect } from 'react'; // Import useEffect hook
import { useDispatch, useSelector } from 'react-redux';
import GetCasesAPI from '../../../functions/api/data/getCases';
import { updateUNSterlizedCases } from '../../../states/slices/cases/casesSlice';

export async function HandleGetCasesByDate(selectedDate, Defaultworkspace, user) {
  const dispatch = useDispatch();
  try {
    const response = await GetCasesAPI(user.userID, Defaultworkspace.defaultWorkspaceID, selectedDate, selectedDate);
    const results = await response.json();
    console.log("response sterlizedData", results);

    if (results.statusCode === 200) {
      switch (results.body.length) {
        case 0:
          console.log("No cases found");
          break;
        default:
          dispatch(updateUNSterlizedCases(results.body)); // Assuming dispatch is available
          break;
      }
    } else {
      // Handle error case
      console.error("Error:", results.error);
    }
  } catch (error) {
    console.error("Loading Error:", error);
  }
}

// export function HandleGetCasesByDate(selectedDate, Defaultworkspace, user) {
//   // const Defaultworkspace = useSelector((state) => state.Defaultworkspace);
//   // const user = useSelector((state) => state.user);
//   const dispatch = useDispatch();

//   // Move the fetching logic inside the useEffect hook
//   useEffect(() => {

//     GetCasesAPI(user["userID"], Defaultworkspace["defaultWorkspaceID"], selectedDate, selectedDate)
//       .then(response => response.text())
//       .then(results => {
//         console.log("response sterlizedData 1", results);
//         const respond = JSON.parse(Object.assign(results));
//         console.log("response sterlizedData 2", respond);

//         if (respond["statusCode"] === 200) {
//           //dispatch(updateUNSterlizedCases(respond["body"]));

//           switch (respond["body"].length) {
//             case 0:
//               console.log("response sterlizedData 3", respond);
//               break;

//             default:
//               dispatch(updateUNSterlizedCases(respond["body"]));
//               break;
//           }
//         } else {
//           // Handle error case
//         }
//       })
//       .catch(errors => {
//         console.log("Loading Error ", errors);
//       });
//   }, []); // Empty dependency array means this effect will run once, similar to componentDidMount
// }
