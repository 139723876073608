import * as React from 'react';
import { useEffect, useState } from 'react';

// Styling
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
//import { makeStyles, styled } from '@mui/styles';
import { styled } from '@mui/system';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import { ColorModeContext, tokens, themeSettings } from '../../../../utilityComponents/theme' 
import MultipleFileUploader from './MultipleFileUploader';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import LoadingButton from '@mui/lab/LoadingButton';
import {Formik} from 'formik'
import { Grid } from '@mui/material';

//Functions
import {HealthCheckBackend } from '../../../../functions/api/data/settings';
import { Box } from '@mui/system';

//
//
// Components STYLINGs START
//
//
//

const colors = tokens("dark");
const themeStyles = themeSettings("dark")


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //backgroundColor: colors.dark_grey[700],

 '& .MuiDialog-container':{
  backgroundColor: 'rgba(196, 196, 196, 0.3)',
  //width:"100%"
  //backgroundColor:"pink"
 },
  '& .MuiDialog-paper':{
    //backgroundColor: colors.dark_grey[400],
    width:"62.50%",
    height:"46.88%",
    borderRadius:"8px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    color:"red"
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

 const UploadBtnStyle = {
    //backgroundColor: colors.dark_grey[400],
    color: colors.greenFlow[100],
    
    '&:hover': {
      backgroundColor: 'transparent',
      //color: '#3c52b2',
  },
  }

  // const useStyles = makeStyles({
    
  //   saveBtnStyle:{
  //     color: colors.text[500],
  //     fontStyle:"SemiBold"
  //   },
  //   cancelBtnStyle:{
  //     color: colors.text[600]
  //   }
    
  // })  


//
//
// Components STYLINGs END
//
//
//


  
export default function FileUploadForm(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  //const stylingClasses = useStyles()

 useEffect(() => {

  HealthCheckBackend().then(response => {
    //console.log(response)
    if (response.status===200){
      setLoading(false)
    }


  }) 
}, []) 




  const handleClickOpen = () => {
    setOpen(true);
    
  }; 

  const handleSave = ()=>{
    setOpen(false);
  }
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>

      <LoadingButton
          size="small"
          onClick={handleClickOpen}
          endIcon={<SendIcon sx={{color:colors.greenFlow[100]}} />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          <span>
            <Typography sx={[themeStyles.typography.h61, UploadBtnStyle]}>
            {loading? "Starting...":"Upload Cases"}
        </Typography>
        </span>
        </LoadingButton>




      <BootstrapDialog
        //overlayStyle={{backgroundColor: 'red'}}
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        >


        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
          
          <Typography sx={themeStyles.typography.h411}>Upload New DICOM Studies</Typography>
        
        </BootstrapDialogTitle>


        <DialogContent >
         
         <Formik initialValues={{}} onSubmit={()=>{}}>
         
         <MultipleFileUploader/>

          </Formik>

        </DialogContent>


        <DialogActions>
 {/*          <Button   onClick={handleCancel}>
            <Typography sx={themeStyles.typography.h6} className={stylingClasses.cancelBtnStyle}>Cancel</Typography>
          </Button> */}
          {/* <Button  onClick={handleSave}>
          <Typography sx={themeStyles.typography.h6} className={stylingClasses.saveBtnStyle}>Close</Typography>
          </Button> */}
          <Button onClick={handleSave}>
            <Typography sx={[themeStyles.typography.h6, {
              color: colors.text[500],
              fontStyle: "SemiBold"
            }]}
              //className={stylingClasses.saveBtnStyle}
              >Close</Typography>
          </Button>
        </DialogActions>


      </BootstrapDialog>

{/*       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openBackDrop}
        //onClick={()=>(setOpen(false))}
      >
        <CircularProgress color="inherit"  />
      </Backdrop>
 */}
    </div>
  );
}