import { useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ColorModeContext, tokens, themeSettings } from "../../../../../utilityComponents/theme";
//import { makeStyles } from "@mui/styles";
import { TextField, IconButton,Button,Tooltip, Typography } from '@mui/material'
//import { Comment } from 'styled-icons/boxicons-solid';
import { useDispatch } from 'react-redux';
import { updatefindings } from '../../../../../states/slices/cases/casesSlice';

const findingsTableElements=["NQ.", "Laterality","View Position","Size x-axis","Size y-axis","Comments"]


// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(),
//       width: "100%"
//     },
//     "& .MuiOutlinedInput-root": {
//       //position: "relative"
//     },
//     "& .MuiIconButton-root": {
//       position: "absolute",
//       top: 0,
//       right: 0
//     }
//   }
// }));



function Row(props) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")
  const { row,index, addcomments } = props;
  const [open, setOpen] = React.useState(false);
  const [description, setDescription] = React.useState(row["structureReporting"]["description"]||"");
  const dispatch= useDispatch();

 function AddComments2Finding(comment){
//console.log("AddComments2Finding", comment)
setDescription(comment)

// const newFinding={...row,structureReporting:{...row["structureReporting"],"description":comment}}

// console.log("AddComments2Finding", newFinding)

const structureReporting={
  "name":row["structureReporting"]["name"],
  "description":description
}
dispatch(updatefindings({ id: row["id"], updatedValues: { "structureReporting": structureReporting } }))


 }


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
{/*         <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="right">{index?index:""}</TableCell>
        <TableCell align="right">{row["structureReporting"]["name"]?row["structureReporting"]["name"]:""}</TableCell>
        <TableCell align="right">{row.type?row.type:""}</TableCell>
        <TableCell align="right">{row.data.width?row.data.width:""}</TableCell>
        <TableCell align="right">{row.data.height?row.data.height:""}</TableCell>
        <TableCell align="right">{row.data.area?row.data.area:""}</TableCell>
        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}
            //width={"100%"} display={"flex"} 
            >
              <Typography variant="h6" gutterBottom component="div">
                Description:
              </Typography>


        {/* <TextField
              sx={{ width: "100%" }}
              fullWidth
              id="outlined-multiline-static"
              placeholder="Describe your finding"
              multiline
              rows={4}

              //value={row["structureReporting"]["description"]?row["structureReporting"]["description"]:""}
              value={description}
              //defaultValue="Default Value"
              variant="outlined"
              onChange={(newValue) => {AddComments2Finding(newValue.target.value)}}
              /> */}

              <Typography
              sx={{ width: "100%" }}
              fullWidth
              id="outlined-multiline-static"
              //placeholder="Describe your finding"
              multiline
              rows={4}
              
              //value={row["structureReporting"]["description"]?row["structureReporting"]["description"]:""}
              //defaultValue="Default Value"
              //variant="outlined"
              //onChange={(newValue) => {AddComments2Finding(newValue.target.value)}}
              > {description}</Typography>        

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function FindingsTable({findings}) {
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")

    // useEffect(() => {
    //   console.log("findings report",findings)
    //     },[findings])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">Number</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Width</TableCell>
            <TableCell align="right">Height</TableCell>
            <TableCell align="right">Area&nbsp;(cm²)</TableCell>
            {/* <TableCell align="right">Area&nbsp;(cm2)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {findings.map((row, index) => (
            <Row key={row.id} row={row} index={index+1} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}



