import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(firstname,lastname) {


    const name = firstname.split(' ')[0] + " " + lastname.split(' ')[0]
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const avatarSX= {
    height:"50",
    width:"50"
}

export default function TeamMembersAvatar({teamMembers, size}) {
    console.log("TeamMembersAvatar", teamMembers)

  return (
    <Stack direction="row" spacing={2}>
      {
      teamMembers.map((member, index)=>{
        const name = member.firstname.split(' ')[0] + " " + member.lastname.split(' ')[0]

        //const Employeename = member.firstname +" "+ member.lastname
        return (<Tooltip title={`${member.firstname} ${member.lastname}`}>
      <Avatar {...stringAvatar(member.firstname,member.lastname)} 
      sx={{width:size==="small"?24:50, height: size==="small"?24:50, fontSize:size==="small"?10:18, bgcolor: stringToColor(name), color:"white"}} />
      </Tooltip>)

        })
      }
      
    </Stack>
  );
}