import React, { useEffect } from 'react'; // Import useEffect hook
import { useDispatch, useSelector } from 'react-redux'
import Getnotifications from '../../../functions/api/notifications/getnotifications'
import { addNotification } from '../../../states/slices/notification/notificationSlice'

export function GetNotifications() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // Move the fetching logic inside the useEffect hook
  useEffect(() => {
    Getnotifications(user["userID"])
    .then(response => response.text())
    .then(result => {
      const respond = JSON.parse(Object.assign(result))
      console.log("notifications", respond)
      if (respond["statusCode"]===200){
        console.log("notifications body", respond["body"])
        dispatch(addNotification(respond["body"]))
      //setNotifications(respond["body"])
    }
  }).catch(error =>
    {
      //setOpenSnackbar({ ...openSnackbar, open: true, Message: "An Error occurred while getting your notifications.", severity: "warning" })
  })
  }, []); // Empty dependency array means this effect will run once, similar to componentDidMount
}
