import React, { useState, useEffect, useRef, useMemo } from 'react';

import { Box, FormGroup, Stack, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, TextareaAutosize, Button, Avatar, Grid } from '@mui/material';
import WelcomeMessage from './WelcomeMessage';
import CompanyLogo from "../../../utilityComponents/images/iconPNG.png";
import axios from 'axios';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { useResponsiveQueries } from '../../../utilityComponents/device';
import { useSelector } from 'react-redux';
//import WebSocket from 'websocket';
import Switch from '@mui/material/Switch';


//const HumanIcon = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
const AiIcon = "https://cdn-icons-png.flaticon.com/512/9887/9887771.png";
const LoadingGif = "https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif";





const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

// const useStyles = makeStyles({
//   container: {
//     //backgroundColor: '#252525',
//     //minHeight: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     fontFamily: 'Arial, sans-serif',
//     padding: '20px',
//     width: '70%',
//   },
//   header: {
//     fontFamily: 'Open Sans',
//     fontSize: '64px',
//     fontWeight: '400',
//     lineHeight: '72px',
//     letterSpacing: '-0.015em',
//     textAlign: 'center',
//     color: '#fff',
//     marginBottom: '20px',
//     background: 'linear-gradient(180deg, #17DFE9 0%, #3A9DF2 100%)',
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent',
//     position: 'relative',
//     //top: '20px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     width: 'inherit',
//     zIndex: 1, // Ensure the header appears above other elements
//   },
//   logo: {
//     width: '100px',
//     height: 'auto',
//     marginBottom: '10px',
//   },
//   messageHistory: {
//     width: 'inherit',
//     // Adjusted maxHeight calculation
//     overflowY: 'auto',
//     zIndex: 1, // Ensure the message history appears above other elements
//   },
//   userMessageContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//     marginBottom: '10px',
//   },
//   botMessageContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     marginBottom: '10px',
//   },
//   welcomeMessage: {
//     fontFamily: 'Open Sans',
//     fontSize: '64px',
//     fontWeight: 400,
//     lineHeight: '72px',
//     letterSpacing: '-0.015em',
//     textAlign: 'center',
//     margin: '20px 0',
//     padding: '20px',
//     borderRadius: '10px',
//     color: 'transparent',
//     background: 'linear-gradient(180deg, #17DFE9 0%, #3A9DF2 100%)',
//     WebkitBackgroundClip: 'text',
//     backgroundClip: 'text',
//   },
//   icon: {
//     width: '30px',
//     marginRight: '10px',
//   },
//   userMessage: {
//     backgroundColor: '#f0f0f0',
//     padding: '10px',
//     borderRadius: '10px',
//     fontSize: '16px',
//     color: "black"
//   },
//   botMessage: {
//     backgroundColor: '#e1f5fe',
//     padding: '10px',
//     borderRadius: '10px',
//     fontSize: '16px',
//     maxWidth: '80%',
//     color: "black"
//   },
//   inputContainer: {
//     position: 'fixed',
//     bottom: '0',
//     width: '95%',
//     //width: 'inherit',
//     padding: '10px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     zIndex: 1, // Ensure the input container appears above other elements
//   },
//   userInput: {
//     backgroundColor: '#9F9F9F',
//     color: 'black',
//     flex: '1',
//     padding: '8px',
//     fontSize: '16px',
//     minHeight: '80px',
//     resize: 'vertical',
//     borderRadius: '5px',
//   },
//   sendButton: {
//     marginLeft: '20px',
//     height: '80px',
//     padding: '8px 16px',
//     fontSize: '16px',
//     cursor: 'pointer',
//     backgroundColor: '#17DFE9',
//     color: 'white',
//     border: 'none',
//     borderRadius: '5px',
//     display: 'flex',
//     alignItems: 'center',
//   },
//   loadingIcon: {
//     width: '20px',
//     marginRight: '5px',
//   },
// });


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Arial, sans-serif',
  padding: '20px',
  width: '70%',
});

const StyledGrid = styled(Grid)({
  fontFamily: 'Open Sans',
  fontSize: '64px',
  fontWeight: '400',
  lineHeight: '72px',
  letterSpacing: '-0.015em',
  textAlign: 'center',
  //color: '#fff',
  marginBottom: '20px',
  background: 'linear-gradient(180deg, #17DFE9 0%, #3A9DF2 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'inherit',
  zIndex: 1,
});

const StyledLogo = styled('img')({
  width: '100px',
  height: 'auto',
  marginBottom: '10px',
});

const MessageHistory = styled(Grid)({
  width: 'inherit',
  overflowY: 'auto',
  zIndex: 1,
  maxHeight: 'calc(80vh - 200px)',
});

const MessageContainer = styled('div')(({ isUser }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isUser ? 'flex-start' : 'flex-end',
  marginBottom: '10px',
  width: "50%"
}));

const Icon = styled('img')({
  width: '30px',
  marginRight: '10px',
});

const UserMessage = styled('div')({
  backgroundColor: '#f0f0f0',
  padding: '10px',
  borderRadius: '10px',
  fontSize: '16px',
  color: 'black',
});

const BotMessage = styled('div')({
  backgroundColor: '#e1f5fe',
  padding: '10px',
  borderRadius: '10px',
  fontSize: '16px',
  maxWidth: '80%',
  color: 'black',
});

const StyledMessageText = styled(Typography)(({ isUser }) => ({
  backgroundColor: isUser ? '#f0f0f0' : '#e1f5fe',
  padding: '10px',
  borderRadius: '10px',
  fontSize: '16px',
  color: 'black',
  // Additional styles here
}));

const MessageText = ({ isUser, children }) => {
  return (
    <StyledMessageText variant="body1" isUser={isUser}>
      {children}
    </StyledMessageText>
  );
};

const InputContainerGrid = styled(Grid)({
  position: 'fixed',
  bottom: '0',
  width: '95%',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
});

const UserInput = styled('textarea')({
  backgroundColor: '#9F9F9F',
  color: 'black',
  flex: '1',
  padding: '8px',
  fontSize: '16px',
  minHeight: '80px',
  resize: 'vertical',
  borderRadius: '5px',
});

const SendButton = styled('button')({
  marginLeft: '20px',
  height: '80px',
  padding: '8px 16px',
  fontSize: '16px',
  cursor: 'pointer',
  backgroundColor: '#17DFE9',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
});

const LoadingIcon = styled('img')({
  width: '20px',
  marginRight: '5px',
});

// Define styled components
// const StyledGrid = styled(Grid)(({ theme }) => ({
//   width: '100%',
// }));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '20px',
  padding: '8px 16px',
  height: "90px",
  fontSize: '16px',
  cursor: 'pointer',
  backgroundColor: '#17DFE9',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const Medicalassistant = () => {
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  //const classes = useStyles();

  const [userInput, setUserInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedQ, setIsCheckedQ] = useState(false); // State variable for Q switch
  const inputRef = useRef(null);
  const messageContainerRef = useRef(null);
  const subWelcomeMessage = "You can ask questions about your medical reports (source documents). NOVU Medical assistance is currently under preview and can answer questions in Engllish."


  //const apiName = 'https://l0pyqge7og.execute-api.eu-central-1.amazonaws.com/dev/qbusiness';
  const apiName = 'https://2qqtk3f5qvwr5x4355xkewm4uu0eyjiu.lambda-url.eu-central-1.on.aws/'
  const pathq = '/qbusiness';
  const { isMobile } = useResponsiveQueries();
  const user = useSelector((state) => state.user)
  const HumanIcon = user.avatarUrl
  const processedMessageIDs = new Set();
  const [connId, setConnId] = useState(null);
  const [socketConn, setSocketConn] = useState(null); // Declare socketConn as a state variable


  const handleSwitchQChange = (event) => {
    setIsCheckedQ(event.target.checked); // Update state variable for Q switch
    console.log("Event", event.target.checked)
  };


  const sendFirstMessage = async () => {
    try {
      console.log("Sending initial request...");
      const response = await axios.post(
        apiName,
        {
          userMessage: "I have some questions about my reports stored in the knowledge base. Can you help me answering them",
          userId: "20"
        }
      );

    } catch (error) {
      console.error('POST call failed: ', error);
    }
  };
  useMemo(() => { sendFirstMessage() }, []);

  /////////////////////////////////////////////
  //
  //    WEBSOCKET
  //
  //
  /////////////////////////////////////////////

  //   const ws = useRef(null);
  //   useEffect(() => {
  //     // Create WebSocket connection
  //     const ws = new WebSocket(`wss://q2qyy9l2b2.execute-api.eu-central-1.amazonaws.com/dev/?userId=${user["userEmail"]}`);

  //     // Connection opened
  //     ws.onopen = () => {
  //       console.log('WebSocket connection opened.');
  //     };

  //     ws.onconnect = () => {
  //       console.log('WebSocket connection established.');
  //     };

  //     // Listen for messages
  //   ws.addEventListener('message', function (event) {
  //   console.log('Message from server:', event.data);

  //   // Handle the received message here, e.g., update UI
  //   const message = JSON.parse(event.data);
  //   if (message.type === 'message') {
  //       // Update UI with the received message content
  //       console.log('Received message:', message.content);
  //   }
  // });



  //     // Listen for messages
  //     ws.onmessage = (event) => {
  //       console.log('Received message:', event.data);
  //       const botResponse = event.data;
  //       const newBotMessage = { message: botResponse, isUser: false };
  //       setChatMessages(prevMessages => [...prevMessages, newBotMessage]);
  //       scrollToBottom();
  //     };

  //     // Error handling
  //     ws.onerror = (error) => {
  //       console.error('WebSocket error:', error);
  //     };

  //     // Cleanup on component unmount
  //     return () => {
  //       ws.close();
  //       console.log('WebSocket connection closed.');
  //     };
  //   }, []); // Empty dependency array to run this effect only once on component mount

  /////////////////////////////////////////////
  //
  //    Websocket connection to Bedrock
  //
  //
  /////////////////////////////////////////////

  useEffect(() => {
    // Establish WebSocket connection when the component mounts
    const conn = new WebSocket(`wss://tb0n3s8qm3.execute-api.eu-central-1.amazonaws.com/dev?userId=${user.userID}`);

    conn.addEventListener('open', () => {
      console.log('WebSocket is connected', conn);
      setSocketConn(conn);
    });

    conn.addEventListener('close', () => {
      console.log('WebSocket Connection is closed');
    });

    conn.addEventListener('error', (error) => {
      console.error('WebSocket Connection is in error', error);
    });


    conn.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.connId) {
        // Save the connection ID
        setConnId(data.connId);
      } else if (data.message) {
        console.log('Message from server:', data.message);

        const messageID = data.message.messageID;

        // Check if the messageID has been processed before
        if (!processedMessageIDs.has(messageID)) {
          // Process the message
          recievedMessage(data.message.data);

          // Add the messageID to the set of processed IDs
          processedMessageIDs.add(messageID);
        } else {
          // Message already processed, ignore it
          console.log('Duplicate message ignored:', messageID);
        }
      }
    });

    // Clean up function to disconnect the WebSocket when the component unmounts
    return () => {
      conn.close();
    };
  }, [user.userID]); // Include user.id in dependency array to send it when it changes

  function sendMessage(userInput) {

    if (socketConn) {
      const payload = {
        eventType: "CLIENTMESSAGE",
        user_id: user.userID,
        conversation_id: "20",
        message: userInput
      };
      socketConn.send(JSON.stringify(payload));
      console.log("Sending request...");
      setUserInput("")

    } else {
      console.error("WebSocket connection not established yet.");
    }
  }

  function recievedMessage(response) {
    const newBotMessage = { message: response, isUser: false };
    setChatMessages(prevMessages => [...prevMessages, newBotMessage]);


  }
  /////////////////////////////////////////////
  //
  //    REST
  //
  //
  /////////////////////////////////////////////


  useEffect(() => {
    console.log(isMobile, "isMobile")

    setTimeout(() => {
      const botResponse = "How can I help you today?";
      const botMessage = { message: botResponse, isUser: false };
      setChatMessages(prevMessages => [...prevMessages, botMessage]);
      scrollToBottom();
    }, 1000);
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);


  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (!userInput) return;

    //sendMessageQ(userInput);
    sendMessageBedrock(userInput)
  };

  function sendMessageBedrock(userInput) {
    const userMessage = {
      userMessage: userInput,
      userId: "20",
      action: "message"
    };

    const newUserMessage = { message: userInput, isUser: true };
    setChatMessages(prevMessages => [...prevMessages, newUserMessage]);

    setIsLoading(true);

    try {
      sendMessage(userInput)
      // const config = {
      //   method: 'post',
      //   //url: 'https://2qqtk3f5qvwr5x4355xkewm4uu0eyjiu.lambda-url.eu-central-1.on.aws/',
      //   url: 'genaib',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   data: JSON.stringify(userMessage)
      // };

      // axios(config)
      //   .then(response => {
      //     console.log(JSON.stringify(response.data));
      //     // Update UI with response data
      //   })
      //   .catch(error => {
      //     console.error('POST call failed: ', error);
      //     // Display error message in UI
      //   })
      //   .finally(() => {
      //     if (!isLoading) { // Assuming you have a state variable for loading
      //       setIsLoading(false);
      //     }
      //   });
      setIsLoading(false);
    } catch (error) {
      console.error('POST call failed: ', error);
      // Handle any generic errors
    }
  }


  const sendMessageQ = async (userInput) => {


    const userMessage = {
      userMessage: userInput,
      userId: "20",
      "action": "message"
    }
    console.log("userMessage", userMessage)

    const newUserMessage = { message: userInput, isUser: true };
    setChatMessages(prevMessages => [...prevMessages, newUserMessage]);

    setIsLoading(true);
    //sendDataToLambda2();

    try {
      console.log("Sending request...");
      setUserInput("")

      const response = await axios.post(apiName, userMessage);

      console.log(response, "response");
      // console.log(response);
      // console.log(response.data);
      if (response.status == 200) {
        console.log(response.json())
        const newBotMessage = { message: response.data, isUser: false };
        setChatMessages(prevMessages => [...prevMessages, newBotMessage]);
      }
    } catch (error) {
      console.error('POST call failed: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  //   const sendDataToLambda2 = async () => {
  //     var data = JSON.stringify({
  //     "userMessage": "I have some questions about my reports stored in the knowledge base. Can you help me answering them",
  //     "userId": "20"
  //   });

  //   var xhr = new XMLHttpRequest();
  //   xhr.withCredentials = true;

  //   xhr.addEventListener("readystatechange", function() {
  //     if(this.readyState === 4) {
  //       console.log(this);
  //     }
  //   });

  //   xhr.open("GET", "https://cwnfnczwh33pfhse5kr7uykdnm0obfhy.lambda-url.eu-central-1.on.aws/");
  //   xhr.setRequestHeader("Content-Type", "application/json");

  //   xhr.send(data);
  // }


  //   const sendDataToLambda = async () => {
  //     try {
  //       console.log("Sending request...");
  //       const data = JSON.stringify({
  //         userMessage: "I have some questions about my reports stored in the knowledge base. Can you help me answering them",
  //         userId: "20"
  //       });

  //       const config = {
  //         method: 'get',
  //         maxBodyLength: Infinity,
  //         url: 'https://cwnfnczwh33pfhse5kr7uykdnm0obfhy.lambda-url.eu-central-1.on.aws/',
  //         headers: { 
  //           'Content-Type': 'application/json'
  //         },
  //         data: data
  //       };

  //       // Set a timeout for the request (e.g., 2 minutes)
  //       const timeoutMillis = 3 * 60 * 1000; // 2 minutes
  //       const response = await axios.request(config, { timeout: timeoutMillis });

  //       console.log('POST call succeeded');
  //       console.log(response.status);
  //       console.log(response.data);

  //       const newBotMessage = { message: response.data, isUser: false };
  //       setChatMessages(prevMessages => [...prevMessages, newBotMessage]);
  //     } catch (error) {
  //       console.error('POST call failed: ', error);
  //       // Handle errors here, e.g., display an error message to the user
  //     }
  //   };



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main>
        <Toolbar variant="dense" />
        <Box >

          {/*     //
        //
        // START ADDING ALL PAGE CONTENTS HERE
        //
        // 
*/}
          <Box sx={{ width: '100%',  maxHeight: `calc(80vh - 200px)`}}>
          {chatMessages.slice(1).length > 1 ? "" :
                <StyledGrid item xs={12} sm={12}>
                  <StyledLogo src={CompanyLogo} alt="Company Logo" />
                  <WelcomeMessage />
                  <Typography
                    sx={[themeStyles.typography.h60, { color: colors.dark_grey[100] }]}
                  >{subWelcomeMessage}</Typography>
                </StyledGrid>

              }
            <Grid container spacing={2} sx={{"overflow-y": "auto", maxHeight: `calc(100vh - 200px)`}}>

              {chatMessages.slice(1).map((message, index) => (
                <MessageHistory
                  xs={12}
                  sm={12}
                  sx={{
                    maxHeight: chatMessages.slice(1).length > 1 ? '80vh' : `calc(100vh - 200px)`,
                    display: "flex",
                    justifyContent: message.isUser ? "flex-start" : "flex-end"
                  }}
                  ref={messageContainerRef}
                  id="MessageHistory"
                >
                  <MessageContainer key={index} isUser={message.isUser}>
                    <Avatar src={message.isUser ? HumanIcon : AiIcon} alt="Icon"
                      //className={classes.icon} 
                      sx={{ width: "30px", height: "30px", marginRight: '10px' }}
                    />
                    <MessageText isUser={message.isUser}>{message.message}</MessageText>
                  </MessageContainer>
                </MessageHistory>
              ))}


              <InputContainerGrid item xs={12} sm={12}
                //className={classes.inputContainer} 
                id={"Textarea"}>
                <UserInput
                  ref={inputRef}
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  //className={classes.userInput}
                  placeholder="How can I help you today?"
                />
                < LoadingButton onClick={handleSendMessage}
                  loading={isLoading}
                  endIcon={<SendIcon />}
                  variant="contained" sx={{
                    marginLeft: '20px',
                    padding: '8px 16px',
                    height: "90px",
                    fontSize: '16px',
                    cursor: 'pointer',
                    backgroundColor: '#17DFE9',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <span>Send</span>
                </LoadingButton>
              </InputContainerGrid>
            </Grid>
          </Box>
          {/*     //
        //
        // END PAGE CONTENTS HERE
        //
        // 
*/}


        </Box>
      </Main >
    </Box >
  );
};

export default Medicalassistant;
