import React from 'react'
import MailIcon from "@mui/icons-material/Mail";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MapIcon from "@mui/icons-material/Map";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import {Zoom, Unknown4, Unknown5, ROI, Unknown7}  from "../../../utilityComponents/images/viewerIcons";
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import 'viewports-core';

import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import TonalityOutlinedIcon from '@mui/icons-material/TonalityOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';


import './contextMenu.css'
import { IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';

export function ViewerMenuContext(){
    const colors = tokens("dark");
    const iconColor = colors.novuColors[120]
    const findingColor = colors.novuColors[110]

    useEffect(() => {
      openItems()
    }, [])
    
    
    function zoom() {
    global.viewportsCore.setMouseButtonFunction(1, 2);
  }
  function windowing() {
    global.viewportsCore.setMouseButtonFunction(1, 1);
  }
  function rotate() {
    viewportsCore.getActiveViewport().applyRightRotation();
  }
  function pan() {
    global.viewportsCore.setMouseButtonFunction(1, 5);
  }
  function flip() {
    viewportsCore.getActiveViewport().applyHorizontalFlip();
  }

  function handleMeasurementChange(tool){
    global.viewportsCore.setMouseButtonFunction(1, 5);
        global.viewportsCore.measurementsController.setActiveMeasurementType(tool);
  }

    function openItems(){
        let toggle = document.querySelector('.toggle');
        let menu = document.querySelector('.menu');
        menu.classList.toggle('active')
    }
      return (
        <div style={{background:"transparent"}} class="menu" >
            <div class="toggle" onClick={openItems}>                
                <AddIcon sx={{fill:"black"}}/>
                </div>
            <li style={{"--i":0}} >
                <Tooltip title={"Zoom"}>
                    <IconButton onClick={()=>{zoom()}}>
                        <a><Zoom sx={{fill:iconColor}}/></a>
                    </IconButton>
                </Tooltip>
            </li>
            <li style={{"--i":1}} >
            <Tooltip title={"Rotate"}>
            <IconButton onClick={()=>{rotate()}}>
                <a href='#'><Rotate90DegreesCcwOutlinedIcon  
                sx={{backgroundColor:"transparent",
                    fill:iconColor}}
                /></a>
                </IconButton>
                </Tooltip>
            </li>
            <li style={{"--i":2}} >
            <Tooltip title={"Pan"}>
            <IconButton onClick={()=>{pan()}}>
                <a href='#'><PanToolAltOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:iconColor}}/></a>
                </IconButton>
                </Tooltip>
            </li>
            <li style={{"--i":"3"}} >
            <Tooltip title={"Windowing/Leveling"}>
            <IconButton onClick={()=>{windowing()}}>
                <a href='#'><TonalityOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:iconColor}}/></a>
                </IconButton>
                </Tooltip>
            </li>
            <li style={{"--i":4}} >
            <Tooltip title={"Flip"}>
            <IconButton onClick={()=>{flip()}}>
                <a href='#'><FlipOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:iconColor}}/></a>
                </IconButton>
                  </Tooltip>
            </li>
            <li style={{"--i":5}} >
            <Tooltip title={"Polyline measurement"}>
            <IconButton onClick={(event)=>{handleMeasurementChange('polyline')}}>
                <a href='#'><PolylineOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:findingColor}}/></a>
                </IconButton>
                  </Tooltip>
            </li>
            <li style={{"--i":6}} >
            <Tooltip title={"Oval measurement"}>
            <IconButton onClick={(event)=>{handleMeasurementChange('oval')}}>
                <a href='#'><CircleOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:findingColor}}/></a>
                </IconButton>
            </Tooltip>
            </li>
            <li style={{"--i":7}} >
            <Tooltip title={"Distance measurement"}>
                <IconButton onClick={(event)=>{handleMeasurementChange('ruler')}}>
                <a href='#'><HorizontalRuleOutlinedIcon sx={{backgroundColor:"transparent",
                    fill:findingColor}}/></a>
                </IconButton>
            </Tooltip>
            </li>

        </div>

      )}