import React, { useState, useRef, useEffect } from 'react';

import "./Tcontroller.css";
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { tokens, themeSettings } from "../../../utilityComponents/theme";
import SearchBarWithIcon from '../searchbarWithIcon/searchbarWithIcon';
import ColumnsVisibility from './controllers/ColumnVisibility';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';


const eye = <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14 8.79565 13.6839 9.55871 13.1213 10.1213C12.5587 10.6839 11.7956 11 11 11C10.2044 11 9.44129 10.6839 8.87868 10.1213C8.31607 9.55871 8 8.79565 8 8C8 7.20435 8.31607 6.44129 8.87868 5.87868C9.44129 5.31607 10.2044 5 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C20.27 12.39 16 15.5 11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5ZM2.18 8C3.83 11.36 7.24 13.5 11 13.5C14.76 13.5 18.17 11.36 19.82 8C18.17 4.64 14.76 2.5 11 2.5C7.24 2.5 3.83 4.64 2.18 8Z" fill="#F5F5F5" />
</svg>

const search = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="#F5F5F5" />
</svg>

const sort = <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.998 14H20.998L16.998 18L12.998 14H15.998V0H17.998V14ZM0.998047 14H10.998V16H0.998047V14ZM4.99805 2V4H0.998047V2H4.99805ZM0.998047 8H7.99805V10H0.998047V8Z" fill="#F5F5F5" />
</svg>

const menu2 = <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="#F5F5F5" />
</svg>

const symbolVerySuspicious = <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7" cy="7.22656" r="7" fill="#E36D6A" />
</svg>

const symbolSuspicious = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7" cy="7" r="7" fill="#F7AB34" />
</svg>
const symbolNormal = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7.86328" cy="7.3125" r="7" fill="#3A9DF2" />
</svg>

const SuspiciousLevels = [
  {
    text: "Very Suspicious",
    icon: symbolVerySuspicious,
  },
  {
    text: " Suspicious",
    icon: symbolSuspicious,
  },
  {
    text: "Normal",
    icon: symbolNormal,
  },
];


const TableController = ({sorting,showClassifications, columnNames, onColumnsVisibilityChange, RefreshCases}) => {
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  function handleSearchField() {
    setExpanded(!expanded);
    setSearchValue('');
  }


  return (

    <>

      <Grid container spacing="0">
        {showClassifications ? SuspiciousLevels.map((Item, index) => (
          <Grid item id={index} key={index} marginRight="26px" 
          alignItems="center" justifyContent="Left">
            <Typography id={index} color={colors.text[100]} sx={[themeStyles.typography.h51]}>{Item.icon} {Item.text}</Typography>
          </Grid>
        )) : ""}
      </Grid>

      


      <Grid item xl={12} md={12}
        display="flex"
        justifyContent="end" 
        alignItems="center">
          
        <Box display={"flex"} flexDirection={"row"} width={"inherit"} >
        <SearchBarWithIcon searchValue={searchValue} expanded={expanded}/>
        <ColumnsVisibility onColumnsVisibilityChange={onColumnsVisibilityChange} columnNames={columnNames}/>
        <IconButton 
        //onClick={handleMenuClick}
        >
        {sort}
      </IconButton>
      <IconButton 
      //onClick={handleMenuClick}
      >
        {menu2}
      </IconButton>
      <Tooltip title="Refresh">
      <IconButton 
      onClick={RefreshCases}
      >
        <RefreshOutlinedIcon/>
      </IconButton>
      </Tooltip>

      


        </Box>  
      </Grid>
    </>


  )
}

export default TableController;