import React from 'react'
import { useEffect, Suspense, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

import Backdrop from '@mui/material/Backdrop';
import { Box, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import {HealthCheckAll } from "../../../functions/api/data/settings";


function SystemHeathcheck() {
    const theme = useTheme();
    const colors = tokens("dark");
    const themeStyles = themeSettings("dark")
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null)


    function healthCheck() {
        setOpen(true)
        HealthCheckAll().then(res => {
            setData(res)
            setOpen(false)
        })
    }
    return (
        <><Box>
            <Button onClick={healthCheck} size="large" variant="text"
                sx={{
                    color: colors.novuColors[120]
                }}> System Health Check</Button>
            <Typography>Main: {data?.status_code === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>orchestrator: {data?.responds.orchestrator === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>ai_breast: {data?.responds.ai_breast === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>ai_covid: {data?.responds.ai_covid === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>Database: {data?.responds.Database === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>Database2: {data?.responds.Redis === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
            <Typography>PACS: {data?.responds.PACS === 200 ? <CheckOutlinedIcon /> : <ClearOutlinedIcon />}</Typography>
        </Box><Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
                <CircularProgress color="inherit" />
            </Backdrop></>

    )
}

export default SystemHeathcheck