//import { signUp } from "supertokens-web-js/recipe/emailpassword";
import { v4 as uuidv4 } from 'uuid';
import { DeleteUser } from "./deleteuser";
import { RegisterNewTeamAPI } from "../api/organisational/registerTeam";


export async function signUpClicked_rc(values) {
    //console.log("Values from Signup form", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const name = values["firstname"]+" "+values["lastname"]
    var raw = JSON.stringify({
        'username':values["username"],
        'password':values["password"],
        'email':values["email"],
        'name':name,
        'customFields':{
            "role":"doctor",
            "organisationID":uuidv4(),
            "title": values["title"],
            "firstname": values["firstname"],
            "lastname": values["lastname"]
        }

    });
    //console.log("FROM SignUP", raw)
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    //console.log(global.config.api_create_user)
    
    return fetch(global.config.api_create_user, requestOptions)

/*     From this result, you will need to take the authToken and set your in your web browser's LocalStorage Meteor.loginToken with the value of authToken. For example in this case, we would do the following:

    var authToken = 'cXvkTF8X4uu-J_2uWelJGt4iuuTxjD5pFHuqhLOQRLJ';
    localStorage.setItem('Meteor.loginToken', authToken);
    Shortly after doing that, your Rocket.Chat screen should refresh and now you are logged in. */
}

// export async function signUpClicked_st(values) {
//     const formFields= [{
//         id: "email",
//         value: values["email"]
//     }, {
//         id: "password",
//         value: values["password"]
//     }]
//     return await signUp({formFields});



//}
