import React, { useState, Suspense, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import Container from "@mui/material/Container";
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Box, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import {ColorModeContext, tokens, themeSettings} from "../../../utilityComponents/theme";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RegisterNewTeamAPI , GetTeamMembers, GetTeamMembersAPI} from "../../../functions/api/organisational/registerTeam";
import TeamMembersAvatar from "./TeamMembers";
import Stack from '@mui/material/Stack';
import { StyledForm, StyledPaper } from "../../subComponents/forms/formcomponenets";



let RigesterTeamMemberSchema = yup.object().shape({
  firstname: yup.string().required().nullable(false),
  lastname: yup.string().required().nullable(false),
  email: yup.string().email().required().nullable(false),
  role: yup.string().required().nullable(false),
  rights: yup.string().required().nullable(false),

});;

// const useStyles = makeStyles(theme => ({
//   paper: {
//     //marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center"
//   },

//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(3)
//   },
  

// }));

// const StyledPaper = styled(Paper)({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   // Add any additional styles here
// });

// const StyledForm = styled('form')({
//   width: '100%',
//   marginTop: 'theme.spacing(3)', // You need to replace 'theme.spacing(3)' with the actual value you want to use
//   // Add any additional styles here
// });

// const StyledTextField = styled(TextField)({
//   backgroundColor: 'black',
//   '& .MuiFormHelperText-root': {
//     backgroundColor: 'transparent',
//   },
// });

export const RigesterTeamMemberForm = () => {
  //const classes = useStyles();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const fieldBackgroundColor= colors.greenFlow[200]
  const [teamMembers, setTeamMembers] = useState([]);
  const [orgaid, setOrgaID] = useState('');
  
  //const resource = GetTeamMembers(localStorage.getItem("organisationID"));

  const Sucessfullynotify = () => toast.success('Your Team members have been successfully updated!', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });

  const Errornotify = () => toast.error('Could not update your team, please try again.', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });


  const InputFieldStyle = {
    borderRadius:"10px",
  backgroundColor:fieldBackgroundColor
  }

useEffect(() => {
  setOrgaID(localStorage.getItem("organisationID"))
    }, [])

useEffect(() => {
  if(orgaid){
  GetTeamMembers(orgaid)}
    }, [orgaid])


  
  function sendToAPI(values){
    RegisterNewTeamAPI(values)
  .then(response => {

    if (response.status ===200){
      console.log("success")
      Sucessfullynotify()
      GetTeamMembers(orgaid)
    }else{
      console.log("failed")
      Errornotify()
    } 
  })
  .catch(error =>{ console.log(error)});
//  GetTeamMembers(orgaid)

  }

  function GetTeamMembers(values){
    GetTeamMembersAPI(values)
    .then(response => response.text())
      .then(result => {
        const data = JSON.parse(Object.assign(result))
        setTeamMembers(data["body"])
        })
        .catch(error =>{ console.log(error)}); 
}

  /* function getTeamMembers(){
    GetTeamMembersAPI(orgaid)  
    .then(response => {

      if (response.status ===200){
        console.log("get Team success")
        Sucessfullynotify()
      }else{
        console.log("failed")
        Errornotify()
      } 
    })
    .catch(error =>{ console.log(error)});
  }
 */
  const role=[
    {"value":"Receptionist", "label":"Receptionist"},
    {"value":"Clinician", "label":"Clinician"},
    {"value":"Radiologist", "label":"Radiologist"},
    {"value":"Head of Dept.", "label":"Head of Dept."},
    {"value":"IT Admin", "label":"IT Admin"},
  ]

  const rights=[
    {"value":"scheduling", "label":"Scheduling"},
    {"value":"viewReport", "label":"View Reports"},
    {"value":"viewCase", "label":"View Case"},
    {"value":"writeReport", "label":"Write Report"},
    {"value":"signReport", "label":"Sign Report"},
    {"value":"assignCases", "label":"Assign Cases"},
    {"value":"all", "label":"All above"},
  ]


  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper
      //className={classes.paper}
      >
          <Box width={"100%"} justifyContent={"center"} display="flex" >
          <span>
          <Typography
            sx={themeStyles.typography.h4}
            color={"white"}
          >
            Your team members: <br/>
          </Typography>
          </span>

           {teamMembers?
            <Stack direction="row" spacing={2}>
           <TeamMembersAvatar teamMembers={teamMembers}  />
           </Stack>
           :""} 
          </Box>

      
      <Divider/>

        <Formik
          initialValues={{
            //id: id,
            firstname: 'organisation',
            lastname: 'address',
            email: 'email@email.com',
            role: '1',
            rights: '',           
          }}
          validationSchema={RigesterTeamMemberSchema}
          onSubmit={values => {
            
            Object.assign(values,{"organisationid":orgaid})
            Object.assign(values,{"employeeid":uuidv4()})
            //console.log("FORMIK", values)
            sendToAPI(values)
            
          }}
        >
          {({ errors, isValid, handleChange, touched }) => (
            <StyledForm
            //className={classes.form}
            >
              <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                  <OutlinedInput
                    //className = {classes.textField}
                    //error={errors.firstname && touched.firstname}
                    //autoComplete="firstname"
                    //name="firstname"
                    variant="outlined"
                    disabled="true"
                    fullWidth
                    size="small"
                    //onChange={handleChange}
                    //id="firstname"
                    placeholder={"Organisation ID: "+orgaid}
                    sx={InputFieldStyle}
       
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <OutlinedInput
                    //className = {classes.textField}
                    error={errors.firstname && touched.firstname}
                    autoComplete="firstname"
                    name="firstname"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="firstname"
                    placeholder="First name"
                    sx={InputFieldStyle}
       
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <OutlinedInput
                    //className = {classes.textField}
                    error={errors.lastname && touched.lastname}
                    autoComplete="lastname"
                    name="lastname"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="lastname"
                    placeholder="Last name"
                    //autoFocus
                    sx={InputFieldStyle}
  
                  />
                </Grid>

                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.email && touched.email}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                    sx={InputFieldStyle}
                  />
                </Grid>


                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Field name="Role" component={CustomizedSelectForFormik}>
                    
                     {role.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>

                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Rights</InputLabel>
                  <Field name="rights" component={CustomizedSelectForFormik}>
                    
                     {rights.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>
                


                             
                <Grid item xs={12}>

                  <Button 
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid}

                  sx={{
                    backgroundColor:colors.greenFlow[100],
                    ":hover":{
                      backgroundColor:colors.greenFlow[100],
                    }
                  }}
                  //onClick={handleSignIN}
                  size="large" 
              //variant="contained"
              >
                <Typography
                  sx={themeStyles.typography.h5}
                  color={"white"}
                >
                  Submit
                </Typography>
              </Button>

                   
                  </Grid>
                    <Typography>{errors.firstname}</Typography>
                    <Typography>{errors.lastname}</Typography>
                    <Typography>{errors.email}</Typography>
                    <Typography>{errors.role}</Typography>
                    <Typography>{errors.right}</Typography>

              </Grid>

              
            </StyledForm>
          )}
        </Formik>
      </StyledPaper>
      <ToastContainer position="bottom-center"
        autoClose={3000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
    </Container>
  );
};
