import { createSlice } from '@reduxjs/toolkit'


export const casesSlice = createSlice({
    name: "cases",
    initialState: {
        unSterlizedCases: {},
        SterlizedCases: {},
        AIfindings: {},
        Manualfindings: {},
        findings: [],
        ActiveStudiesInViewer: {},
        CurrentInstanceOpenedInViewer: {},
        report: {
            "state": '<p>Hey this <strong>editor</strong> rocks 😀</p>',
            "raw": ""
        }
    },
    reducers: {
        updateUNSterlizedCases: (state, action) => {
            //console.log("cases payload", action.payload)
            state.unSterlizedCases = action.payload
        },
        updateSterlizedCases: (state, action) => {
            //console.log("cases payload", action.payload)
            state.SterlizedCases = action.payload
        },
        updateSterlizedCasesComments: (state, action) => {
            //console.log("cases payload", action.payload)
            state.SterlizedCases = Object.assign(state.SterlizedCases, action.payload)
        },
        updateAIfindings: (state, action) => {
            //console.log("cases payload", action.payload)
            state.AIfindings = action.payload
        },
        updatefindingsOLD: (state, action) => {
            //console.log("updatefindings Slice payload", action.payload)
            state.findings = action.payload
        },
        resetfindings: (state, action) => {
            //console.log("resetfindings", action.payload)
            state.findings = []
        },
        bulkUpdateFindings: (state, action) => {
            state.findings = action.payload
        },
        updatefindings: (state, action) => {
            //console.log("updatefindings Slice payload", action.payload)
            const { id, updatedValues } = action.payload;
            const existingObjectIndex = state.findings.findIndex(obj => obj.id === id);

            //console.log("State updated", state.findings[existingObjectIndex])

            if (existingObjectIndex !== -1) {
                state.findings[existingObjectIndex] = {
                    ...state.findings[existingObjectIndex],
                    ...updatedValues
                };
            }


        },
        updateFindingsViewer: (state, action) => {
            //console.log("updateFindingsViewer Slice payload", action.payload)
            const { seriesUid } = action.payload;
            const existingObjectIndex = state.findings.findIndex(obj => obj.seriesUid === seriesUid);
            //console.log("existingObjectIndex",existingObjectIndex)
        },
        addfindings: (state, action) => {
            //console.log("addfindings payload", action.payload)
            const { id } = action.payload;
            const existingObjectIndex = state.findings.findIndex(obj => obj.id === id);

            if (existingObjectIndex === -1) {
                state.findings.push(action.payload);
            }
        },
        deletefindings: (state, action) => {
            const { id } = action.payload;
            const existingObjectIndex = state.findings.findIndex(obj => obj.id === id);

            if (existingObjectIndex !== -1) {
                state.findings.splice(existingObjectIndex, 1);
            }
        },
        resetReport: (state, action) => {
            //console.log("resetfindings", action.payload)
            state.report.state = '<p>Write your <strong>Main Findings</strong> here.</p>'
        },
        updateReportState: (state, action) => {
            //console.log("updateReportState Slice payload", action.payload)
            state.report.state = action.payload
        },
        updateReportRaw: (state, action) => {
            //console.log("updateReportRaw Slice payload", action.payload)
            state.report.raw = action.payload
        },
        updateActiveStudiesInViewer: (state, action) => {
            const { ActiveContainer, SeriesInstanceUID } = action.payload;
            //console.log("updateActiveStudiesInViewer Slice payload", ActiveContainer, SeriesInstanceUID)            
            state.ActiveStudiesInViewer[SeriesInstanceUID] = ActiveContainer
        },
        resetActiveStudiesInViewer: (state, action) => {
            state.ActiveStudiesInViewer = {}
        },

        updateCurrentInstanceOpenedInViewer: (state, action) => {
            const { containerId, instanceUID, SeriesInstanceUID, study , DefaultActiveContainer} = action.payload;
            console.log("updateCurrentInstanceOpenedInViewer Slice payload",instanceUID,SeriesInstanceUID, study)            
            // state.CurrentInstanceOpenedInViewer[SeriesInstanceUID]={
            //     instanceUid:instanceUid,
            //     containerId:containerId
            // }
            state.CurrentInstanceOpenedInViewer[containerId] = {
                DefaultActiveContainer:DefaultActiveContainer,
                study:study,
                SeriesInstanceUID: SeriesInstanceUID,
                instanceUID: instanceUID
            }
        },
        removeColumnCurrentInstanceOpenedInViewer: (state, action) => {

            
            const { column } = action.payload;
            console.log("removeColumnCurrentInstanceOpenedInViewer redux", column)
            const newState = { ...state };
            Object.keys(newState.CurrentInstanceOpenedInViewer).forEach(key => {
                const [, col] = key.split('-').slice(2);
                if (parseInt(col) > column) {
                    delete newState.CurrentInstanceOpenedInViewer[key];
                    console.log("DELETED", key)
                }
            });
            state.CurrentInstanceOpenedInViewer = newState.CurrentInstanceOpenedInViewer;
        },

        removeRowCurrentInstanceOpenedInViewer: (state, action) => {
            const { row } = action.payload;
            console.log("removeRowCurrentInstanceOpenedInViewer redux", row)
            const newState = { ...state };
            Object.keys(newState.CurrentInstanceOpenedInViewer).forEach(key => {
                console.log(key)
                const [viewport, r] = key.split('-').slice(1);
                console.log(viewport, r)
                if (parseInt(r) > row) {
                    delete newState.CurrentInstanceOpenedInViewer[key];
                    console.log("DELETED", key)
                }
            });
            state.CurrentInstanceOpenedInViewer = newState.CurrentInstanceOpenedInViewer;

        },
        resetCurrentInstanceOpenedInViewer: (state, action) => {
            state.CurrentInstanceOpenedInViewer = {}
        },




    }
})


export const { SterlizedCases,removeColumnCurrentInstanceOpenedInViewer, removeRowCurrentInstanceOpenedInViewer, resetCurrentInstanceOpenedInViewer, updateCurrentInstanceOpenedInViewer, resetActiveStudiesInViewer, updateActiveStudiesInViewer, updateFindingsViewer, resetReport, updateReportRaw, bulkUpdateFindings, resetfindings, updateReportState, deletefindings, updateUNSterlizedCases, updateSterlizedCases, updateSterlizedCasesComments, addfindings, updateAIfindings, updatefindings } = casesSlice.actions;
const casesReducer = casesSlice.reducer;
export default casesReducer;
