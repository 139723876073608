import React from 'react';
import { Grid, Paper, CardContent, Typography } from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

import { tokens } from '../../../utilityComponents/theme';
import { StyledPaper } from '../forms/formcomponenets';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     height: '200px',
//     padding: theme.spacing(2),
//     textAlign: 'center', // Center text within the Paper component
//   },
//   label: {
//     textAlign: 'left', // Align text to the left
//   },
// }));

// const StyledPaper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   height: '200px',
//   padding: theme.spacing(2),
//   textAlign: 'center',
// }));

// const StyledLabel = styled('div')(({ theme }) => ({
//   textAlign: 'left',
// }));



const FourCards = ({ labels, values, colors }) => {
  //const classes = useStyles();
  const themecolors = tokens("dark");
  

  return (
    <Grid container spacing={3} margin={"20px 10px 20px 10px"}>
      {labels.map((label, index) => (
        <Grid key={label} item xs={12} sm={6} md={3}>
          <StyledPaper elevation={3} style={{ backgroundColor: colors[index] }} 
          //className={classes.StyledPaper}
          >
            <CardContent>
              <Typography variant="h5" color="primary" 
              sx={{ textAlign: 'left'}}
              //className={classes.label}
              >{label}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h3" color={themecolors.dark_grey[400]} >{values[index]}</Typography>
            </CardContent>
          </StyledPaper>
        </Grid>
      ))}
    </Grid>
  );
}

export default FourCards;
