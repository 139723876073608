import React from 'react'

export function AssignCase2User(StudyInstanceUID, initiator,reciever,status) {
    console.log("trigger AssignCase2User", StudyInstanceUID, initiator,reciever,status)

const values = {
    "StudyInstanceUID": StudyInstanceUID,
    "status":status,
    "initiator": initiator,
    "reciever":reciever}


var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"values":  values});
console.log("FROM API", values)

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(global.config.api_get_case_status, requestOptions)

}
