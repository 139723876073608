export default function DELETEanonymizationAPI(study,studyID){
    console.log("from DELETEanonymizationAPI", study,studyID)
    const values = {
       "StudyInstanceUID": study["StudyInstanceUID"],
       "studyID":studyID
    }
    //StudyInstanceUID
    console.log("DELETEanonymizationAPI", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.api_anonymization_delete, requestOptions)

}
