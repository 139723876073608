import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import GetWorkspaceUsers from '../api/workspace/getUsers';

// // Function to fetch avatar from API or cache
// async function getAvatar(url) {
//     try {
//       const response = await fetch(url);
//       if (response.ok) {
//         const avatarBlob = await response.blob();
//         return URL.createObjectURL(avatarBlob);
//       } else {
//         throw new Error('Failed to fetch avatar');
//       }
//     } catch (error) {
//       console.error('Error fetching avatar:', error);
//       return null; // Return null if fetching fails
//     }
//   }

//   // Fetch avatars and save them in Redux store on application startup
// const avatarUrls = ['https://example.com/avatar1.jpg', 'https://example.com/avatar2.jpg'];
// avatarUrls.forEach(async (url) => {
//   try {
//     const avatarSrc = await getAvatar(url);
//     if (avatarSrc) {
//       store.dispatch(fetchAvatarSuccess(url, avatarSrc));
//     } else {
//       store.dispatch(fetchAvatarFailure(url, 'Failed to fetch avatar'));
//     }
//   } catch (error) {
//     store.dispatch(fetchAvatarFailure(url, error.message));
//   }
// });


  
  export default function GetAndStoreAvatars() {
    const [teammembers, setTeamMembers] = useState([])
    const [workspaceDetails, setWorkspaceDetails] = useState({
        WorkspaceName:"",
        WorkspaceContactPerson:"",
        WorkspaceEmail:""
      })
    const user = useSelector((state)=>state.user)

    useEffect(() => {
        console.log("loading avatars from GetAndStore")
        GetAvatars(user)
    }, [])
    
    function GetAvatars(user){
        GetWorkspaceUsers(user["userID"], user["organisationid"])
          .then(response => response.text())
          .then(result => {
            const respond = JSON.parse(Object.assign(result))
            console.log("Users in My workspace", respond["statusCode"])
            console.log("Users in My workspace", respond)
    
            if (respond["statusCode"] === 200) {
              console.log("Mapping Users in My workspace", respond["statusCode"])
              respond["body"].map((member) => {
                // assign Avatars and Name to teams
                const memberName = member.firstname + ' ' + member.lastname
                const Useravatar = global.config.api_get_users_avatar + "?userId=" + member.employeeid
                Object.assign(member, { "memberName": memberName });
                Object.assign(member, { "Useravatar": Useravatar });
               
                if (member.role === "Owner") {
                  setWorkspaceDetails({...workspaceDetails, WorkspaceName:member.name, WorkspaceContactPerson:memberName, WorkspaceEmail:user["userEmail"],WorkspaceID:user["organisationid"]})
                }
              })
    
              //console.log("Mapping Users in My workspace transoformed", respond["body"])
              setTeamMembers(respond["body"])
              console.log(respond["body"])
            } 
    
    
          })
          .catch(error =>
            {console.log(error)    
          }
          );
    }


  }
  