export default function changeMemberAPI(teammember) {
   
    console.log("changeMemberAPI",teammember)
    const values = {
        "WorkspaceRole":teammember["role"],
        "WorkspaceOwnerID":teammember["ownerid"],
        "WorkspaceStatus":teammember["status"],
        "WorkspaceID":teammember["organisationid"],
        "WorkspaceRights": teammember["rights"],
        "employeeid":teammember["employeeid"],
    }

    console.log("changeMemberAPI Request values", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "values": values });
    //console.log("FROM API", raw)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(global.config.update_user_in_workspace, requestOptions)

}