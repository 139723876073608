import React from 'react'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ColorModeContext, tokens, themeSettings } from "../../../../utilityComponents/theme";
import { ROI}  from "../../../../utilityComponents/images/viewerIcons";
import { MenuListImageTools } from './MenuLists';
import { usePopupState, bindHover,bindMenu,} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export function RoiMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  function BoundryBox(){
    viewportsCore.setActiveSegmentingAnnotationToolId('bounding-box-2d');
  }

  const MenuListImageTools=[
    {
        icon: <HighlightAltOutlinedIcon fontSize="small" />,
        name:"Boundry Box",        
        shortcut:"⌘B",
        functionname:()=>BoundryBox()
    },
    {
      icon: <CircleOutlinedIcon fontSize="small" />,
      name:"Circle",        
      shortcut:"⌘C",
      functionname:props.customFunction
  },
    {
        icon: <PolylineOutlinedIcon fontSize="small" />,
        name:"Polyline",        
        shortcut:"⌘P",
        functionname:props.rotate
    },
    /* {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    },
    {
        icon: <ContentCut fontSize="small" />,
        name:"TEXT",        
        shortcut:"⌘Z",
        functionname:props.customFunction
    } */
]
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...bindHover(popupState)}
      >
        <ROI fontSize='large'/>
      </IconButton>
      <HoverMenu
        {...bindMenu(popupState)}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >

        {MenuListImageTools.map((Item,index)=>(
            <MenuItem onClick={Item.functionname} sx={{width: "220px"}} key={index}>
          <ListItemIcon>
            {Item.icon}
          </ListItemIcon>
          <ListItemText>{Item.name}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {Item.shortcut}
          </Typography>
        </MenuItem>
        )) }
        
      </HoverMenu>
    </div>
  );
}