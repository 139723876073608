import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';

import Container from "@mui/material/Container";
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import {ColorModeContext, tokens, themeSettings} from "../../../utilityComponents/theme";
import {registerNewAi} from "../../../functions/api/ai/registerNewAi";
import { useEffect } from "react";
import {RegisterNewOrganisationAPI, GetOrganisationAPI} from "../../../functions/api/organisational/registerNewOrganisation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledForm, StyledPaper, StyledTextField } from "../../subComponents/forms/formcomponenets";


let RigesterOrganisationSchema = yup.object().shape({
  //id: yup.string().required().nullable(false),
  name: yup.string().required().nullable(false),
  address: yup.string().required().nullable(false),
  contactperson: yup.string().required().nullable(false),
  email: yup.string().email().required().nullable(false),
  licenseType: yup.string().required().nullable(false),
  licenseValidity: yup.string().required().nullable(false),
  licenseStatues: yup.string().required().nullable(false),

});;

// const useStyles = makeStyles(theme => ({
//   paper: {
//     //marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center"
//   },

//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(3)
//   },
//   submit: {
//     //margin: theme.spacing(3, 0, 2)
//   },
//   textField: {
//     backgroundColor:"black",
//     "& .MuiFormHelperText-root":{
//       backgroundColor: "transparent"
//     },
//     ":&.hover":{
      
//     }
//   }

// }));


// const StyledPaper = styled(Paper)({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   // Add any additional styles here
// });

// const StyledForm = styled('form')({
//   width: '100%',
//   marginTop: 'theme.spacing(3)', // You need to replace 'theme.spacing(3)' with the actual value you want to use
//   // Add any additional styles here
// });

// const StyledTextField = styled(TextField)({
//   backgroundColor: 'black',
//   '& .MuiFormHelperText-root': {
//     backgroundColor: 'transparent',
//   },
// });


export const RigesterOrganisationForm = () => {
  //const classes = useStyles();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const fieldBackgroundColor= colors.greenFlow[200]
  const [bodyexaminationpart, setBodyexaminationpart] = useState('');
  const [id, setID] = useState('');

  const Sucessfullynotify = () => toast.success('Your data has been successfully updated!', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });

  const Errornotify = () => toast.error('Could not update your data, please try again.', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });


  const InputFieldStyle = {
    borderRadius:"10px",
  backgroundColor:fieldBackgroundColor
  }

  useEffect(() => {
setID(localStorage.getItem("organisationID"))
  }, [])
  
  function sendToAPI(values){
    RegisterNewOrganisationAPI(values)
  .then(response => {

    if (response.status ===200){
      console.log("success")
      Sucessfullynotify()
    }else{
      console.log("failed")
      Errornotify()
    } 
  })
  .catch(error =>{ console.log(error)});

  }

  const licenseType=[
    {"value":"Individual", "label":"Individual"},
    {"value":"Clinic", "label":"Clinic"},
    {"value":"Hospital", "label":"Hospital"},
  ]

  const licenseValidity=[
    {"value":"Monthly", "label":"Monthly"},
    {"value":"Annual", "label":"Annual"},
    {"value":"PerCase", "label":"Pay-per-use"},
  ]

  const licenseStatues=[
    {"value":"Active", "label":"Active"},
    {"value":"Deactivate", "label":"Deactivate"},
  ]


  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper //className={classes.paper}
      >

        <Formik
          initialValues={{
            //id: id,
            name: 'organisation',
            address: 'address',
            contactperson: 'ContactPerson',
            email: 'email@email.com',
            license_type: '1',
            license_validity: 'pay-per-use',
            license_statues: 'active',
           
          }}
          validationSchema={RigesterOrganisationSchema}
          onSubmit={values => {
            
            Object.assign(values,{"id":id})
            //console.log("FORMIK", values)
            sendToAPI(values)
            
          }}
        >
          {({ errors, isValid, handleChange, touched }) => (
            <StyledForm 
            //className={classes.form}
            >
              <Grid container spacing={2}>

              {/*   <Grid item xs={12} sm={12}>
                  <OutlinedInput
                    className = {classes.textField}
                    error={errors.id && touched.id}
                    autoComplete="id"
                    name="id"
                    variant="outlined"
                    disabled="true"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="id"
                    placeholder={id}
                    sx={InputFieldStyle}
       
                  />
                </Grid> */}

                <Grid item xs={12} sm={12}>
                  <StyledTextField
                    //className = {classes.textField}
                    error={errors.name && touched.name}
                    autoComplete="name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="name"
                    placeholder="Organisation Name"
                    sx={InputFieldStyle}
       
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <StyledTextField
                    //className = {classes.textField}
                    error={errors.address && touched.address}
                    autoComplete="address"
                    name="address"
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="address"
                    placeholder="Organisation Address"
                    //autoFocus
                    sx={InputFieldStyle}
  
                  />
                </Grid>

                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.contactperson && touched.contactperson}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="contactperson"
                    placeholder="Contact Person"
                    name="contactperson"
                    autoComplete="contactperson"
                    sx={InputFieldStyle}
                  />
                </Grid>

                <Grid item xs={12}>
                  <OutlinedInput
                    error={errors.email && touched.email}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                    sx={InputFieldStyle}
                  />
                </Grid>


                <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">License Type</InputLabel>
                  <Field name="licenseType" component={CustomizedSelectForFormik}>
                    
                     {licenseType.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>

                <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">license Validity</InputLabel>
                  <Field name="licenseValidity" component={CustomizedSelectForFormik}>
                    
                     {licenseValidity.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>
                

                <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">license Statues</InputLabel>
                  <Field name="licenseStatues" component={CustomizedSelectForFormik}>
                    
                     {licenseStatues.map((item,index)=>(
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
               
                  </Field>
                </FormControl>
                </Grid>


                             
                <Grid item xs={12}>

                  <Button 
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isValid}

                  sx={{
                    backgroundColor:colors.greenFlow[100],
                    ":hover":{
                      backgroundColor:colors.greenFlow[100],
                    }
                  }}
                  //onClick={handleSignIN}
                  size="large" 
              //variant="contained"
              >
                <Typography
                  sx={themeStyles.typography.h5}
                  color={"white"}
                >
                  Submit
                </Typography>
              </Button>

                   
                  </Grid>
                    <Typography>{errors.id}</Typography>
                    <Typography>{errors.name}</Typography>
                    <Typography>{errors.address}</Typography>
                    <Typography>{errors.contactperson}</Typography>
                    <Typography>{errors.email}</Typography>
                    <Typography>{errors.licenseType}</Typography>
                    <Typography>{errors.licenseValidity}</Typography>
                    <Typography>{errors.licenseStatues}</Typography>


              </Grid>

              
            </StyledForm>
          )}
        </Formik>
        <Typography
                  sx={themeStyles.typography.h6}
                  color={"grey"}
                >
                  Your Organisation ID: <br/>
                   {id}
        </Typography>
      </StyledPaper>
      <ToastContainer position="bottom-center"
        autoClose={3000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
    </Container>
  );
};
