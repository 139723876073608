import React, { useState, Suspense, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';

import Container from "@mui/material/Container";
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { Box, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import {ColorModeContext, tokens, themeSettings} from "../../../utilityComponents/theme";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RegisterNewTeamAPI , GetTeamMembers, GetTeamMembersAPI} from "../../../functions/api/organisational/registerTeam";
import TeamMembersAvatar from "./TeamMembers";
import Stack from '@mui/material/Stack';
import { StyledPaper } from "../../subComponents/forms/formcomponenets";



let RigesterTeamMemberSchema = yup.object().shape({
  firstname: yup.string().required().nullable(false),
  lastname: yup.string().required().nullable(false),
  email: yup.string().email().required().nullable(false),
  role: yup.string().required().nullable(false),
  rights: yup.string().required().nullable(false),

});;

// const useStyles = makeStyles(theme => ({
//   paper: {
//     //marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center"
//   },

//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(3)
//   },
//   submit: {
//     //margin: theme.spacing(3, 0, 2)
//   },
//   textField: {
//     backgroundColor:"black",
//     "& .MuiFormHelperText-root":{
//       backgroundColor: "transparent"
//     },
//     ":&.hover":{
      
//     }
//   }

// }));




export const ViewerSettings = () => {
  //const classes = useStyles();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");
  const fieldBackgroundColor= colors.greenFlow[200]
  const [teamMembers, setTeamMembers] = useState([]);
  const [orgaid, setOrgaID] = useState('');
  
  //const resource = GetTeamMembers(localStorage.getItem("organisationID"));

  const Sucessfullynotify = () => toast.success('Your Team members have been successfully updated!', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });

  const Errornotify = () => toast.error('Could not update your team, please try again.', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    style: {
      width: "400px"
    }
  });


  const InputFieldStyle = {
    borderRadius:"10px",
  backgroundColor:fieldBackgroundColor
  }
  
  function sendToAPI(values){
    RegisterNewTeamAPI(values)
  .then(response => {

    if (response.status ===200){
      console.log("success")
      Sucessfullynotify()
      GetTeamMembers(orgaid)
    }else{
      console.log("failed")
      Errornotify()
    } 
  })
  .catch(error =>{ console.log(error)});
//  GetTeamMembers(orgaid)

  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper>
          <Box width={"100%"} justifyContent={"center"} display="flex" >
          <span>
          <Typography
            sx={themeStyles.typography.h4}
            color={"white"}
          >
            Your team members: <br/>
          </Typography>
          </span>

           {teamMembers?
            <Stack direction="row" spacing={2}>
           <TeamMembersAvatar teamMembers={teamMembers}  />
           </Stack>
           :""} 
          </Box>

      
      <Divider/>
</StyledPaper>
      <ToastContainer position="bottom-center"
        autoClose={3000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
    </Container>
  );
};
