
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import { tokens } from '../../../../../utilityComponents/theme';


const colors = tokens("dark");
const findingColor = colors.novuColors[110]
const iconColor = findingColor

export const MeasurmentsActions=[
    {
      icon: <CircleOutlinedIcon sx={{color:iconColor}} fontSize="small" />,
      name:"Oval",        
      shortcut:"⌘P",
      functionname:()=>handleMeasurementChange('oval')
  },
    // {
    //     icon: <HorizontalRuleOutlinedIcon sx={{color:iconColor}} fontSize="small" />,
    //     name:"Distance",        
    //     shortcut:"",
    //     functionname: ()=>handleMeasurementChange('ruler')
    // },
    {
      icon: <ChangeHistoryOutlinedIcon sx={{color:iconColor}} fontSize="small" />,
      name:"Angle",        
      shortcut:"",
      functionname:()=>handleMeasurementChange('angle')
    },
    {
      icon: <PolylineOutlinedIcon sx={{color:iconColor}} fontSize="small" />,
      name: "Polyline",
      shortcut: "",
      functionname: () => handleMeasurementChange('polyline')
    },
    {
      icon: <RectangleOutlinedIcon sx={{color:iconColor}} fontSize="small" />,
      name: "Area",
      shortcut: "",
      functionname: () => handleMeasurementChange('area')
    }]

    function handleMeasurementChange(tool){
        global.viewportsCore.setMouseButtonFunction(1, 5);
            global.viewportsCore.measurementsController.setActiveMeasurementType(tool);
      }
    

