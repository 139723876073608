export function GetCommentAPI(Item) {
  const user = JSON.parse(localStorage.getItem('user'))

  const values={
    "StudyInstanceUID": Item["StudyInstanceUID"],
    "user_id":user["userID"],
    "organisationid":user["organisationid"],
    }


    console.log("FROM SendComment API", values)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"values": values});
    //console.log("FROM API", raw)
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch(global.config.api_get_comment, requestOptions)
    
    }