//React components
import React, { useState, useEffect, useRef } from "react";

//MUI Components
import { Box, Divider, IconButton, Table, TableContainer, Toolbar } from "@mui/material";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from "@mui/material/styles";
import { styled } from '@mui/system';

//import { makeStyles } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {  Card, CardContent } from '@mui/material';


// Custom Styles
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";

// import data and charts
import { pieData } from './demodata/pieData'
import { PieChart } from './charts/pieChart'
import BarChartKPI from "./charts/BarChart";
import FourCards from "../../subComponents/4cards";

//
//
// Components STYLINGs START
//
//
//
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    margin: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duexxampleration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,

    }),
  })
);


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     height: '200px',
//     padding: theme.spacing(2),
//     textAlign: 'center', // Center text within the Paper component
//   },
//   label: {
//     textAlign: 'center', // Align text to the left
//   },
//   UploadBtnStyle: {
//     backgroundColor: colors.dark_grey[400],
//     borderColor: 'transparent',
//     color: colors.greenFlow[100],
//     "border-radius": "5px",
//     '&:hover': {
//       backgroundColor: 'transparent',
//       //color: '#3c52b2',
//     },
//   },
//   CustomOverlayStyle: {
//     color: colors.dark_grey[200],
//     marginTop: "300"
//   }
// }));



//
//
// Components STYLINGs END
//
//
//


const Performance = () => {
  //Styles 
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark")
  const webRef = useRef(null);
  const ref = React.useRef();
  const user = JSON.parse(localStorage.getItem("user"))
  const token = sessionStorage.getItem("Meteor.loginToken")
  const cardscolors = ['#FFC107', '#03A9F4', '#4CAF50', '#FF5722'];
  //const classes = useStyles();
  // Example figures
  const totalCases = 100;
  const averageTimePerCase = 20;
  const installedApps = 20;
  const totalRad = 15;

  const modelPerformanceLabels = ['Total Cases', 'Average Time per Case', 'Installed AI Apps', 'Total Revenue'];
  const utilizationLabels = ['Total Patients', 'Average Time per Patient', 'Installed AI Apps', 'Total Revenue'];
  const downtimeLabels = ['Total Downtime', 'Average Downtime per Incident', 'Installed AI Apps', 'Total Revenue'];


  return (

    <Box
      //sx={boxstyles} 
      id={"ChatBox"}
      sx={{ display: "flex" }}
    >
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main styled={{ backgroundColor: "inherit" }}
        id={"MainContentChat"}>
        <Toolbar variant="dense" />
        <Box backgroundColor="inherit"
        id="box after main"
          //sx={{ margin: "-24px -24px -24px 24px" }}
        >
          {/*     //
          //
          // START ADDING ALL PAGE CONTENTS HERE
          //
          // 
  */}

          <Box backgroundColor="inherit"
          //justifyContent={"space-between"} display="flex" alignItems={"center"}
            sx={{ margin: "5.5% 24px 4% 24px" }}
          >
            <Typography
              align="center"
              //onClick={()=>setChecked(true)}
              color={colors.text[500]}
              sx={themeStyles.typography.h21}
              >
              Radiologists Business Intelligence and QA
            </Typography>
          </Box>

          <Grid container spacing={3} display={"flex"} 
          //margin={"20px 20px 20px 20px"}
          alignContent={"center"} alignItems={"center"}>

          <FourCards
           labels={modelPerformanceLabels}
           values={[totalCases, averageTimePerCase,installedApps,totalRad]}
          colors={['#FFC107', '#03A9F4', '#4CAF50', '#FF5722']}
          /> 
          <Divider/>
            
            <Grid item xs="12" xl="6" justifyContent={"center"} 
            //display={"flex"}
             >
            <Typography variant="h5" color="secondary" sx={{textAlign: 'center'}}
            //className={classes.label}
            >Detailed summary for Cases</Typography>

              <Box height="500px">

                <PieChart data={pieData} />
              </Box>
              </Grid>
              <Grid item xs="12" xl="6">
              <Typography variant="h5" color="secondary" sx={{textAlign: 'center'}}
              //</Grid>className={classes.label}
              >Average Time per Casesc Break-down</Typography>

                <Box height="500px">
                  <BarChartKPI />
                </Box>
              </Grid>
            </Grid>

  





            {/*     //
          //
          // STOP PAGE CONTENTS HERE
          //
          // 
  */}
        </Box>
      </Main >
    </Box >


  )
}

export default Performance;