import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {  Typography } from '@mui/material'
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";


const colors = tokens("dark");
const themeStyles = themeSettings("dark")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
     // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '10%',
      //backgroundColor:colors.dark_grey[400],
      boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25);'
    },
  },
};

const names = [
  'Receptionist',
  'Clinician',
  'Radiologist',
  'Head of Department',
  'Admin',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Selector({options,value,placeholder}) {
  const theme = useTheme();
 

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {

    const {
      target: { value },
    } = event;

    //console.log(value)
    setPersonName( typeof value === 'string' ? value.split(',') : value,);
  };

  return (
        <Box sx={{
            width:"inherit",
            height:"inherit"
        }}>
      <FormControl sx={{ 
        //m: 1, 
        //width: 200, 
        //mt: 3
     }}
        >
        <Select
          sx={{ 
            width:"100%",
            boxShadow: 'none', 
            '.MuiOutlinedInput-notchedOutline': { border: 0 }, 
            "& .MuiSvgIcon-root": {color: "transparent"},
        }}
          //multiple={"false"}
          displayEmpty
          //defaultValue={value}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              //return <em>{placeholder}</em>;
              return(
                <Box >
                <Typography
                    align="center"
                    //sx={themeStyles.typography.h60}
                    //color={colors.dark_grey[300]}
                    
                    >
                    {placeholder==="Roles"?value:
                    placeholder==="Status"?value:
                    value?"Allow":"Deny"}
                  </Typography>
                  </Box>
                  
              )
            }

            return selected.join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          /* renderValue={(selected) => (
            <Box sx={{ display: 'flex', justifyContent:"center", flexWrap: 'wrap' }}>
             {selected.map((value) => (
               <Chip key={value} label={value} />
             ))}
           </Box>  
          )}*/

        >

{/*           <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem> */}
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              <Box height="36px" width="100%" borderRadius={"2px"} alignItems={"center"} display="flex" justifyContent={"center"}
              backgroundColor={name==="Allow"?colors.novuColors[800]:
                              name==="Deny"?colors.novuColors[100]:colors.dark_grey[500]}
                              border={name!=="Allow"||name!=="Deny"?'1px solid #4D4D4D':""}
                              >
              <Typography
                    align="center"
                    sx={themeStyles.typography.h8}
                    color={colors.text[100]}
                    
                    >
                  {name}
                  </Typography>
              
              </Box>

            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Box>

  );
}